import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import DrawerWorkflowTab from "./DrawerWorkflowTab";

export default function DrawerWorkflowTabs({ isMapLoading }) {
  const { workflow, setWorkflow } = useContext(ApplicationStateContext);

  return (
    <WorkflowTabContainer>
      <TabContainer>
        <DrawerWorkflowTab
          isActive={workflow === "owned" ? true : false}
          isDisabled={isMapLoading}
          tabColor="var(--blue)"
          handleClickEvent={() => setWorkflow("owned")}
        >
          Owned
        </DrawerWorkflowTab>

        <DrawerWorkflowTab
          isActive={workflow === "opportunities" ? true : false}
          isDisabled={isMapLoading}
          tabColor="var(--darkGreen)"
          handleClickEvent={() => setWorkflow("opportunities")}
        >
          Opportunities
        </DrawerWorkflowTab>

        <DrawerWorkflowTab
          isActive={workflow === "potential" ? true : false}
          isDisabled={isMapLoading}
          textColor="var(--black)"
          tabColor="var(--darkYellow)"
          handleClickEvent={() => setWorkflow("potential")}
        >
          Total Potential
        </DrawerWorkflowTab>
      </TabContainer>
    </WorkflowTabContainer>
  );
}

DrawerWorkflowTabs.propTypes = {
  isMapLoading: PropTypes.bool.isRequired
};

const WorkflowTabContainer = styled.div`
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`;

const TabContainer = styled.div`
  display: flex;
  padding-right: 80px;
`;
