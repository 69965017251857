import React, { useState, useEffect } from "react";
import IconCustomer from "../icons/IconCustomer";
import IconLocation from "../icons/IconLocation";
import IconLane from "../icons/IconLane";
import IconExcel from "../icons/IconExcel";
import useFormIconSize from "./useFormIconSize";
// sizeToAssign can be "small, medium, large"

export default function useFormIcon(iconToAssign, sizeToAssign) {
  const assignedSize = useFormIconSize(iconToAssign, sizeToAssign);
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    function assignIcon() {
      switch (iconToAssign) {
        case "Customer":
          setIcon(<IconCustomer size={assignedSize} />);
          break;
        case "Location":
          setIcon(<IconLocation size={assignedSize} />);
          break;
        case "Lane":
          setIcon(<IconLane size={assignedSize} />);
          break;
        case "Upload":
          setIcon(<IconExcel />);
          break;
        default:
          setIcon("");
          break;
      }
    }

    assignIcon();
  }, [assignedSize, iconToAssign, sizeToAssign]);

  return icon;
}
