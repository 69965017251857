import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { Storage } from 'aws-amplify'
import MyMessage from './MyMessage';
import OtherMessage from './OtherMessage';

export default function({ message, setMessages, setFilterTerms }) {
    const currentUser = JSON.parse(sessionStorage.getItem("user"))
    const [profileImage, setProfileImage] = useState(null)

    const formatDate = (iso) => {
        return format(new Date(iso), 'k:mm EEE M/d/yy')
    }

    useEffect(() => {
        if(message.User.profileImage) {
            (async () => {
                const image = await Storage.get(message.User.profileImage)
                setProfileImage(image)
            })()
        }
    }, [])

    if(currentUser.id === message.User.id) {
        return <MyMessage message={message} profileImage={profileImage} setMessages={setMessages} formatDate={formatDate} setFilterTerms={setFilterTerms} />
    }
    else {
        return <OtherMessage message={message} formatDate={formatDate} profileImage={profileImage} />
    }
}
