import React, { useContext, useEffect, useState } from 'react'
import './Ledger.css'
import { LedgerContext } from '../../providers/LedgerProvider';
import LedgerContainer from './LedgerContainer';

export default function UserLedger({ user }) {
    const { getMessages } = useContext(LedgerContext)
    const [messages, setMessages] = useState([])

    useEffect(() => {
        getMessages(user.ledgerId)
            .then(ledger => {
                if(ledger.Messages) {
                    setMessages(ledger.Messages)
                }
            })
    }, [])

    return <LedgerContainer messages={messages} ledgerId={user.ledgerId} setMessages={setMessages} placeholder={user.firstName}/>
}