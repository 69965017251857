import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SearchContext } from '../../providers/SearchProvider'

const SearchResultCustomerLocation = ({result, id}) => {
    const { closeSearch } = useContext(SearchContext)
    const history = useHistory()

    const handleClick = () => {
        closeSearch()
        history.push(`/location/${result._source.id}`)
    }
    
    return (
        <div className={`search__results__item`} id={id} onClick={handleClick}>
        <span className='search__results__emoji' role='img' aria-label='x'>📍</span>
        {result._source.city}, {result._source.state}  - {result._source.customerName}
        <span className='search__results__tag tag tag--purple'>Location</span>
        </div>
    )
}

export default SearchResultCustomerLocation