import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DrawerSectionHeader from "./DrawerSectionHeader";
import DrawerContactsList from "./DrawerContactsList";
import DrawerContactsForm from "./DrawerContactsForm";
import useContacts from "../../hooks/useContacts";

export default function DrawerContacts({ page, item }) {
  const {
    contacts,
    levelOneContacts,
    levelTwoContacts,
    levelThreeContacts
  } = useContacts(page, item);

  const [contactToEdit, setContactToEdit] = useState(null);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  return (
    <ContactContainer>
      <DrawerSectionHeader
        hasUnderline
        isEditing={isEditActive}
        setIsEditing={contacts.length === 0 ? null : setIsEditActive}
        setIsAdding={setIsFormModalOpen}
      >
        Contact Hierarchy
      </DrawerSectionHeader>

      <DrawerContactsList
        page={page}
        levelOneContacts={levelOneContacts}
        levelTwoContacts={levelTwoContacts}
        levelThreeContacts={levelThreeContacts}
        isEditActive={isEditActive}
        setIsFormModalOpen={setIsFormModalOpen}
        setContactToEdit={setContactToEdit}
      />

      <DrawerContactsForm
        page={page}
        item={item}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        setIsEditActive={setIsEditActive}
        contactToEdit={contactToEdit}
        setContactToEdit={setContactToEdit}
      />
    </ContactContainer>
  );
}

DrawerContacts.propTypes = {
  page: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
};

const ContactContainer = styled.section`
  margin: 3rem 5px 0 0;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;
