import React from 'react'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export default function HeaderButtonInvite({ setIsInviteOpen }) {
    return (
        <button className='headerButtonInvite__button' onClick={() => setIsInviteOpen(true)}>
            <PersonAddIcon style={{ fill: "white", width: "25px", height: "25px", marginRight: '5px' }}/>
            <div className='headerButtonInvite__button__text'>Invite Users</div>
        </button>
    )
}