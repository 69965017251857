import React, { useContext, useState } from "react";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import UpdateImage from "../../library/images/UpdateImage";
import SettingsCardTitle from "../SettingsCardTitle";

export default function ManageBrokerage({ brokerage, setBrokerage }) {
  const { updateBrokerage, getBrokerage } = useContext(BrokerageContext);
  const [name, setName] = useState(brokerage?.name);
  const [address, setAddress] = useState(brokerage?.address);
  const [address2, setAddress2] = useState(brokerage?.address2);
  const [city, setCity] = useState(brokerage?.city);
  const [state, setState] = useState(brokerage?.state);
  const [zip, setZip] = useState(brokerage?.zipcode);
  const [email, setEmail] = useState(brokerage?.email);
  const [phone, setPhone] = useState(brokerage?.phone);

  const handleInputChange = setter => e => {
    setter(e.target.value);
  };

  async function handleSubmit() {
    const updatedBrokerage = {
      id: brokerage.id,
      name: name,
      address: address,
      address2: address2,
      city: city,
      state: state,
      zipcode: zip,
      email: email,
      phone: phone
    };
    const result = await updateBrokerage(updatedBrokerage);
    const br = await getBrokerage(brokerage.id);
    setBrokerage(br);
  }

  if (!brokerage) {
    return null;
  }

  return (
    <>
      <SettingsCardTitle>Manage Brokerage</SettingsCardTitle>

      <div className="settings__profile">
        <div className="settings__profile__left">
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Name</div>
            <input
              className="settings__profile__input__field"
              placeholder="Brokerage"
              value={name}
              defaultValue={name}
              onChange={handleInputChange(setName)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Location</div>
            <input
              className="settings__brokerage__input__field"
              placeholder="Address 1"
              value={address}
              defaultValue={address}
              onChange={handleInputChange(setAddress)}
            />
            <input
              className="settings__brokerage__input__field"
              placeholder="Address 2"
              value={address2}
              defaultValue={address2}
              onChange={handleInputChange(setAddress2)}
            />
            <input
              className="settings__brokerage__input__field--half"
              placeholder="City"
              value={city}
              defaultValue={city}
              onChange={handleInputChange(setCity)}
            />
            <select
              className="settings__brokerage__select__field--quarter"
              value={state}
              defaultValue={state}
              onChange={handleInputChange(setState)}
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <input
              className="settings__brokerage__input__field--quarter"
              placeholder="Zipcode"
              value={zip}
              defaultValue={zip}
              onChange={handleInputChange(setZip)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Email</div>
            <input
              className="settings__profile__input__field"
              placeholder="Email"
              value={email}
              defaultValue={email}
              onChange={handleInputChange(setEmail)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Phone</div>
            <input
              className="settings__profile__input__field--half"
              placeholder="Phone"
              value={phone}
              defaultValue={phone}
              onChange={handleInputChange(setPhone)}
            />
          </div>
          <div className="settings__save click" onClick={handleSubmit}>
            Save
          </div>
        </div>
        <div className="settings__profile__right">
          <UpdateImage sourceType="Brokerage" source={brokerage} />
        </div>
      </div>
    </>
  );
}
