import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import UpdateImage from "../../library/images/UpdateImage";
import SettingsCardTitle from "../SettingsCardTitle";
// import {states} from "./utils";
import { CircularProgress } from "@material-ui/core";

export default function EditUser() {
  const history = useHistory();
  const { updateBrokerage, createBrokerage, updateUserFromCustomBrokerage, createUserFromCustomBrokerage } = useContext(BrokerageContext);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [fullName, setFullName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [emailValid, setEmailValid] = useState(false);
  const [phone, setPhone] = useState(user?.phone);
  const [clickedButton, setClickedButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [adminChecked, setAdminChecked] = useState(true);
  const [sysAdminChecked, setSysAdminChecked] = useState(false);

  useEffect(()=>{
    if(!!history.location.state && !!history.location.state.editing)
      setUser(history.location.state.user);
    else{
      setIsCreating(true);
      setIsLoading(false);
    }
  },[])

  useEffect(()=>{
    if(!!user){
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setFullName(user.fullName)      
      setEmail(user.email)
      setPhone(user.phone);
      setAdminChecked(user.admin);
      setSysAdminChecked(user.sysAdmin);
      setIsLoading(false);
    }
  },[user])

  useEffect(()=>{
    if(!!email){
      ValidateEmail(email);
    }
    else {
      setEmailValid(false);
    }
  },[email])

  const handleInputChange = setter => e => {
    setter(e.target.value);
  };

  async function handleSubmit() {
    const payload = {
      id: user?.id,
      brokerageId: isCreating?history.location.state.brokerageId:user.brokerageId,
      firstName: firstName,
      lastName: lastName,
      userName: fullName,
      email: email,
      phone: phone,
      admin: adminChecked,
      sysAdmin: sysAdminChecked,
    };
    setClickedButton(true);
    //try catch
    let response;
    try{
      if(!isCreating) await updateUserFromCustomBrokerage(payload);
      else response = await createUserFromCustomBrokerage(payload)
      if(response!= '409') history.push("/settings/usersSA");
    } catch(e){
      console.log('here error',e);
    }
    if(response == '409'){
      setClickedButton(false);
      setEmailError(true);

    }
  }

  function ValidateEmail(mail){
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        setEmailError(false);
        return setEmailValid(true)
    }
    return setEmailValid(false)
  }

  const handleCheckBoxChange = (setter,value) => () => {
    setter(!value);
  }

  if (isLoading) 
    return <CircularProgress
    color="secondary"
    style={{ width: "10em", height: "10em", margin:"0 29.2px" }}
  />
  

  return (
    <>
      <SettingsCardTitle>
        {isCreating?"Create":"Edit"} User
      </SettingsCardTitle>

      <div className="settings__profile">
        <div className="settings__profile__left">
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">First Name</div>
            <input
              className="settings__profile__input__field"
              placeholder="First Name"
              value={!firstName?"":firstName}
              // defaultValue={!name?"":name}
              onChange={handleInputChange(setFirstName)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Last Name</div>
            <input
              className="settings__profile__input__field"
              placeholder="Last Name"
              value={!lastName?"":lastName}
              // defaultValue={!name?"":name}
              onChange={handleInputChange(setLastName)}
            />
          </div>        
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Email</div>
            <input
              className="settings__profile__input__field"
              placeholder="Email"
              value={!email?"":email}
              disabled={!isCreating}
              onChange={handleInputChange(setEmail)}
            />
            {emailError && <div className="settings__profile__input__title" style={{color:'red'}}>Email already in use</div>}
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Phone</div>
            <input
              className="settings__profile__input__field--half"
              placeholder="Phone"
              value={!phone?"":phone}
              onChange={handleInputChange(setPhone)}
            />
          </div>
          <div className="admin__wrapper">
            <div className="settings__profile__input admin__wrapper">
              <div className="settings__profile__input__title admin__label">Admin</div>
              <input
                type="checkbox"
                value={adminChecked}
                checked={!!adminChecked}
                onChange={handleCheckBoxChange(setAdminChecked,adminChecked)}
              />
            </div>
            <div className="settings__profile__input admin__wrapper">
              <div className="settings__profile__input__title admin__label">Sys Admin</div>
              <input
                type="checkbox"
                value={sysAdminChecked}
                checked={!!sysAdminChecked}
                onChange={handleCheckBoxChange(setSysAdminChecked,sysAdminChecked)}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {clickedButton ? (
              <CircularProgress
                color="secondary"
                style={{ width: "3em", height: "3em", margin:"0 29.2px" }}
              />
            ) : (
              <div
                className={`settings__update ${!emailValid?'disabled':'click enabled'} manage_comp`}
                onClick={handleSubmit}
                disabled={!emailValid}
              >
                {isCreating?"Create":"Save"}
              </div>
            )}
            <div
              className="settings__save click manage_comp"
              onClick={() => history.push("/settings/usersSA")}
            >
              Back
            </div>
          </div>
        </div>
        <div className="settings__profile__right">
          <UpdateImage sourceType="User" source={user} />
        </div>
      </div>
    </>
  );
}
