import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { LaneContext } from "../providers/LaneProvider";
import { laneToastMessages } from "../generic/ToastMessages";
import { CircularProgress } from "@material-ui/core";
import useLanes from "../../hooks/useLanes";
import EditButton from "../generic/buttons/EditButton";
import DrawerLanesTable from "./DrawerLanesTable";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

export default function DrawerLanes({ view }) {
  const { workflow } = useContext(ApplicationStateContext);
  const { updateLane } = useContext(LaneContext);
  const [showEdit, setShowEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedLanes, setEditedLanes] = useState([]);
  const { customerId, locationId } = useParams();
  const { lanes, areLanesLoading } = useLanes(customerId ?? locationId, view);

  function setEditableLanes(editedLane) {
    const lanes = [...editedLanes];
    // If that item has already been edited, remove the old one
    const index = lanes.findIndex(lane => lane.id === editedLane.id);
    if (index !== -1) {
      lanes.splice(index, 1); // removes item from array
    }

    setEditedLanes(lanes.concat(editedLane));
  }

  const handleCancel = () => {
    setEditedLanes([]);
    setShowEdit(false);
  };

  async function handleSave() {
    try {
      setIsSubmitting(true);
      await Promise.all(editedLanes.map(lane => updateLane(lane, true)));
      laneToastMessages.updateMultipleSuccess();
      setEditedLanes([]);
      setShowEdit(false);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      laneToastMessages.updateMultipleFailure();
      console.error("DrawerLanes.js - handleSave(): ", error);
    }
  }

  return (
    <div className="lanes">
      <div className="titleContainer lanes__titleContainer">
        <div className="title lanes__title">Lanes</div>
        {showEdit ? (
          <div className="btnContainer lanes__btns">
            <div onClick={handleCancel} className="btn__cancel">
              Cancel
            </div>
            <button
              className="btn__save"
              onClick={handleSave}
              disabled={isSubmitting}
              style={{ width: "50px", height: "26px" }}
            >
              {isSubmitting ? (
                <CircularProgress
                  color="secondary"
                  style={{
                    width: "14px",
                    height: "14px"
                  }}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        ) : (
          <div className="lanes__edit" onClick={() => setShowEdit(true)}>
            <EditButton />
          </div>
        )}
      </div>

      <DrawerLanesTable
        lanes={lanes}
        workflow={workflow}
        showEdit={showEdit}
        setEditableLanes={setEditableLanes}
        isLoading={areLanesLoading}
      />
    </div>
  );
}

DrawerLanes.propTypes = {
  view: PropTypes.string.isRequired // customer, location
};
