import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@material-ui/core";
import DrawerToolbeltState from "./DrawerToolbeltState";
import { checkForNull } from "../generic/Utils";
import { useLocation } from "react-router";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import { toolbeltToastMessages } from "../generic/ToastMessages";
// Logic for handling & updating toolbelt items & state

export default function DrawerToolbeltItem({
  item,
  inputType,
  valueType,
  valueIsCurrency,
  updateMethod,
  nameOfValue,
  title,
  label,
  placeholder,
  infoButton
}) {
  const {
    setIsSubmittingToolbelt,
    setTriggerToolbeltSave,
    setClickedToolbeltTitle
  } = useContext(ApplicationStateContext);
  const location = useLocation();
  const [value, setValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  async function updateItem(key, value) {
    let updatedValue = null;

    if (value === "") {
      if (
        nameOfValue === "estimatedVolume" ||
        nameOfValue === "estimatedSpend" ||
        nameOfValue === "rate" ||
        nameOfValue === "potentialVolume" ||
        nameOfValue === "currentVolume"
      ) {
        updatedValue = 0;
      } else {
        updatedValue = null;
      }
    } else {
      updatedValue = value;
    }

    // TODO: Have the frontend display the error in the form input, in real-time
    if (
      nameOfValue === "estimatedVolume" ||
      nameOfValue === "estimatedSpend" ||
      nameOfValue === "rate" ||
      nameOfValue === "potentialVolume" ||
      nameOfValue === "currentVolume"
    ) {
      if (typeof value !== "number" && value.includes("-")) {
        toolbeltToastMessages.errorNoNegatives();
        return;
      }
    }

    // TODO: Have the frontend display the error in the form input, in real-time
    if (nameOfValue === "currentVolume" && value > item?.potentialVolume) {
      toolbeltToastMessages.errorNoOwnedGreaterThanPotential();
      return;
    }

    const newItem = { ...item };
    newItem[key] = updatedValue;

    // updateMethod options based on Customer, Location, Lane
    let option = null;
    if (newItem.name) {
      option = newItem.name; // Customers & Locations have names
    } else {
      option = false; // Lane needs false as its option
    }

    const response = await updateMethod(newItem, option);
    if (response !== null) {
      item[key] = updatedValue;
      setIsEditing(false);
      setTriggerToolbeltSave(false);
    }
  }

  async function triggerUpdate(e) {
    if (e !== undefined) {
      // If user clicked "Save" button,
      // do not re-open the form.
      // The next form to open is based on the clickedToolbeltTitle
      e.preventDefault();
      setClickedToolbeltTitle("");
    }
    // Check if the submit button is NOT disabled
    if (value !== null) {
      setIsSubmittingToolbelt(true);
      await updateItem(nameOfValue, value, setIsEditing);
    }
    setIsSubmittingToolbelt(false);
  }

  // Originally had this as being set with state & a useEffect
  // But that caused issues with the form input not updating correctly.
  // Moving it out of state, and setting it on initial render fixes issues
  function selectInputToRender(type) {
    const formInputs = {
      text: (
        <TextField
          style={{ backgroundColor: "white" }}
          id={nameOfValue}
          name={nameOfValue}
          value={value}
          onChange={e => setValue(e.target.value)}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          size="small"
          autoComplete="new-password"
          InputProps={
            valueIsCurrency && {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }
          }
        />
      ),
      multiline: (
        <TextField
          style={{ backgroundColor: "white" }}
          id={nameOfValue}
          name={nameOfValue}
          value={value}
          onChange={e => setValue(e.target.value)}
          label={label}
          placeholder={placeholder}
          multiline
          rows={6}
          autoComplete="new-password"
          variant="outlined"
          size="small"
        />
      ),
      number: (
        <TextField
          style={{ backgroundColor: "white" }}
          id={nameOfValue}
          name={nameOfValue}
          onChange={e => setValue(e.target.value)}
          label={label}
          value={value === 0 || value === "0" ? "" : value}
          variant="outlined"
          type="number"
          size="small"
          autoComplete="new-password"
          InputProps={{
            inputProps: {
              min: 0,
              max:
                nameOfValue === "currentVolume"
                  ? item?.potentialVolume
                  : undefined
            },
            startAdornment: valueIsCurrency && (
              <InputAdornment position="start">$</InputAdornment>
            )
          }}
        />
      )
    };

    return formInputs[type] ?? null;
  }

  // Parse value from database;
  // If it's a number, assign number
  // If it's a string, check for null value
  useEffect(() => {
    const property = item[nameOfValue];

    if (
      nameOfValue === "estimatedVolume" ||
      nameOfValue === "estimatedSpend" ||
      nameOfValue === "rate" ||
      nameOfValue === "opportunityVolume" ||
      nameOfValue === "currentVolume"
    ) {
      if (property === null) {
        // estimatedVol & estimatedSpend default to null; while rate, oppVol, currVol default to 0
        setValue("");
      } else {
        setValue(property);
      }
    } else {
      setValue(checkForNull(property));
    }
  }, [isEditing, item, nameOfValue]);

  useEffect(() => {
    setIsEditing(false);
  }, [location]);

  return (
    <DrawerToolbeltState
      onSave={triggerUpdate}
      valueType={valueType}
      valueIsCurrency={valueIsCurrency}
      originalValue={checkForNull(item[nameOfValue])}
      inputValue={value}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      title={title}
      input={selectInputToRender(inputType)}
      infoButton={infoButton}
    />
  );
}

DrawerToolbeltItem.propTypes = {
  item: PropTypes.object.isRequired,
  inputType: PropTypes.string.isRequired, // text, multiline, numberTicker
  valueType: PropTypes.string,
  valueIsCurrency: PropTypes.bool,
  updateMethod: PropTypes.func.isRequired,
  nameOfValue: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  infoButton: PropTypes.object
};
