import React from "react";
import { useHistory } from "react-router-dom";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import "./HeaderButtons.css";

export default function HeaderButtonHome({ setIsAddOpen }) {
  const history = useHistory();

  return (
    <button
      className="headerButtonHome__button"
      onClick={() => history.push("/")}
    >
      <HomeRoundedIcon
        style={{
          width: "27px",
          height: "27px"
        }}
      />
    </button>
  );
}
