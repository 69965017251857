import React from "react";

export default function FormDeleteWorkflowCustomer(objectNameToDelete) {
  return (
    <>
      {" "}
      Deleting <span style={{ fontWeight: 900 }}>
        {objectNameToDelete}
      </span>{" "}
      will delete all of its associated locations and lanes.
    </>
  );
}
