import { CircularProgress, Dialog } from "@material-ui/core";
import React, { useContext, useState } from "react";
import UpdateImage from "../../library/images/UpdateImage";
import { CustomerContext } from "../../providers/CustomerProvider";
import "./CustomerDrawer.css";

export default function EditCustomerInfo({ open, customer, setOpen }) {
  const { updateCustomer } = useContext(CustomerContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(
    customer.displayName === null ? customer.name : customer.displayName
  );
  const [phone, setPhone] = useState(customer.phone ?? "");
  const [email, setEmail] = useState(customer.email ?? "");

  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handlePhoneChange = e => {
    setPhone(e.target.value);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleCancel = e => {
    setOpen(false);
  };

  async function handleSave(e) {
    setIsSubmitting(true);
    e.preventDefault();

    const response = await updateCustomer(
      {
        id: customer.id,
        displayName: name,
        phone: phone,
        email: email
      },
      customer.name
    );

    if (response !== null) {
      setOpen(false);
      setIsSubmitting(false);
    }
  }

  return (
    <Dialog open={open}>
      <div className="editDialog">
        <div className="editDialog__top">
          <div className="editDialog__title">Edit Customer Info</div>
          <div className="editDialog__btns">
            <div className="cancel dialog__btn click" onClick={handleCancel}>
              Cancel
            </div>
            {isSubmitting ? (
              <CircularProgress
                color="secondary"
                style={{ width: "19px", height: "19px", marginRight: "20px" }}
              />
            ) : (
              <div className="save dialog__btn click" onClick={handleSave}>
                Save
              </div>
            )}
          </div>
        </div>
        <div className="editDialog__middle">
          <div className="editDialog__image">
            <UpdateImage sourceType="Customer" source={customer} />
          </div>
          <input
            className="input editDialog__input--full"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="editDialog__bottom">
          <input
            className="input editDialog__input--quarter"
            value={phone}
            onChange={handlePhoneChange}
            placeholder="phone"
          />
          <input
            className="input editDialog__input--half"
            value={email}
            onChange={handleEmailChange}
            placeholder="email"
          />
        </div>
      </div>
    </Dialog>
  );
}
