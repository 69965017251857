import { Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";

export default function HeaderMenuHelp({ open, handleClose, isAdmin }) {
  const { getAdminsForBrokerage } = useContext(UserContext);
  const [admins, setAdmins] = useState(false);

  useEffect(() => {
    if (isAdmin === false) {
      async function getAdmins() {
        const admins = await getAdminsForBrokerage();
        if (admins !== null) {
          setAdmins(admins);
        }
      }

      if (open) {
        getAdmins();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="helpDialog">
        <div className="helpDialog__title">Help</div>
        {isAdmin === false ? (
          <>
            <div>
              Please contact your Terralanes admins for immediate assistance.
            </div>
            <div className="helpAdminList">
              {admins.length &&
                admins.map(ad => {
                  return (
                    <div key={ad.id} className="helpAdmin">
                      <div className="helpAdmin__name">
                        {ad.firstName} {ad.lastName}
                      </div>
                      <div>
                        <a href={`mailto:${ad.email}`}>{ad.email}</a>
                      </div>
                      <div>{ad.phone}</div>
                    </div>
                  );
                })}
            </div>
            <div className="helpDialog__footer">
              If you’re still experiencing issues, contact{" "}
              <a href="mailto:support@terralanes.com">support@terralanes.com</a>
              . We will get back to you within 30 minutes.
            </div>
          </>
        ) : (
          <div>
            Please contact{" "}
            <a href="mailto:support@terralanes.com">support@terralanes.com</a>{" "}
            if you're experiencing an issue. We will get back to you within 30
            minutes.
          </div>
        )}
      </div>
    </Dialog>
  );
}
