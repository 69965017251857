import React from "react";
import PropTypes from "prop-types";
import PhoneIcon from "@material-ui/icons/Phone";
import TooltipStructureButton from "./TooltipStructureButton";
import TooltipStructureCopy from "./TooltipStructureCopy";
import "./Tooltip.css";

export default function TooltipPhone(props) {
  TooltipPhone.defaultProps = {
    variant: "tooltip__smallCircle"
  };

  const iconStyle = {
    fontSize: "17px",
    color: "#919191"
  };

  return (
    <TooltipStructureButton
      variant={props.variant}
      style={props.style}
      position={props.position}
      icon={<PhoneIcon style={iconStyle} />}
      buttonText={props.name ? `Call ${props.name}` : null}
    >
      <span
        className="tooltip__emoji"
        style={{ fontSize: "20px" }} // Override to match email emoji size
        role="img"
        aria-label="phone"
      >
        📞
      </span>
      {!props.children ? (
        <p>No number entered</p>
      ) : (
        <>
          {props.children}
          <TooltipStructureCopy content={props.children} />
        </>
      )}
    </TooltipStructureButton>
  );
}

TooltipPhone.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object,
    variant: PropTypes.string.isRequired,
    position: PropTypes.string, // 'left', 'right'
    name: PropTypes.string
    // Possible variants:
    // tooltip__smallCircle
    // tooltip__blueOutline
  })
};
