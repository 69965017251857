import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormAddWorkflowSelectButton from "./FormAddWorkflowSelectButton";
import FormWorkflowTip from "./FormWorkflowTip";
// Holds all select button components

export default function FormAddWorkflowSelect({
  closeModal,
  setActiveForm,
  stepCustomerForm,
  stepLocationForm,
  stepLaneForm
}) {
  const history = useHistory();

  const TIP_MESSAGE =
    "Customers must be added before Locations. Locations must be added before Lanes.";

  function handleUploadClick() {
    closeModal();
    history.push(`/settings/upload`);
  }

  return (
    <MainContainer>
      <FormWorkflowTip text={TIP_MESSAGE} />
      <TopRowContainer>
        <FormAddWorkflowSelectButton
          icon={"Customer"}
          text={"Customer"}
          onClickEvent={() => setActiveForm(stepCustomerForm)}
        />
        <FormAddWorkflowSelectButton
          icon={"Location"}
          text={"Location"}
          onClickEvent={() => setActiveForm(stepLocationForm)}
        />
        <FormAddWorkflowSelectButton
          icon={"Lane"}
          text={"Lane"}
          onClickEvent={() => setActiveForm(stepLaneForm)}
        />
      </TopRowContainer>

      <DividingLine>
        <CenteredLineText>OR</CenteredLineText>
      </DividingLine>

      <BottomRowContainer>
        <FormAddWorkflowSelectButton
          icon={"Upload"}
          text={"Upload a .csv"}
          style={uploadButtonOverrides}
          onClickEvent={() => handleUploadClick()}
          isRowLayout
        />
      </BottomRowContainer>
    </MainContainer>
  );
}

FormAddWorkflowSelect.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setActiveForm: PropTypes.func.isRequired,
  stepCustomerForm: PropTypes.object.isRequired,
  stepLocationForm: PropTypes.object.isRequired,
  stepLaneForm: PropTypes.object.isRequired
};

const MainContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const TopRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  height: 45%;
`;

const DividingLine = styled.div`
  position: relative;
  color: var(--darkBlue);
  font-weight: 900;
  text-align: center;
  margin: 0 2em 1em;
  border-width: 2px;
  border-color: var(--lightLavender);
  border-top-style: solid;
`;

const CenteredLineText = styled.span`
  display: inline-block;
  position: absolute;
  padding: 0 1em;
  background-color: white;
  top: -10px;
  right: 295px;
`;

const BottomRowContainer = styled.div`
  display: flex;
`;

const uploadButtonOverrides = {
  flexGrow: 1,
  margin: "1em 17em 3em",
  height: "100%"
};
