import React, { useState, useContext } from "react";
import { LaneContext } from "../../providers/LaneProvider";
import { numberWithCommas } from "../../../components/generic/Utils";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import "./RateTag.css";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";

const determineVolumeToDisplay = (workflow, lane) => {
  if (workflow === "owned") {
    return lane.currentVolume;
  } else if (workflow === "opportunities") {
    return lane.opportunityVolume;
  } else if (workflow === "potential") {
    return lane.potentialVolume;
  } else {
    return null;
  }
};

const determineVolumeToEdit = (workflow, lane, volumeInput) => {
  const updatedLane = lane;
  if (workflow === "owned") {
    updatedLane.currentVolume = +volumeInput;
    return updatedLane;
  } else if (workflow === "opportunities") {
    updatedLane.opportunityVolume = +volumeInput;
    return updatedLane;
  } else if (workflow === "potential") {
    updatedLane.potentialVolume = +volumeInput;
    return updatedLane;
  } else {
    return null;
  }
};

export default function LoadsTag({ lane, color }) {
  const { workflow } = useContext(ApplicationStateContext);
  const { updateLane } = useContext(LaneContext);
  const [showEdit, setShowEdit] = useState(false);
  const [volumeInput, setVolumeInput] = useState(
    determineVolumeToDisplay(workflow, lane)
  );

  async function enterKeyHandler(e) {
    if (e.keyCode === 13) {
      const updatedLane = determineVolumeToEdit(workflow, lane, volumeInput);
      try {
        await updateLane(updatedLane);
        setShowEdit(false);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleBlur = () => {
    setVolumeInput(determineVolumeToDisplay(workflow, lane));
    setShowEdit(false);
  };

  return (
    <div className={`chip chip--${color}`}>
      <div className={`chip--${color}__top`}>LOADS</div>
      <div className={`chip--${color}__bottom`}>
        <span style={{ marginRight: "5px" }} role="img" aria-label="calendar">
          🗓
        </span>
        {showEdit ? (
          <input
            className="tagInput"
            type="number"
            min={0}
            value={volumeInput}
            onChange={event => setVolumeInput(event.target.value)}
            onKeyUp={e => enterKeyHandler(e)}
            onBlur={handleBlur}
          />
        ) : (
          <div>
            {numberWithCommas(determineVolumeToDisplay(workflow, lane))}/mo
          </div>
        )}
        <button
          style={{ marginLeft: "5px" }}
          onClick={() => setShowEdit(!showEdit)}
        >
          <CreateOutlinedIcon
            className={`editBtn__img editBtn__${color}`}
            style={{ fontSize: "16px" }}
          />
        </button>
      </div>
    </div>
  );
}
