import React from "react";
import PropTypes from "prop-types";
// Animated pulsing circle that wraps an icon

export default function FormSubmitPulse({ icon }) {
  const iconStyle = {
    gridColumn: "1",
    gridRow: "1",
    placeSelf: "center"
  };

  return (
    <div style={{ display: "grid" }}>
      <svg
        style={{ gridColumn: "1", gridRow: "1" }}
        width="150"
        height="150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="75"
          cy="75"
          fill="none"
          r="70"
          stroke="var(--lavender)"
          strokeWidth="14"
        >
          <animate
            attributeName="r"
            from="55"
            to="70"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="75" cy="75" fill="var(--darkBlue)" r="65" />
      </svg>
      <div style={iconStyle}>{icon}</div>
    </div>
  );
}

FormSubmitPulse.propTypes = {
  icon: PropTypes.object
};
