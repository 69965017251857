import React, { useContext } from "react";
import { useHistory } from "react-router";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import { CustomerContext } from "../providers/CustomerProvider";
import { LaneContext } from "../providers/LaneProvider";
import FormAddWorkflowTutorialWelcome from "./FormAddWorkflowTutorialWelcome";
import FormAddWorkflowTutorialAddData from "./FormAddWorkflowTutorialAddData";
import FormAddWorkflowCustomer from "./FormAddWorkflowCustomer";
import FormAddWorkflowLane from "./FormAddWorkflowLane";
import FormAddWorkflowSubmitting from "./FormAddWorkflowSubmit";
import FormAddWorkflowTutorialLane from "./FormAddWorkflowTutorialLane";
import FormAddWorkflowTutorialImageStep from "./FormAddWorkflowTutorialImageStep";
import imageVisualize from "../../images/welcome-visualize.png";
import imageAcquire from "../../images/welcome-acquire.png";
import imageManage from "../../images/welcome-manage.png";
// Contains all logic & steps for the tutorial workflow

export default function FormAddWorkflowTutorialSteps(setActiveModal) {
  const { setIsDrawerOpen } = useContext(ApplicationStateContext);
  const { addCustomer } = useContext(CustomerContext);
  const { addLane } = useContext(LaneContext);
  const history = useHistory();

  const stepCustomerForm = {
    modalHeight: "30em",
    modalWidth: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "Enter Customer Details",
    headerIcon: "Customer",
    isSubmitting: false,
    content: <FormAddWorkflowCustomer onSubmit={addCustomerSubmit} />
  };

  const stepAddData = {
    modalWidth: "28em",
    modalHeightMin: "",
    modalHeight: "20em",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "Getting started",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowTutorialAddData
        setActiveForm={setActiveModal}
        stepCustomerForm={stepCustomerForm}
      />
    )
  };

  const stepManage = {
    modalWidth: "clamp(550px, 80vw, 900px)",
    modalHeight: "fit-content",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "How we can help - Managing",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowTutorialImageStep
        key="3"
        text="3. Manage and maximize your relationships with zero tribal knowledge."
        image={imageManage}
        imageAltText={
          "Dashboard view containing all information about customer."
        }
        setActiveForm={setActiveModal}
        nextComponentStep={stepAddData}
      />
    )
  };

  const stepAcquire = {
    modalWidth: "clamp(550px, 67vw, 680px)",
    modalHeight: "fit-content",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "How we can help - Acquiring",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowTutorialImageStep
        key="2"
        text="2. Acquire opportunities and volume as fast as possible."
        imageWidth="clamp(100px, 45vh, 410px)"
        image={imageAcquire}
        imageAltText={"Conversation toolbelt that helps users win business."}
        setActiveForm={setActiveModal}
        nextComponentStep={stepManage}
      />
    )
  };

  const stepVisualize = {
    modalWidth: "clamp(550px, 80vw, 900px)",
    modalHeight: "fit-content",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "How we can help - Visualizing",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowTutorialImageStep
        key="1"
        text="1. Visualize your opportunities with your customers."
        image={imageVisualize}
        imageAltText={"Map of USA with lanes visualized."}
        setActiveForm={setActiveModal}
        nextComponentStep={stepAcquire}
      />
    )
  };

  const stepWelcome = {
    modalWidth: "28em",
    modalHeight: "16em",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "Welcome to Terralanes!",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowTutorialWelcome
        setActiveForm={setActiveModal}
        stepAddData={stepVisualize}
      />
    )
  };

  const stepCustomerSubmitting = {
    modalHeight: "18em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: true,
    content: (
      <FormAddWorkflowSubmitting
        iconToAssign={"Customer"}
        text={"Adding customer..."}
      />
    )
  };

  const stepLaneForm = {
    modalHeight: "36em",
    modalWidth: "35em",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "Now let's add a lane!",
    headerIcon: "Lane",
    isSubmitting: false,
    content: <FormAddWorkflowLane onSubmit={addLaneSubmit} />
  };

  const stepLaneSubmitting = {
    modalHeight: "18em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: true,
    content: (
      <FormAddWorkflowSubmitting
        iconToAssign={"Lane"}
        text={"Adding lane..."}
      />
    )
  };

  const stepLanePage = {
    modalHeight: "18em",
    modalWidth: "31em",
    hasHeader: true,
    hasHeaderCloseBtn: false,
    headerText: "You've added a lane opportunity!",
    headerIcon: "Lane",
    isSubmitting: false,
    content: <FormAddWorkflowTutorialLane />
  };

  async function addCustomerSubmit(e) {
    // Form validation lives in FormAddWorkflowCustomer
    e.preventDefault();

    setActiveModal(stepCustomerSubmitting);
    const customerFormData = JSON.parse(
      sessionStorage.getItem("customerFormData")
    );

    // Change optional inputs from empty strings to null
    const preppedCustomer = {
      name: customerFormData.name,
      address:
        customerFormData.address === "" ? null : customerFormData.address,
      address2:
        customerFormData.address2 === "" ? null : customerFormData.address2,
      city: customerFormData.city,
      state: customerFormData.state,
      zipCode: customerFormData.zipCode === "" ? null : customerFormData.zipCode
    };

    const response = await addCustomer(preppedCustomer);

    if (response !== null) {
      sessionStorage.removeItem("customerFormData");
      setActiveModal(stepLaneForm);
    } else {
      setActiveModal(stepCustomerForm);
    }
  }

  async function addLaneSubmit(e) {
    // Form validation lives in FormAddWorkflowLane
    e.preventDefault();

    setActiveModal(stepLaneSubmitting);
    const laneFormData = JSON.parse(sessionStorage.getItem("laneFormData"));

    const preppedLane = {
      locationId: laneFormData.locationId,
      truckType: laneFormData.truckType,
      inbound: laneFormData.inbound === "1" ? true : false,
      address: laneFormData.address === "" ? null : laneFormData.address,
      address2: laneFormData.address2 === "" ? null : laneFormData.address2,
      city: laneFormData.city,
      state: laneFormData.state,
      zipCode: laneFormData.zipCode === "" ? null : laneFormData.zipCode
    };

    const response = await addLane(preppedLane);

    if (response !== null) {
      sessionStorage.removeItem("laneFormData");
      history.push(`/lane/${response.id}`);
      setIsDrawerOpen(true);
      setActiveModal(stepLanePage);
    } else {
      setActiveModal(stepLaneForm);
    }
  }

  return stepWelcome;
}
