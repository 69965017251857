import { Dialog } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ButtonSolid from '../../generic/buttons/ButtonSolid'
import { BillingContext } from '../../providers/BillingProvider'

export default function CurrentPlanOptions({ brokerage, subscription }) {
    const history = useHistory()
    const { updateStripeSubscriptionPrice } = useContext(BillingContext)
    const [selected, setSelected] = useState(subscription.items.data[0].price.recurring.interval)
    const [open, setOpen] = useState(false) // confirmation dialog controls
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleClick = selection => e => {
        setSelected(selection)
    }

    const handleDialogClose = () => {
        setOpen(false)
    }

    async function handleSaveClick() {
        if (subscription.items.data[0].price.recurring.interval !== selected) {
            setOpen(true)
        }
    }

    async function handleSubmit() {
        if (!isSubmitting) {
            setIsSubmitting(true)
            const subId = subscription.items.data[0].id
            const subQuantity = brokerage.teammates
            const priceId = selected === 'month' ? process.env.REACT_APP_MONTHLY_PRICEID : process.env.REACT_APP_YEARLY_PRICEID
            try {
                const sub = await updateStripeSubscriptionPrice(subId, priceId, subQuantity)
                toast.success(`Successfully updated to a ${selected}ly subscription plan. Refresh the page to see your updated information.`)
                setOpen(false)
            }
            catch (e) {
                toast.error(`Failed to update your subscription.`)
            }
            setIsSubmitting(false)
        }
    }

    const handleNvmClick = () => {
        setOpen(false)
    }

    const handleManageClick = () => {
        history.push('/settings/users')
    }

    return (
        <div className='manageBilling__options'>
            <div className='manageBilling__options__left'>
                <div className='team__edit__title'>Pro Plan</div>
                <div className='currentPlan__count'>{brokerage.teammates} users</div>
                <div className='currentPlan__manage click' onClick={handleManageClick}>Manage</div>
            </div>
            <div className='manageBilling__options__right'>
                <div className='team__edit__title'>Billing Cycle</div>
                <div className='billingCycle'>
                    <div className={`${selected === 'month' ? 'billingCycle__btn--selected click' : 'billingCycle__btn click'}`} id='billingCycle__btn--left' onClick={handleClick('month')}>Monthly</div>
                    <div className={`${selected === 'year' ? 'billingCycle__btn--selected click' : 'billingCycle__btn click'}`} id='billingCycle__btn--right' onClick={handleClick('year')}>Yearly</div>
                </div>
            </div>
            <div className='manageBilling__save'>
                <ButtonSolid color='red' onClick={handleSaveClick} disabled={selected === subscription.items.data[0].price.recurring.interval}>Save</ButtonSolid>
            </div>
            <Dialog open={open} onClose={handleDialogClose}>
                <div className='confirmBilling'>
                    <div className='settings__card__title'>Are you sure you want to switch to {selected === 'month' ? 'monthly' : 'yearly'} billing?</div>
                    <div className='confirmBilling__details'>You're currently paying {subscription.items.data[0].price.recurring.interval === 'month' ? `$${(subscription.items.data[0].quantity - 1) * 50}/month` : `$${(subscription.items.data[0].quantity - 1) * 480}/year`} for your current plan.
                        By switching to {subscription.items.data[0].price.recurring.interval === 'month' ? 'yearly' : 'monthly'},
                        you'll be charged {selected === 'month' ? `$${(subscription.items.data[0].quantity - 1) * 50}/month` : `$${(subscription.items.data[0].quantity - 1) * 480}/year`}.</div>
                    {subscription.items.data[0].price.recurring.interval === 'month'
                        ? <div className='confirmBilling__details'>Changing from a Monthly Plan to a Yearly Plan will credit your account with the unused time from this month.
                            You will be billed and your yearly cycle will begin today.</div>
                        : <div className='confirmBilling__details'>Changing from a Yearly Plan to a Monthly Plan will credit your account with the unused time on the yearly plan.
                            You will begin being charged the monthly rate today.</div>
                    }
                    <div className='confirmBilling__save'>
                        <div className='click confirmBilling__nvm' onClick={handleNvmClick}>Nevermind</div>
                        <div className='click confirmBilling__yes' onClick={handleSubmit}>{isSubmitting ? 'Submitting...' : `Yes, switch to ${selected}ly`}</div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}