import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import { LocationContext } from "../components/providers/LocationProvider";
import MapDrawerLayout from "../components/layouts/MapDrawerLayout";
import LocationViewDrawerContent from "./LocationViewDrawerContent";
import useLanes from "../hooks/useLanes";

export default function LocationView() {
  const { locationId } = useParams();
  const { workflow, refreshOnAdd } = useContext(ApplicationStateContext);
  const { getLocation, wasLocationUpdated } = useContext(LocationContext);
  const [location, setLocation] = useState();
  const { lanes, areLanesLoading, spend, loadsPerMonth } = useLanes(
    locationId,
    "location"
  );
  const [isDrawerLoading, setIsDrawerLoading] = useState(true);

  useEffect(() => {
    async function getLocationData() {
      const response = await getLocation(locationId, workflow);
      if (response === null) {
        // TODO: Show error
        setIsDrawerLoading(false);
      } else if (response) {
        setLocation(response);
        setIsDrawerLoading(false);
      }
    }

    getLocationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, lanes, wasLocationUpdated, workflow, refreshOnAdd]);

  return (
    <MapDrawerLayout
      mapLanes={lanes}
      isMapLoading={areLanesLoading}
      drawerContent={
        <LocationViewDrawerContent
          location={location}
          lanes={lanes}
          spend={spend}
          loadsPerMonth={loadsPerMonth}
        />
      }
      isDrawerLoading={isDrawerLoading}
    />
  );
}
