import React, { useContext } from "react";
import styled from "styled-components";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import DrawerCustomerTopListItem from "./DrawerCustomerTopListItem";

export default function DrawerCustomerTopList({ customers, name }) {
  const { setIsAddModalOpen } = useContext(ApplicationStateContext);

  return (
    <section>
      <header className="titleContainer">
        <div className="title">{`${name} Top Customers`}</div>
      </header>
      <CustomerContainer className="hide__scroll">
        {customers.length ? (
          customers.map(c => (
            <div key={c.id} className="border__listItem">
              <DrawerCustomerTopListItem customer={c} />
            </div>
          ))
        ) : (
          <ButtonOutlined
            isAdding
            style={{ width: "100%" }}
            text="Add Customer"
            onClick={() => setIsAddModalOpen(true)}
          />
        )}
      </CustomerContainer>
    </section>
  );
}

const CustomerContainer = styled.div`
  max-height: 543px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
