import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";

export default function SettingsHeader({ user }) {
  const headerStyle = {
    height: "100px",
    background: "#141272",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontWeight: "900",
    paddingLeft: "6%"
  };

  const titleStyle = {
    fontSize: "28px",
    marginLeft: "15px"
  };

  const tagStyle = {
    marginLeft: "20px",
    marginTop: "5px",
    fontSize: "0.7em",
    letterSpacing: "-0.6px",
    padding: "2px 5px"
  };

  return (
    <header style={headerStyle}>
      <SettingsIcon
        style={{
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: "28px",
          marginTop: "3px"
        }}
      />
      <div style={titleStyle}>Settings</div>
      {user.admin && (
        <div style={tagStyle} className="tag tag--purple--inverse">
          ADMIN
        </div>
      )}
      {user.sysAdmin && (
        <div style={tagStyle} className="tag tag--purple--inverse">
          SYS ADMIN
        </div>
      )}
    </header>
  );
}
