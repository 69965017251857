import React from "react";
import PropTypes from "prop-types";
import ProfileImage from "../library/profile/ProfileImage";
import TooltipEmail from "../library/tooltip/TooltipEmail";
import TooltipPhone from "../library/tooltip/TooltipPhone";
import "./DrawerIdCard.css";
// Drawer Id Cards contain a profile image, name, and contact info for Contacts, Teammates, and Customers
// To use the DrawerIdCard, create an object called idCard and pass it in. Required props are at bottom of file

export default function DrawerIdCard({ idCard }) {
  let nameButtonColor = "";

  function assignNameButtonColor() {
    // If we have a click event, assign color
    if (idCard.onClickEvent !== null) {
      switch (idCard.profileType) {
        case "teammate":
          nameButtonColor = "idCard__name--btn--blue";
          break;

        case "customer":
          nameButtonColor = "idCard__name--btn--red";
          break;

        default:
          nameButtonColor = "";
          break;
      }
    }
  }

  function getFirstName(name) {
    const [first] = name.split(" ");
    return first;
  }

  // Called outside of any useEffect to ensure classes are assigned before initial render
  assignNameButtonColor();

  return (
    <section className="idCard__container">
      <ProfileImage
        className="idCard__profileImage"
        size="large"
        location="list"
        type={idCard.profileType}
        name={idCard.name}
        image={idCard.profileImage}
        icon={idCard.teamIcon}
      />
      {// If we have tags, display them
      idCard.tags.length > 0 && (
        <div className={`idCard__tags`}>{idCard.tags}</div>
      )}

      {idCard.onClickEvent === null ? (
        <div
          className={`idCard__name ${
            idCard.tags.length === 0 ? "idCard__name--noTags" : ""
          }`}
        >
          {idCard.name}
        </div>
      ) : (
        // Button hover color is based on type
        <button
          className={`idCard__name ${
            // If we have no tags, assign the correct style
            idCard.tags.length === 0 ? "idCard__name--noTags" : ""
          } idCard__name--btn ${nameButtonColor}`}
          onClick={idCard.onClickEvent}
        >
          <span>{idCard.name}</span>
        </button>
      )}

      <div
        className={`idCard__title ${
          idCard.tags.length === 0 ? "idCard__title--noTags" : ""
        }`}
      >
        {idCard.subHeading}
      </div>
      {idCard.showContactInfo === true ? (
        <div className={`idCard__btns${idCard.isBigButton ? "--big" : ""}`}>
          <TooltipPhone
            name={idCard.isBigButton ? getFirstName(idCard.name) : null}
            position={idCard.toolTipPosition}
            variant={
              idCard.isBigButton
                ? "tooltip__blueOutline"
                : "tooltip__smallCircle"
            }
          >
            {idCard.phone}
          </TooltipPhone>

          <TooltipEmail
            name={idCard.isBigButton ? getFirstName(idCard.name) : null}
            position={idCard.toolTipPosition}
            variant={
              idCard.isBigButton
                ? "tooltip__blueOutline"
                : "tooltip__smallCircle"
            }
          >
            {idCard.email}
          </TooltipEmail>
        </div>
      ) : null}
    </section>
  );
}

DrawerIdCard.propTypes = {
  idCard: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    subHeading: PropTypes.string,
    showContactInfo: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    teamIcon: PropTypes.string,
    profileImage: PropTypes.string,
    profileType: PropTypes.string.isRequired,
    toolTipPosition: PropTypes.string, // "left", "right"
    // Same types as ProfileImage.js
    isBigButton: PropTypes.bool,
    // Used by ContactHierarchy.js to switch contact buttons to primary
    onClickEvent: PropTypes.func
    // If it's not null, then make the name clickable to push to a new route
  }).isRequired
};
