import React, { useState, useContext, useEffect } from 'react'
import { Card, TextField } from "@material-ui/core";
import { UserContext } from '../providers/UserProvider';
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';
import { AlertContext } from '../providers/AlertProvider';

// This is the primary login form
export default function LoginForm() {
    const {signIn, setIsLoggedIn, getCurrentUserProfile } = useContext(UserContext)
    const { email, setEmail, password, setPassword, setCognitoUser, setLoginComponent} = useContext(ApplicationStateContext)
    const { setAlertOpen, setAlertMessage } = useContext(AlertContext)
    const [enabled, setEnabled] = useState(false)
    const [attempting, setAttempting] = useState(false)

    useEffect(() => {
        setAttempting(false)
    }, [])

    useEffect(() => {
        if(email !== '' && password !== '') {
            setEnabled(true)
        }
        else {
            setEnabled(false)
        }
    }, [email, password])

    const handleKeyPress = (e) => {
        if (enabled && e.keyCode === 13) {
            setAttempting(true)
            loginSubmit()
        }
    }

    // on submit
    const loginSubmit = () => {
        // if both fields are not blank
        if(enabled){
            setAttempting(true)
            signIn(email, password)
            .then(user => {
                // if a password change is required by Cognito
                if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setCognitoUser(user)
                    setLoginComponent('newPassword')
                }
                else {
                    getCurrentUserProfile()
                        .then(user => {
                            if(user.confirmed === false) {
                                sessionStorage.setItem("incompleteUser", JSON.stringify(user))
                                setLoginComponent('createProfile')
                            }
                            else {
                                sessionStorage.setItem("user", JSON.stringify(user))
                                setIsLoggedIn(true)
                            }
                        })
                    .catch((err) => {
                        setAttempting(false)
                        setEnabled(false)
                        setAlertMessage('Sign in attempt was successful. However, there was error retrieving your data.')
                        setAlertOpen(true)
                    })
                }
            })
            .catch((err) => {
                setAttempting(false)
                setEnabled(false)
                setAlertMessage("Sign in attempt failed.")
                setAlertOpen(true)
            })
        }
    };

    return (
        <div className='auth'>
            <div className='auth__header'>
                <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__title'>Sign In</div>
                    <div className='auth__form__field--top'>
                        <TextField autoComplete="new-password" id="email" variant='outlined' label='Email' onChange={e => setEmail(e.target.value)} className='auth__form__input'/>
                    </div>
                    <div className="auth__form__field">
                        <TextField autoComplete="new-password" id="password" type="password" variant='outlined' label='Password' onChange={e => setPassword(e.target.value)} className='auth__form__input' onKeyDown={handleKeyPress}/>
                    </div>
                    <div className='auth__form__field'>
                        <button className={`auth__form__submit btn ${enabled ? 'btn-enabled' : 'btn-disabled'}`} onClick={loginSubmit}>{attempting ? 'Signing you in...' : 'Sign In'}</button>
                    </div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text' onClick={() => setLoginComponent('forgotPassword')}>Forgot Password?</div>
                    </div>
                </Card>
            </div>
            <div className='auth__footer'>
                <div className='auth__footer__text'>If you do not have an account, <a className='auth__footer__text__link'>request a seat from your company's Terralanes manager</a>.</div>
            </div>
        </div>
    )
}