import React from 'react'
import BillingCycleMonthly from './BillingCycleMonthly'
import BillingCycleYearly from './BillingCycleYearly'
import './BillingCycle.css'

export default function BillingCycleSelect({ brokerage, billingCycle, setBillingCycle, numberOfUsers }) {
    return (
        <div className='billingContainer'>
            <BillingCycleMonthly selected={billingCycle === 'monthly'} setSelected={setBillingCycle} users={numberOfUsers} brokerage={brokerage} />
            <BillingCycleYearly selected={billingCycle === 'yearly'} setSelected={setBillingCycle} users={numberOfUsers} brokerage={brokerage} />
        </div>
    )
}