import React, { useContext, useState, useEffect } from "react";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import ManageCompany from "./ManageCompany";
import { CircularProgress, Dialog } from "@material-ui/core";

export default function SelectCompanyOption() {
  const { getAllBrokerages, deleteBrokerage } = useContext(BrokerageContext);
  const [brokerages, setBrokerages] = useState([]);
  const [currentBr, setCurrentBr] = useState(null);
  const [currentBrName, setCurrentBrName] = useState(currentBr?.name);
  const [buttonPressed, setButtonPressed] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);


  useEffect(()=>{
    if(buttonPressed === ""){
      setCurrentBr(null);
      getABrokerage();
    }
  },[buttonPressed]);

  const handleInputChange = (setter,setter1) => e => {
    console.log('here e target val parsed',JSON.parse(e.target.value));
    let val = JSON.parse(e.target.value);
    setter(val);
    setter1(!val.name?"No Name Yet":val.name);
  };

  function sortBrokerages(a,b){
    if(a.disabled) return -1;
    else if(b.disabled) return 1;
    else if(!b.name && !!a.name)
      return -1;
    else if (!a.name && !!b.name) 
      return 1;
    else if(!a.name && !b.name){
      if(a.id < b.id)
        return -1;
      else if(a.id >= b.id)
        return 1;
    }
    else if(a.name.toLowerCase() <= b.name.toLowerCase())
      return -1;
    else if(a.name.toLowerCase() > b.name.toLowerCase())
      return 1;
    
    return 0;
  }

  async function handleDeleteBrokerage(){
    setIsDeleting(true);
    await deleteBrokerage(currentBr.id);
    setCurrentBr(null);
    getABrokerage();
    setIsDeleting(false);
    setDialogOpen(false);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  async function getABrokerage(){
    const br = await getAllBrokerages();
    console.log('here br',br);
    br.push({disabled: true, name:"Select a company"})
    const brSorted = br.sort(sortBrokerages).sort(sortBrokerages);
    setBrokerages(brSorted);
  }

  if (!brokerages.length)
    getABrokerage();

  async function handleSubmit(mode) {
    setButtonPressed(mode);
  }

  if(buttonPressed.length)
    return (
      <ManageCompany
        brokerage={buttonPressed === "add" ? {} : currentBr}
        setButtonPressed={setButtonPressed}
        mode={buttonPressed}
      />
    );

  return (
    <div className="settings__profile">
      <div className="settings__profile__left1">
        <div className="settings__profile__input__title">New Company</div>
        <div className="settings__save click button_div" onClick={()=>handleSubmit("add")}>
          Add
        </div>
      </div>
      <div className="settings__profile__right1">
        <div className="settings__profile__input__title">Edit Existing Company</div>
        <div style={{display:"flex", flexDirection: "row"}}>
          {
            !brokerages.length && <CircularProgress
            color="secondary"
            style={{ width: "3em", height: "3em"}}
          />
          }
          {
            !!brokerages.length && <select
              className="settings__brokerage__select__field--quarter1"
              value={JSON.stringify(currentBr)}
              onChange={handleInputChange(setCurrentBr,setCurrentBrName)}
            >          
              {
                brokerages.map((b,index)=>{
                return  <option value={JSON.stringify(b)} key={index} hidden={b.disabled} >
                  {b.id}{!b.disabled && " - "}{ !b.name?"No Name Yet":b.name}
                </option> }) 
              }
            </select>

          }
          <div className={`${!!currentBr?"click enabled":"disabled"} settings__update button_div`} onClick={()=>handleSubmit("update")}>
            Update
          </div>
          <div className={`${!!currentBr?"click enabled":"disabled"} settings__update button_div`} onClick={()=>setDialogOpen(true)}>
            Delete
          </div>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <div className="dialog settings__profile__dialog">
          <div className="dialog__title title mr--50">Delete Company?</div>
          <div className="dialog__btns">
            <div
              className="cancel dialog__btn click"
              onClick={handleDialogClose}
            >
              Cancel
            </div>
            {isDeleting ? (
              <CircularProgress
                color="secondary"
                style={{ width: "19px", height: "19px", marginRight: "20px" }}
              />
            ) : (
              <div
                className="save dialog__btn click"
                onClick={handleDeleteBrokerage}
              >
                Delete
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>     
  );
}