import React from "react";

export default function IconCheck() {
  return (
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.7077 3.13234L21.6805 41.6595L11.7836 31.762L11.7836 31.762C9.20219 29.1806 5.01676 29.1805 2.43603 31.7621C-0.145332 34.3435 -0.145396 38.5289 2.43615 41.1096L22.6803 61.3536L23.087 61.7603L23.4331 61.3009L61.2657 11.0868C63.4628 8.17116 62.8798 4.02688 59.9642 1.8309C57.0486 -0.365737 52.9043 0.217357 50.7078 3.13231L50.7077 3.13234Z"
        fill="white"
      />
    </svg>
  );
}
