import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { CustomerContext } from "../providers/CustomerProvider";
import FormModal from "./FormModal";
import FormDeleteWorkflowLocation from "./FormDeleteWorkflowLocation";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import FormDeleteWorkflowCustomer from "./FormDeleteWorkflowCustomer";
import { LocationContext } from "../providers/LocationProvider";
import { LaneContext } from "../providers/LaneProvider";
// TODO:
// Delete for Location
// Delete for Lane

export default function FormDeleteWorkflow({
  isModalOpen,
  closeModal,
  objectNameToDelete
}) {
  const { deleteCustomer } = useContext(CustomerContext);
  const { deleteLocation } = useContext(LocationContext);
  const { deleteLane } = useContext(LaneContext);
  const routerLocation = useLocation();
  const [typeToDelete, setTypeToDelete] = useState("");
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteMethod, setDeleteMethod] = useState(null);
  const [deleteText, setDeleteText] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    margin: "0 2rem 2rem"
  };

  const headingStyle = {
    fontWeight: 900,
    fontSize: "1.4rem",
    marginBottom: "1.4rem"
  };

  const buttonContainerStyle = {
    display: "flex",
    placeSelf: "flex-end",
    marginTop: "3rem"
  };

  const paragraphStyle = {
    fontWeight: 400,
    marginBottom: "0.25rem"
  };

  async function handleDelete() {
    setIsSubmitting(true);
    if (deleteMethod !== null && idToDelete !== null) {
      await deleteMethod(idToDelete);
    } else {
      console.error("No delete method or id.");
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    function readUrl() {
      const path = routerLocation.pathname;
      const [, item, id] = path.split("/", 3);

      setTypeToDelete(item);
      setIdToDelete(id);
    }

    readUrl();
  }, [routerLocation.pathname]);

  useEffect(() => {
    function setDeleteModal() {
      if (typeToDelete) {
        switch (typeToDelete) {
          case "customer":
            setDeleteText(FormDeleteWorkflowCustomer(objectNameToDelete));
            setDeleteMethod(() => deleteCustomer);
            break;
          case "location":
            setDeleteText(FormDeleteWorkflowLocation(objectNameToDelete));
            setDeleteMethod(() => deleteLocation);
            break;
          case "lane":
            setDeleteMethod(() => deleteLane);
            break;
          default:
            console.error("incorrect item give to delete modal");
        }
      }
    }
    setDeleteModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectNameToDelete, typeToDelete]);

  return (
    <FormModal
      modalWidth="36rem"
      modalHeight={!objectNameToDelete ? "12rem" : "15rem"}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    >
      <div style={containerStyle}>
        <h2 style={headingStyle}>
          Are you sure you want to delete this {typeToDelete}?
        </h2>
        <p style={paragraphStyle}>{deleteText}</p>
        <p style={paragraphStyle}>
          You will be unable to access this data again.
        </p>
        <div style={buttonContainerStyle}>
          <ButtonSolid
            style={{
              padding: "1.25rem",
              fontSize: "0.87rem"
            }}
            color="gray"
            onClick={() => closeModal()}
          >
            Cancel
          </ButtonSolid>
          <ButtonSolid
            style={{
              padding: "1.25rem",
              fontSize: "0.87rem",
              marginLeft: "0.5rem",
              textTransform: "capitalize",
              minWidth: "8.5rem"
            }}
            color="red"
            onClick={handleDelete}
            isSubmitting={isSubmitting}
          >
            Delete {typeToDelete}
          </ButtonSolid>
        </div>
      </div>
    </FormModal>
  );
}

FormDeleteWorkflow.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  objectNameToDelete: PropTypes.string
};
