import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

// AWS Amplify
Amplify.configure(awsconfig)

// if(window.location.host.includes("localhost"))
//     Amplify.configure({region:'us-east-1',bucket:"amplify-nextlanesapp-dev-215803-deployment"});
Amplify.configure({region:'us-east-1',bucket:"amplify-terralanes-master-31259-deployment"});
Auth.configure({
    authenticationFlowType: 'USER_SRP_AUTH'
})

// This file renders the App component to the DOM /

ReactDOM.render(<App />, document.getElementById('root'));
