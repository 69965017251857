import React, { useState, useContext } from "react";
import { TagContext } from "../../providers/TagProvider";
import DeleteButton from "../../generic/buttons/DeleteButton";
import { CircularProgress } from "@material-ui/core";

const addEmoji = type => {
  switch (type) {
    case "Accessorial":
      return <span className="quickTag__emoji">👀</span>;
    case "Equipment":
      return <span className="quickTag__emoji">🚚</span>;
    case "Equipment Length":
      return <span className="quickTag__emoji">🚚</span>;
    case "Location":
      return <span className="quickTag__emoji">📍</span>;
    case "Commodity":
      return <span className="quickTag__emoji">📦</span>;
    case "Load":
      return <span className="quickTag__emoji">⚖️</span>;
    case "Tarp":
      return <span className="quickTag__emoji">⚖️</span>;
  }
};

export default function QuickTag({ tag, page, item }) {
  const { deleteTag } = useContext(TagContext);
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClick = () => {
    if (!isDeleting) {
      setShowDelete(!showDelete);
    }
  };

  async function handleDelete() {
    setIsDeleting(true);
    await deleteTag(page, item, tag);
    setIsDeleting(false);
  }

  return (
    <div
      className="tag quickTag click mr--5"
      onClick={handleClick}
      style={{ cursor: isDeleting ? "auto" : "pointer" }}
    >
      <div className="quickTag__content">
        <div style={{ display: "grid", justifyItems: "center" }}>
          {addEmoji(tag.type)}
          {isDeleting ? (
            <CircularProgress
              color="secondary"
              style={{
                width: "20px",
                height: "20px",
                gridColumn: "2",
                gridRow: "1"
              }}
            />
          ) : null}
          <span
            style={{
              gridColumn: "2",
              gridRow: "1",
              marginLeft: "5px",
              placeSelf: "center"
            }}
          >
            {tag.content.toUpperCase()}
          </span>
        </div>
      </div>
      {showDelete && <DeleteButton onClick={handleDelete} />}
    </div>
  );
}
