import React from "react";
import styled from "styled-components";
import FormModal from "../forms/FormModal";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";
import ButtonSolid from "../generic/buttons/ButtonSolid";

export default function SettingsContentUploadModal({
  isModalOpen,
  setIsModalOpen
}) {
  return (
    <FormModal
      isModalOpen={isModalOpen}
      closeModal={() => {}}
      modalHeight={"25em"}
      modalWidth={"26em"}
      hasHeader={false}
      hasHeaderCloseBtn={false}
      isSubmitting={false}
    >
      <SectionContainer>
        <CircleContainer>
          <svg
            style={svgOverrides}
            width="150"
            height="150"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="75" cy="75" fill="var(--darkBlue)" r="65" />
          </svg>
          <IconContainer>
            <LoopRoundedIcon style={iconOverrides} />
          </IconContainer>
        </CircleContainer>

        <HeaderText>Upload in progress!</HeaderText>
        <BodyText>
          You will be <Bold>emailed</Bold> when the upload has completed. Feel
          free to leave this page.
        </BodyText>

        <ButtonSolid
          onClick={() => setIsModalOpen(false)}
          style={buttonOverrides}
          color="red"
        >
          Confirm
        </ButtonSolid>
      </SectionContainer>
    </FormModal>
  );
}

const SectionContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1em 2em 2em 2em;
`;

const CircleContainer = styled.div`
  display: grid;
`;

const IconContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  place-self: center;
`;

const HeaderText = styled.h2`
  font-size: 1.4em;
  font-weight: 900;
  margin-top: 1em;
`;

const BodyText = styled.p`
  margin-top: 1em;
  text-align: center;
  font-size: 1.1em;
  font-weight: 300;
`;

const Bold = styled.span`
  font-weight: 900;
`;

const svgOverrides = {
  gridColumn: "1",
  gridRow: "1",
  placeSelf: "center"
};

const iconOverrides = {
  fill: "white",
  height: "3em",
  width: "3em"
};

const buttonOverrides = {
  marginTop: "2em",
  padding: "1.2em",
  fontSize: "1.1em"
};
