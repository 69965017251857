import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useFormIcon from "./useFormIcon";

export default function FormAddWorkflowSelectButton({
  icon,
  text,
  type,
  style,
  onClickEvent,
  isSingleButton,
  isRowLayout
}) {
  const assignedIcon = useFormIcon(icon, "medium");

  return (
    <SelectButton
      type={type}
      style={style}
      onClick={() => onClickEvent()}
      isSingleButton={isSingleButton}
      isRowLayout={isRowLayout}
    >
      <SelectButtonIcon>{assignedIcon}</SelectButtonIcon>
      <SelectButtonText isRowLayout={isRowLayout}>{text}</SelectButtonText>
    </SelectButton>
  );
}

FormAddWorkflowSelectButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
  onClickEvent: PropTypes.func.isRequired,
  isSingleButton: PropTypes.bool,
  isRowLayout: PropTypes.bool
};

const SelectButton = styled.button`
  display: flex;
  flex-flow: ${props => (props.isRowLayout ? "row nowrap" : "column nowrap")};
  justify-content: center;
  align-items: center;
  border: var(--lightLavender) 3px solid;
  border-radius: 7px;
  margin: ${props => (props.isSingleButton ? "2.75em" : "30px 0")};
  width: ${props => (props.isSingleButton ? "40%" : "25%")};
  transition: all 0.15s;

  &:hover {
    border: var(--darkRed) 3px solid;
    background-color: var(--lightRed);
  }

  &:hover > div {
    color: var(--darkRed);
  }

  &:hover > div:first-of-type {
    background-color: var(--darkRed);
  }
`;

const SelectButtonIcon = styled.div`
  background-color: var(--lavender);
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 10px;
`;

const SelectButtonText = styled.div`
  color: var(--darkBlue);
  font-weight: 900;
  font-size: 1.3em;
  margin-top: ${props => (props.isRowLayout ? "0" : "0.75em")};
  margin-left: ${props => (props.isRowLayout ? "0.75em" : "0")};
`;
