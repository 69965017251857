import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
// useProfileImage fetches and returns state of profileImages from AWS

export default function useProfileImage(object) {
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    async function getProfileImage() {
      if (object?.profileImage) {
        const image = await Storage.get(object.profileImage);
        setProfileImage(image);
      } else if (object?.logo) {
        const image = await Storage.get(object.logo);
        setProfileImage(image);
      }
    }

    getProfileImage();
  }, [object, object?.logo, object?.profileImage]);

  return profileImage;
}
