import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SearchContext } from '../../providers/SearchProvider'

const SearchResultTeammate = ({result, id}) => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"))
    const { closeSearch } = useContext(SearchContext)
    const history = useHistory()

    const handleClick = () => {
        closeSearch()
        if(currentUser.id === result._source.id){
            history.push('')
            return
        }
        history.push(`/user/${result._source.id}`)
    }

    return (
        <div className='search__results__item' id={id} onClick={handleClick}>
            <span className='search__results__emoji' role='img' aria-label='x'>😊</span>
            {result._source.firstName} {result._source.lastName}
            <span className='search__results__tag tag tag--blue'>{result._source.id === currentUser.id ? 'You' : 'Teammate'}</span>
            </div>
    )
}

export default SearchResultTeammate