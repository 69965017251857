import React, { useState, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ApplicationStateContext } from "./ApplicationStateProvider";
import { AlertContext } from "./AlertProvider";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";

export const UserContext = createContext();

export function UserProvider(props) {
  const locationRoute = useLocation()
  const apiUrl = process.env.REACT_APP_API_URL + "/user1";
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const { setLoginComponent } = useContext(ApplicationStateContext);
  const { setAlertModal, setAlertMessage, setAlertOpen } = useContext(
    AlertContext
  );
  const [isLoggedIn, setIsLoggedIn] = useState(currentUser != null);
  const [isSysAdmin, setIsSysAdmin] = useState(currentUser != null && currentUser.sysAdmin != null);
  const [showTutorial, setShowTutorial] = useState(null);

  async function signIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      return user;
    } catch (error) {
      console.log("error sigining in: ", error);
    }
  }

  async function setCookie() {
    const token = await getToken();
    var expires = "";  
    var date = new Date();
    date.setTime(date.getTime() + (24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = "nxtlauth" + "=" + (token || "")  + expires + "; path=/";
  }

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      setAlertMessage("There was an error signing you out.");
      setAlertOpen(true);
    }
    setLoginComponent("login");
    sessionStorage.clear();
    setIsLoggedIn(false);
  }

  async function getToken() {
    const session = await Auth.currentSession();
    const idToken = session.idToken.jwtToken;
    return idToken;
  }

  async function getCurrentUserProfile() {
    const token = await getToken();
    const user = await fetch(`${apiUrl}`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    }).then(resp => resp.json());
    return user;
  }

  async function getUser(userId) {
    const token = await getToken();
    const user = await fetch(`${apiUrl}/${userId}`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    }).then(resp => resp.json());

    return user;
  }

  // Only used to update the current user
  async function updateUserProfile(user) {
    const token = await getToken();
    const response = await fetch(`${apiUrl}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    });
    const updatedUser = await getCurrentUserProfile();
    return updatedUser;
  }

  async function getTeamsForUser() {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/teams`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const teams = await responseHandler({ response, setAlertModal });

      return teams;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function getTopCustomersForUser(userId, workflow) {
    const token = await getToken();
    const topCustomers = await fetch(
      `${process.env.REACT_APP_API_URL}/customer1/top/${userId}?status=${workflow}`,
      {
        method: "GET",
        headers: {
          Authorization: token
        }
      }
    ).then(resp => resp.json());

    const [, path] = locationRoute.pathname.split("/", 2)

    if (topCustomers.length === 0 && path !== 'user') {
      setShowTutorial(true)
    } else {
      setShowTutorial(false)
    }

    return topCustomers;
  }

  async function getTopLanesForUser(userId, workflow) {
    if (workflow) {
      const token = await getToken();
      const topLanes = await fetch(
        `${process.env.REACT_APP_API_URL}/lane1/top/${userId}?status=${workflow}`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      ).then(resp => resp.json());

      if (topLanes.length === 0) {
        setShowTutorial(true)
      } else {
        setShowTutorial(false)
      }
  
      return topLanes;
    }
  }

  async function promoteUserToAdmin(id) {    
    const token = await getToken();
    const response = await fetch(`${apiUrl}/${id}/admin`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({field: "A"})
    });
    // const updatedUser = await getUser(user.id)
    return response;
  }
  async function promoteUserToSysAdmin(id) {
    const token = await getToken();
    const response = await fetch(`${apiUrl}/${id}/admin`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({field: "SA"})
    });
    // const updatedUser = await getUser(user.id)
    return response;
  }
  
  async function deleteUserFromCustomBrokerage(userId) {
    const token = await getToken()
    const users = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: "DELETE",
        headers: {
            Authorization: token,
            "Content-Type": "application/json"
        }
    });

    return;
  }

  async function demoteUserFromAdmin(user) {
    user.admin = false;
    const token = await getToken();
    const response = await fetch(`${apiUrl}/${user.id}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    });
    // const updatedUser = await getUser(user.id)
    return response;
  }

  async function changeActiveStatus(user, bool) {
    user.active = bool;
    const token = await getToken();
    const response = await fetch(`${apiUrl}/${user.id}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    });
    // const updatedUser = await getUser(user.id)
    return response;
  }

  async function createNewAccount(user, resend) {
    if (resend) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/account1?resend=true`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(user)
        }
      );
      return response;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/account1?resend=false`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      }
    );
    return response;
  }

  async function getAdminsForBrokerage() {
    const token = await getToken();
    const admins = await fetch(`${apiUrl}/admins`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    }).then(resp => resp.json());

    return admins;
  }

  return (
    <UserContext.Provider
      value={{
        signIn,
        signOut,
        isLoggedIn,
        setIsLoggedIn,
        getToken,
        setCookie,
        getCurrentUserProfile,
        updateUserProfile,
        getTeamsForUser,
        getUser,
        getTopCustomersForUser,
        getTopLanesForUser,
        promoteUserToAdmin,
        promoteUserToSysAdmin,
        demoteUserFromAdmin,
        changeActiveStatus,
        createNewAccount,
        getAdminsForBrokerage,
        showTutorial,
        setShowTutorial,
        deleteUserFromCustomBrokerage
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
