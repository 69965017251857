import React, { useContext, useState } from "react";
import { TagContext } from "../../../providers/TagProvider";
import { CircularProgress } from "@material-ui/core";

export default function CustomTag({ page, item }) {
  const { addCustomTag } = useContext(TagContext);
  const [content, setContent] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    setContent(e.target.value);
    if (content !== "" && isSubmitDisabled === true) {
      setIsSubmitDisabled(false);
    }
  };

  async function handleAddCustom() {
    setIsSubmitting(true);
    setIsSubmitDisabled(true);
    if (content !== "") {
      const newTag = {
        content: content,
        type: "Custom"
      };
      const response = await addCustomTag(page, item, newTag);
      if (response) {
        setContent("");
      }
    }
    setIsSubmitting(false);
    setIsSubmitDisabled(true);
  }

  return (
    <div className="addTags__custom">
      <div className="addTags__custom__input">
        <input
          className="addTags__custom__field"
          placeholder="Enter Custom Tag..."
          value={content}
          onChange={handleChange}
        />
        <button
          disabled={isSubmitDisabled}
          className={
            content !== ""
              ? "addTags__custom__btn--enabled click"
              : "addTags__custom__btn--disabled"
          }
          onClick={handleAddCustom}
        >
          {isSubmitting ? (
            <CircularProgress
              color="inherit"
              style={{
                width: "20px",
                height: "20px"
              }}
            />
          ) : (
            <span style={{ fontSize: "1.5em" }}>+</span>
          )}
        </button>
      </div>
    </div>
  );
}
