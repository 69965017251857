import React, { useContext, useEffect, useRef, useState } from 'react'
import './Ledger.css'
import { Box, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { LedgerContext } from '../../providers/LedgerProvider';
import Message from './messages/Message';
import ScrollToBottom from 'react-scroll-to-bottom';

export default function LedgerContainer({ messages, ledgerId, setMessages, placeholder }) {
    const currentUser = JSON.parse(sessionStorage.getItem("user"))
    const { postMessage, filterMessages } = useContext(LedgerContext)
    const [newMessage, setNewMessage] = useState('')
    const [focus, setFocus] = useState(0)
    const [publish, setPublish] = useState(false)

    useEffect(() => {
        setNewMessage('')
    }, [])

    useEffect(() => {
        if(newMessage !== '') {
            setPublish(true)
        }
        else {
            setPublish(false)
        }
    }, [newMessage])

    //KEYBOARD EVENTS
    const [shiftPressed, setShiftPressed] = useState(false)

    const keydownHandler = (e) => {
        switch(e.key) {
            case 'Shift':
                setShiftPressed(true)
                break;
            case 'Enter':
                if(!shiftPressed) {
                    setNewMessage('')
                    setFilterTerms('')
                    if(newMessage !== '') {
                        const message = {
                            userId: currentUser.id,
                            ledgerId: ledgerId,
                            content: newMessage
                        }

                        postMessage(message)
                            .then(ledger => setMessages(ledger.Messages))
                    }
                }
                break;
        }
    }

    const keyupHandler = (e) => {
        switch(e.key) {
            case 'Shift':
                setShiftPressed(false)
                break;
            case 'Enter':
                if(!shiftPressed) {
                    setNewMessage('')
                }
                break;
        }
    }

    const publishHandler = () => {
        setNewMessage('')
        setFilterTerms('')
            const message = {
            userId: currentUser.id,
            ledgerId: ledgerId,
            content: newMessage
        }
        postMessage(message)
            .then(ledger => setMessages(ledger.Messages))
    }

    useEffect(() => {
        window.addEventListener('keydown', keydownHandler);
        window.addEventListener('keyup', keyupHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', keydownHandler);
            window.removeEventListener('keyup', keyupHandler);
        };
    }, [newMessage, shiftPressed])

    //FILTER
    const [filterTerms, setFilterTerms] = useState('')
    const [filteredMessages, setFilteredMessages] = useState([])

    const handleFilter = (e) => {
        setFilterTerms(e.target.value)
    }

    const displayMessages = () => {
        if(filterTerms !== '') {
            // Checking if it's an array because a backend issue could return not an array
            if (Array.isArray(filteredMessages) && filteredMessages.length > 0 && !filteredMessages.includes(null)) {
                return filteredMessages.map(m => {
                    if (m !== null) {
                        return <Message key={m.id} message={m} setMessages={setMessages} setFilterTerms={setFilterTerms}/>
                    }
                })
            } else {
                // Should probably display: "No results found" or some kind of feedback
                return (
                    <div className='ledger__empty'>
                        <div className='ledger__empty__title'>No results found.</div>
                        <div className='ledger__empty__text'>Try searching for a different keyword.</div>
                    </div>
                )
            }
        }
        else {
            if(messages.length > 0) {
                return messages.map(m => {
                    return <Message key={m.id} message={m} setMessages={setMessages} setFilterTerms={setFilterTerms} />
                })
            }
            else {
                return (
                    <div className='ledger__empty'>
                        <div className='ledger__empty__title'>Nothing to see here!</div>
                        <div className='ledger__empty__text'>Start a conversation or add a note on this page.</div>
                    </div>
                )
            }
        }
    }

    useEffect(() => {
        if(filterTerms !== '') {
            filterMessages(ledgerId, filterTerms)
                .then(results => setFilteredMessages(results))
        }
        else {
            setFilteredMessages(messages)
        }
    }, [filterTerms, messages])

    return (
        <div className='ledger'>
            <div className='titleContainer'><div className='title'>Ledger</div></div>
            <div className='ledger__filter'>
                <TextField variant='outlined'
                    onChange={handleFilter}
                    value={filterTerms}
                    className='ledger__filter__input'
                    placeholder='Search'
                    size='small' 
                    autoComplete="new-password"
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position='start' style={{color: '#979797'}}>
                                <SearchIcon />
                        </InputAdornment>
                    )}}
                />
            </div>
            
                <ScrollToBottom className='ledger__messages' followButtonClassName='none' scrollViewClassName='ledger__scroll' initialScrollBehavior='auto'>
                    <div className='ledger__messages__list'>
                        {displayMessages()}
                    </div>
                </ScrollToBottom>
            
            <div className='ledger__input'>
                <div className='ledger__publish'>
                    <div className={publish ? 'ledger__publish__btn ledger__publish__btn--enabled' : 'ledger__publish__btn ledger__publish__btn--disabled'}
                        onClick={publishHandler}>Publish</div>
                </div>
                <Box className='ledger__add' boxShadow={focus} onFocus={() => setFocus(1)} onBlur={() => setFocus(0)}>
                    <textarea className='ledger__add__input'
                        rows={1} 
                        placeholder={`Add an internal note for ${placeholder}...`} 
                        onChange={(e) => setNewMessage(e.target.value)} 
                        value={newMessage} />
                </Box>
            </div>
        </div>
    )
}