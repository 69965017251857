import React, { useContext, useState } from "react";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import CustomerBio from "../components/drawers/customer/CustomerBio";
import Locations from "../components/drawers/customer/Locations";
import DrawerContacts from "../components/drawers/DrawerContacts";
import DrawerTeammateTaggedList from "../components/drawers/DrawerTeammateTaggedList";
import QuickTagsList from "../components/drawers/tags/QuickTagsList";
import EditCustomerInfo from "../components/drawers/customer/EditCustomerInfo";
import ProfileImage from "../components/library/profile/ProfileImage";
import useProfileImage from "../hooks/useProfileImage";
import DrawerLanes from "../components/drawers/DrawerLanes";
import CustomerDefaultTags from "../components/drawers/tags/CustomerDefaultTags";
import ButtonOutlined from "../components/generic/buttons/ButtonOutlined";
import DrawerToolbelt from "../components/drawers/DrawerToolbelt";
import TooltipPhone from "../components/library/tooltip/TooltipPhone";
import TooltipEmail from "../components/library/tooltip/TooltipEmail";
import DrawerHeaderSettingsButton from "../components/drawers/DrawerHeaderSettingsButton";

export default function CustomerViewDrawerContent({
  customer,
  lanes,
  locations,
  spend,
  freq
}) {
  const { isDrawerOpen } = useContext(ApplicationStateContext);
  const [edit, setEdit] = useState(false); // controls EditCustomerInfo dialog state
  const profileImage = useProfileImage(customer);

  return (
    <>
      <header
        className={`drawer__upper mb--10 drawer__upper--${
          isDrawerOpen ? "open" : "close"
        }`}
      >
        <div className="drawer__top">
          <section className="drawer__column--2">
            <div className="drawer__image mr--10">
              <ProfileImage
                size="large"
                location="drawerHeader"
                type="customer"
                image={profileImage}
                name={customer.name}
              />
            </div>
            <div className="drawer__info">
              <div className="drawer__row">
                <DrawerHeaderSettingsButton
                  openEditModal={() => setEdit(true)}
                  objectName={customer.name}
                />
                <div
                  className="tag tag--red mr--5"
                  style={{ margin: "0 0.5rem" }}
                >
                  Customer
                </div>
                {// TODO: To simplify checking, if they submit an empty string, make it null
                (customer.phone === null && customer.email === null) ||
                (customer.phone === "" && customer.email === "") ||
                (customer.phone === null && customer.email === "") ||
                (customer.phone === "" && customer.email === null) ? (
                  <ButtonOutlined
                    isAdding
                    text={"Add Details"}
                    onClick={() => setEdit(true)}
                  />
                ) : null}
                <EditCustomerInfo
                  open={edit}
                  customer={customer}
                  setOpen={setEdit}
                />
              </div>
              <div className="drawer__row">
                <div className="drawer__name">
                  {customer.displayName || customer.name}
                </div>

                <TooltipPhone>
                  {customer.phoneExt
                    ? `${customer.phone} x${customer.phoneExt}`
                    : customer.phone}
                </TooltipPhone>

                <TooltipEmail style={{ marginLeft: "5px" }}>
                  {customer.email}
                </TooltipEmail>
              </div>
            </div>
          </section>
          <CustomerDefaultTags
            locationCount={locations.length}
            laneCount={lanes.length}
            volume={freq}
            spend={spend}
          />
        </div>
      </header>

      <section className="drawer__lower" style={{ display: "flex" }}>
        <div className="drawer__lower__top">
          <QuickTagsList page="customer" item={customer} />
        </div>
        <div className="drawer__lower__columns">
          <div className="drawer__lower__column drawer__lower__column--left">
            <DrawerToolbelt type={"customer"} customer={customer} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--middle">
            <Locations locations={locations} />
            <DrawerLanes view={"customer"} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--right">
            <DrawerTeammateTaggedList customer={customer} page="customer" />
            <CustomerBio customer={customer} />
            <DrawerContacts page="customer" item={customer} />
          </div>
        </div>
      </section>
    </>
  );
}
