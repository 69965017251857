import React, { useContext } from "react";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import { UserContext } from "../providers/UserProvider";

export default function FormAddWorkflowTutorialLane() {
  const { setShowTutorial } = useContext(UserContext);

  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-around",
    margin: "2em"
  };

  const paragraphStyle = {
    fontSize: "1.2em",
    lineHeight: "1.5em"
  };

  const spanStyle = {
    fontWeight: "900"
  };

  return (
    <section style={containerStyle}>
      <p style={paragraphStyle}>
        This <span style={spanStyle}>Lane Page</span> is designed to help you
        acquire this business.
      </p>
      <p style={paragraphStyle}>
        You'll need to know this lane's <span style={spanStyle}>rate</span> and{" "}
        <span style={spanStyle}>volume</span>.
      </p>

      <ButtonSolid
        style={{
          padding: "1.4em",
          fontSize: "1em"
        }}
        onClick={() => setShowTutorial(false)}
        color="red"
      >
        <span style={spanStyle}>
          Get Started with the Conversation Toolbelt!
        </span>
      </ButtonSolid>
    </section>
  );
}
