import React from 'react'
import './Buttons.css'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

export default function EditButton({ onClick }) {
    return (
        <div className='editBtn' onClick={onClick}>
            <CreateOutlinedIcon className='editBtn__img' style={{ fontSize: '16px' }} />
            <div className='editBtn__txt'>Edit</div>
        </div>
    )
}