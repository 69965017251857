import { CircularProgress, Dialog } from "@material-ui/core";
import React, { useContext, useState } from "react";
import Hours from "../../generic/select/Hours";
import { LocationContext } from "../../providers/LocationProvider";
import "./CustomerDrawer.css";

export default function EditLocationInfo({ open, location, setOpen }) {
  const { updateLocation } = useContext(LocationContext);
  const [email, setEmail] = useState(location.email);
  const [phone, setPhone] = useState(location.phone);
  const [openTime, setOpenTime] = useState(location.open);
  const [closeTime, setCloseTime] = useState(location.close);
  const [hoursType, setHoursType] = useState(location.hoursType || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = e => {
    setPhone(e.target.value);
  };

  const handleOpenTimeChange = e => {
    setOpenTime(e.target.value);
  };

  const handleCloseTimeChange = e => {
    setCloseTime(e.target.value);
  };

  const handleHoursTypeChange = e => {
    setHoursType(e.target.value);
  };

  const handleCancel = e => {
    setOpen(false);
  };

  async function handleSave(e) {
    setIsSubmitting(true);
    e.preventDefault();
    const updatedLocation = {
      id: location.id,
      open: openTime,
      close: closeTime,
      hoursType: hoursType,
      phone: phone,
      email: email
    };
    const response = await updateLocation(updatedLocation);
    if (response !== null) {
      setOpen(false);
      setIsSubmitting(false);
    }
  }

  return (
    <Dialog open={open}>
      <div className="editDialog">
        <div className="editDialog__top">
          <div className="editDialog__title">Edit Location Info</div>
          <div className="editDialog__btns">
            <div className="cancel dialog__btn click" onClick={handleCancel}>
              Cancel
            </div>
            {isSubmitting ? (
              <CircularProgress
                color="secondary"
                style={{ width: "19px", height: "19px", marginRight: "20px" }}
              />
            ) : (
              <div className="save dialog__btn click" onClick={handleSave}>
                Save
              </div>
            )}
          </div>
        </div>
        <div className="editDialog__bottom">
          <input
            className="input editDialog__input--quarter"
            placeholder="phone"
            value={phone}
            onChange={handlePhoneChange}
          />
          <input
            className="input editDialog__input--half"
            placeholder="email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="editDialog__bottom">
          <select
            className="input editDialog__input--quarter"
            onChange={handleOpenTimeChange}
            value={openTime}
          >
            <option value={null}>Open</option>
            <Hours />
          </select>
          <select
            className="input editDialog__input--quarter"
            onChange={handleCloseTimeChange}
            value={closeTime}
          >
            <option value={null}>Close</option>
            <Hours />
          </select>
          <select
            className="input editDialog__input--half"
            onChange={handleHoursTypeChange}
            value={hoursType}
          >
            <option value={""}>Hours Type</option>
            <option value={"FCFS"}>FCFS</option>
            <option value={"Appt Only"}>Appt Only</option>
          </select>
        </div>
      </div>
    </Dialog>
  );
}
