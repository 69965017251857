import React from 'react'
import TagOption from '../TagOption'

export default function Commodity({ page, item }) {
    return (
        <div className='addTags__tagList'>
            <TagOption id={54} content={'Pallatized'} page={page} item={item} />
            <TagOption id={55} content={'Drums'} page={page} item={item} />
            <TagOption id={56} content={'Floor Loaded'} page={page} item={item} />
            <TagOption id={57} content={'Bags'} page={page} item={item} />
            <TagOption id={58} content={'Rolls'} page={page} item={item} />
            <TagOption id={59} content={'Boxed'} page={page} item={item} />
            <TagOption id={60} content={'Wrapped'} page={page} item={item} />
            <TagOption id={61} content={'Straps'} page={page} item={item} />
            <TagOption id={62} content={'Load Locks'} page={page} item={item} />
            <TagOption id={63} content={'Refrigerated'} page={page} item={item} />
            <TagOption id={64} content={'Frozen'} page={page} item={item} />
            <TagOption id={65} content={'Fresh'} page={page} item={item} />
            <TagOption id={66} content={'Food Grade'} page={page} item={item} />
            <TagOption id={67} content={'Hazardous'} page={page} item={item} />
            <TagOption id={68} content={'Liquid'} page={page} item={item} />
            <TagOption id={69} content={'High Value'} page={page} item={item} />
            <TagOption id={70} content={'Live Animals'} page={page} item={item} />
        </div>
    )
}