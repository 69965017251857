import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemEstimatedVolume({
  item,
  updateMethod
}) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"number"}
      valueType={"loads per month"}
      updateMethod={updateMethod}
      nameOfValue="estimatedVolume"
      title={"Estimated Volume"}
      label={"Loads per month"}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          This is just an estimate. Give it your best guess.
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemEstimatedVolume.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
