import React from "react";

export default function SettingsCardTitle(props) {
  const titleStyle = {
    margin: "0",
    marginBottom: "1.5em",
    fontSize: "1.4em",
    fontWeight: 900
  };

  return <h2 style={titleStyle}>{props.children}</h2>;
}
