import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DrawerSectionHeader from "./DrawerSectionHeader";
import DrawerToolbeltListCustomer from "./DrawerToolbeltListCustomer";
import DrawerToolbeltListLocation from "./DrawerToolbeltListLocation";
import DrawerToolbeltListLane from "./DrawerToolbeltListLane";
// Selects toolbelt component based on page

export default function DrawerToolbelt({ type, customer, location, lane }) {
  const [activeToolbelt, setActiveToolbelt] = useState(null);

  useEffect(() => {
    function selectToolbelt(toolbelt) {
      const toolbelts = {
        customer: <DrawerToolbeltListCustomer customer={customer} />,
        location: <DrawerToolbeltListLocation location={location} />,
        lane: <DrawerToolbeltListLane lane={lane} />
      };

      return toolbelts[toolbelt] ?? null;
    }

    setActiveToolbelt(selectToolbelt(type));
  }, [customer, lane, location, type]);

  return (
    <section style={{ marginBottom: "3rem" }}>
      <DrawerSectionHeader hasUnderline>
        Conversation Toolbelt
      </DrawerSectionHeader>
      {activeToolbelt}
    </section>
  );
}

DrawerToolbelt.propTypes = {
  type: PropTypes.string.isRequired, // Customer, Location, Lane
  customer: PropTypes.object,
  location: PropTypes.object,
  lane: PropTypes.object
};
