import React, { useState, useEffect, useContext } from "react";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import DrawerCustomerTopList from "../components/drawers/DrawerCustomerTopList";
import TopLanes from "../components/drawers/user/TopLanes";
import UserLedger from "../components/drawers/ledger/UserLedger";
import { UserContext } from "../components/providers/UserProvider";
import QuickTagsList from "../components/drawers/tags/QuickTagsList";
import ProfileImage from "../components/library/profile/ProfileImage";
import useProfileImage from "../hooks/useProfileImage";
import TooltipPhone from "../components/library/tooltip/TooltipPhone";
import TooltipEmail from "../components/library/tooltip/TooltipEmail";
import UserDefaultTags from "../components/drawers/tags/UserDefaultTags";

export default function UserViewDrawerContent({
  user,
  lanes,
  spend,
  loadsPerMonth
}) {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const profileImage = useProfileImage(user);
  const { getTopCustomersForUser } = useContext(UserContext);
  const [customers, setCustomers] = useState([]);
  const { isDrawerOpen, workflow } = useContext(ApplicationStateContext);

  useEffect(() => {
    getTopCustomersForUser(user.id, workflow).then(customers =>
      setCustomers(customers)
    );
  }, [getTopCustomersForUser, user, workflow]);

  return (
    <>
      <header
        className={`drawer__upper mb--10 drawer__upper--${
          isDrawerOpen ? "open" : "close"
        }`}
      >
        <div className="drawer__top">
          <section className="drawer__column--2">
            <div className="drawer__image mr--10">
              <ProfileImage
                size="large"
                location="drawerHeader"
                type="teammate"
                name={user.firstName}
                image={profileImage}
                icon={user.Team?.icon}
              />
            </div>
            <div className="drawer__info">
              <div className="drawer__row">
                <div className="tag tag--blue--inverse mr--5">Teammate</div>
                {currentUser.id === user.id && (
                  <div className="tag tag--blue">You</div>
                )}
              </div>
              <div className="drawer__row">
                <div className="drawer__name">
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div className="drawer__row">
                <div className="mr--5">{user.title}</div>

                <TooltipPhone>
                  {user.phoneExt
                    ? `${user.phone} x${user.phoneExt}`
                    : user.phone}
                </TooltipPhone>

                <TooltipEmail style={{ marginLeft: "5px" }}>
                  {user.email}
                </TooltipEmail>
              </div>
            </div>
          </section>
          <UserDefaultTags
            customerCount={user.customerCount}
            loadsPerMonth={loadsPerMonth}
            spend={spend}
          />
        </div>
      </header>

      <section className="drawer__lower" style={{ display: "flex" }}>
        <div className="drawer__lower__top">
          <QuickTagsList page="user" item={user} />
        </div>
        <div className="drawer__lower__columns">
          <div className="drawer__lower__column drawer__lower__column--left">
            <DrawerCustomerTopList
              user={user}
              customers={customers}
              name={`${user.firstName}'s`}
            />
          </div>
          <div className="drawer__lower__column drawer__lower__column--middle">
            <TopLanes lanes={lanes} name={`${user.firstName}'s`} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--right">
            <UserLedger user={user} />
          </div>
        </div>
      </section>
    </>
  );
}
