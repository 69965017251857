import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";
import IconCheck from "../icons/IconCheck";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

export default function DrawerToolbeltStateAdded({
  title,
  infoButton,
  valueType,
  originalValue,
  valueIsCurrency,
  onEdit
}) {
  const containerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "var(--lightestGreen)",
    border: "2px solid var(--lightestGreen)",
    borderRadius: "10px",
    padding: "1em",
    margin: "0.5em 0"
  };

  const headerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  };

  const circleStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "var(--darkGreen)",
    height: "8px",
    width: "8px",
    padding: "4px",
    marginRight: "5px",
    borderRadius: "50%"
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "0.9em",
    color: "var(--darkGreen)"
  };

  const valueStyle = {
    fontWeight: "400",
    fontSize: "0.9em",
    paddingRight: "20px",
    marginTop: "0.5em",
    whiteSpace: "pre-wrap"
  };

  const {
    isSubmittingToolbelt,
    clickedToolbeltTitle,
    triggerToolbeltSave,
    isToolbeltCancelButtonClicked
  } = useContext(ApplicationStateContext);

  // Same checks as DrawerToolbeltStateEmpty
  useEffect(() => {
    if (
      clickedToolbeltTitle === title &&
      triggerToolbeltSave === false &&
      isToolbeltCancelButtonClicked === false
    ) {
      onEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clickedToolbeltTitle,
    triggerToolbeltSave,
    isToolbeltCancelButtonClicked,
    onEdit
  ]);

  return (
    <div style={containerStyle}>
      <div>
        <header style={headerStyle}>
          <div style={circleStyle}>
            <IconCheck />
          </div>
          <span style={titleStyle}>{title}</span>
          {infoButton}
        </header>
        <div style={valueStyle}>
          {valueIsCurrency === true ? "$" : ""}
          {originalValue} {valueType}
        </div>
      </div>

      <ButtonOutlined
        style={{ width: "4.5em" }}
        text={"Edit"}
        onClick={onEdit}
        color="green"
        isSubmitting={isSubmittingToolbelt}
        isDisabled={isSubmittingToolbelt}
      />
    </div>
  );
}

DrawerToolbeltStateAdded.propTypes = {
  title: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
  originalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueIsCurrency: PropTypes.bool,
  valueType: PropTypes.string,
  onEdit: PropTypes.func.isRequired
};
