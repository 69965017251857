import React, { useContext, useState } from "react";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import UpdateImage from "../../library/images/UpdateImage";
import SettingsCardTitle from "../SettingsCardTitle";
import {states} from "./utils";
import { CircularProgress } from "@material-ui/core";

export default function ManageCompany({ brokerage, setButtonPressed, mode }) {
  const { updateBrokerage, createBrokerage, getBrokerage, getAllBrokerages } = useContext(BrokerageContext);
  const [name, setName] = useState(brokerage?.name);
  const [address, setAddress] = useState(brokerage?.address);
  const [address2, setAddress2] = useState(brokerage?.address2);
  const [city, setCity] = useState(brokerage?.city);
  const [state, setState] = useState(brokerage?.state);
  const [zip, setZip] = useState(brokerage?.zipcode);
  const [email, setEmail] = useState(brokerage?.email);
  const [phone, setPhone] = useState(brokerage?.phone);
  const [clickedButton, setClickedButton] = useState(false);

  const handleInputChange = setter => e => {
    setter(e.target.value);
  };

  async function handleSubmit() {
    const payload = {
      id: brokerage.id,
      name: name,
      address: address,
      address2: address2,
      city: city,
      state: state,
      zipcode: zip,
      email: email,
      phone: phone
    };
    setClickedButton(true);
    if(mode==="add"){
      delete updateBrokerage.id;
      await createBrokerage(payload);
    } else
      await updateBrokerage(payload);
    setClickedButton(false);
    setButtonPressed("")
  }

  if (!brokerage) {
    return null;
  }

  return (
    <>
      <SettingsCardTitle>
        {mode === "add" ? "Create" : "Manage"} {name == null ? "Company" : name}
      </SettingsCardTitle>

      <div className="settings__profile">
        <div className="settings__profile__left">
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Name</div>
            <input
              className="settings__profile__input__field"
              placeholder="Brokerage"
              value={!name?"":name}
              // defaultValue={!name?"":name}
              onChange={handleInputChange(setName)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Location</div>
            <input
              className="settings__brokerage__input__field"
              placeholder="Address 1"
              value={!address?"":address}
              // defaultValue={!address?"":address}
              onChange={handleInputChange(setAddress)}
            />
            <input
              className="settings__brokerage__input__field"
              placeholder="Address 2"
              value={!address2?"":address2}
              // defaultValue={!address2?"":address2}
              onChange={handleInputChange(setAddress2)}
            />
            <input
              className="settings__brokerage__input__field--half"
              placeholder="City"
              value={!city?"":city}
              // defaultValue={!city?"":city}
              onChange={handleInputChange(setCity)}
            />
            <select
              className="settings__brokerage__select__field--quarter"
              value={state}
              onChange={handleInputChange(setState)}
            >
              {states.map((e, i) => {
                return (
                  <option value={e.value} key={i} hidden={e.disabled}>
                    {e.label}
                  </option>
                );
              })}
            </select>
            <input
              className="settings__brokerage__input__field--quarter"
              placeholder="Zipcode"
              value={zip}
              defaultValue={zip}
              onChange={handleInputChange(setZip)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Email</div>
            <input
              className="settings__profile__input__field"
              placeholder="Email"
              value={email}
              defaultValue={email}
              onChange={handleInputChange(setEmail)}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Phone</div>
            <input
              className="settings__profile__input__field--half"
              placeholder="Phone"
              value={phone}
              defaultValue={phone}
              onChange={handleInputChange(setPhone)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {clickedButton ? (
              <CircularProgress
                color="secondary"
                style={{ width: "3em", height: "3em", margin:"0 29.2px" }}
              />
            ) : (
              <div
                className="settings__save click manage_comp"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Create" : "Save"}
              </div>
            )}
            <div
              className="settings__save click manage_comp"
              onClick={() => setButtonPressed("")}
            >
              Back
            </div>
          </div>
        </div>
        <div className="settings__profile__right">
          <UpdateImage sourceType="Brokerage" source={brokerage} />
        </div>
      </div>
    </>
  );
}
