import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonGear from "../generic/buttons/ButtonGear";
import FormDeleteWorkflow from "../forms/FormDeleteWorkflow";

export default function DrawerHeaderSettingsButton(props) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  let items = [];

  if (props.openEditModal) {
    items = [
      {
        title: "Edit",
        method: () => props.openEditModal()
      },
      {
        title: "Delete",
        method: () => setIsDeleteOpen(true)
      }
    ];
  } else {
    items = [
      {
        title: "Delete",
        method: () => setIsDeleteOpen(true)
      }
    ];
  }

  return (
    <>
      <ButtonGear popoutItems={items} style={props.style} />
      <FormDeleteWorkflow
        objectNameToDelete={props.objectName}
        isModalOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
      />
    </>
  );
}

DrawerHeaderSettingsButton.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object,
    openEditModal: PropTypes.func.isRequired,
    objectName: PropTypes.string
  })
};
