import React, { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import ManageUser from "./ManageUser";
import { SearchContext } from "../../providers/SearchProvider";
import debounce from "lodash.debounce";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";

export default function ManageUsers() {
  const { getUsersInBrokerage } = useContext(BrokerageContext);
  const { searchUsersInBrokerage } = useContext(SearchContext);
  const { setIsInviteModalOpen } = useContext(ApplicationStateContext);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [sortBy, setSortBy] = useState("user");

  const sortUsers = users => {
    let sortedUsers;
    switch (sortBy) {
      case "user":
        sortedUsers = users.sort((a, b) => {
          if (a.firstName > b.firstName) {
            return 1;
          } else if (a.firstName < b.firstName) {
            return -1;
          }

          if (a.lastName > b.lastName) {
            return 1;
          } else if (a.lastName < b.lastName) {
            return -1;
          } else {
            return 0;
          }
        });
        return sortedUsers;
      case "team":
        sortedUsers = users.sort((a, b) => {
          if (!b.Team) {
            return -1;
          } else if (a.Team?.name > b.Team?.name) {
            return -1;
          } else if (a.Team?.name < b.Team?.name) {
            return 0;
          } else {
            return 1;
          }
        });
        return sortedUsers;
      case "status":
        sortedUsers = users.sort((a, b) => {
          if (!b.active) {
            return -1;
          } else if (a.active === b.active) {
            return 0;
          } else {
            return 1;
          }
        });
        return sortedUsers;
    }
  };

  async function refresh() {
    const users = await getUsersInBrokerage();
    setUsers(users);
  }

  // DEBOUNCE FOR SEARCH
  async function updateResults() {
    const results = await searchUsersInBrokerage(query);
    if (results !== null) {
      setResults(results);
    }
  }

  const delayedResults = useCallback(debounce(updateResults, 300), [query]);

  useEffect(() => {
    (async function() {
      const users = await getUsersInBrokerage();
      const sortedUsers = sortUsers(users);
      setUsers(sortedUsers);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    delayedResults();

    return delayedResults.cancel; // cancel previous  calls during useEffect cleanup.
  }, [query, delayedResults]);

  const handleQuery = e => {
    setQuery(e.target.value);
  };

  const renderUsers = () => {
    if (results.length) {
      const components = results.map(r => {
        return <ManageUser user={r} refresh={refresh} />;
      });

      return components;
    } else {
      const components = users.map(user => {
        return (
          <ManageUser
            key={user.id}
            user={user}
            refresh={refresh}
            setSortBy={setSortBy}
          />
        );
      });
      return components;
    }
  };

  const handleUserClick = () => {
    setSortBy("user");
  };

  // const handleTeamClick = () => {
  //     setSortBy('team')
  // }

  const handleStatusClick = () => {
    setSortBy("status");
  };

  return (
    <>
      <div className="settings__user__header">
        <div className="settings__user__titles">
          <div className="settings__card__title--noBottomMargin">
            Manage Users
          </div>
          <div className="settings__user__subtitle">
            {users.length} Active Users
          </div>
        </div>
        <div
          className="settings__user__btn btn__save"
          onClick={() => setIsInviteModalOpen(true)}
        >
          + Invite New User
        </div>
      </div>
      <div className="settings__user__search">
        <SearchIcon style={{ color: "#D8D8D8" }} />
        <input
          className="settings__user__input"
          placeholder="Search Users"
          onChange={handleQuery}
          value={query}
        />
      </div>
      <div className="settings__user__table">
        <div className="settings__user__thead">
          <div
            className="settings__user__th click"
            onClick={handleUserClick}
            style={{ margin: "0" }}
          >
            User{" "}
            {sortBy === "user" && (
              <ExpandLessIcon
                style={{
                  fontSize: "20px",
                  transform: "scaleY(-1)",
                  outline: "none"
                }}
              />
            )}
          </div>
          {
            // <div className='settings__user__th click' onClick={handleTeamClick}>
            //     Team {sortBy === 'team' &&
            //     <ExpandLessIcon style={{fontSize: '20px', transform: 'scaleY(-1)', outline: 'none'}} />}
            //     </div>
          }
          <div className="settings__user__th click" onClick={handleStatusClick}>
            Account Status{" "}
            {sortBy === "status" && (
              <ExpandLessIcon
                style={{
                  fontSize: "20px",
                  transform: "scaleY(-1)",
                  outline: "none"
                }}
              />
            )}
          </div>
        </div>
        {!!users.length && renderUsers()}
      </div>
    </>
  );
}
