import { format } from 'date-fns'
import React from 'react'
import {numberWithCommas} from '../../generic/Utils'

export default function PaymentHistory({ invoices }) {

    return (
        <div className='manageBilling__card'>
            <div className='settings__card__title'>Payment History </div>
            <div className='paymentHistory'>
                <div className='paymentHistory__thead'>
                    <div className='paymentHistory__th'>Billing Date</div>
                    <div className='paymentHistory__th'>Total Billed</div>
                    <div className='paymentHistory__th'>Status</div>
                </div>
                {
                    invoices.data[0].lines.data.map(line => {
                        return (
                            <div className='paymentHistory__trow'>
                                <div className='paymentHistory__td'>{format(new Date(line.period.start * 1000), 'MMMM d, y')}</div>
                                <div className='paymentHistory__td'>${numberWithCommas(line.amount / 100)}</div>
                                <div className='paymentHistory__td'>Paid</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}