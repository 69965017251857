import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import UpgradeAccountWorkflow from '../billing/UpgradeAccountWorkflow'
import './FormInviteWorkflow.css'
import FormModalFooterBidirectional from './FormModalFooterBidirectional'
import InviteMoreUsersWorkflow from '../billing/InviteMoreUsersWorkflow'
import { toast } from 'react-toastify'

export default function FormInviteWorkflowInputs({ activeModal, setActiveModal, closeModal, existingUsers, brokerage }) {
    const prevStagedUsers = JSON.parse(sessionStorage.getItem('stagedInvites'))
    const [stagedUsers, setStagedUsers] = useState(prevStagedUsers?.length > 0 ? prevStagedUsers : [''])
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (stagedUsers.length > 0 && stagedUsers.slice(-1)[0] !== '') {
            setIsActive(true)
        }
        else {
            setIsActive(false)
        }
    }, [stagedUsers])

    const addAdditionalInput = () => {
        const lastValue = stagedUsers.slice(-1)[0]
        if (lastValue === '') {
            return
        }
        setStagedUsers(prev => prev.concat(''))
    }

    const handleChange = index => e => {
        if (e.target.value === '' && stagedUsers.length > 1) {
            setStagedUsers(prev => prev.filter((v, i) => i !== index))
            return
        }
        const updated = [...stagedUsers]
        updated[index] = e.target.value
        setStagedUsers(updated)
    }

    const handleDelete = index => {
        setStagedUsers(prev => prev.filter((v, i) => i !== index))
    }

    const onSubmit = () => {
        const filteredStagedUsers = stagedUsers.filter(email => {
            if (existingUsers.find(u => u.email === email)) {
                toast.error(`${email} has already been invited.`)
                return
            }
            else {
                return email
            }
        })
        sessionStorage.setItem('stagedInvites', JSON.stringify(filteredStagedUsers))
        // -------------------------
        // TO DO: Differentiate previously upgraded vs. yet to upgrade workflows
        // -------------------------

        const stepDataSelection = brokerage.stripeSubscriptionId
            ? {
                modalWidth: "45em",
                modalHeight: "40em",
                hasHeader: true,
                hasHeaderCloseBtn: true,
                headerText: brokerage.stripeSubscriptionId ? "Review Your Invites" : "Upgrade Your Account",
                headerIcon: "",
                isSubmitting: false,
                flexColumn: true,
                content: (
                    <InviteMoreUsersWorkflow brokerage={brokerage} existingUsers={existingUsers} setActiveModal={setActiveModal} activeModal={activeModal} closeModal={closeModal} />
                )
            }
            : {
                modalWidth: "45em",
                modalHeight: "40em",
                hasHeader: true,
                hasHeaderCloseBtn: true,
                headerText: brokerage.stripeSubscriptionId ? "Review Your Invites" : "Upgrade Your Account",
                headerIcon: "",
                isSubmitting: false,
                flexColumn: true,
                content: (
                    <UpgradeAccountWorkflow brokerage={brokerage} existingUsers={existingUsers} setActiveModal={setActiveModal} activeModal={activeModal} closeModal={closeModal} />
                )
            };
        setActiveModal(stepDataSelection)
    }

    return (
        <div className='formInviteWorkflowInputs'>
            {(brokerage && !brokerage.stripeSubscriptionId) &&
                <div className='formInviteWorkflowInputs__upgrade'>It looks like you haven’t set up billing for your account yet.
                    By inviting additional users, your plan will be upgraded to a premium account. {' '}
                    <a href='https://terralanes.com/pricing' target='_blank'>Learn more about pricing</a>.</div>
            }
            <div className='formInviteWorkflowInputs__list'>
                {stagedUsers.map((v, i) => {
                    return <div className='formInviteWorkflowInputs__inputContainer' key={i}>
                        <TextField
                            id={`${i}`}
                            key={i}
                            name='email'
                            autoComplete='off'
                            style={{ width: '99%', marginTop: '8px' }}
                            variant='outlined'
                            size='small'
                            placeholder='name@logisticsco.com'
                            value={v}
                            onChange={handleChange(i)}
                        />
                        <button className='formInviteWorkflowInputs__inputDelete' onClick={() => handleDelete(i)}>x</button>
                    </div>
                })}
            </div>
            <button onClick={addAdditionalInput} className='formInviteWorkflowInputs__add'>+ Add Another</button>
            <FormModalFooterBidirectional isActive={isActive} onAdvanceClick={onSubmit} buttonAdvanceLabel='Continue' buttonPrevious={false} />
        </div>
    )
}