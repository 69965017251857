import React from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function BillingCycleYearly({ brokerage, selected, setSelected, users }) {
    return (
        <div className='billing'>
            <div className={selected ? 'billing__top--selected click' : 'billing__top click'} onClick={() => setSelected('yearly')}>
            {selected
                ? <CheckCircleOutlineIcon className='billing__icon--selected'/>
                : <RadioButtonUncheckedIcon className='billing__icon' />
            }
            Yearly (Save 20%)
            </div>
            <div className='billing__main'>
                <div className='billing__section'>
                    <div className={selected ? 'billing__section__title--selected' : 'billing__section__title'}>Total Users</div>
                    <div className={selected ? 'billing__section__content--selected' : 'billing__section__content'}>{brokerage.teammates + users - 1}</div>
                    <div className='billing__spacer__bottom'>{}</div>
                </div>
                <div className='billing__section'>
                    <div className='billing__spacer__top'>{}</div>
                    <div className={selected ? 'billing__section__icon--selected' : 'billing__section__icon'}>{'\u2715'}</div>
                    <div className='billing__spacer__bottom'>{}</div>
                </div>
                <div className='billing__section'>
                    <div className={selected ? 'billing__section__title--selected' : 'billing__section__title'}>Price per User</div>
                    <div className={selected ? 'billing__section__content--selected' : 'billing__section__content'}>$50</div>
                    <div className='billing__spacer__bottom'>{}</div>
                </div>
                <div className='billing__section'>
                    <div className='billing__spacer__top'>{}</div>
                    <div className={selected ? 'billing__section__icon--selected' : 'billing__section__icon'}>=</div>
                    <div className='billing__spacer__bottom'>{}</div>
                </div>
                <div className='billing__section'>
                    <div className={selected ? 'billing__section__title--selected billing__red' : 'billing__section__title'}>Total Billed Yearly</div>
                    <div className={selected ? 'billing__section__content--selected billing__red' : 'billing__section__content'}>${(brokerage.teammates + users - 1) * 40 * 12}</div>
                    <div className={selected ? 'billing__section__subtitle--selected billing__red billing__strikethrough' : 'billing__section__subtitle billing__strikethrough'}>${(brokerage.teammates + users - 1)*50*12}/year</div>
                </div>
            </div>
        </div>
    )
}