import React, { useContext } from "react";
import DrawerLanesTableRow from "./DrawerLanesTableRow";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

// DrawerLanes decides which Owned, Opp, Total to show
export default function DrawerLanesTable({
  lanes,
  workflow,
  showEdit,
  setEditableLanes,
  isLoading
}) {
  const { setIsAddModalOpen } = useContext(ApplicationStateContext);

  // Refactor to use HTML elements
  return (
    <div className="table">
      <div className="thead">
        <div className="th td--wide">Origin</div>
        <div className="th td--wide">Destination</div>
        <div className="th">Loads/mo</div>
        <div className="th">Rate</div>
      </div>
      <div
        className="tbody"
        style={{ display: "flex", flexFlow: "column nowrap" }}
      >
        {isLoading ? (
          <CircularProgress
            color="secondary"
            style={{ placeSelf: "center", margin: "50px" }}
          />
        ) : lanes.length === 0 ? (
          workflow === "owned" ? (
            <div style={{ margin: "2em", textAlign: "center" }}>
              No owned lanes
            </div>
          ) : (
            <ButtonOutlined
              isAdding
              text="Add Lane"
              style={{ margin: "2em" }}
              onClick={() => setIsAddModalOpen(true)}
            />
          )
        ) : (
          lanes.map((l, i) => {
            return (
              <DrawerLanesTableRow
                lane={l}
                workflow={workflow}
                key={l.id+i}
                showEdit={showEdit}
                setEditableLanes={setEditableLanes}
                i={i}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

DrawerLanesTable.propTypes = {
  lanes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  workflow: PropTypes.string.isRequired,
  showEdit: PropTypes.bool.isRequired,
  setEditableLanes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};
