import React, { useContext } from "react";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";
import TopLaneRow from "./TopLaneRow";
import "./TopLanes.css";

export default function TopLanes({ name, lanes }) {
  const { workflow } = useContext(ApplicationStateContext);

  return (
    <div className="topLanes">
      <div className="titleContainer">
        <div className="title">{`${name} Top Lanes`}</div>
      </div>
      <div className="table">
        <div className="thead">
          <div className="th td--wide">Origin</div>
          <div className="th td--wide">Destination</div>
          <div className="th">Loads/mo</div>
          <div className="th">Rate</div>
        </div>
        <div className="tbody">
          {lanes.length ? (
            lanes.map(lane => (
              <TopLaneRow key={lane.id} lane={lane} workflow={workflow} />
            ))
          ) : (
            <div className="trow">
              <div className="td" style={{ textAlign: "center" }}>
                {workflow === "owned"
                  ? "No owned lanes."
                  : "No lanes added yet."}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
