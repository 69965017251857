import React from "react";
import PropTypes from "prop-types";
import IconGear from "../../icons/IconGear";
import Popout from "../../library/popout/Popout";
import useComponentVisible from "../../../hooks/useComponentVisible";
import "./ButtonGear.css";

export default function ButtonGear(props) {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  return (
    <button
      className={`buttonGear__button buttonGear__button--active--${isComponentVisible}`}
      style={props.style}
      onClick={() => setIsComponentVisible(true)}
    >
      <IconGear />
      <Popout
        ref={ref}
        isVisible={isComponentVisible}
        popoutItems={props.popoutItems}
      />
    </button>
  );
}

ButtonGear.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object,
    popoutItems: PropTypes.array.isRequired
  })
};
