import React from "react";
import styled from "styled-components";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

export default function SettingsContentUploadDownloadTemplate() {
  function handleClick() {
    const s3 = `https://terralanes-public-assets.s3.amazonaws.com/Terralanes-CSV-Upload-Template.xlsx`;
    window.open(s3, "_blank").focus();
  }

  return (
    <TemplateButton type="button" onClick={handleClick}>
      <GetAppOutlinedIcon style={iconStyle} /> Download Template
    </TemplateButton>
  );
}

const TemplateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  padding-right: 1.4em;
  margin-top: 1em;
  margin-left: 5em;
  width: fit-content;
  border-radius: 9px;
  border: 2px solid var(--darkRed);
  color: var(--darkRed);
  font-size: 1.1em;
  font-weight: 900;
  transition: background-color 0.15s;

  &:hover {
    background-color: var(--lightRed);
  }
`;

const iconStyle = {
  width: "2em",
  height: "2em"
};
