import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormModal from "./FormModal";
import FormInviteUsersInputs from "./FormInviteWorkflowInputs";
import { BrokerageContext } from "../providers/BrokerageProvider";

export default function FormInviteWorkflow({ isModalOpen, closeModal }) {
  const { getBrokerage, getUsersInBrokerage } = useContext(BrokerageContext)
  const currentUser = JSON.parse(sessionStorage.getItem('user'))
  const [existingUsers, setExistingUsers] = useState([])
  const [brokerage, setBrokerage] = useState()
  const [activeModal, setActiveModal] = useState(null);

  useEffect(() => {
    async function checkUsersInBrokerage() {
        const users = await getUsersInBrokerage()
        setExistingUsers(users)
        const brokerage = await getBrokerage(currentUser.brokerageId)
        setBrokerage(brokerage)
    }
    checkUsersInBrokerage()
}, [isModalOpen])

  const stepDataSelection = {
    modalWidth: "45em",
    modalHeight: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Invite Users",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormInviteUsersInputs activeModal={activeModal} setActiveModal={setActiveModal} closeModal={closeModal} brokerage={brokerage} existingUsers={existingUsers}/>
    )
  };

  useEffect(() => {
    // Debounce resetting modal until closing animation completes
    const handler = setTimeout(() => {
      setActiveModal(stepDataSelection);
    }, 400);

    return () => {
      clearTimeout(handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  if (activeModal === null) {
    return null;
  }

  return (
    <FormModal
      modalWidth={activeModal.modalWidth}
      modalHeight={activeModal.modalHeight}
      isModalOpen={isModalOpen}
      closeModal={activeModal.isSubmitting ? () => { } : closeModal} // Need an empty function instead of null
      hasHeader={activeModal.hasHeader}
      hasHeaderCloseBtn={activeModal.hasHeaderCloseBtn}
      headerIcon={activeModal.headerIcon}
      headerText={activeModal.headerText}
    >
      {activeModal.content}
    </FormModal>
  );
}

FormInviteWorkflow.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
