import { useState, useEffect } from "react";

export default function useFormIconSize(iconName, size) {
  const [assignedSize, setAssignedSize] = useState("5");
  const [iconSizes, setIconSizes] = useState({
    small: "0",
    medium: "0",
    large: "0"
  });

  useEffect(() => {
    const customerSizes = {
      small: "20",
      medium: "30",
      large: "70"
    };

    const locationsSizes = {
      small: "17",
      medium: "30",
      large: "70"
    };

    const laneSizes = {
      small: "16",
      medium: "25",
      large: "70"
    };

    function assignIconSizes() {
      switch (iconName) {
        case "Customer":
          setIconSizes(customerSizes);
          break;
        case "Location":
          setIconSizes(locationsSizes);
          break;
        case "Lane":
          setIconSizes(laneSizes);
          break;
        default:
          break;
      }
    }

    assignIconSizes();
  }, [iconName]);

  useEffect(() => {
    function assignSize() {
      switch (size) {
        case "small":
          setAssignedSize(iconSizes.small);
          break;
        case "medium":
          setAssignedSize(iconSizes.medium);
          break;
        case "large":
          setAssignedSize(iconSizes.large);
          break;
        default:
          setAssignedSize("25");
          break;
      }
    }

    assignSize();
  }, [iconSizes.large, iconSizes.medium, iconSizes.small, size]);

  return assignedSize;
}
