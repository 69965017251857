import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SettingsNavItem from "./SettingsNavItem";

export default function SettingsNav({ user, selectedItem, handleClick }) {
  return (
    <NavContainer>
      <Nav>
        <SettingsNavItem
          name="profile"
          selectedItem={selectedItem}
          handleClick={handleClick}
        >
          Update Profile
        </SettingsNavItem>

        <SettingsNavItem
          name="password"
          selectedItem={selectedItem}
          handleClick={handleClick}
        >
          Update Password
        </SettingsNavItem>

        <SettingsNavItem
          name="upload"
          selectedItem={selectedItem}
          handleClick={handleClick}
        >
          Upload Data
        </SettingsNavItem>

        {user.admin && (
          <>
            <SettingsNavItem
              name="brokerage"
              selectedItem={selectedItem}
              handleClick={handleClick}
            >
              Manage Brokerage
            </SettingsNavItem>
            
            {user.sysAdmin && <SettingsNavItem
              name="company"
              selectedItem={selectedItem}
              handleClick={handleClick}
            >
              Manage Company
            </SettingsNavItem>}

            <SettingsNavItem
              name="users"
              selectedItem={selectedItem}
              handleClick={handleClick}
            >
              Manage Users
            </SettingsNavItem>

            {user.sysAdmin && <SettingsNavItem
              name="usersSA"
              selectedItem={selectedItem}
              handleClick={handleClick}
            >
              Manage Users of Companies
            </SettingsNavItem>}

            <SettingsNavItem
              name="billing"
              selectedItem={selectedItem}
              handleClick={handleClick}
            >
              Manage Billing
            </SettingsNavItem>
          </>
        )}
      </Nav>
    </NavContainer>
  );
}

SettingsNav.propTypes = {
  user: PropTypes.object,
  selectedItem: PropTypes.string,
  handClick: PropTypes.func
};

const NavContainer = styled.div`
  flex-basis: 20%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const Nav = styled.div`
  margin-top: 40px;
  flex-basis: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
