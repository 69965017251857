import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../providers/UserProvider";
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import './Auth.css'
import { Card, TextField } from "@material-ui/core";
import { Auth } from 'aws-amplify'
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

// This component is responsible for a password change in two instances:
// 1. a new user must change their temporary password
// 2. a user has forgotten their password

export default function NewPassword({cognitoUser}) {
    const { getCurrentUserProfile } = useContext(UserContext);
    const { setLoginComponent } = useContext(ApplicationStateContext)
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [error, setError] = useState(false)
    const verificationCode = sessionStorage.getItem("resetVerification")
    const resetEmail = sessionStorage.getItem('resetEmail')

    useEffect(() => {
        // if both fields are not blank
        if(confirm !== '' && password !== '') {
            // if the passwords match
            if(confirm === password) {
                // turn off the error display and enable submit
                setError(false)
                setEnabled(true)
            }
            else {
                // display error when passwords do not match
                setError(true)
            }
        }
        else {
            setError(false)
            setEnabled(false)
        }
    }, [confirm, password])

    // on submit
    const newPasswordSubmit = (e) => {
        // allow only if submit is enabled
        if(enabled) {
            // if the reset password verification code has not been set (new user)
            if(verificationCode === null) {
                // complete the new password required for new users
                Auth.completeNewPassword(
                    cognitoUser,
                    password,
                ).then(() => {
                    getCurrentUserProfile()
                        .then(user => {
                            sessionStorage.setItem("incompleteUser", JSON.stringify(user))
                            setLoginComponent("createProfile")
                        })
                })
            }
            // else if the reset password verification code has been set (existing user)
            else if (resetEmail !== null && verificationCode !== null) {
                // reset the user's password
                Auth.forgotPasswordSubmit(resetEmail, verificationCode, password)
                    .then(data => console.log(data))
                    .then(() => {
                        setLoginComponent('passwordResetSuccess')
                        sessionStorage.clear()
                    })
                    .catch((err) => {
                        console.log(err)
                        sessionStorage.removeItem('resetVerification')
                        setLoginComponent('passwordResetFail')
                    });
            }
        }
    };

    return (
        <div className='auth'>
            <div className='auth__header'>
                <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__title'>Choose a new password</div>
                    <div className='auth__form__field--top'>
                        <TextField autoComplete="new-password" id="password" type="password" variant='outlined' label='Password' onChange={e => setPassword(e.target.value)} className='auth__form__input'/>
                    </div>
                    <div className='auth__form__field'>
                        <TextField autoComplete="new-password" id="passwordConfirm" type="password" variant='outlined' label='Confirm Password' 
                            error={error} onChange={e => setConfirm(e.target.value)} className='auth__form__input'/>
                    </div>
                    <div className='auth__form__field'>
                        <button className={`auth__form__submit btn ${enabled ? 'btn-enabled' : 'btn-disabled'}`} onClick={newPasswordSubmit}>Submit</button>
                    </div>
                </Card>
            </div>
        </div>
    );
}