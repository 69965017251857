import React, { useState, useContext, useEffect } from 'react'
import { Card, TextField } from "@material-ui/core";
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';

// this component takes the verification code from the users email
// and forwards the password reset work flow to NewPassword

export default function ForgotPasswordConfirm() {
    const { setLoginComponent, verificationCode, setVerificationCode } = useContext(ApplicationStateContext)
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        if(verificationCode !== '') {
            setEnabled(true)
        }
        else {
            setEnabled(false)
        }
    }, [verificationCode])

    const forgetPasswordSubmit = () => {
        if(enabled) {
            sessionStorage.setItem("resetVerification", verificationCode)
            setLoginComponent('newPassword')
        }
    }

    return (
        <div className='auth'>
            <div className='auth__header'>
                <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__emoji'>📧</div>
                    <div className='auth__form__title--withEmoji auth__form__title--long'>Please enter the verification code found in your email.</div>
                    <div className='auth__form__field--top'>
                        <TextField autoComplete="new-password" id="verification" variant='outlined' label='Verification Code' className='auth__form__input' onChange={e => setVerificationCode(e.target.value)}/>
                    </div>
                    <div className='auth__form__field'>
                        <button className={`auth__form__submit btn ${enabled ? 'btn-enabled' : 'btn-disabled'}`} onClick={forgetPasswordSubmit}>Verify</button>
                    </div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text' onClick={() => setLoginComponent('login')}>← Go Back</div>
                    </div>
                </Card>
            </div>
        </div>
    )
}