import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DrawerContactsListItem from "./DrawerContactsListItem";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";

export default function DrawerContactsList({
  page,
  levelOneContacts,
  levelTwoContacts,
  levelThreeContacts,
  isEditActive,
  setIsFormModalOpen,
  setContactToEdit
}) {
  return (
    <ContactContainer>
      {levelOneContacts?.length === 0 &&
      levelTwoContacts?.length === 0 &&
      levelThreeContacts?.length === 0 ? (
        <ButtonOutlined
          style={{ margin: "2em", width: "85%" }}
          isAdding
          text={"Add Contact"}
          onClick={() => setIsFormModalOpen(true)}
        />
      ) : (
        <>
          {levelOneContacts?.length > 0 ? (
            <section>
              {levelOneContacts?.map(c => (
                <DrawerContactsListItem
                  page={page}
                  key={c.id}
                  contact={c}
                  isPrimary
                  isEditActive={isEditActive}
                  setIsFormModalOpen={setIsFormModalOpen}
                  setContactToEdit={setContactToEdit}
                />
              ))}
            </section>
          ) : null}

          {// Check for level two contacts & no level ones.
          // If so make these primary
          // Otherwise, check if we have level two contacts
          // & level ones then display level two as secondary
          levelTwoContacts?.length > 0 && levelOneContacts.length === 0 ? (
            <section>
              {levelTwoContacts?.map(c => (
                <DrawerContactsListItem
                  page={page}
                  key={c.id}
                  contact={c}
                  isPrimary
                  isEditActive={isEditActive}
                  setIsFormModalOpen={setIsFormModalOpen}
                  setContactToEdit={setContactToEdit}
                />
              ))}
            </section>
          ) : levelTwoContacts?.length > 0 ? (
            <BorderedSection>
              {levelTwoContacts?.map(c => (
                <DrawerContactsListItem
                  page={page}
                  key={c.id}
                  contact={c}
                  isEditActive={isEditActive}
                  setIsFormModalOpen={setIsFormModalOpen}
                  setContactToEdit={setContactToEdit}
                />
              ))}
            </BorderedSection>
          ) : null}

          {levelThreeContacts?.length > 0 &&
          levelOneContacts?.length === 0 &&
          levelTwoContacts?.length === 0 ? (
            <section>
              {levelThreeContacts.map(c => (
                <DrawerContactsListItem
                  page={page}
                  key={c.id}
                  contact={c}
                  isPrimary
                  isEditActive={isEditActive}
                  setIsFormModalOpen={setIsFormModalOpen}
                  setContactToEdit={setContactToEdit}
                />
              ))}
            </section>
          ) : levelThreeContacts?.length > 0 ? (
            <BorderedSection>
              {levelThreeContacts?.map(c => (
                <DrawerContactsListItem
                  page={page}
                  key={c.id}
                  contact={c}
                  isEditActive={isEditActive}
                  setIsFormModalOpen={setIsFormModalOpen}
                  setContactToEdit={setContactToEdit}
                />
              ))}
            </BorderedSection>
          ) : null}
        </>
      )}
    </ContactContainer>
  );
}

DrawerContactsList.propTypes = {
  page: PropTypes.string.isRequired,
  levelOneContacts: PropTypes.array.isRequired,
  levelTwoContacts: PropTypes.array.isRequired,
  levelThreeContacts: PropTypes.array.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  setIsFormModalOpen: PropTypes.func.isRequired,
  setContactToEdit: PropTypes.func.isRequired
};

const ContactContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  width: 100%;
`;

const BorderedSection = styled.section`
  border-top: 1px solid rgba(210, 213, 213, 0.5);
`;
