import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ButtonOutlined from "../../generic/buttons/ButtonOutlined";
import { numberWithCommas } from "../../generic/Utils";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";

export default function Locations({ locations }) {
  const { workflow, setIsAddModalOpen } = useContext(ApplicationStateContext);
  const history = useHistory();

  return (
    <div className="locations" style={{ marginBottom: "3rem" }}>
      <div className="titleContainer locations__titleContainer">
        <div className="title locations__title">Locations</div>
      </div>
      <div className="table">
        <div className="thead">
          <div className="th td--xwide">Location</div>
          <div className="th">Loads/Mo</div>
          <div className="th">Spend/Mo</div>
        </div>
        <div
          className="tbody locationTable__tableBodyContainer"
        >
          {locations.length === 0 ? (
            workflow === "owned" ? (
              <div style={{ margin: "2em", textAlign: "center" }}>
                No owned locations
              </div>
            ) : (
              <ButtonOutlined
                style={{ margin: "2em" }}
                isAdding
                text="Add Location"
                onClick={() => setIsAddModalOpen(true)}
              />
            )
          ) : (
            locations.map(l => {
              return (
                <div key={l.id} className="trow">
                  <div
                    className="td td--xwide click"
                    onClick={() => history.push(`/location/${l.Location.id}`)}
                  >
                    {l.Location.city}, {l.Location.state}
                  </div>
                  <div className="td">{l.loadsPerMonth}</div>
                  <div className="td td--bold">
                    ${l.spend ? numberWithCommas(l.spend) : "0"}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
