import React from 'react'
import TagOption from '../TagOption'

export default function Location({ page, item }) {
    return (
        <div className='addTags__tagList'>
            <TagOption id={42} content={'Appt. Only'} page={page} item={item} />
            <TagOption id={43} content={'24 Hours'} page={page} item={item} />
            <TagOption id={44} content={'FCFS'} page={page} item={item} />
            <TagOption id={45} content={'Night Only'} page={page} item={item} />
            <TagOption id={46} content={'Live Load'} page={page} item={item} />
            <TagOption id={47} content={'Live Unload'} page={page} item={item} />
            <TagOption id={48} content={'Drop & Hook'} page={page} item={item} />
            <TagOption id={49} content={'Drop Yard'} page={page} item={item} />
            <TagOption id={50} content={'No Loading Dock'} page={page} item={item} />
        </div>
    )
}