import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemEstimatedSpend({
  item,
  updateMethod
}) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"number"}
      valueType={"spend per month"}
      valueIsCurrency
      updateMethod={updateMethod}
      nameOfValue="estimatedSpend"
      title={"Estimated Spend"}
      label={"Spend per month"}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          This is just an estimate. Give it your best guess.
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemEstimatedSpend.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
