import React from "react";
import PropTypes from "prop-types";
import "./ButtonTextOnly.css";

export default function ButtonTextOnly(props) {
  return (
    <button
      style={props.style}
      type={props.type}
      className="buttonTextOnly__btn"
      onClick={() => props.onClick()}
    >
      <span className="buttonTextOnly__standard">{props.children}</span>
      <span className="buttonTextOnly__bold">{props.children}</span>
    </button>
  );
}

ButtonTextOnly.propTypes = {
  props: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })
};
