import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DrawerContactsFormSearchItem from "./DrawerContactsFormSearchItem";
import { CircularProgress } from "@material-ui/core";

export default function DrawerContactsFormSearch({
  results,
  isSearchLoading,
  handleSearchSelect
}) {
  return (
    <SearchResultsContainer>
      {isSearchLoading === true ? (
        <CircularProgress color="secondary" style={spinnerStyle} />
      ) : (
        results?.map(r => (
          <DrawerContactsFormSearchItem
            key={r.id}
            contact={r}
            handleSearchSelect={handleSearchSelect}
          />
        ))
      )}
      {results.length === 0 && isSearchLoading === false ? (
        <NoResults>No contacts found</NoResults>
      ) : null}
    </SearchResultsContainer>
  );
}

DrawerContactsFormSearch.propTypes = {
  results: PropTypes.array.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  handleSearchSelect: PropTypes.func.isRequired
};

const SearchResultsContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  background-color: white;
  width: 56.6%;
  top: 45px;
  border: 2px solid var(--gray);
  border-radius: 2px;
  z-index: 2;
`;

const NoResults = styled.div`
  margin: 1em;
  pointer-events: none;
`;

const spinnerStyle = {
  width: "35px",
  height: "35px",
  margin: "1em 0em",
  alignSelf: "center"
};
