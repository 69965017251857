import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LaneContext } from "../providers/LaneProvider";
import DrawerToolbeltItemCurrentRate from "./DrawerToolbeltItemCurrentRate";
import DrawerToolbeltItemRequirements from "./DrawerToolbeltItemRequirements";
import DrawerToolbeltItemPainPoints from "./DrawerToolbeltItemPainPoints";
import DrawerToolbeltItemCompetitionAnalysis from "./DrawerToolbeltItemCompetitionAnalysis";
import DrawerToolbeltItemTotalPotentialVolume from "./DrawerToolbeltItemTotalPotentialVolume";
import DrawerToolbeltItemOwnedVolume from "./DrawerToolbeltItemOwnedVolume";

export default function DrawerToolbeltListLane({ lane }) {
  const { updateLane } = useContext(LaneContext);

  return (
    <>
      <DrawerToolbeltItemCurrentRate item={lane} updateMethod={updateLane} />
      <DrawerToolbeltItemTotalPotentialVolume
        item={lane}
        updateMethod={updateLane}
      />
      <DrawerToolbeltItemRequirements item={lane} updateMethod={updateLane} />
      <DrawerToolbeltItemPainPoints item={lane} updateMethod={updateLane} />
      <DrawerToolbeltItemCompetitionAnalysis
        item={lane}
        updateMethod={updateLane}
      />
      <DrawerToolbeltItemOwnedVolume item={lane} updateMethod={updateLane} />
    </>
  );
}

DrawerToolbeltListLane.propTypes = {
  lane: PropTypes.object
};
