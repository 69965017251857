import React, { useContext, useState, useEffect, useRef } from "react";
import { LaneContext } from "../../providers/LaneProvider";
import { format } from "date-fns";
import { CircularProgress } from "@material-ui/core";
import ButtonOutlined from "../../generic/buttons/ButtonOutlined";
import { numberWithCommas } from "../../generic/Utils";
import { InputAdornment, TextField } from "@material-ui/core";

export default function MarketFeedbackContainer({ lane }) {
  const { getMarketFeedbackForLane, addMarketFeedbackToLane } = useContext(
    LaneContext
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [rate, setRate] = useState(0);
  const [MCN, setMCN] = useState(0);
  const [showInputs, setShowInputs] = useState(true);

  const tbody = useRef();

  async function handleMCNChange(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    if (e.currentTarget.value.length <= 6) {
      setMCN(e.currentTarget.value);
    }
  }

  async function handleRateChange(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    if (e.currentTarget.value.length <= 5) {
      setRate(e.currentTarget.value);
    }
  }

  async function handleAdd() {
    if (rate !== "" && MCN !== "") {
      setIsSubmitting(true);
      const newMarketFeedback = {
        rate: rate,
        motorCarrierNumber: MCN
      };
      await addMarketFeedbackToLane(lane.id, newMarketFeedback);
      // TODO: Only reset to false if it was successfully added
      setMCN("");
      setRate("");
      const marketFeedback = await getMarketFeedbackForLane(lane.id);
      setFeedback(marketFeedback);
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    (async function() {
      const marketFeedback = await getMarketFeedbackForLane(lane.id);
      setFeedback(marketFeedback);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lane.id]);

  useEffect(() => {
    if (feedback.length > 0) {
      setShowInputs(true);
    } else {
      setShowInputs(false);
    }
  }, [feedback]);

  useEffect(() => {
    if (rate !== "" && MCN.length === 6) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [MCN, rate]);

  useEffect(() => {
    // Set table body scrollbar to bottom
    if (tbody.current !== undefined) {
      tbody.current.scrollTop = tbody.current.scrollHeight;
    }
  }, [feedback]);

  return (
    <div className="marketFeedback">
      <div className="titleContainer">
        <div className="title">Market Feedback</div>
      </div>
      <div className="table">
        <div className="thead">
          <div className="th">Rate</div>
          <div className="th">MC#</div>
          <div className="th">Date/Time</div>
        </div>
        <div className="tbody tbody--noScroll marketFeedback__tableBodyContainer">
          {feedback.length > 0 && (
            <div ref={tbody} className="tbody__inner--scroll">
              {feedback.map(mf => {
                const date = format(new Date(mf.createdAt), "LLL d");
                const time = format(new Date(mf.createdAt), "k:m");
                return (
                  <div key={mf.id} className="trow">
                    <div className="td td--bold">
                      ${numberWithCommas(mf.rate)}
                    </div>
                    <div className="td">{mf.motorCarrierNumber}</div>
                    <div className="td marketFeedback__datetime">
                      <div className="marketFeedback__date">{date}</div>
                      <div className="marketFeedback__time">{time}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {showInputs === false ? (
            <ButtonOutlined
              style={{ margin: "2em 2em" }}
              text="Add Market Feedback"
              isAdding
              onClick={() => setShowInputs(true)}
            />
          ) : (
            <div
              style={{ height: "4em", backgroundColor: "var(--lightBlue)" }}
              className="trow"
            >
              <div style={{ flexBasis: "35%" }} className="td">
                <TextField
                  style={{ backgroundColor: "white", width: "5.7em" }}
                  name={"rate"}
                  onChange={handleRateChange}
                  label={"Rate"}
                  value={rate === 0 ? "" : rate}
                  variant="outlined"
                  size="small"
                  autoComplete="new-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </div>
              <div style={{ flexBasis: "35%" }} className="td">
                <TextField
                  style={{ backgroundColor: "white", width: "5.2em" }}
                  name={"MCN"}
                  onChange={handleMCNChange}
                  label={"MC#"}
                  value={MCN === 0 ? "" : MCN}
                  variant="outlined"
                  size="small"
                  autoComplete="new-password"
                />
              </div>
              <div
                style={{ flexBasis: "35%" }}
                className="td--xsmall marketFeedback__btnContainer"
              >
                {
                  // This is the same button used by the Add Customer Quick Tags, later make this a component
                }
                <button
                  disabled={isSubmitDisabled || isSubmitting}
                  className={
                    rate !== "" && MCN.length === 6
                      ? "addTags__custom__btn--enabled click"
                      : "addTags__custom__btn--disabled"
                  }
                  style={{ width: "30px", height: "30px", borderRadius: "3px" }}
                  onClick={handleAdd}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  ) : (
                    <span style={{ fontSize: "1.5em" }}>+</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
