import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { passwordToastMessages } from "../generic/ToastMessages";
import SettingsContentUpdatePasswordRequirements from "./SettingsContentUpdatePasswordRequirements";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import { TextField } from "@material-ui/core";
import SettingsCardTitle from "./SettingsCardTitle";

export default function SettingsContentUpdatePassword() {
  const formStyle = {
    display: "flex",
    flexFlow: "column nowrap"
  };

  const fieldsetStyle = {
    margin: 0,
    marginBottom: "1em",
    padding: 0,
    width: "16em"
  };

  const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    confirmedPassword: ""
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordData, setPasswordData] = useState(defaultPasswordData);

  function handleControlledInputChange(e) {
    const newPasswordData = { ...passwordData };
    newPasswordData[e.target.name] = e.target.value;
    setPasswordData(newPasswordData);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          passwordData.oldPassword,
          passwordData.newPassword
        );
      })
      .then(() => {
        passwordToastMessages.updateSuccess();
      })
      .catch(err => {
        console.log(err);
        passwordToastMessages.updateFailure();
      });

    setIsSubmitting(false);
    setPasswordData(defaultPasswordData);
  }

  useEffect(() => {
    if (
      passwordData.newPassword !== "" &&
      passwordData.confirmedPassword !== "" &&
      passwordData.newPassword === passwordData.confirmedPassword &&
      passwordData.oldPassword !== ""
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [passwordData]);

  return (
    <>
      <SettingsCardTitle>Update Password</SettingsCardTitle>

      <form style={formStyle}>
        <fieldset style={fieldsetStyle}>
          <TextField
            type="password"
            name="oldPassword"
            label="Old Password"
            variant="outlined"
            size="small"
            value={passwordData.oldPassword}
            placeholder="Old Password"
            onChange={handleControlledInputChange}
          />
        </fieldset>

        <SettingsContentUpdatePasswordRequirements />

        <fieldset style={fieldsetStyle}>
          <TextField
            type="password"
            name="newPassword"
            label="New Password"
            variant="outlined"
            size="small"
            value={passwordData.newPassword}
            placeholder="New Password"
            onChange={handleControlledInputChange}
          />
        </fieldset>

        <fieldset style={fieldsetStyle}>
          <TextField
            type="password"
            name="confirmedPassword"
            label="Confirmed Password"
            variant="outlined"
            size="small"
            value={passwordData.confirmedPassword}
            placeholder="Confirmed Password"
            onChange={handleControlledInputChange}
          />
        </fieldset>

        <div style={{ marginTop: "2em" }}>
          <ButtonSolid
            type="button"
            style={{ padding: "1.5em", fontSize: "0.9em", minWidth: "8em" }}
            color="red"
            disabled={isSubmitDisabled}
            isSubmitting={isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </ButtonSolid>
        </div>
      </form>
    </>
  );
}
