import React, { useContext } from 'react'
import { numberWithCommas, getColorForWorkflow } from '../../../components/generic/Utils'
import { ApplicationStateContext } from '../../../components/providers/ApplicationStateProvider'

export default function CustomerDefaultTags({ locationCount, laneCount, volume, spend }) {
  const { workflow } = useContext(ApplicationStateContext)
  const color = getColorForWorkflow(workflow)

  return (
    <section className="drawer__column--2 drawer__column--right">
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>LOCATIONS</div>
        <div className={`chip--${color}__bottom`}>📍{locationCount}</div>
      </div>
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>LANES</div>
        <div className={`chip--${color}__bottom`}>🛣 {laneCount}</div>
      </div>
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>LOADS</div>
        <div className={`chip--${color}__bottom`}>🗓 {volume}/mo</div>
      </div>
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>SPEND</div>
        <div className={`chip--${color}__bottom`}>💳 ${numberWithCommas(spend)}</div>
      </div>
    </section>
  )
}
