import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import { TextField, InputAdornment } from "@material-ui/core";
import FormModal from "../forms/FormModal";
import FormModalFooter from "../forms/FormModalFooter";
import { ContactContext } from "../providers/ContactProvider";
import DrawerContactsFormSearch from "./DrawerContactsFormSearch";
import TooltipInformation from "../library/tooltip/TooltipInformation";
// Contains modal and form

export default function DrawerContactsForm({
  page,
  item,
  isOpen,
  setIsOpen,
  setIsEditActive,
  contactToEdit,
  setContactToEdit
}) {
  const defaultContact = {
    firstName: "",
    lastName: "",
    title: "",
    level: "",
    phone: "",
    email: ""
  };

  const { searchExistingContacts, addContact, editContact } = useContext(
    ContactContext
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(true);
  const [contactSelectedFromSearch, setContactSelectedFromSearch] = useState(
    null
  );
  const [contactData, setContactData] = useState(defaultContact);
  const [originalContact, setOriginalContact] = useState(null);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleModalClose() {
    setSearchTerm("");
    setContactToEdit(null);
    setOriginalContact(null);
    setIsOpen(false);
  }

  function handleControlledInputChange(e) {
    const newContactData = { ...contactData };
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    if (contactToEdit === null) {
      let isExisting = false;
      if (contactData.id !== undefined) {
        isExisting = true;
      }
      const response = await addContact(
        page,
        item,
        contactData,
        isExisting,
        false
      );
      if (response !== null) {
        setIsOpen(false);
        setIsEditActive(false);
        setContactData(defaultContact);
      }
    } else {
      const response = await editContact(contactData);
      if (response !== null) {
        setIsOpen(false);
        setContactData(defaultContact);
      }
    }
    setIsSubmitting(false);
  }

  async function handleSearch(term) {
    const response = await searchExistingContacts(term, page, item.id);
    if (response !== null) {
      setIsSearchLoading(false);
      setSearchResults(response);
    } else {
      setIsSearchLoading(false);
      setSearchResults([]);
    }
  }

  function handleSearchSelect(contact) {
    setContactSelectedFromSearch(contact);
    setSearchTerm("");
  }

  // When form opens, check if there is data to edit
  useEffect(() => {
    if (isOpen && contactToEdit !== null) {
      setOriginalContact({ ...contactToEdit });
    } else if (isOpen === false) {
      // Whenever form closes, reset it
      setOriginalContact(null);
      setContactToEdit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, contactToEdit]);

  useEffect(() => {
    function validateInputs(data) {
      if (data.firstName !== "" && data.level !== "") {
        setIsSubmitActive(true);
      } else {
        setIsSubmitActive(false);
      }
    }
    if (isOpen) {
      validateInputs(contactData);
      // Validation for editing form
      if (contactToEdit !== null && originalContact !== null) {
        let newCarrier = { ...contactData };
        if (JSON.stringify(newCarrier) === JSON.stringify(originalContact)) {
          setIsSubmitActive(false);
        } else {
          validateInputs(contactData);
        }
      }
    }
  }, [contactData, contactToEdit, originalContact, isOpen]);

  useEffect(() => {
    // Show the contact to edit or empty form
    if (contactToEdit !== null) {
      setContactData(contactToEdit);
    } else if (contactToEdit === null) {
      setContactData(defaultContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactToEdit, isOpen]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (searchTerm !== "") {
        const prepped = searchTerm.toLowerCase().trim();
        handleSearch(prepped);
      } else if (searchTerm === "") {
        setSearchResults([]);
      }
    }, 400);

    return () => {
      clearTimeout(debounceSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    function autoFillFromSearch() {
      setContactData(contactSelectedFromSearch);
    }

    if (contactSelectedFromSearch !== null) {
      autoFillFromSearch();
    }
  }, [contactSelectedFromSearch]);

  return (
    <FormModal
      modalWidth={"38em"}
      modalHeight={
        contactToEdit !== null
          ? "20em"
          : contactData.id === undefined
          ? "26em"
          : "30em"
      }
      isModalOpen={isOpen}
      closeModal={handleModalClose}
      hasHeader={true}
      hasHeaderCloseBtn={true}
      headerIcon={""}
      headerText={contactToEdit === null ? "Add a Contact" : "Edit Contact"}
    >
      <FormContainer>
        <StyledForm>
          {contactToEdit === null ? (
            <SearchFieldset>
              <legend>
                Search for an existing contact to auto-complete form
              </legend>

              <TextField
                style={{ width: "60%" }}
                name="search"
                label="Existing Contact Search"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={searchTerm}
                placeholder="Search for an existing contact"
                onChange={e => {
                  setIsSearchLoading(true);
                  setSearchTerm(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#979797" }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              {searchTerm === "" ? null : (
                <DrawerContactsFormSearch
                  results={searchResults}
                  isSearchLoading={isSearchLoading}
                  handleSearchSelect={handleSearchSelect}
                />
              )}
            </SearchFieldset>
          ) : null}
          <fieldset>
            <legend>
              {contactToEdit !== null
                ? "Edit contact"
                : contactData.id === undefined
                ? "Add new contact"
                : `Adding existing contact`}
            </legend>

            {contactData.id === undefined || contactToEdit !== null ? null : (
              <BlueExistingNote>
                Any edits here will affect this contact across the app.
              </BlueExistingNote>
            )}

            <FieldWrapper>
              <TextField
                name="firstName"
                label="First Name"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={contactData.firstName}
                placeholder="First Name"
                onChange={handleControlledInputChange}
                required
              />

              <TextField
                name="lastName"
                label="Last Name (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={contactData.lastName}
                placeholder="Last Name (optional)"
                onChange={handleControlledInputChange}
              />
            </FieldWrapper>

            <FieldWrapper>
              <LevelSelectWrapper>
                <TextField
                  style={{ marginRight: "1em", width: "60%" }}
                  name="level"
                  variant="outlined"
                  autoComplete="new-password"
                  size="small"
                  label={"Select Level"}
                  select
                  SelectProps={{
                    native: true
                  }}
                  value={contactData.level}
                  onChange={handleControlledInputChange}
                  required
                >
                  <option value=""></option>
                  <option value={"1"}>Level 1</option>
                  <option value={"2"}>Level 2</option>
                  <option value={"3"}>Level 3</option>
                </TextField>
                <TooltipInformation>
                  Levels help you escalate communication clearly. Level 1 is a
                  daily contact and level 3 is a higher ranking executive.
                </TooltipInformation>
              </LevelSelectWrapper>

              <TextField
                style={{ margin: "1em 0em" }}
                name="title"
                label="Title (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={contactData.title}
                placeholder="Title (optional)"
                onChange={handleControlledInputChange}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TextField
                name="phone"
                label="Contact Phone (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={contactData.phone}
                placeholder="Contact Phone (optional)"
                onChange={handleControlledInputChange}
              />

              <TextField
                name="email"
                label="Contact Email (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={contactData.email}
                placeholder="Contact Email (optional)"
                onChange={handleControlledInputChange}
              />
            </FieldWrapper>
          </fieldset>
        </StyledForm>
        <FormModalFooter
          isSubmit
          isSubmitting={isSubmitting}
          onClickEvent={handleSubmit}
          isActive={isSubmitActive}
        />
      </FormContainer>
    </FormModal>
  );
}

DrawerContactsForm.propTypes = {
  page: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsEditActive: PropTypes.func,
  contactToEdit: PropTypes.object,
  setContactToEdit: PropTypes.func
};

const FormContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-between;
`;

const BlueExistingNote = styled.section`
  color: var(--darkBlue);
  font-size: 0.9em;
  font-weight: 700;
  width: fit-content;
  background-color: var(--lightBlue);
  margin-bottom: 1.5em;
  padding: 10px;
  border-radius: 7px;
`;

const StyledForm = styled.form`
  margin: 2em;
`;

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchFieldset = styled.fieldset`
  position: relative;
  margin-bottom: 1em;
`;

const LevelSelectWrapper = styled.div`
  display: flex;
  margin: 1em 0em;
  width: 47%;
  align-items: center;
`;
