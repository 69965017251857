import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { getVolumeKey, numberWithCommas } from "../generic/Utils";

export default function DrawerLanesTableRow({
  lane,
  workflow,
  showEdit,
  setEditableLanes,
  i
}) {
  const history = useHistory();
  const [focused, setFocused] = useState(false);
  const [volumePerMonth, setVolumePerMonth] = useState(0);
  const [rate, setRate] = useState(0);
  const [volumeKey, setVolumeKey] = useState(""); // set by getVolumeKey
  const [editedVolumePerMonth, setEditedVolumePerMonth] = useState(0);
  const [editedRate, setEditedRate] = useState(lane.rate || 0);

  useEffect(() => {
    function setValuesByWorkflowState() {
      const key = getVolumeKey(workflow);
      setVolumeKey(key);
      setVolumePerMonth(lane[key]);
      setEditedVolumePerMonth(lane[key]);
      setRate(lane.rate);
    }

    setValuesByWorkflowState();
  }, [lane, workflow]);

  const handleClick = () => {
    if (!showEdit) {
      history.push(`/lane/${lane.id}`);
    }
  };

  const handleBlur = () => {
    const editedLane = { ...lane };
    // Only add to the edited array if the numbers changed
    if (
      editedLane[volumeKey] !== +editedVolumePerMonth ||
      editedLane.rate !== +editedRate
    ) {
      editedLane[volumeKey] = +editedVolumePerMonth;
      editedLane.rate = +editedRate;

      setEditableLanes(editedLane);
    }
    setFocused(false);
  };

  return (
    <div
      onClick={handleClick}
      className={focused ? "trow trow--focused" : "trow lanes__table__row"}
    >
      <div className="td td--wide">
        {lane.origin.city}, {lane.origin.state}
      </div>
      <div className="td td--wide">
        {lane.destination.city}, {lane.destination.state}
      </div>
      {showEdit ? (
        <div className="td lanes__inputContainer">
          <input
            className="tinput lanes__input"
            type="number"
            value={editedVolumePerMonth}
            onChange={e => setEditedVolumePerMonth(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
          />
        </div>
      ) : (
        <div className="td">{volumePerMonth}</div>
      )}
      {showEdit ? (
        <div className="td lanes__inputContainer">
          <input
            className="tinput lanes__input"
            type="number"
            value={editedRate}
            onChange={e => setEditedRate(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
          />
        </div>
      ) : (
        <div className="td td--bold">${numberWithCommas(rate)}</div>
      )}
    </div>
  );
}

DrawerLanesTableRow.propTypes = {
  lane: PropTypes.object.isRequired,
  workflow: PropTypes.string.isRequired,
  showEdit: PropTypes.bool.isRequired,
  setEditableLanes: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired
};
