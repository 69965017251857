import React, { useState } from "react";
import styled from "styled-components";
import SettingsCardTitle from "./SettingsCardTitle";
import SettingsContentUploadDownloadTemplate from "./SettingsContentUploadDownloadTemplate";
import SettingsContentUploadDropZone from "./SettingsContentUploadDropZone";
import SettingsContentUploadTable from "./SettingsContentUploadTable";
import SettingsContentUploadModal from "./SettingsContentUploadModal";

export default function SettingsContentUpload() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SettingsCardTitle>Upload Data</SettingsCardTitle>

      <SectionContainer>
        <InstructionContainer>
          <CircleNumber>1</CircleNumber>
          <MainInstruction>Download the template.xlsx file</MainInstruction>
          <SubInstruction>
            and open it in your preferred spreadsheet editor
          </SubInstruction>
        </InstructionContainer>
        <SettingsContentUploadDownloadTemplate />
      </SectionContainer>

      <SectionContainer>
        <InstructionContainer>
          <CircleNumber>2</CircleNumber>
          <MainInstruction>Fill in required columns</MainInstruction>
          <SubInstruction>
            and include optional information to make the most of Terralanes
          </SubInstruction>
        </InstructionContainer>
        <SettingsContentUploadTable />
      </SectionContainer>

      <SectionContainer>
        <InstructionContainer>
          <CircleNumber>3</CircleNumber>
          <MainInstruction>Upload your CSV file</MainInstruction>
          <SubInstruction>
            and receive an email upon completion of processing
          </SubInstruction>
        </InstructionContainer>
        <SettingsContentUploadDropZone setIsModalOpen={setIsModalOpen} />
      </SectionContainer>

      <SettingsContentUploadModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

const SectionContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5em;
`;

const InstructionContainer = styled.div`
  display: grid;
  width: fit-content;
`;

const CircleNumber = styled.div`
  color: white;
  font-size: 1.75em;
  font-weight: 900;
  background-color: var(--darkBlue);
  grid-row: 1 / 3;
  grid-column: 1;
  place-self: center;
  padding: 0.5em 0.8em;
  border-radius: 50%;
  margin-right: 1em;
  pointer-events: none;
`;

const MainInstruction = styled.div`
  color: var(--darkBlue);
  font-weight: 700;
  grid-column: 2;
  align-self: end;
`;

const SubInstruction = styled.div`
  color: var(--black);
  font-weight: 300;
  grid-column: 2;
`;
