import React, { useContext, useEffect, useState } from "react";
import { TagContext } from "../../providers/TagProvider";
import { CircularProgress } from "@material-ui/core";

export default function TagOption({ id, content, page, item }) {
  const { tags, addExistingTag, deleteTag } = useContext(TagContext);
  const [selected, setSelected] = useState(false);
  const [tag, setTag] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const tagSelected = tags.findIndex(t => t.id === id);
    if (tagSelected > -1) {
      setTag(tags[tagSelected]);
      setSelected(true);
    } else if (tagSelected === -1) {
      setSelected(false);
    }
  }, [id, tags]);

  async function handleClick() {
    setIsSubmitting(true);
    if (selected) {
      await deleteTag(page, item, tag);
      setIsSubmitting(false);
    } else if (!selected) {
      switch (page) {
        case "user":
          const newUserTag = {
            userId: item.id,
            tagId: id
          };
          await addExistingTag(page, item, newUserTag);
          break;
        case "customer":
          const newCustomerTag = {
            customerId: item.id,
            tagId: id
          };
          await addExistingTag(page, item, newCustomerTag);
          break;
        case "location":
          const newLocationTag = {
            locationId: item.id,
            tagId: id
          };
          await addExistingTag(page, item, newLocationTag);
          break;
        case "lane":
          const newLaneTag = {
            laneId: item.id,
            tagId: id
          };
          await addExistingTag(page, item, newLaneTag);
          break;
      }
      setIsSubmitting(false);
    }
  }

  return (
    <button
      className={`tag tag--add ${selected ? "tag--selected" : ""} `}
      onClick={handleClick}
      disabled={isSubmitting}
      style={{
        display: "grid",
        justifyItems: "center"
      }}
    >
      {isSubmitting && (
        <CircularProgress
          color={selected ? "inherit" : "secondary"}
          style={{
            width: "14px",
            height: "14px",
            gridColumn: "1",
            gridRow: "1"
          }}
        />
      )}
      <span style={{ gridColumn: "1", gridRow: "1" }}>
        {content.toUpperCase()}
      </span>
    </button>
  );
}
