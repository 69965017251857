import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { UploadContext } from "../providers/UploadProvider";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";

export default function SettingsContentUploadDropZone({ setIsModalOpen }) {
  const { uploadCSV } = useContext(UploadContext);
  const [dropzoneContent, setDropzoneContent] = useState(null);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Need open modal state

  const onDrop = useCallback(file => {
    if (file.length === 1) {
      setSelectedFile(file[0]);
      setIsUploadActive(true);
    } else {
      setIsUploadActive(false);
    }
  }, []);

  const dropzoneOptions = {
    onDrop,
    noClick: isUploadActive,
    accept: ".csv",
    maxFiles: 1
  };

  const {
    open,
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive
  } = useDropzone(dropzoneOptions);

  useEffect(() => {
    async function handleUploadSubmit() {
      if (isUploadActive) {
        setIsSubmitting(true);
        const response = await uploadCSV(selectedFile);
        if (response !== null) {
          setIsModalOpen(true);
          setIsUploadSuccessful(true);
        }
        setIsSubmitting(false);
      }
    }

    function setContent() {
      if (isDragActive === true) {
        setDropzoneContent(<BoldText>Drop file!</BoldText>);
        return;
      }
      if (fileRejections?.length > 0) {
        setDropzoneContent(
          <ColumnContainer>
            <BoldText fileRejections={fileRejections}>
              {fileRejections[0]?.errors[0]?.message}!
            </BoldText>
            <LightText fileRejections={fileRejections}>
              You attempted to upload: {fileRejections[0]?.file.name}
            </LightText>
            <ButtonSolid style={buttonStyle} onClick={() => null} color="red">
              <PublishOutlinedIcon style={arrowIconStyle} /> Select a Different
              File
            </ButtonSolid>
          </ColumnContainer>
        );
        return;
      }
      if (acceptedFiles?.length > 0) {
        setDropzoneContent(
          <ColumnContainer>
            <RowContainer>
              <DescriptionOutlinedIcon style={fileIconStyle} />
              <BoldText acceptedFiles={acceptedFiles}>
                {acceptedFiles[0].name}
              </BoldText>
            </RowContainer>

            <RowContainer>
              {isUploadSuccessful === true ? (
                <BoldText margin="1em" color="var(--darkGreen)">
                  Upload in progress. You'll be emailed when it's completed.
                </BoldText>
              ) : (
                <>
                  <ButtonSolid
                    isSubmitting={isSubmitting}
                    style={buttonStyle}
                    onClick={handleUploadSubmit}
                    color="darkBlue"
                  >
                    <PublishOutlinedIcon style={arrowIconStyle} /> Upload CSV
                    File
                  </ButtonSolid>

                  <ButtonOutlined
                    disabled={isSubmitting}
                    style={buttonStyle}
                    onClick={isSubmitting === true ? () => {} : () => open()}
                    text={
                      <>
                        <DescriptionOutlinedIcon style={fileIconStyleBlue} />
                        Select Different File
                      </>
                    }
                    color="darkBlue"
                  />
                </>
              )}
            </RowContainer>
          </ColumnContainer>
        );
        return;
      }
      setDropzoneContent(
        <ColumnContainer>
          <BoldText fileRejections={fileRejections}>
            Drag and drop your finished .csv file or ...
          </BoldText>
          <ButtonSolid style={buttonStyle} onClick={() => null} color="red">
            <PublishOutlinedIcon style={arrowIconStyle} /> Select File
          </ButtonSolid>
        </ColumnContainer>
      );
    }

    setContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFile,
    isSubmitting,
    isUploadActive,
    isDragActive,
    acceptedFiles,
    fileRejections
  ]);

  return (
    <DropArea
      {...getRootProps({ isDragActive, fileRejections, acceptedFiles })}
    >
      <div>
        <input {...getInputProps()} />
        {dropzoneContent}
      </div>
    </DropArea>
  );
}

const getDropAreaBorder = props => {
  if (props.isDragActive) {
    return "solid 3px var(--darkBlue)";
  }
  if (props.acceptedFiles?.length > 0) {
    return "solid 3px var(--darkGreen)";
  }
  if (props.fileRejections?.length > 0) {
    return "solid 3px var(--darkRed)";
  }
  return "dashed 3px var(--darkBlue)";
};

const getDropAreaBackgroundColor = props => {
  if (props.isDragActive) {
    return "var(--lightBlue)";
  }
  if (props.acceptedFiles?.length > 0) {
    return "var(--lightGreen)";
  }
  if (props.fileRejections?.length > 0) {
    return "var(--lightRed)";
  }
  return "transparent";
};

const getDropAreaBackgroundHoverColor = props => {
  if (props.acceptedFiles?.length > 0) {
    return "var(--lightGreen)";
  }
  if (props.fileRejections?.length > 0) {
    return "var(--lightRed)";
  }
  return "var(--lightBlue)";
};

const getTextColor = props => {
  if (props.acceptedFiles?.length > 0) {
    return "var(--darkGreen)";
  }
  if (props.fileRejections?.length > 0) {
    return "var(--darkRed)";
  }
  return "var(--darkBlue)";
};

const DropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  margin-left: 5.5em;
  width: 37em;
  height: 14em;
  text-align: center;
  background-color: ${props => getDropAreaBackgroundColor(props)};
  border: ${props => getDropAreaBorder(props)};
  border-radius: 9px;
  transition: all 0.15s;

  &:hover {
    background-color: ${props => getDropAreaBackgroundHoverColor(props)};
    cursor: pointer;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const RowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  align-self: center;
`;

const BoldText = styled.p.attrs(props => ({
  margin: props.margin || "0",
  color: props.color || getTextColor(props)
}))`
  color: ${props => props.color};
  margin: ${props => props.margin};
  font-weight: 900;
  font-size: 1.2em;
`;

const LightText = styled.p`
  color: ${props => getTextColor(props)};
  font-weight: 400;
  font-size: 1em;
  text-align: left;
  margin-top: 0.5em;
`;

const buttonStyle = {
  fontSize: "1.1em",
  padding: "1.5em",
  width: "fit-content",
  placeSelf: "center",
  marginTop: "1em",
  marginLeft: "0.25em",
  marginRight: "0.25em"
};

const arrowIconStyle = {
  width: "1.5em",
  height: "1.5em",
  marginRight: "0.25em"
};

const fileIconStyle = {
  fill: "var(--darkGreen)",
  height: "1.1em",
  width: "1.1em",
  marginRight: "0.25em"
};

const fileIconStyleBlue = {
  fill: "var(--darkBlue)",
  height: "1.1em",
  width: "1.1em",
  marginRight: "0.25em"
};
