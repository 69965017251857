import React, { createContext, useContext, useState } from "react";
import responseHandler from "./utils/responseHandler";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import errorHandler from "./utils/errorHandler";

export const SearchContext = createContext();

const SearchProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL + "/search1";
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);

  //SEARCH RESULTS
  const [terms, setTerms] = useState("");
  const [active, setActive] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null); // anchors popper to input element if input !== '' [search.js]
  const open = Boolean(anchorEl); // opens popper if anchor element is not null [search.js]
  const closeSearch = () => {
    setAnchorEl(null);
    setTerms("");
  };

  async function getResults(q) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}?q=${q}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const results = responseHandler({ response, setAlertModal });

      return results;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function searchUsersInBrokerage(q) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/users?q=${q}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const results = await responseHandler({ response, setAlertModal });

      return results;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  return (
    <SearchContext.Provider
      value={{
        getResults,
        terms,
        setTerms,
        active,
        setActive,
        anchorEl,
        setAnchorEl,
        closeSearch,
        open,
        searchUsersInBrokerage
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
