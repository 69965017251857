import React, { useContext, useEffect, useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { LaneContext } from "../providers/LaneProvider";
import FormModal from "../forms/FormModal";
import FormModalFooter from "../forms/FormModalFooter";
import ButtonTextOnly from "../generic/buttons/ButtonTextOnly";
// Contains modal and form

export default function DrawerCarriersForm({
  laneId,
  isOpen,
  setIsOpen,
  setIsEditActive,
  carrierToEdit,
  setCarrierToEdit
}) {
  const { addCarrier, updateCarrier } = useContext(LaneContext);

  const sectionStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: "1",
    justifyContent: "space-between"
  };

  const formStyle = {
    margin: "2em"
  };

  const fieldContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "80%"
  };

  const defaultCarrier = {
    laneId,
    name: "",
    mcn: 0,
    historicalRate: 0,
    contactName: "",
    contactPhone: "",
    contactEmail: ""
  };

  const [carrierData, setCarrierData] = useState(defaultCarrier);
  const [originalCarrier, setOriginalCarrier] = useState(null);
  const [isOptionalShowing, setIsOptionalShowing] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleControlledInputChange(e) {
    const newCarrierData = { ...carrierData };

    if (e.target.name === "mcn") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
      if (e.currentTarget.value.length <= 6) {
        newCarrierData[e.target.name] = e.target.value;
        setCarrierData(newCarrierData);
      }
      return;
    }

    if (e.target.name === "historicalRate") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
      if (e.currentTarget.value.length <= 5) {
        newCarrierData[e.target.name] = e.target.value;
        setCarrierData(newCarrierData);
      }
      return;
    }

    newCarrierData[e.target.name] = e.target.value;
    setCarrierData(newCarrierData);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    if (carrierToEdit === null) {
      const response = await addCarrier(carrierData);

      if (response !== null) {
        setIsOpen(false);
        setIsEditActive(false);
        setIsOptionalShowing(false);
        setCarrierData(defaultCarrier);
      }
    } else {
      const response = await updateCarrier(carrierData);

      if (response !== null) {
        setIsOpen(false);
        setIsOptionalShowing(false);
        setCarrierData(defaultCarrier);
      }
    }
    setIsSubmitting(false);
  }

  // When form opens, check if there is data to edit
  useEffect(() => {
    if (isOpen && carrierToEdit !== null) {
      setOriginalCarrier({ ...carrierToEdit });
    } else if (isOpen === false) {
      // Whenever form closes, reset it
      setOriginalCarrier(null);
      setCarrierToEdit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, carrierToEdit]);

  useEffect(() => {
    function validateInputs(data) {
      if (
        data.name !== "" &&
        data.mcn.length === 6 &&
        data.historicalRate !== "" &&
        data.historicalRate !== 0
      ) {
        setIsSubmitActive(true);
      } else {
        setIsSubmitActive(false);
      }
    }

    if (isOpen) {
      validateInputs(carrierData);

      // Validation for editing form
      if (carrierToEdit !== null && originalCarrier !== null) {
        let newCarrier = { ...carrierData };
        if (JSON.stringify(newCarrier) === JSON.stringify(originalCarrier)) {
          setIsSubmitActive(false);
        } else {
          validateInputs(carrierData);
        }
      }
    }
  }, [carrierData, carrierToEdit, originalCarrier, isOpen, laneId]);

  useEffect(() => {
    // Show the carrier to edit or empty form
    if (carrierToEdit !== null) {
      setCarrierData(carrierToEdit);
      if (
        carrierToEdit.contactName !== "" ||
        carrierToEdit.contactPhone !== "" ||
        carrierToEdit.contactEmail !== ""
      ) {
        setIsOptionalShowing(true);
      }
    } else if (carrierToEdit === null) {
      setCarrierData(defaultCarrier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierToEdit, isOpen]);

  return (
    <FormModal
      modalWidth={"30em"}
      modalHeight={isOptionalShowing ? "28em" : "17em"}
      isModalOpen={isOpen}
      closeModal={() => {
        setCarrierToEdit(null);
        setOriginalCarrier(null);
        setIsOptionalShowing(false);
        setIsOpen(false);
      }}
      hasHeader={true}
      hasHeaderCloseBtn={true}
      headerIcon={""}
      headerText={carrierToEdit === null ? "Add a Carrier" : "Edit Carrier"}
    >
      <section style={sectionStyle}>
        <form style={formStyle}>
          <TextField
            style={{ width: "80%", marginBottom: "1em" }}
            name="name"
            label="Carrier Name"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={carrierData.name}
            placeholder="Enter Carrier Name"
            onChange={handleControlledInputChange}
            required
          />

          <div style={fieldContainerStyle}>
            <TextField
              style={{
                backgroundColor: "white",
                width: "8em",
                flexBasis: "40%"
              }}
              name={"mcn"}
              onChange={handleControlledInputChange}
              label={"MC Number"}
              value={carrierData.mcn === 0 ? "" : carrierData.mcn}
              variant="outlined"
              size="small"
              autoComplete="new-password"
              required
            />

            <TextField
              style={{
                backgroundColor: "white",
                width: "8em",
                flexBasis: "40%"
              }}
              name={"historicalRate"}
              onChange={handleControlledInputChange}
              label={"Historical Rate"}
              value={
                carrierData.historicalRate === 0
                  ? ""
                  : carrierData.historicalRate
              }
              variant="outlined"
              size="small"
              autoComplete="new-password"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </div>

          {isOptionalShowing === false ? (
            <ButtonTextOnly
              onClick={() => setIsOptionalShowing(true)}
              type="button"
              style={{ fontSize: "0.9em", textAlign: "left", marginTop: "2em" }}
            >
              + Add optional contact information
            </ButtonTextOnly>
          ) : (
            <fieldset
              style={{ marginTop: "2em", width: "74%" }}
              className="formAddWorkflowForm__fieldset--optional"
            >
              <legend style={{ padding: "0 0.5em" }}>Optional</legend>

              <TextField
                name="contactName"
                label="Contact Name (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={carrierData.contactName}
                placeholder="Contact Name"
                onChange={handleControlledInputChange}
              />

              <TextField
                style={{ margin: "1em 0em", width: "70%" }}
                name="contactPhone"
                label="Contact Phone (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={carrierData.contactPhone}
                placeholder="Contact Phone"
                onChange={handleControlledInputChange}
              />

              <TextField
                style={{ width: "70%" }}
                name="contactEmail"
                label="Contact Email (optional)"
                variant="outlined"
                size="small"
                autoComplete="new-password"
                value={carrierData.contactEmail}
                placeholder="Contact Email"
                onChange={handleControlledInputChange}
              />
            </fieldset>
          )}
        </form>
        <FormModalFooter
          isSubmit
          isSubmitting={isSubmitting}
          onClickEvent={handleSubmit}
          isActive={isSubmitActive}
        />
      </section>
    </FormModal>
  );
}
