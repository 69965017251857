import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import EditButton from "../generic/buttons/EditButton";
import ButtonTextOnly from "../generic/buttons/ButtonTextOnly";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";

export default function DrawerSectionHeader(props) {
  return (
    <header>
      <MainContainer>
        <HeaderText>{props.children}</HeaderText>
        <ButtonContainer>
          {props.setIsEditing ? (
            props.isEditing === false ? (
              <EditButton onClick={() => props.setIsEditing(true)} />
            ) : (
              <>
                <ButtonTextOnly
                  style={{ marginRight: "0.5em" }}
                  onClick={() => props.setIsEditing(false)}
                >
                  Close
                </ButtonTextOnly>
                {props.setIsAdding ? (
                  <ButtonOutlined
                    onClick={() => props.setIsAdding(true)}
                    color="blue"
                    text="Add"
                    isAdding
                  />
                ) : null}
              </>
            )
          ) : null}
        </ButtonContainer>
      </MainContainer>
      {props.hasUnderline ? <Underline /> : null}
    </header>
  );
}

DrawerSectionHeader.propTypes = {
  props: PropTypes.shape({
    hasUnderline: PropTypes.bool,
    isEditing: PropTypes.bool,
    setIsEditing: PropTypes.func,
    setIsAdding: PropTypes.func
  })
};

const MainContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 6px;
`;

const HeaderText = styled.h2`
  font-weight: 900;
  margin-bottom: 0.5em;
`;

const Underline = styled.hr`
  border: none;
  height: 1px;
  background-color: var(--lightGray);
  margin: 0;
`;
