import React, { useContext, useEffect, useState } from 'react'
import { useHistory  } from "react-router";
import { Storage } from 'aws-amplify'
import { Popover } from '@material-ui/core'
import { UserContext } from '../../providers/UserProvider'
import { CircularProgress, Dialog } from '@material-ui/core'

export default function ManageUserAdmin({ user, refresh }) {
    const history = useHistory();
    const { promoteUserToAdmin, promoteUserToSysAdmin, changeActiveStatus, deleteUserFromCustomBrokerage } = useContext(UserContext)
    const [profileImage, setProfileImage] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const open = Boolean(anchorEl)

    useEffect(() => {
        if (user.profileImage) {
            (async () => {
                const image = await Storage.get(user.profileImage)
                setProfileImage(image)
            })()
        }
    }, [user])

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    async function handleMakeAdminClick() {
        setAnchorEl(null)
        await promoteUserToAdmin(user.id)
        refresh()
    }

    async function handleMakeSysAdminClick() {
        setAnchorEl(null)
        await promoteUserToSysAdmin(user.id)
        refresh()
    }

    async function handleDeactivateUserClick() {
        setAnchorEl(null)
        await changeActiveStatus(user, false)
        refresh()
    }
    async function deleteUser() {
        setIsDeleting(true);
        try{
            await deleteUserFromCustomBrokerage(user.id);
            setAnchorEl(null)        
            refresh()
        } catch(e){
            console.log('here error:',e);
        }
        setAnchorEl(null);
        setIsDeleting(false);
        setDialogOpen(false);
    }

    async function handleDeleteUserClick(){
        setAnchorEl(null);
        setDialogOpen(true);
        await deleteUser();
    }

    async function handleEditUserClick() {
        const payload = {
            user,
            editing:true
        }
        history.push(`editUser/${user.id}`,payload);
        // setAnchorEl(null)
        // sestEditPressed(true);
        // refresh()
    }

    async function handleReactivateUserClick() {
        setAnchorEl(null)
        await changeActiveStatus(user, true)
        refresh()
    }


    return (
        <>
        <div className={user.active ? 'settings__user__trow' : 'settings__user__trow--deactivated'}>
            <div className='settings__user__td settings__user__info'>
                {profileImage
                    ? <img className='settings__user__img' src={profileImage} />
                    : <div className='settings__user__img stamp stamp--blue'>{user.firstName?.charAt(0)}</div>
                }
                <div>
                    <div className='settings__user__name'>
                        {user.firstName} {user.lastName}
                        {user.admin &&
                            <span className='tag tag--purple--dark settings__user__tag'>Admin</span>
                        }
                        {user.sysAdmin &&
                            <span className='tag tag--purple--dark settings__user__tag'>Sys Admin</span>
                        }
                    </div>
                    <div className='settings__user__email'>{user.email}</div>
                </div>
            </div>
            {
                // <div className='settings__user__td'>{user.Team?.name}</div>
            }
            <div className='settings__user__td settings__user__status'>
                {user.active
                    ? <div>Activated</div>
                    : <div>Deactivated</div>
                }
                <div className='click settings__user__menu' onClick={handleClick}>...</div>
            </div>
        </div>
        <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
                <div className='settings__user__popover'>
                    <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleEditUserClick}>Edit User</div>
                    {user.admin 
                        ? <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleMakeAdminClick}>Remove Admin</div>
                        : <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleMakeAdminClick}>Make Admin</div>
                    }
                    {user.sysAdmin 
                        ? <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleMakeSysAdminClick}>Remove Sys Admin</div>
                        : <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleMakeSysAdminClick}>Make Sys Admin</div>
                    }
                    {user.active 
                        ? <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleDeactivateUserClick}>Deactivate User</div>
                        : <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleReactivateUserClick}>Reactivate User</div>
                    }
                    <div className='settings__user__popover__btn popover__btn--red click' onClick={handleDeleteUserClick}>Delete User</div>
                </div>
        </Popover>
        <Dialog open={dialogOpen} onClose={()=>setDialogOpen(false)}>
        <div className="dialog settings__profile__dialog">
          <div className="dialog__title title mr--50">Delete User?</div>
          <div className="dialog__btns">
            <div
              className="cancel dialog__btn click"
              onClick={()=>setDialogOpen(false)}
            >
              Cancel
            </div>
            {isDeleting ? (
              <CircularProgress
                color="secondary"
                style={{ width: "19px", height: "19px", marginRight: "20px" }}
              />
            ) : (
              <div
                className="save dialog__btn click"
                onClick={handleDeleteUserClick}
              >
                Delete
              </div>
            )}
          </div>
        </div>
      </Dialog>
        </>
    )
}