import React, { useState, useContext } from "react";
import { LaneContext } from "../../providers/LaneProvider";
import { numberWithCommas } from "../../../components/generic/Utils";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import "./RateTag.css";

export default function RateTag({ lane, color }) {
  const { updateLane } = useContext(LaneContext);
  const [showEdit, setShowEdit] = useState(false);
  const [rateInput, setRateInput] = useState(lane?.rate);

  async function enterKeyHandler(e) {
    if (e.keyCode === 13) {
      lane.rate = rateInput;
      try {
        await updateLane(lane);
        setShowEdit(false);
      } catch (error) {
        console.error(error)
      }
    }
  };

  const handleBlur = () => {
    setRateInput(lane.rate)
    setShowEdit(false);
  };

  return (
    <div className={`chip chip--${color}`}>
      <div className={`chip--${color}__top`}>RATE</div>
      <div className={`chip--${color}__bottom`}>
      <span style={{ marginRight: "5px" }} role="img" aria-label="money">💵</span>
        {showEdit ? (
          <input
            className="tagInput"
            type="number"
            min={0}
            value={rateInput}
            onChange={(event) => setRateInput(event.target.value)}
            onKeyUp={(e) => enterKeyHandler(e)}
            onBlur={handleBlur}
          />
        ) : (
          <div>${numberWithCommas(lane.rate)}</div>
        )}
          <button style={{ marginLeft: "5px" }} onClick={() => setShowEdit(!showEdit)}>
            <CreateOutlinedIcon
              className={`editBtn__img editBtn__${color}`}
              style={{ fontSize: "16px" }}
            />
          </button>
      </div>
    </div>
  );
}
