import { Dialog } from '@material-ui/core'
import React, { useContext } from 'react'
import { AlertContext } from '../providers/AlertProvider'
import Logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import './Alert.css'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'baseline'  // default center
    }
});

export default function Alert() {
    const { alertOpen, setAlertOpen, alertMessage, setAlertMessage } = useContext(AlertContext)
    const classes = useStyles();

    const handleClose = () => {
        setAlertOpen(false)
        setAlertMessage('')
    }

    return (
        <Dialog open={alertOpen} onClose={handleClose} classes={{scrollPaper: classes.scrollPaper }}>
            <div className='alert'>
                <img src={Logo} alt='Terralanes Logo' className='alert__logo'/>
                <div className='alert__title'>Oops! There was an error...</div>
                <div className='alert__message'>Error: {alertMessage}</div>
            </div>
        </Dialog>
    )
}