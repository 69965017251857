import React, { createContext, useContext, useState } from "react";
import { UserContext } from "./UserProvider";

export const BillingContext = createContext();

const BillingProvider = props => {
  const { getToken } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  async function createStripeCustomer(
    brokerageId,
    billingEmail,
    billingAddress,
    brokerageName
  ) {
    const token = await getToken();
    const result = await fetch(
      `${apiUrl}/create-stripe-customer1/${brokerageId}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: billingEmail,
          address: billingAddress,
          name: brokerageName
        })
      }
    ).then(resp => resp.json());

    return result;
  }

  async function createStripeSubscription(
    brokerageId,
    customerId,
    paymentMethodId,
    priceId,
    quantity
  ) {
    const token = await getToken();
    const result = await fetch(`${apiUrl}/create-stripe-subscription1`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        brokerageId: brokerageId,
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        quantity: quantity
      })
    }).then(resp => resp.json());

    return result;
  }

  async function getCustomerBillingDetails() {
    const token = await getToken();
    const details = await fetch(`${apiUrl}/billing-details1`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    }).then(resp => resp.json());

    return details;
  }

  // -----------------------------------------
  // These two functions are identical, so let's combine them when we get a spare moment

  async function updateStripeSubscriptionPrice(
    subscriptionId,
    priceId,
    quantity
  ) {
    const token = await getToken();
    const result = await fetch(`${apiUrl}/subscription1`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        items: [
          {
            id: subscriptionId,
            price: priceId,
            quantity: quantity
          }
        ]
      })
    });

    return result;
  }

  async function updateStripeSubscriptionQuantity(
    subscriptionItemId,
    newQuantity,
    priceId
  ) {
    const token = await getToken();
    const result = await fetch(`${apiUrl}/subscription1`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        items: [
          {
            id: subscriptionItemId,
            quantity: newQuantity,
            price: priceId
          }
        ]
      })
    });

    return result;
  }

  // -----------------------------------------

  async function updateStripeCustomerDefaultPaymentMethod(
    customerId,
    paymentMethodId
  ) {
    const token = await getToken();
    const result = await fetch(`${apiUrl}/default-payment1`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId
      })
    }).then(resp => resp.json());

    return result;
  }

  async function createStripeSetupIntentForCustomer(customerId) {
    const token = await getToken();
    const intent = await fetch(`${apiUrl}/setup-intent1`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ customerId: customerId })
    }).then(resp => resp.json());

    return intent;
  }

  return (
    <BillingContext.Provider
      value={{
        createStripeCustomer,
        createStripeSubscription,
        getCustomerBillingDetails,
        updateStripeSubscriptionPrice,
        createStripeSetupIntentForCustomer,
        updateStripeCustomerDefaultPaymentMethod,
        updateStripeSubscriptionQuantity
      }}
    >
      {props.children}
    </BillingContext.Provider>
  );
};

export default BillingProvider;
