import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import {
  laneToastMessages,
  carrierToastMessages
} from "../generic/ToastMessages";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";

export const LaneContext = createContext();

const LaneProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL + "/lane1";
  const history = useHistory();
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  // Global lanesUpdated state that allows for the useLanes custom hook to re-fetch specific lane data when it changes
  // Otherwise there is no way to communicate a re-render event between LaneProvider & useLanes
  const [lanesUpdated, setLanesUpdated] = useState(false);
  const [refreshOnCarrierChange, setRefreshOnCarrierChange] = useState(false);

  // **** NOT IN USE: PROBABLY DELETE ****
  async function getLanesForCurrentUser() {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const lanes = await responseHandler({ response, setAlertModal });

      return lanes;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }
  // **** END OF UNUSED FUNCTION ****

  async function getLanesForUser(userId, workflow) {
    try {
      const token = await getToken();
      let fetchedAll = false;
      let page = 0;
      let lanesFull = [];
      let loadsPerMonth = 0;
      let spend = 0;
      let lanesTR;

      while(!fetchedAll){
        const response = await fetch(
          `${apiUrl}/top/${userId}?status=${workflow}&page=${page}`,
          {
            method: "GET",
            headers: {
              Authorization: token
            }
          }
        );

        let lanes = await responseHandler({
          response,
          setAlertModal
        });
        lanesFull = lanesFull.concat(lanes.Lanes);
        loadsPerMonth+= lanes.loadsPerMonth;
        spend+= lanes.spend;
        
        lanesTR = lanes;

        lanesTR.Lanes = lanesFull;
        lanesTR.loadsPerMonth = loadsPerMonth;
        lanesTR.spend = spend;
        fetchedAll = lanes.fetchedAll
        page++;
      }

      return lanesTR;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getLanesForCustomer(customerId, workflow) {
    try {
      const token = await getToken();
      let fetchedAll = false;
      let page = 0;
      let lanesFull = [];
      let loadsPerMonth = 0;
      let spend = 0;
      let lanesTR;
      while(!fetchedAll){
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lanes1/customer/${customerId}?status=${workflow}&page=${page}`,
          {
            method: "GET",
            headers: {
              Authorization: token
            }
          }
        );
        let lanes = await responseHandler({
          response,
          setAlertModal
        });
        lanesFull = lanesFull.concat(lanes.Lanes);
        loadsPerMonth+= lanes.loadsPerMonth;
        spend+= lanes.spend;
        
        lanesTR = lanes;

        lanesTR.Lanes = lanesFull;
        lanesTR.loadsPerMonth = loadsPerMonth;
        lanesTR.spend = spend;
        fetchedAll = lanes.fetchedAll
        page++;
      }
      
      return lanesTR;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getLanesForLocation(locationId, workflow) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/location/${locationId}/lanes?status=${workflow}  `,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const lanes = await responseHandler({
        response,
        setAlertModal
      });

      return lanes;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getLanesForTeam(teamId) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/team1/${teamId}/lanes`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const lanes = await responseHandler({
        response,
        setAlertModal
      });

      return lanes;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getLanesForBrokerage(brokerageId) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brokerage1/${brokerageId}/lanes`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const lanes = await responseHandler({
        response,
        setAlertModal
      });

      return lanes;
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getLane(laneId, workflow) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${laneId}?status=${workflow}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const lane = await responseHandler({ response, setAlertModal });
      return lane;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function addLane(lane) {
    console.log('here is body',lane);
    let l = {
      address: null,
      address2: null,
      city: "Louisville",
      inbound: false,
      cityAux: "Dallas",
      stateAux:"TX",
      state: "KY",
      truckType: "van",
      zipCode: null,
      brokerageId: 202,
      customerId: 299,
    }
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(l)
      });

      const addedLane = await responseHandler({
        response,
        setAlertModal
      });

      return addedLane;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function updateLane(lane, isUpdatingMultipleLanes) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${lane.id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(lane)
      });

      // Because this update function is used on both single & multiple items,
      // Separating out their response handlers to show correct notifications
      if (isUpdatingMultipleLanes === true) {
        // The toast notifications occur in Lane.js after all lanes have been updated
        // Otherwise we get multiple toasts
        const result = await responseHandler({ response, setAlertModal });
        setLanesUpdated(!lanesUpdated);
        return result;
      } else {
        // Update a single lane:
        const result = await responseHandler({
          response: response,
          toastSuccess: laneToastMessages.updateSuccess,
          toastError: laneToastMessages.updateFailure,
          setAlertModal
        });
        setLanesUpdated(!lanesUpdated);
        return result;
      }
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function deleteLane(id) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: token
        }
      });

      await responseHandler({
        response,
        toastSuccess: laneToastMessages.updateSuccess,
        toastError: laneToastMessages.updateFailure,
        setAlertModal
      });

      history.push("/");
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  // **** ALL BELOW ARE NOT YET TESTED ****
  async function getMarketFeedbackForLane(laneId) {
    const token = await getToken();
    const marketFeedback = await fetch(`${apiUrl}/${laneId}/feedback`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      }
    }).then(res => res.json());
    return marketFeedback;
  }

  async function addMarketFeedbackToLane(laneId, marketFeedback) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${laneId}/feedback`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(marketFeedback)
      });

      await responseHandler({
        response,
        toastSuccess: laneToastMessages.addMarketFeedbackSuccess,
        toastError: laneToastMessages.addMarketFeedbackFailure
      });
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }
  // **** END OF FUNCTIONS THAT NEED TESTING****

  async function getCarriersForLane(laneId) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${laneId}/carriers`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const carriers = await responseHandler({ response, setAlertModal });

      return carriers;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function addCarrier(carrier) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${carrier.laneId}/carriers`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(carrier)
      });

      await responseHandler({
        response,
        setAlertModal,
        toastSuccess: carrierToastMessages.addSuccess,
        toastError: carrierToastMessages.addFailure
      });

      setRefreshOnCarrierChange(!refreshOnCarrierChange);
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function updateCarrier(carrier) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${carrier.laneId}/carriers`, {
        method: "PUT",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(carrier)
      });

      await responseHandler({
        response,
        setAlertModal,
        toastSuccess: carrierToastMessages.updateSuccess,
        toastError: carrierToastMessages.updateFailure
      });

      setRefreshOnCarrierChange(!refreshOnCarrierChange);
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function deleteCarrier(carrier) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${carrier.laneId}/carriers`, {
        method: "DELETE",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(carrier)
      });

      await responseHandler({
        response,
        setAlertModal,
        toastSuccess: carrierToastMessages.deleteSuccess,
        toastError: carrierToastMessages.deleteFailure
      });

      setRefreshOnCarrierChange(!refreshOnCarrierChange);
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  return (
    <LaneContext.Provider
      value={{
        lanesUpdated,
        getLanesForUser,
        addLane,
        updateLane,
        deleteLane,
        getLanesForCustomer,
        getLane,
        getLanesForLocation,
        getMarketFeedbackForLane,
        addMarketFeedbackToLane,
        getCarriersForLane,
        getLanesForTeam,
        getLanesForBrokerage,
        addCarrier,
        updateCarrier,
        deleteCarrier,
        refreshOnCarrierChange
      }}
    >
      {props.children}
    </LaneContext.Provider>
  );
};

export default LaneProvider;
