import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import "./ButtonOutlined.css";

export default function ButtonOutlined({
  text,
  onClick,
  isAdding,
  color,
  style,
  isSubmitting,
  isDisabled
}) {
  return (
    <button
      disabled={isDisabled}
      style={style}
      className={`buttonOutlined__btn buttonOutlined__btn--${color} ${
        isDisabled === true ? `buttonOutlined__btn--${color}--disabled` : ""
      }`}
      onClick={e => onClick(e)}
    >
      {isSubmitting === true ? (
        <CircularProgress
          color="inherit"
          style={{
            width: "14px",
            height: "14px"
          }}
        />
      ) : (
        <>
          {isAdding && "+ "}
          {text}
        </>
      )}
    </button>
  );
}

ButtonOutlined.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool
};
