import { useContext } from "react";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import polyline from "@mapbox/polyline";

export default function useMapLayers() {
  const { workflow } = useContext(ApplicationStateContext);

  // Based on colors from App.css
  const OWNED_COLOR = "#4e67d8";
  const OPPORTUNITY_COLOR = "#197429";

  function assignCustomerColor(
    ownedVolume,
    ownedCustomers,
    originId,
    destinationId
  ) {
    // Need to create & check against an array of customers with owned lanes, otherwise
    // The last lane that's checked decides the customer's color
    if (ownedCustomers.length > 0) {
      if (ownedCustomers.find(destId => destId === destinationId)) {
        return OWNED_COLOR;
      }

      if (ownedCustomers.find(origId => origId === originId)) {
        return OWNED_COLOR;
      }
    }

    if (ownedVolume > 0) {
      if (originId) {
        ownedCustomers.push(originId);
      }
      if (destinationId) {
        ownedCustomers.push(destinationId);
      }
      return OWNED_COLOR;
    } else {
      return OPPORTUNITY_COLOR;
    }
  }

  function assignColorByWorkflow(
    ownedVolume,
    ownedCustomers,
    originId,
    destinationId
  ) {
    if (workflow === "owned") {
      return OWNED_COLOR;
    } else if (workflow === "opportunities") {
      return OPPORTUNITY_COLOR;
    } else {
      if (Array.isArray(ownedCustomers) === true) {
        return assignCustomerColor(
          ownedVolume,
          ownedCustomers,
          originId,
          destinationId
        );
      } else {
        if (ownedVolume > 0) {
          return OWNED_COLOR;
        } else {
          return OPPORTUNITY_COLOR;
        }
      }
    }
  }

  function createRouteLayer(lanes) {
    const data = {
      type: "FeatureCollection",
      features: lanes.map((lane, i) => {
        const r = {
          type: "Feature",
          id: `route-${i}`,
          properties: {
            //customerId: lanes[i].origin.CustomerLocation
            //  ? lanes[i].origin.CustomerLocation.Customer.id
            //  : lanes[i].destination.CustomerLocation.Customer.id,
            laneId: lanes[i].id,
            originLocationId: lanes[i].originLocationId,
            destinationLocationId: lanes[i].destinationLocationId,
            customerOrigin: Boolean(lanes[i].origin.CustomerLocation),
            customerDestination: Boolean(lanes[i].destination.CustomerLocation),
            // originName: lanes[i].origin.CustomerLocation
            //   ? lanes[i].origin.CustomerLocation.Customer.name
            //   : lanes[i].origin.LanePartner.name,
            originCity: lanes[i].origin.city,
            originState: lanes[i].origin.state || lanes[i].destination.state,
            destinationCity:
              lanes[i].destination.city || lanes[i].destination.city,
            destinationState:
              lanes[i].destination.state || lanes[i].destination.state,
            color: assignColorByWorkflow(lanes[i].currentVolume)
          },
          geometry:
            lane.routeGeometry !== null
              ? polyline.toGeoJSON(lane.routeGeometry)
              : ""
        };
        return r;
      })
    };
    return data;
  }

  function createCustomerLayer(lanes) {
    // Need this array to check if customer already has an owned lane
    let ownedCustomers = [];

    const data = {
      type: "FeatureCollection",
      features: lanes
        .map((lane, i) => {
          let r;
          if (
            lanes[i].origin.CustomerLocation &&
            lanes[i].destination.CustomerLocation
          ) {
            // IF LANE HAS CUSTOMER LOCATIONS FOR ORIGIN AND DEST
            r = [
              {
                type: "Feature",
                id: `marker-customer-${i}`,
                geometry: {
                  type: "Point",
                  coordinates: [
                    +lane.origin.lnglat.split(",")[0],
                    +lane.origin.lnglat.split(",")[1]
                  ]
                },
                properties: {
                  customerId: lanes[i].origin.CustomerLocation.Customer.id,
                  customerName: lanes[i].origin.CustomerLocation.Customer.name,
                  customerLocationId: lanes[i].originLocationId,
                  customerAddress: lanes[i].origin.address,
                  customerCity: lanes[i].origin.city,
                  customerState: lanes[i].origin.state,
                  customerPhone: lanes[i].origin.phone,
                  customerEmail: lanes[i].origin.email,
                  customerOpen: lanes[i].origin.open,
                  customerClose: lanes[i].origin.close,
                  color: assignColorByWorkflow(
                    lanes[i].currentVolume,
                    ownedCustomers,
                    lanes[i].originLocationId,
                    lanes[i].destinationLocationId
                  )
                }
              },
              {
                type: "Feature",
                id: `marker-customer-${i}b`,
                geometry: {
                  type: "Point",
                  coordinates: [
                    +lane.destination.lnglat.split(",")[0],
                    +lane.destination.lnglat.split(",")[1]
                  ]
                },
                properties: {
                  customerId: lanes[i].destination.CustomerLocation.Customer.id,
                  customerName:
                    lanes[i].destination.CustomerLocation.Customer.name,
                  customerLocationId: lanes[i].destinationLocationId,
                  customerAddress: lanes[i].destination.address,
                  customerCity: lanes[i].destination.city,
                  customerState: lanes[i].destination.state,
                  customerPhone: lanes[i].destination.phone,
                  customerEmail: lanes[i].destination.email,
                  customerOpen: lanes[i].destination.open,
                  customerClose: lanes[i].destination.close,
                  color: assignColorByWorkflow(
                    lanes[i].currentVolume,
                    ownedCustomers,
                    lanes[i].originLocationId,
                    lanes[i].destinationLocationId
                  )
                }
              }
            ];
            return r;
          } else if (lanes[i].origin.CustomerLocation) {
            // IF CUSTOMER LOCATION IS ORIGIN
            r = {
              type: "Feature",
              id: `marker-customer-${i}`,
              geometry: {
                type: "Point",
                coordinates: [
                  +lane.origin.lnglat.split(",")[0],
                  +lane.origin.lnglat.split(",")[1]
                ]
              },
              properties: {
                customerId: lanes[i].origin.CustomerLocation.Customer.id,
                customerName: lanes[i].origin.CustomerLocation.Customer.name,
                customerLocationId: lanes[i].originLocationId,
                customerAddress: lanes[i].origin.address,
                customerCity: lanes[i].origin.city,
                customerState: lanes[i].origin.state,
                customerPhone: lanes[i].origin.phone,
                customerEmail: lanes[i].origin.email,
                customerOpen: lanes[i].origin.open,
                customerClose: lanes[i].origin.close,
                color: assignColorByWorkflow(
                  lanes[i].currentVolume,
                  ownedCustomers,
                  lanes[i].originLocationId
                )
              }
            };
            return r;
          } else if (lanes[i].destination.CustomerLocation) {
            // IF CUSTOMER LOCATION IS DESTINATION
            r = {
              type: "Feature",
              id: `marker-customer-${i}`,
              geometry: {
                type: "Point",
                coordinates: [
                  +lane.destination.lnglat.split(",")[0],
                  +lane.destination.lnglat.split(",")[1]
                ]
              },
              properties: {
                customerId: lanes[i].destination.CustomerLocation.Customer.id,
                customerName:
                  lanes[i].destination.CustomerLocation.Customer.name,
                customerLocationId: lanes[i].destinationLocationId,
                customerAddress: lanes[i].destination.address,
                customerCity: lanes[i].destination.city,
                customerState: lanes[i].destination.state,
                customerPhone: lanes[i].destination.phone,
                customerEmail: lanes[i].destination.email,
                customerOpen: lanes[i].destination.open,
                customerClose: lanes[i].destination.close,
                color: assignColorByWorkflow(
                  lanes[i].currentVolume,
                  ownedCustomers,
                  undefined,
                  lanes[i].destinationLocationId
                )
              }
            };
          }
          return r;
        })
        .flat()
    };

    ownedCustomers = null;
    return data;
  }

  function createLanePartnerLayer(lanes) {
    const data = {
      type: "FeatureCollection",
      features: lanes.map((lane, i) => {
        let r;
        if (lanes[i].destination.LanePartner) {
          r = {
            type: "Feature",
            id: `marker-partner-${i}`,
            geometry: {
              type: "Point",
              coordinates: [
                +lane.destination.lnglat.split(",")[0],
                +lane.destination.lnglat.split(",")[1]
              ]
            },
            properties: {
              color: assignColorByWorkflow(lanes[i].currentVolume)
            }
          };
          return r;
        } else if (lanes[i].origin.LanePartner) {
          r = {
            type: "Feature",
            id: `marker-partner-${i}`,
            geometry: {
              type: "Point",
              coordinates: [
                +lane.origin.lnglat.split(",")[0],
                +lane.origin.lnglat.split(",")[1]
              ]
            },
            properties: {
              color: assignColorByWorkflow(lanes[i].currentVolume)
            }
          };
          return r;
        } else return {};
      })
    };
    return data;
  }

  return {
    createRouteLayer,
    createCustomerLayer,
    createLanePartnerLayer
  };
}
