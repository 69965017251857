import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../components/providers/UserProvider";
import MapDrawerLayout from "../components/layouts/MapDrawerLayout";
import useLanes from "../hooks/useLanes";
import UserViewDrawerContent from "./UserViewDrawerContent";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";

const UserView = () => {
  const { userId } = useParams();
  const { refreshOnAdd } = useContext(ApplicationStateContext);
  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState();
  const { lanes, areLanesLoading, spend, loadsPerMonth } = useLanes(
    userId,
    "user"
  );
  const [isDrawerLoading, setIsDrawerLoading] = useState(true);

  useEffect(() => {
    async function getUserData() {
      const user = await getUser(userId);
      setUser(user);
      setIsDrawerLoading(false);
    }

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, refreshOnAdd]);

  return (
    <MapDrawerLayout
      mapLanes={lanes}
      isMapLoading={areLanesLoading}
      drawerContent={
        <UserViewDrawerContent
          user={user}
          lanes={lanes}
          spend={spend}
          loadsPerMonth={loadsPerMonth}
        />
      }
      isDrawerLoading={isDrawerLoading}
    />
  );
};

export default UserView;
