import { toast } from "react-toastify";
// All toast messages used for notifications.
// To have fewer exports & import messages,
// export objects with all messages for a type

// Styled like green stamps
function toastSuccess(message) {
  toast.success(message);
}

// Styled like red stamps
function toastError(message) {
  toast.error(message);
}

// Styled black & white like tool tips
function toastInfo(message) {
  toast.info(message);
}

export const uploadToastMessages = {
  failure() {
    const m = `Unable to upload file. Please try again.`;
    toastError(m);
  }
};

export const copyToastMessages = {
  success(item) {
    const m = `Copied ${item} to clipboard!`;
    toastSuccess(m);
  },
  failure(item) {
    const m = `Unable to copy ${item} to clipboard.`;
    toastError(m);
  }
};

// Tagged Teammates
export const removeTeammateFailure = name => `Unable to remove ${name}.`;

export const registerToastMessages = {
  emailTaken() {
    const m = `An account already exists with this email`;
    toastError(m);
  },
  emailVerificationIncorrect() {
    const m = `The code you entered is incorrect.`;
    toastError(m);
  },
  emailVerificationFailure() {
    const m = `Error while verifying email.`;
    toastError(m);
  },
  brokerageAddFailure() {
    const m = `Error while adding brokerage information`;
    toastError(m);
  }
};

export const userToastMessage = {
  profileUpdateSuccess() {
    const m = `Profile successfully updated.`;
    toastSuccess(m);
  }
};

export const settingsToastMessages = {
  teamCreateWarning() {
    const m = `Give your team an icon and name`;
    toastInfo(m);
  }
};

export const passwordToastMessages = {
  updateSuccess() {
    const m = `Password successfully updated.`;
    toastSuccess(m);
  },
  updateFailure() {
    const m = `Error updating password.`;
    toastError(m);
  }
};

export const toolbeltToastMessages = {
  errorNoNegatives() {
    const m = `Can't save negative numbers!`;
    toastError(m);
  },
  errorNoOwnedGreaterThanPotential() {
    const m = `Can't save Owned Volume greater than Total Potential Volume`;
    toastError(m);
  }
};

export const contactToastMessages = {
  addSuccess(contactName) {
    const m = `Added ${contactName}!`;
    toastSuccess(m);
  },
  addFailure(contactName) {
    const m = `Unable to add ${contactName}!`;
    toastError(m);
  },
  editSuccess(contactName) {
    const m = `Updated ${contactName}!`;
    toastSuccess(m);
  },
  editFailure(contactName) {
    const m = `Unable to edit ${contactName}`;
    toastError(m);
  },
  deleteSuccess(contactName) {
    const m = `Deleted ${contactName}`;
    toastSuccess(m);
  },
  deleteFailure(contactName) {
    const m = `Unable to delete ${contactName}`;
    toastError(m);
  }
};

export const customerToastMessages = {
  updateSuccess(customerName) {
    const m = `Updated ${customerName}`;
    toastSuccess(m);
  },
  updateFailure(customerName) {
    const m = `Unable to update ${customerName}`;
    toastError(m);
  },
  deleteSuccess() {
    const m = `Customer deleted!`;
    toastSuccess(m);
  },
  deleteFailure() {
    const m = `Unable to delete customer!`;
    toastError(m);
  }
};

export const laneToastMessages = {
  updateSuccess() {
    const m = `Lane updated!`;
    toastSuccess(m);
  },
  updateMultipleSuccess() {
    const m = `Lanes updated!`;
    toastSuccess(m);
  },
  updateMultipleFailure() {
    const m = `Unable to update lanes!`;
    toastError(m);
  },
  updateFailure() {
    const m = `Unable to update lane!`;
    toastError(m);
  },
  deleteSuccess() {
    const m = `Lane deleted!`;
    toastSuccess(m);
  },
  deleteFailure() {
    const m = `Unable to delete lane!`;
    toastError(m);
  },
  addMarketFeedbackSuccess() {
    const m = "Added Market Feedback!";
    toastSuccess(m);
  },
  addMarketFeedbackFailure() {
    const m = "Unable to add Market Feedback!";
    toastError(m);
  }
};

export const carrierToastMessages = {
  addSuccess() {
    const m = `Carrier added!`;
    toastSuccess(m);
  },
  addFailure() {
    const m = `Unable to add Carrier!`;
    toastError(m);
  },
  updateSuccess() {
    const m = `Carrier updated!`;
    toastSuccess(m);
  },
  updateFailure() {
    const m = `Unable to update Carrier!`;
    toastError(m);
  },
  deleteSuccess() {
    const m = `Carrier deleted!`;
    toastSuccess(m);
  },
  deleteFailure() {
    const m = `Unable to delete Carrier!`;
    toastError(m);
  }
};

export const locationToastMessages = {
  updateSuccess() {
    const m = `Location updated!`;
    toastSuccess(m);
  },
  updateFailure() {
    const m = `Unable to update location!`;
    toastError(m);
  },
  deleteSuccess() {
    const m = `Location deleted!`;
    toastSuccess(m);
  },
  deleteFailure() {
    const m = `Unable to delete location!`;
    toastError(m);
  }
};

export const taggedTeammateToastMessages = {
  addSuccess({ teammateName, typeName }) {
    const m = `Tagged ${teammateName} to ${typeName}`;
    toastSuccess(m);
  },
  addFailure({ teammateName, typeName }) {
    const m = `Unable to tag ${teammateName} to ${typeName}`;
    toastError(m);
  },
  removeSuccess({ teammateName, typeName }) {
    const m = `Removed ${teammateName} from ${typeName}`;
    toastSuccess(m);
  },
  removeFailure({ teammateName, typeName }) {
    const m = `Unable to remove ${teammateName} from ${typeName}`;
    toastError(m);
  }
};

export const teamToastMessages = {
  addSuccess(name) {
    const m = `Added ${name}!`;
    toastSuccess(m);
  },
  addFailure(name) {
    const m = `Unable to add ${name}`;
    toastError(m);
  },
  addTeammateSuccess() {
    const m = `Added teammate`;
    toastSuccess(m);
  },
  addTeammateFailure() {
    const m = `Unable to add teammate`;
    toastError(m);
  },
  addTeammatesSuccess() {
    const m = `Added teammates`;
    toastSuccess(m);
  },
  addTeammatesFailure() {
    const m = `Unable to add teammates`;
    toastError(m);
  },
  updateSuccess(name) {
    const m = `Updated ${name}!`;
    toastSuccess(m);
  },
  updateFailure(name) {
    const m = `Unable to update ${name}`;
    toastError(m);
  },
  removeSuccess(name) {
    const m = `${name} deleted!`;
    toastSuccess(m);
  },
  removeFailure(name) {
    const m = `Unable to delete ${name}!`;
    toastError(m);
  },
  removeTeammateSuccess(name) {
    const m = `Removed ${name} from team.`;
    toastSuccess(m);
  },
  removeTeammateFailure(name) {
    const m = `Unable to remove ${name} from team.`;
    toastError(m);
  }
};

export const tagToastMessages = {
  addSuccess() {
    const m = `Added tag!`;
    toastSuccess(m);
  },
  addNamedSuccess(tagContent) {
    const m = `Added tag: ${tagContent}!`;
    toastSuccess(m);
  },
  addFailure() {
    const m = `Unable to add tag.`;
    toastError(m);
  },
  removeSuccess(tagContent) {
    const m = `Removed tag: ${tagContent}!`;
    toastSuccess(m);
  },
  removeFailure(tagContent) {
    const m = `Unable to remove tag: ${tagContent}`;
    toastError(m);
  }
};
