import React, { useContext, useState } from "react";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import CustomerBio from "../components/drawers/customer/CustomerBio";
import DrawerContacts from "../components/drawers/DrawerContacts";
import DrawerTeammateTaggedList from "../components/drawers/DrawerTeammateTaggedList";
import DrawerCarriers from "../components/drawers/DrawerCarriers";
import QuickTagsList from "../components/drawers/tags/QuickTagsList";
import LaneDefaultTags from "../components/drawers/tags/LaneDefaultTags";
import MarketFeedbackContainer from "../components/drawers/customer/MarketFeedbackContainer";
import DrawerToolbelt from "../components/drawers/DrawerToolbelt";
// import ButtonOutlined from "../components/generic/buttons/ButtonOutlined";
// import TooltipPhone from "../components/library/tooltip/TooltipPhone";
// import TooltipEmail from "../components/library/tooltip/TooltipEmail";
import DrawerHeaderSettingsButton from "../components/drawers/DrawerHeaderSettingsButton";

export default function LaneViewDrawerContent({ lane }) {
  const { isDrawerOpen } = useContext(ApplicationStateContext);
  // const [edit, setEdit] = useState(false);
  const history = useHistory();

  return (
    <>
      <header
        className={`drawer__upper mb--10 drawer__upper--${
          isDrawerOpen ? "open" : "close"
        }`}
      >
        <div className="drawer__top">
          <section className="drawer__column--2 drawer__noImage">
            <div className="drawer__row">
              <DrawerHeaderSettingsButton />
              <div
                className="tag tag--red mr--5"
                style={{ margin: "0 0.5rem" }}
              >
                Lane
              </div>
              {lane.truckType === null ? null : (
                <div className="tag tag--red--light">{lane.truckType}</div>
              )}
              {
                // <ButtonOutlined
                //   isAdding
                //   text={"Add Details"}
                //   onClick={() => setEdit(true)}
                // />
              }
            </div>
            <div className="drawer__row">
              <div className="drawer__name">
                <Tooltip
                  title={
                    <div className="drawer__tooltip">
                      <div className="drawer__tooltip__symbol">📍</div>
                      <div>
                        <div>{lane.origin.address}</div>
                        <div>
                          {lane.origin.city}, {lane.origin.state}{" "}
                          {lane.origin.zipcode}
                        </div>
                      </div>
                    </div>
                  }
                  placement="left"
                >
                  <span
                    className={`border--dashed ${
                      lane.origin.CustomerLocation ? "click" : ""
                    }`}
                    onClick={() => {
                      if (lane.origin.CustomerLocation) {
                        history.push(`/location/${lane.originLocationId}`);
                      }
                    }}
                  >
                    {lane.origin.city}, {lane.origin.state}
                  </span>
                </Tooltip>
                <span> to </span>
                <Tooltip
                  title={
                    <div className="drawer__tooltip">
                      <div className="drawer__tooltip__symbol">📍</div>
                      <div>
                        <div>{lane.destination.address}</div>
                        <div>
                          {lane.destination.city}, {lane.destination.state}{" "}
                          {lane.destination.zipcode}
                        </div>
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <span
                    className={`border--dashed ${
                      lane.destination.CustomerLocation ? "click" : ""
                    }`}
                    onClick={() => {
                      if (lane.destination.CustomerLocation) {
                        history.push(`/location/${lane.destinationLocationId}`);
                      }
                    }}
                  >
                    {lane.destination.city}, {lane.destination.state}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="drawer__row">
              <div
                className="drawer__customerName click"
                onClick={() => {
                  history.push(`/customer/${lane.loads[0].customerId}`);
                  // if (lane.origin.CustomerLocation !== null) {
                  //   history.push(
                  //     `/customer/${lane.origin.CustomerLocation.Customer.id}`
                  //   );
                  // } else if (lane.destination.CustomerLocation !== null) {
                  //   history.push(
                  //     `/customer/${lane.destination.CustomerLocation.customerId}`
                  //   );
                  // } else {
                  //   console.error(
                  //     "lane.origin.CustomerLocation and lane.destination.CustomerLocation are both null! Can't route to customer!"
                  //   );
                  // }
                }}
              >
                {(lane.loads && lane.loads[0])
                  ? lane.loads[0].Customer.displayName
                  : ""}
              </div>

              {
                // Origin currently can't be editing in the frontend
                // <TooltipPhone
                //   style={{ marginLeft: "5px" }}
                //   variant="tooltip__smallCircle"
                // >
                //   {lane.origin.phoneExt
                //     ? `${lane.origin.phone} x${lane.origin.phoneExt}`
                //     : lane.origin.phone}
                // </TooltipPhone>
                // <TooltipEmail
                //   style={{ marginLeft: "5px" }}
                //   variant="tooltip__smallCircle"
                // >
                //   {lane.origin.email}
                // </TooltipEmail>
              }
            </div>
          </section>

          <LaneDefaultTags lane={lane} />
        </div>
      </header>

      <section className="drawer__lower" style={{ display: "flex" }}>
        <div className="drawer__lower__top">
          <QuickTagsList page="lane" item={lane} />
        </div>
        <div className="drawer__lower__columns">
          <div className="drawer__lower__column drawer__lower__column--left">
            <DrawerToolbelt type={"lane"} lane={lane} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--middle">
            <DrawerCarriers lane={lane} />
            <MarketFeedbackContainer lane={lane} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--right">
            <DrawerTeammateTaggedList page="lane" lane={lane} />
            {/* <CustomerBio
              customer={
                lane.origin.CustomerLocation
                  ? lane.origin.CustomerLocation.Customer
                  : lane.destination.CustomerLocation.Customer
              }
            /> */}
            <DrawerContacts page="lane" item={lane} />
          </div>
        </div>
      </section>
    </>
  );
}
