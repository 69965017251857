import React, { createContext, useState } from "react";

export const AlertContext = createContext();

const AlertProvider = props => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  function setAlertModal(message) {
    setAlertMessage(message);
    setAlertOpen(true);
  }

  return (
    <AlertContext.Provider
      value={{
        setAlertModal,
        alertOpen,
        setAlertOpen,
        alertMessage,
        setAlertMessage
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
