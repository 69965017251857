import React from "react";
import { useParams } from "react-router-dom";
import MapDrawerLayout from "../components/layouts/MapDrawerLayout";
import LaneViewDrawerContent from "./LaneViewDrawerContent";
import useLane from "../hooks/useLanes";

export default function LaneView() {
  const { laneId } = useParams();
  const { areLanesLoading, lane, lanes } = useLane(laneId, "lane");

  return (
    <MapDrawerLayout
      mapLanes={lanes}
      isMapLoading={areLanesLoading}
      drawerContent={<LaneViewDrawerContent lane={lane} />}
      isDrawerLoading={false} // no loading state for now, otherwise
      // the drawer loading indicator pop-ups on every lane update & it looks really awkward
    />
  );
}
