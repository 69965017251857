import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react'
import CreditCardForm from './form/CreditCardForm';
import PropTypes from 'prop-types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function SubmitCreditCard({ afterSubmit, newCustomer, brokerage, paymentPlan, usersCount, customerId }) {

    return (
        <Elements stripe={stripePromise}>
            <CreditCardForm afterSubmit={afterSubmit} brokerage={brokerage} paymentPlan={paymentPlan} usersCount={usersCount} newCustomer={newCustomer} customerId={customerId} />
        </Elements>
    )
}

SubmitCreditCard.propTypes = {
    afterSubmit: PropTypes.func,
    brokerage: PropTypes.object.isRequired,
    paymentPlan: PropTypes.string.isRequired, // 'monthly' or 'yearly'
    usersCount: PropTypes.number.isRequired, // integer
    newCustomer: PropTypes.bool.isRequired,
    customerId: PropTypes.string // required for existing customers
}