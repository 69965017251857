import React, { createContext, useContext, useState } from "react";
import responseHandler from "./utils/responseHandler";
import { contactToastMessages } from "../generic/ToastMessages";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import errorHandler from "./utils/errorHandler";

export const ContactContext = createContext();

const ContactProvider = props => {
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const apiUrl = process.env.REACT_APP_API_URL + "/contact1";
  const [wereContactsUpdated, setWereContactsUpdated] = useState(false);

  async function getContacts(page, item) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${item.id}?contactType=${page}`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      });

      const contacts = await responseHandler({
        response,
        setAlertModal
      });

      return contacts;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function addContact(page, item, contact, existing, universal) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${apiUrl}/${item.id}?contactType=${page}&existing=${existing}&universal=${universal}`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(contact)
        }
      );

      const name = `${contact.firstName} ${contact.lastName}`;

      await responseHandler({
        response: response,
        toastSuccess: contactToastMessages.addSuccess,
        toastSuccessParam: name,
        toastError: contactToastMessages.addFailure,
        toastErrorParam: name,
        setAlertModal
      });

      setWereContactsUpdated(!wereContactsUpdated);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function editContact(contact) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(contact)
      });

      const name = `${contact.firstName} ${contact.lastName}`;

      await responseHandler({
        response: response,
        toastSuccess: contactToastMessages.editSuccess,
        toastSuccessParam: name,
        toastError: contactToastMessages.editFailure,
        toastErrorParam: name,
        setAlertModal
      });

      setWereContactsUpdated(!wereContactsUpdated);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function deleteContact(page, contact) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}?contactType=${page}`, {
        method: "DELETE",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(contact)
      });

      const name = `${contact.firstName} ${contact.lastName}`;

      await responseHandler({
        response: response,
        toastSuccess: contactToastMessages.deleteSuccess,
        toastSuccessParam: name,
        toastError: contactToastMessages.deleteFailure,
        toastErrorParam: name,
        setAlertModal
      });

      setWereContactsUpdated(!wereContactsUpdated);
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function searchExistingContacts(q, itemType, itemId) {
    try {
      const token = await getToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/search1/contacts?q=${q}&itemType=${itemType}&itemId=${itemId}`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const searchResults = await responseHandler({ response, setAlertModal });

      return searchResults;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  return (
    <ContactContext.Provider
      value={{
        getContacts,
        editContact,
        addContact,
        deleteContact,
        wereContactsUpdated,
        searchExistingContacts
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};

export default ContactProvider;
