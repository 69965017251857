import { useContext, useEffect, useState } from "react";
import { ContactContext } from "../components/providers/ContactProvider";
// useContacts fetches & filters contacts

export default function useContacts(page, item) {
  const { getContacts, wereContactsUpdated } = useContext(ContactContext);
  const [contacts, setContacts] = useState([]);
  const [levelOneContacts, setLevelOneContacts] = useState([]);
  const [levelTwoContacts, setLevelTwoContacts] = useState([]);
  const [levelThreeContacts, setLevelThreeContacts] = useState([]);

  useEffect(() => {
    function filterContacts(contacts) {
      const levelOne = [];
      const levelTwo = [];
      const levelThree = [];
      contacts.forEach(contact => {
        switch (contact?.level) {
          case 1:
            levelOne.push(contact);
            break;
          case 2:
            levelTwo.push(contact);
            break;
          case 3:
            levelThree.push(contact);
            break;
          default:
            // This should never run
            console.error("Contact has no level assigned!");
            break;
        }
      });

      return { levelOne, levelTwo, levelThree };
    }

    async function fetchContacts() {
      const response = await getContacts(page, item);

      if (response !== null) {
        const { levelOne, levelTwo, levelThree } = filterContacts(response);
        setContacts(response);
        setLevelOneContacts(levelOne);
        setLevelTwoContacts(levelTwo);
        setLevelThreeContacts(levelThree);
      }
    }

    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, item, wereContactsUpdated]);

  return { contacts, levelOneContacts, levelTwoContacts, levelThreeContacts };
}
