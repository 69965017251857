import React, { useContext } from "react";
import { useHistory } from "react-router";
import { LaneContext } from "../providers/LaneProvider";
import FormAddWorkflowLane from "./FormAddWorkflowLane";
import FormAddWorkflowSubmitting from "./FormAddWorkflowSubmit";
import FormAddWorkflowSuccess from "./FormAddWorkflowSuccess";
// Contains all logic & steps for the lane workflow
// Lives here and imported into FormAddWorkflow to keep that file small

export default function FormAddWorkflowLaneSteps(setActiveModal, closeModal) {
  const { addLane } = useContext(LaneContext);
  const history = useHistory();

  let goToAddedId = null;

  function goToAddedObject() {
    history.push(`/lane/${goToAddedId}`);
    closeModal();
  }

  const stepLaneForm = {
    modalHeight: "37em",
    modalWidth: "35em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Enter Lane Details",
    headerIcon: "Lane",
    isSubmitting: false,
    content: <FormAddWorkflowLane onSubmit={addLaneSubmit} />
  };

  const stepLaneSubmitting = {
    modalHeight: "18em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: true,
    content: (
      <FormAddWorkflowSubmitting
        iconToAssign={"Lane"}
        text={"Adding lane..."}
      />
    )
  };

  const stepLaneSuccess = {
    modalHeight: "20em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: true,
    headerText: "",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowSuccess
        mainText={"Lane added!"}
        buttonText={"View Lane"}
        goToAdded={goToAddedObject}
        goToAddLane={function() {
          setActiveModal(stepLaneForm);
        }}
      />
    )
  };

  async function addLaneSubmit(e) {
    // Form validation lives in FormAddWorkflowLane
    e.preventDefault();

    setActiveModal(stepLaneSubmitting);
    const laneFormData = JSON.parse(sessionStorage.getItem("laneFormData"));

    const preppedLane = {
      locationId: laneFormData.locationId,
      truckType: laneFormData.truckType,
      inbound: laneFormData.inbound === "1" ? true : false,
      address: laneFormData.address === "" ? null : laneFormData.address,
      address2: laneFormData.address2 === "" ? null : laneFormData.address2,
      city: laneFormData.city,
      state: laneFormData.state,
      zipCode: laneFormData.zipCode === "" ? null : laneFormData.zipCode
    };

    const response = await addLane(preppedLane);

    if (response !== null) {
      sessionStorage.removeItem("laneFormData");
      goToAddedId = response.id;
      setActiveModal(stepLaneSuccess);
    } else {
      setActiveModal(stepLaneForm);
    }
  }

  return stepLaneForm;
}
