import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrokerageContext } from "../providers/BrokerageProvider";
import FormModalFooter from "./FormModalFooter";
import useSessionStorage from "../../hooks/useSessionStorage";
import InputStates from "../inputs/InputStates";
import { CircularProgress, TextField } from "@material-ui/core";
import { CustomerContext } from "../providers/CustomerProvider";

export default function FormAddWorkflowLane({ onSubmit }) {
  const { getCustomersInBrokerage } = useContext(BrokerageContext);
  const { getLocationsForCustomers } = useContext(CustomerContext);
  const [customers, setCustomers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isLocationSelectDisabled, setIsLocationSelectDisabled] = useState(
    true
  );
  const [customerLocations, setCustomerLocations] = useState([]);
  const [laneData, setLaneData] = useSessionStorage("laneFormData", {
    locationId: "",
    customerId: "", // Not saved in Db, used for fetching locations
    inbound: "",
    truckType: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: ""
  });

  const addFormContainerStyle = {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  };

  const addFormStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: "1",
    width: "90%",
    margin: "30px 20px 0px 20px"
  };

  const customerFieldsetContainer = {
    display: "flex",
    flexFlow: "row nowrap"
  };

  const typeFieldsetContainer = {
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: "1em"
  };

  const locationFieldSetStyle = {
    marginTop: "1em",
    flexGrow: "0.5",
    justifyContent: "space-between"
  };

  function handleControlledInputChange(e) {
    const newLocationData = { ...laneData };
    newLocationData[e.target.name] = e.target.value;
    setLaneData(newLocationData);
  }

  useEffect(() => {
    async function fetchCustomers() {
      const response = await getCustomersInBrokerage();
      if (response !== null) {
        setIsLoading(false);
        setCustomers(response);
      }
      // If this occurs, we had an error and were not able to load customers
      // So we need to inform user somehow
      setIsLoading(false);
    }

    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Customer Location input
  useEffect(() => {
    async function fetchCustomerLocations() {
      if (laneData.customerId !== "") {
        const locations = await getLocationsForCustomers(
          laneData.customerId,
          "potential"
        );

        if (locations !== null) {
          laneData.locationId = "";
          setCustomerLocations(locations);
          setIsLocationSelectDisabled(false);
        }
      } else if (laneData.customerId === "") {
        laneData.locationId = "";
        setIsLocationSelectDisabled(true);
      }
    }

    fetchCustomerLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneData.customerId]);

  // Basic form validation
  useEffect(() => {
    // Check if required fields have been entered
    if (
      laneData.customerId !== "" &&
      laneData.locationId !== "" &&
      laneData.truckType !== "" &&
      laneData.inbound !== "" &&
      laneData.city !== "" &&
      laneData.state !== ""
    ) {
      setIsSubmitActive(true);
    } else {
      setIsSubmitActive(false);
    }
  }, [laneData]);

  if (isLoading || customers === null) {
    return (
      <CircularProgress color="secondary" style={{ placeSelf: "center" }} />
    );
  }

  return (
    <section style={addFormContainerStyle}>
      <form noValidate onSubmit={onSubmit} style={addFormStyle}>
        <div style={customerFieldsetContainer}>
          <fieldset style={{ minWidth: "186px" }}>
            <legend>Customer</legend>
            <TextField
              name="customerId"
              variant="outlined"
              autoComplete="new-password"
              size="small"
              label="Select Customer"
              select
              SelectProps={{
                native: true
              }}
              value={laneData.customerId}
              onChange={handleControlledInputChange}
              required
            >
              <option value=""></option>
              {customers.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </TextField>
          </fieldset>

          <fieldset style={{ flexGrow: "1" }}>
            <legend>Customer Location</legend>
            <TextField
              name="locationId"
              variant="outlined"
              autoComplete="new-password"
              size="small"
              label={"Select Location"}
              select
              disabled={isLocationSelectDisabled}
              SelectProps={{
                native: true
              }}
              value={laneData.locationId}
              onChange={handleControlledInputChange}
              required
            >
              <option value=""></option>
              {customerLocations.map(cl => (
                <option key={cl.Location.id} value={cl.Location.id}>
                  {cl.Location.city}, {cl.Location.state}
                </option>
              ))}
            </TextField>
          </fieldset>
        </div>

        <div style={typeFieldsetContainer}>
          <fieldset style={{ flexBasis: "37%" }}>
            <legend>Truck Type</legend>
            <TextField
              name="truckType"
              variant="outlined"
              autoComplete="new-password"
              size="small"
              label="Select Truck Type"
              select
              SelectProps={{
                native: true
              }}
              value={laneData.truckType}
              onChange={handleControlledInputChange}
              required
            >
              <option value=""></option>
              <option value="van">Van</option>
              <option value="reefer">Reefer</option>
              <option value="flatbed">Flatbed</option>
            </TextField>
          </fieldset>

          <fieldset style={{ flexBasis: "37%" }}>
            <legend>Lane Type</legend>
            <TextField
              name="inbound"
              variant="outlined"
              autoComplete="new-password"
              label="Select Lane Type"
              size="small"
              select
              SelectProps={{
                native: true
              }}
              value={laneData.inbound}
              onChange={handleControlledInputChange}
              required
            >
              <option value=""></option>
              <option value="1">Inbound</option>
              <option value="2">Outbound</option>
            </TextField>
          </fieldset>
        </div>

        {laneData.inbound === "" ? null : (
          <>
            <fieldset style={locationFieldSetStyle}>
              <legend>
                {laneData.inbound === "1"
                  ? "Shipper Address"
                  : "Receiver Address"}
              </legend>

              <div
                style={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "space-between"
                }}
              >
                <TextField
                  style={{ width: "65%" }}
                  name="city"
                  label="City"
                  variant="outlined"
                  autoComplete="new-password"
                  size="small"
                  value={laneData.city}
                  placeholder="City"
                  onChange={handleControlledInputChange}
                  required
                />

                <TextField
                  style={{ flexBasis: "25%" }}
                  name="state"
                  label="State"
                  variant="outlined"
                  autoComplete="new-password"
                  size="small"
                  select
                  SelectProps={{
                    native: true
                  }}
                  value={laneData.state}
                  placeholder="State"
                  onChange={handleControlledInputChange}
                  required
                >
                  <InputStates />
                </TextField>
              </div>
            </fieldset>

            <fieldset className="formAddWorkflowForm__fieldset--optional">
              <legend style={{ padding: "0 0.5em" }}>Optional</legend>

              <TextField
                name="address"
                label="Address 1 (optional)"
                variant="outlined"
                autoComplete="new-password"
                size="small"
                value={laneData.address1}
                placeholder="Address 1"
                onChange={handleControlledInputChange}
              />

              <TextField
                style={{ margin: "1em 0em" }}
                name="address2"
                label="Address 2 (optional)"
                variant="outlined"
                autoComplete="new-password"
                size="small"
                value={laneData.address2}
                placeholder="Address 2"
                onChange={handleControlledInputChange}
              />

              <TextField
                style={{ width: "41%" }}
                name="zipCode"
                label="Zip Code (optional)"
                variant="outlined"
                autoComplete="new-password"
                size="small"
                inputProps={{ maxLength: 5 }}
                value={laneData.zipCode}
                placeholder="Zip Code"
                onChange={handleControlledInputChange}
              />
            </fieldset>
          </>
        )}
      </form>
      <FormModalFooter onClickEvent={onSubmit} isActive={isSubmitActive} />
    </section>
  );
}

FormAddWorkflowLane.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
