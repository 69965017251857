import React, { useEffect, useContext, useState } from "react";
import CurrentUserViewDrawerContent from "./CurrentUserViewDrawerContent";
import { UserContext } from "../components/providers/UserProvider";
import MapDrawerLayout from "../components/layouts/MapDrawerLayout";
import useLanes from "../hooks/useLanes";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";

export default function CurrentUserView() {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const { refreshOnAdd } = useContext(ApplicationStateContext);
  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState(); // use state variable and not context bc customerCount, loadsPerWeek, etc. update
  const { lanes, areLanesLoading, spend, loadsPerMonth } = useLanes(
    currentUser.id,
    "user"
  );
  const [isDrawerLoading, setIsDrawerLoading] = useState(true);

  useEffect(() => {
    async function getCurrentUserData() {
      const user = await getUser(currentUser.id);
      setUser(user);
      setIsDrawerLoading(false);
    }

    getCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, refreshOnAdd]);

  return (
    <MapDrawerLayout
      mapLanes={lanes}
      isMapLoading={areLanesLoading}
      drawerContent={
        <CurrentUserViewDrawerContent
          user={user}
          lanes={lanes}
          spend={spend}
          loadsPerMonth={loadsPerMonth}
        />
      }
      isDrawerLoading={isDrawerLoading}
    />
  );
}
