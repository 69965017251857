import React from "react";
import { useHistory } from "react-router-dom";
import ButtonSolid from "../components/generic/buttons/ButtonSolid";

export default function NotFoundView() {
  const history = useHistory();

  const mainContainerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5rem",
    textAlign: "center",
    height: "100vh"
  };

  const h1Style = {
    color: "#242424",
    fontWeight: 900
  };

  const paragraphStyle = {
    lineHeight: "2rem",
    width: "50vw"
  };

  const buttonStyle = {
    padding: "1.5rem",
    fontSize: "0.9rem",
    marginTop: "2rem"
  };

  return (
    <main class="notFound__background" style={mainContainerStyle}>
      <h1 style={h1Style}>Oop! You've taken a wrong turn.</h1>
      <p style={paragraphStyle}>
        The page you're looking for cannot be found. If you're still having
        trouble getting to your destination, contact{" "}
        <a href="mailto:support@terralanes.com">support@terralanes.com</a>
      </p>
      <ButtonSolid
        style={buttonStyle}
        color="red"
        onClick={() => history.push("/")}
      >
        Return to Home
      </ButtonSolid>
    </main>
  );
}
