import React, { useContext, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BillingContext } from '../../../providers/BillingProvider';
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

// CARD ELEMENT
const cardElementOptions = {
    style: {
        base: {
            fontFamily: 'Helvetica Neue',
            fontSize: '16px',
            fontWeight: '400',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
}

const CardInput = ({ onChange }) => (
    <div className='input setup__input stripe__input'>
        <div style={{ flexBasis: '100%' }}>
            <CardElement options={cardElementOptions} onChange={onChange} />
        </div>
    </div>
)

export default function CreditCardForm({ afterSubmit, brokerage, paymentPlan, usersCount, newCustomer, customerId }) {
    const { createStripeSubscription, createStripeCustomer, updateStripeCustomerDefaultPaymentMethod, createStripeSetupIntentForCustomer } = useContext(BillingContext)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const handleNameChange = e => {
        setName(e.target.value)
    }
    const handleEmailChange = e => {
        setEmail(e.target.value)
    }
    const handleAddressChange = e => {
        setAddress(e.target.value)
    }
    const handleAddress2Change = e => {
        setAddress2(e.target.value)
    }
    const handleCityChange = e => {
        setCity(e.target.value)
    }
    const handleStateChange = e => {
        setState(e.target.value)
    }
    const handleZipChange = e => {
        setZip(e.target.value)
    }

    const stripe = useStripe()
    const elements = useElements()

    async function submitCardPayment(e) {
        e.preventDefault()
        if (!loading) {
            try {
                setLoading(true)

                if (newCustomer) {

                    const billingAddress = {
                        line1: address,
                        line2: address2,
                        city: city,
                        state: state,
                        postal_code: zip
                    }

                    const customer = await createStripeCustomer(brokerage.id, brokerage.email, billingAddress, brokerage.name)

                    if (!stripe || !elements) {
                        // Stripe.js has not loaded yet. Make sure to disable
                        // form submission until Stripe.js has loaded.
                        console.log("STRIPE HAS NOT LOADED")
                        return
                    }

                    // Get a reference to a mounted CardElement. Elements knows how
                    // to find your CardElement because there can only ever be one of
                    // each type of element.
                    const cardElement = elements.getElement(CardElement)

                    // Use your card Element with other Stripe.js APIs
                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                        type: 'card',
                        card: cardElement,
                        billing_details: {
                            name: name,
                            email: email,
                            address: {
                                city: city,
                                line1: address,
                                line2: address2,
                                postal_code: zip
                            }
                        }
                    })

                    if (error) {
                        console.log('[error]', error);
                    } else {
                        const priceId = (paymentPlan === 'yearly') ? process.env.REACT_APP_YEARLY_PRICEID : process.env.REACT_APP_MONTHLY_PRICEID
                        const result = await createStripeSubscription(brokerage.id, customer.id, paymentMethod.id, priceId, usersCount)
                    }
                }
                else {
                    const billingDetails = {
                        name: name,
                        email: email,
                        address: {
                            city: city,
                            line1: address,
                            line2: address2,
                            postal_code: zip
                        }
                    }

                    const setupIntent = await createStripeSetupIntentForCustomer(customerId)

                    const result = await stripe.confirmCardSetup(setupIntent["client_secret"], {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details: billingDetails
                        }
                    })

                    if (result.error) {
                        throw Error(result.error.message)
                    }

                    const paymentMethodId = result.setupIntent['payment_method']

                    const resp = await updateStripeCustomerDefaultPaymentMethod(customerId, paymentMethodId)
                }
                toast.success("Successfully updated payment information.")
            }
            catch (e) {
                toast.error(e)
            }
            setLoading(false)
            await afterSubmit()
        }
    }

    return (
        <form className='paymentInfo__form' onSubmit={submitCardPayment}>
            <div className='input__title setup__input__title'>Credit Card</div>
            <input className='input setup__input' placeholder='Cardholder Name' onChange={handleNameChange} value={name} />
            <input className='input setup__input' placeholder='Cardholder Email' onChange={handleEmailChange} value={email} />
            <CardInput />
            <div className='input__title setup__input__title'>Billing Address</div>
            <input className='input setup__input' placeholder='Address 1' onChange={handleAddressChange} value={address} />
            <input className='input setup__input' placeholder='Address 2' onChange={handleAddress2Change} value={address2} />
            <div className='setup__input__row'>
                <input className='input setup__input--half' placeholder='City' onChange={handleCityChange} value={city} />
                <input className='input setup__input--quarter' placeholder='State' onChange={handleStateChange} value={state} />
                <input className='input setup__input--quarter' placeholder='Zip' onChange={handleZipChange} value={zip} />
            </div>
            <button type='submit' className={loading ? 'roundedBtn setup__save--disabled' : 'roundedBtn click setup__save'}>{'Save'}</button>
        </form>
    )
}

CreditCardForm.propTypes = {
    brokerage: PropTypes.object.isRequired,
    paymentPlan: PropTypes.string.isRequired, // 'monthly' or 'yearly'
    usersCount: PropTypes.number.isRequired, // integer
    newCustomer: PropTypes.bool.isRequired,
    customerId: PropTypes.string // required for existing customers
}