import React, { useContext } from "react";
import { useHistory } from "react-router";
import { CustomerContext } from "../providers/CustomerProvider";
import FormAddWorkflowCustomer from "./FormAddWorkflowCustomer";
import FormAddWorkflowSubmitting from "./FormAddWorkflowSubmit";
import FormAddWorkflowSuccess from "./FormAddWorkflowSuccess";
// Contains all logic & steps for the customer workflow
// Lives here and imported into FormAddWorkflow to keep that file small

export default function FormAddWorkflowCustomerSteps(
  setActiveModal,
  closeModal,
  stepLaneForm
) {
  const { addCustomer } = useContext(CustomerContext);
  const history = useHistory();

  let goToAddedId = null;

  function goToAddedObject() {
    history.push(`/customer/${goToAddedId}`);
    closeModal();
  }

  const stepCustomerForm = {
    modalHeight: "30em",
    modalWidth: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Enter Customer Details",
    headerIcon: "Customer",
    isSubmitting: false,
    content: <FormAddWorkflowCustomer onSubmit={addCustomerSubmit} />
  };

  const stepCustomerSubmitting = {
    modalHeight: "18em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: true,
    content: (
      <FormAddWorkflowSubmitting
        iconToAssign={"Customer"}
        text={"Adding customer..."}
      />
    )
  };

  const stepCustomerSuccess = {
    modalHeight: "20em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: true,
    headerText: "",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowSuccess
        mainText={"Customer added!"}
        buttonText={"View Customer"}
        goToAdded={goToAddedObject}
        goToForm={function() {
          setActiveModal(stepCustomerForm);
        }}
        goToAddLane={function() {
          setActiveModal(stepLaneForm);
        }}
      />
    )
  };

  async function addCustomerSubmit(e) {
    // Form validation lives in FormAddWorkflowCustomer
    e.preventDefault();

    setActiveModal(stepCustomerSubmitting);
    const customerFormData = JSON.parse(
      sessionStorage.getItem("customerFormData")
    );

    // Change optional inputs from empty strings to null
    const preppedCustomer = {
      name: customerFormData.name,
      address:
        customerFormData.address === "" ? null : customerFormData.address,
      address2:
        customerFormData.address2 === "" ? null : customerFormData.address2,
      city: customerFormData.city,
      state: customerFormData.state,
      zipCode: customerFormData.zipCode === "" ? null : customerFormData.zipCode
    };

    const response = await addCustomer(preppedCustomer);

    if (response !== null) {
      sessionStorage.removeItem("customerFormData");
      goToAddedId = response.id;
      setActiveModal(stepCustomerSuccess);
    } else {
      setActiveModal(stepCustomerForm);
    }
  }

  return stepCustomerForm;
}
