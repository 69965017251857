import React, { createContext, useContext, useState } from "react";
import { customerToastMessages } from "../generic/ToastMessages";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import errorHandler from "./utils/errorHandler";
import responseHandler from "./utils/responseHandler";
import { useHistory } from "react-router-dom";

export const CustomerContext = createContext();

const CustomerProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL + "/customer1";
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const history = useHistory()
  const [wasCustomerUpdated, setWasCustomerUpdated] = useState(false)

  async function getCustomer(customerId) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${customerId}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const customer = await responseHandler({
        response,
        setAlertModal
      });

      return customer;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null; // Return null if any issue occurs
    }
  }

  async function addCustomer(customer) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(customer)
      })

      const addedCustomer = await responseHandler({
        response,
        setAlertModal
      })

      return addedCustomer
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function updateCustomer(customer, customerNameForToasts) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${customer.id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(customer)
      });

      // Prep customer name for displaying toast success/failure
      const customerName =
        customer.displayName === null || customer.displayName === ""
          ? customerNameForToasts
          : customer.displayName;

      const result = await responseHandler({
        response: response,
        toastSuccess: customerToastMessages.updateSuccess,
        toastSuccessParam: customerName,
        toastError: customerToastMessages.updateFailure,
        toastErrorParam: customerName,
        setAlertModal
      });

      setWasCustomerUpdated(!wasCustomerUpdated)

      return result;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function deleteCustomer(id) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${+id}`, {
        method: "DELETE",
        headers: {
          Authorization: token,
        },
      });
      
      await responseHandler({
        response: response,
        toastSuccess: customerToastMessages.deleteSuccess,
        toastError: customerToastMessages.deleteFailure,
        setAlertModal
      });

      history.push("/")
  } catch (error){
    errorHandler(error, setAlertModal)
  }
}

  async function getLocationsForCustomers(customerId, workflow) {
    if (workflow) {
      try {
        const token = await getToken();
  
        const response = await fetch(`${apiUrl}/${customerId}/locations?status=${workflow}`, {
          method: "GET",
          headers: {
            Authorization: token
          }
        });
  
        const locations = await responseHandler({
          response,
          setAlertModal
        });
  
        return locations;
      } catch (error) {
        errorHandler(error, setAlertModal);
        return null;
      }
    }
  }

  // Does not appear to be used any where. Did not add error handling
  const getLngLat = address => {
    return fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
    ).then(resp => resp.json());
  };

  return (
    <CustomerContext.Provider
      value={{
        getCustomer,
        addCustomer,
        wasCustomerUpdated,
        updateCustomer,
        deleteCustomer,
        getLocationsForCustomers,
        getLngLat,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
