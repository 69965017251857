import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormModalFooter from "./FormModalFooter";
import useSessionStorage from "../../hooks/useSessionStorage";
import { TextField } from "@material-ui/core";
import InputStates from "../inputs/InputStates";

export default function FormAddWorkflowCustomer({ onSubmit }) {
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [customerData, setCustomerData] = useSessionStorage(
    "customerFormData",
    {
      name: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zipCode: ""
    }
  );

  const addFormContainerStyle = {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  };

  const addFormStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: "1",
    width: "90%",
    margin: "30px 20px 0px 20px"
  };

  const hqFieldSetStyle = {
    marginTop: "1em",
    flexGrow: "0.5",
    justifyContent: "space-between"
  };

  function handleControlledInputChange(e) {
    const newCustomerData = { ...customerData };
    newCustomerData[e.target.name] = e.target.value;
    setCustomerData(newCustomerData);
  }

  useEffect(() => {
    // Check if required fields have been enter
    if (
      customerData.name !== "" &&
      customerData.city !== "" &&
      customerData.state !== ""
    ) {
      setIsSubmitActive(true);
    } else {
      setIsSubmitActive(false);
    }
  }, [customerData]);

  return (
    <section style={addFormContainerStyle}>
      <form noValidate onSubmit={onSubmit} style={addFormStyle}>
        <fieldset>
          <legend>Customer Name</legend>
          <TextField
            name="name"
            label="Customer Name"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={customerData.name}
            placeholder="Enter Customer Name"
            onChange={handleControlledInputChange}
            required
          />
        </fieldset>

        <fieldset style={hqFieldSetStyle}>
          <legend>Headquarters Location</legend>

          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between"
            }}
          >
            <TextField
              style={{ width: "65%" }}
              name="city"
              label="City"
              variant="outlined"
              size="small"
              autoComplete="new-password"
              value={customerData.city}
              placeholder="City"
              onChange={handleControlledInputChange}
              required
            />

            <TextField
              style={{ flexBasis: "25%" }}
              name="state"
              label="State"
              variant="outlined"
              size="small"
              select
              SelectProps={{
                native: true
              }}
              autoComplete="new-password"
              value={customerData.state}
              placeholder="State"
              onChange={handleControlledInputChange}
              required
            >
              <InputStates />
            </TextField>
          </div>
        </fieldset>

        <fieldset className="formAddWorkflowForm__fieldset--optional">
          <legend style={{ padding: "0 0.5em" }}>Optional</legend>

          <TextField
            name="address"
            label="Address 1 (optional)"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={customerData.address1}
            placeholder="Address 1"
            onChange={handleControlledInputChange}
          />

          <TextField
            style={{ margin: "1em 0em" }}
            name="address2"
            label="Address 2 (optional)"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={customerData.address2}
            placeholder="Address 2"
            onChange={handleControlledInputChange}
          />

          <TextField
            style={{ width: "41%" }}
            name="zipCode"
            label="Zip Code (optional)"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={customerData.zipCode}
            inputProps={{ maxLength: 5 }}
            placeholder="Zip Code"
            onChange={handleControlledInputChange}
          />
        </fieldset>
      </form>
      <FormModalFooter onClickEvent={onSubmit} isActive={isSubmitActive} />
    </section>
  );
}

FormAddWorkflowCustomer.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
