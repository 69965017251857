import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemTotalPotentialVolume({
  item,
  updateMethod
}) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"number"}
      valueType={"loads per month"}
      updateMethod={updateMethod}
      nameOfValue="potentialVolume"
      title={"Total Potential Volume"}
      label={"loads per month"}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          This is the total amount of volume the customer has on this lane.
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemTotalPotentialVolume.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
