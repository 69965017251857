import React, { useEffect } from 'react'
import './PaymentMethod.css'

export default function PaymentMethodCollect({handleControlledInputChange, paymentInfoAddress, setPaymentInfoAddress }) {
    return (
        <div className='paymentMethod'>
            <input
                autoComplete='off'
                className='input paymentMethod__input'
                placeholder='Cardholder Name'
                name='cardholder'
                value={paymentInfoAddress.cardholder}
                onChange={handleControlledInputChange} />
            <input
                autoComplete='off'
                className='input paymentMethod__input'
                placeholder='Cardholder Email'
                name='email'
                value={paymentInfoAddress.email}
                onChange={handleControlledInputChange} />
            <div className='input__title'>Billing Address</div>
            <input
                autoComplete='off'
                className='input'
                placeholder='Address 1'
                name='address'
                value={paymentInfoAddress.address}
                onChange={handleControlledInputChange} />
            <input
                autoComplete='off'
                className='input'
                placeholder='Address 2'
                name='address2'
                value={paymentInfoAddress.address2}
                onChange={handleControlledInputChange} />
            <div className='paymentMethod__inputRow'>
                <input
                    autoComplete='off'
                    className='input paymentMethod__input--half'
                    placeholder='City'
                    name='city'
                    value={paymentInfoAddress.city}
                    onChange={handleControlledInputChange} />
                <input
                    autoComplete='off'
                    className='input paymentMethod__input--half'
                    placeholder='State'
                    name='state'
                    value={paymentInfoAddress.state}
                    onChange={handleControlledInputChange} />
            </div>
        </div>
    )
}