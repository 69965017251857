import React, { useState, useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import UpdateImage from "../library/images/UpdateImage";
import useProfileImage from "../../hooks/useProfileImage";
import SettingsCardTitle from "./SettingsCardTitle";

export default function SettingsContentUpdateProfile({ teams }) {
  const { updateUserProfile } = useContext(UserContext);
  const { setRefreshHeader } = useContext(ApplicationStateContext);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  // const [team, setTeam] = useState(currentUser.teamId)
  const [title, setTitle] = useState(currentUser.title);
  const [phone, setPhone] = useState(currentUser.phone);
  // const [ext, setExt] = useState(currentUser.phoneExt)
  const profileImage = useProfileImage(currentUser);

  const handleFirstNameChange = e => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = e => {
    setLastName(e.target.value);
  };
  // const handleTeamChange = (e) => {
  //     setTeam(e.target.value)
  // }
  const handleTitleChange = e => {
    setTitle(e.target.value);
  };
  const handlePhoneChange = e => {
    setPhone(e.target.value);
  };

  async function handleSubmit() {
    currentUser.firstName = firstName;
    currentUser.lastName = lastName;
    // currentUser.teamId = +team
    currentUser.title = title;
    currentUser.phone = phone;

    // TODO: when updating UserProvider, add check for if the return value is null
    // Do not set sessionStorage
    const updated = await updateUserProfile(currentUser);
    sessionStorage.setItem("user", JSON.stringify(updated));
    setRefreshHeader(true);
  }

  return (
    <>
      <SettingsCardTitle>Update Profile</SettingsCardTitle>
      <div className="settings__profile">
        <div className="settings__profile__left">
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Name</div>
            <input
              className="settings__profile__input__field--half"
              value={firstName}
              placeholder="First Name"
              onChange={handleFirstNameChange}
            />
            <input
              className="settings__profile__input__field--half"
              value={lastName}
              placeholder="Last Name"
              onChange={handleLastNameChange}
            />
          </div>
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Role</div>
            <input
              className="settings__profile__input__field"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          {
            // <div className='settings__profile__input'>
            //     <div className='settings__profile__input__title'>Team</div>
            //     <select className='settings__profile__select' onChange={handleTeamChange} value={team}>
            //         {
            //             teams.map(t => {
            //                 return <option value={t.id} key={t.id}>{t.icon} {t.name}</option>
            //             })
            //         }
            //     </select>
            // </div>
          }
          <div className="settings__profile__input">
            <div className="settings__profile__input__title">Phone Number</div>
            <input
              className="settings__profile__input__field--half"
              value={phone}
              onChange={handlePhoneChange}
            />
            <input
              className="settings__profile__input__field--half"
              placeholder="Extension No."
            />
          </div>
        </div>
        <div className="settings__profile__right">
          <UpdateImage
            sourceType="User"
            source={currentUser}
            image={profileImage}
          />
        </div>
      </div>
      <div className="settings__profile__btnContainer">
        <div className="settings__save click" onClick={handleSubmit}>
          Save
        </div>
      </div>
    </>
  );
}
