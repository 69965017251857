import React, { useContext, useEffect, useState } from 'react'
import ReviewPlan from './ReviewPlan'
import FormInviteUsersInputs from '../forms/FormInviteWorkflowInputs'
import FormModalFooterBidirectional from '../forms/FormModalFooterBidirectional'
import { BillingContext } from '../providers/BillingProvider'
import { toast } from 'react-toastify'
import { RegistrationContext } from '../providers/RegistrationProvider'

export default function InviteMoreUsersWorkflow({ brokerage, existingUsers, activeModal, setActiveModal, closeModal }) {
    const { getCustomerBillingDetails, updateStripeSubscriptionQuantity } = useContext(BillingContext)
    const { sendInvitesToNewUsers } = useContext(RegistrationContext)
    const stagedInvites = JSON.parse(sessionStorage.getItem('stagedInvites'))
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [billingCycle, setBillingCycle] = useState()
    const [subscriptionItem, setSubscriptionItem] = useState()

    useEffect(() => {
        async function getBillingCycle() {
            const billingDetais = await getCustomerBillingDetails()
            setBillingCycle(billingDetais.subscription.plan.interval + 'ly')
            setSubscriptionItem(billingDetais.subscription.items.data[0])
            console.log("BILLING: ", billingDetais)
        }

        getBillingCycle()
    }, [])

    const handlePreviousClick = () => {
        setActiveModal(stepDataSelection)
    }

    const stepDataSelection = {
        modalWidth: "45em",
        modalHeight: "30em",
        hasHeader: true,
        hasHeaderCloseBtn: true,
        headerText: "Invite Users",
        headerIcon: "",
        isSubmitting: false,
        content: (
            <FormInviteUsersInputs activeModal={activeModal} setActiveModal={setActiveModal} closeModal={closeModal} brokerage={brokerage} existingUsers={existingUsers} />
        )
    }

    async function handleAdvanceClick() {
        setIsSubmitting(true)
        try {
            const response = await updateStripeSubscriptionQuantity(subscriptionItem.id, (brokerage.teammates + stagedInvites.length), billingCycle === 'monthly' ? process.env.REACT_APP_MONTHLY_PRICEID : process.env.REACT_APP_YEARLY_PRICEID)
            const resp = await sendInvitesToNewUsers(stagedInvites)
            toast.success(`Successfully invited ${stagedInvites.length} new users.`)
            closeModal()
            setIsSubmitting(false)
            // setTimeout(() => {sessionStorage.removeItem('stagedInvites')}, 500)
            sessionStorage.removeItem('stagedInvites')
        }
        catch (e) {
            console.error(e)
            toast.error('Error inviting new users.')
        }
    }

    if (!billingCycle) {
        return <div>Loading...</div>
    }

    return (
        <div className='upgradeAccount'>
            <ReviewPlan stagedInvites={stagedInvites} billingCycle={billingCycle} brokerage={brokerage} />
            <FormModalFooterBidirectional
                isActive={isSubmitting ? false : true}
                onAdvanceClick={handleAdvanceClick}
                buttonAdvanceLabel={isSubmitting ? 'Submitting...' : 'Submit'}
                buttonPrevious={true}
                onPreviousClick={handlePreviousClick} />
        </div>
    )
}