import React from "react";
import PropTypes from "prop-types";
import IconClose from "../icons/IconClose";
import useFormIcon from "./useFormIcon";
import "./FormModal.css";
// Contains modal & its header

export default function FormModal(props) {
  const icon = useFormIcon(props.headerIcon, "small");

  const headerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "1em",
    color: "white",
    backgroundColor: props.hasHeader ? "var(--darkBlue)" : "transparent",
    fontSize: "1.2em",
    fontWeight: "900"
  };

  const modalBackground = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    pointerEvents: props.isModalOpen ? "all" : "none",
    opacity: props.isModalOpen ? "1" : "0",
    transition: "0.15s",
    zIndex: "1299"
  };

  const modalCard = {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "8px",
    overflowY: "auto",
    overflow: "hidden",
    zIndex: "0"
  };

  const modalContent = {
    flexGrow: "1",
    display: "flex",
    justifyContent: "space-around",
    height: `${props.modalHeight}`,
    width: `${props.modalWidth}`
  };

  function handleModalClose(e) {
    if (e.target.dataset.modalBackground) {
      props.closeModal(false);
    }
  }

  if (props.isModalOpen === null) {
    return null;
  }

  return (
    <section
      style={modalBackground}
      data-modal-background
      onMouseDown={handleModalClose}
    >
      <section style={modalCard}>
        <header style={headerStyle}>
          <div className="formModal__header__iconText">
            {icon} <span>{props.headerText}</span>
          </div>
          {props.hasHeaderCloseBtn ? (
            <button onClick={() => props.closeModal(false)}>
              <IconClose color={props.hasHeader ? "white" : "var(--gray)"} />
            </button>
          ) : null}
        </header>
        <div style={modalContent}>{props.children}</div>
      </section>
    </section>
  );
}

FormModal.propTypes = {
  modalHeight: PropTypes.string.isRequired,
  modalWidth: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  hasHeader: PropTypes.bool,
  hasHeaderCloseBtn: PropTypes.bool,
  headerIcon: PropTypes.string,
  headerText: PropTypes.string
};
