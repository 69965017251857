import React from "react";
import PropTypes from "prop-types";
import FormSubmitPulse from "./FormSubmitPulse";
import useFormIcon from "./useFormIcon";
// Holds text & pulsing icon

export default function FormAddWorkflowSubmitting({ text, iconToAssign }) {
  const icon = useFormIcon(iconToAssign, "large");

  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    marginTop: "1em"
  };

  const textStyle = {
    fontSize: "1.4em",
    fontWeight: "900",
    marginTop: "1em"
  };

  return (
    <section style={containerStyle}>
      <FormSubmitPulse icon={icon} />
      <span style={textStyle}>{text}</span>
    </section>
  );
}

FormAddWorkflowSubmitting.propTypes = {
  text: PropTypes.string.isRequired,
  iconToAssign: PropTypes.string.isRequired
};
