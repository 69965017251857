import React, { createContext, useContext } from "react";
import { UserContext } from "./UserProvider";

export const RegistrationContext = createContext();

const RegistrationProvider = props => {
  const { getToken } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  async function sendUserInviteEmail(email) {
    const token = await getToken();
    const resp = await fetch(`${apiUrl}/onboarding1/user`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email: email })
    });

    return resp;
  }

  async function sendInvitesToNewUsers(arrayOfUsersToInvite) {
    await Promise.all(
      arrayOfUsersToInvite.map(inv => {
        return sendUserInviteEmail(inv);
      })
    );
  }

  async function getInvitedUser(userId) {
    const user = await fetch(`${apiUrl}/user1/email/${userId}`, {
      method: "GET"
    }).then(resp => resp.json());

    return user;
  }

  return (
    <RegistrationContext.Provider
      value={{
        getInvitedUser,
        sendInvitesToNewUsers
      }}
    >
      {props.children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
