import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";

export default function DrawerToolbeltItemRequirements({ item, updateMethod }) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"multiline"}
      updateMethod={updateMethod}
      nameOfValue="requirements"
      title={"Requirements"}
      placeholder={"Enter Requirements..."}
    />
  );
}

DrawerToolbeltItemRequirements.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
