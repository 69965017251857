import { Dialog } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import ButtonOutlined from '../../generic/buttons/ButtonOutlined'
import { TagContext } from '../../providers/TagProvider'
import AddQuickTags from './AddQuickTags'
import QuickTag from './QuickTag'
import './Tag.css'

export default function QuickTagsList({ page, item }) {
    const { getTags, tags } = useContext(TagContext)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        getTags(page, item)
    }, [page, item])

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className='quickTags'>
            {tags.length > 0
                ? <div className='quickTag__add click mr--5' onClick={handleClick}>+ Add Quick Tag</div>
                : <ButtonOutlined text='Add Quick Tag' isAdding onClick={handleClick}/>
            }
            <div className='quickTags__list'>
                {
                    tags.map(t => {
                        return <QuickTag key={t.id} tag={t} page={page} item={item} />
                    })
                }
            </div>
            <Dialog onClose={handleClose} open={open}>
                <AddQuickTags page={page} item={item} handleClose={handleClose}/>
            </Dialog>
        </div>
    )
}