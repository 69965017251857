import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

export default function DrawerToolbeltStateEmpty({ title, infoButton, onAdd }) {
  const containerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    border: "2px solid var(--lightGray)",
    borderRadius: "10px",
    padding: "1em",
    margin: "0.5em 0"
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "0.9em"
  };

  const {
    isSubmittingToolbelt,
    clickedToolbeltTitle,
    triggerToolbeltSave,
    isToolbeltCancelButtonClicked
  } = useContext(ApplicationStateContext);

  // Same checks as DrawerToolbeltStateAdded
  useEffect(() => {
    if (
      clickedToolbeltTitle === title &&
      triggerToolbeltSave === false &&
      isToolbeltCancelButtonClicked === false
    ) {
      onAdd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clickedToolbeltTitle,
    triggerToolbeltSave,
    isToolbeltCancelButtonClicked,
    onAdd
  ]);

  return (
    <div style={containerStyle}>
      <div>
        <span style={titleStyle}>{title}</span>
        {infoButton}
      </div>
      <ButtonOutlined
        style={{ width: "4.5em" }}
        isAdding
        text={"Add"}
        onClick={onAdd}
        isSubmitting={isSubmittingToolbelt}
        isDisabled={isSubmittingToolbelt}
      />
    </div>
  );
}

DrawerToolbeltStateEmpty.propTypes = {
  title: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
  onAdd: PropTypes.func.isRequired
};
