import React, { useEffect, useState, useContext } from "react";
import ReactMapGL, { Layer, Source, Popup } from "react-map-gl";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import MapPopupCustomer from "./MapPopupCustomer";
import MapPopupLane from "./MapPopupLane";
import { Backdrop, CircularProgress } from "@material-ui/core";
import useMapLayers from "./useMapLayers";
import "./Map.css";

export default function MapContainer({ lanes, isLoading }) {
  const { workflow } = useContext(ApplicationStateContext);
  const [popup, setPopup] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [customerPopupOpen, setCustomerPopupOpen] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 38.0,
    longitude: -97.0,
    zoom: 4
  });
  const [routeData, setRouteData] = useState(null);
  const [customerMarkerData, setCustomerMarkerData] = useState(null);
  const [partnerMarkerData, setPartnerMarkerData] = useState(null);
  const {
    createRouteLayer,
    createCustomerLayer,
    createLanePartnerLayer
  } = useMapLayers();

  const routeLayer = {
    id: "routes-line",
    type: "line",
    source: "routes",
    layout: {
      "line-join": "round",
      "line-cap": "round"
    },
    paint: {
      "line-color": ["get", "color"],
      "line-width": 2
    },
    filter: ["==", "$type", "LineString"]
  };

  const customerMarkerLayer = {
    id: "routes-customer-marker",
    type: "circle",
    source: "locations",
    paint: {
      "circle-radius": 8,
      "circle-color": ["get", "color"]
    },
    filter: ["==", "$type", "Point"]
  };

  const partnerMarkerLayer = {
    id: "routes-partner-marker",
    type: "circle",
    source: "locations",
    paint: {
      "circle-opacity": 0,
      "circle-stroke-width": 2,
      "circle-stroke-color": ["get", "color"]
      // 'circle-radius': 8,
      // 'circle-color': '#FA4065'
    },
    filter: ["==", "$type", "Point"]
  };

  function handleHover(e) {
    if (
      e.features &&
      e.features.length > 0 &&
      !customerPopupOpen &&
      e.features[0].source === "routes"
    ) {
      setPopup(
        <Popup
          latitude={e.lngLat[1]}
          longitude={e.lngLat[0]}
          closeButton={true}
          closeOnClick={false}
          onClose={() => setShowPopup(false)}
          anchor="bottom-left"
        >
          <MapPopupLane properties={e.features[0].properties} />
        </Popup>
      );
      setShowPopup(true);
    }
  }

  function handleClick(e) {
    if (
      e.features &&
      e.features.length > 0 &&
      e.features[0].source === "customerLocations"
    ) {
      const handleClose = () => {
        setShowPopup(false);
        setCustomerPopupOpen(false);
      };
      setCustomerPopupOpen(true);
      setPopup(
        <Popup
          latitude={e.lngLat[1]}
          longitude={e.lngLat[0]}
          closeButton={true}
          closeOnClick={false}
          onClose={handleClose}
          anchor="bottom-left"
        >
          <MapPopupCustomer properties={e.features[0].properties} />
        </Popup>
      );
      setShowPopup(true);
    }
  }

  // Resets map layers on lane change
  useEffect(
    () => {
      setShowPopup(false);
      setCustomerPopupOpen(false);

      let lanesArray = lanes;
      let isSingleLane = false;
      // if it's the lane page, the lane is an object, so make it an array
      if (Array.isArray(lanes) === false) {
        isSingleLane = true;
        lanesArray = [lanes];
      }

      setRouteData(createRouteLayer(lanesArray));
      setCustomerMarkerData(createCustomerLayer(lanesArray));
      setPartnerMarkerData(createLanePartnerLayer(lanesArray));

      // Owned lane pages without current volume should not show on map
      if (
        isSingleLane &&
        workflow === "owned" &&
        lanesArray[0]?.currentVolume === 0
      ) {
        setCustomerMarkerData(createCustomerLayer([]));
        setPartnerMarkerData(createLanePartnerLayer([]));
      }
    },
    // Lanes should be the only dependency; if workflow is a dependency, filtering happens twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lanes]
  );

  return (
    <section style={{ backgroundColor: "#eff0f0" }}>
      <ReactMapGL
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        mapStyle="mapbox://styles/parkerhodge/ck9aml3rw12zz1ipluw7qi0zk"
        onHover={handleHover}
        onClick={handleClick}
        minZoom={3}
        maxPitch={0}
        dragRotate={false}
        {...viewport}
        onViewportChange={nextViewport =>
          setViewport({ ...nextViewport, width: "100vw", height: "94vh" })
        }
        interactiveLayerIds={["routes-line", "routes-customer-marker"]}
      >
        <Source id="routes" type="geojson" data={routeData}>
          <Layer {...routeLayer} />
        </Source>
        <Source id="customerLocations" type="geojson" data={customerMarkerData}>
          <Layer {...customerMarkerLayer} />
        </Source>
        <Source id="partnerLocations" type="geojson" data={partnerMarkerData}>
          <Layer {...partnerMarkerLayer} />
        </Source>
        {showPopup && popup}
      </ReactMapGL>

      <Backdrop
        open={isLoading}
        style={{ zIndex: 0, backgroundColor: "#0000000f" }}
      >
        <CircularProgress
          style={{
            zIndex: 1,
            backgroundColor: "#f8f8f8",
            borderRadius: "50%",
            border: "solid #f8f8f8 5px",
            boxShadow: "#0000004a 0 0 10px"
          }}
          color="secondary"
          className="loading"
        />
      </Backdrop>
    </section>
  );
}
