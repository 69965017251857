import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrokerageContext } from "../providers/BrokerageProvider";
import FormModalFooter from "./FormModalFooter";
import useSessionStorage from "../../hooks/useSessionStorage";
import InputStates from "../inputs/InputStates";
import { CircularProgress, TextField } from "@material-ui/core";

export default function FormAddWorkflowLocation({ onSubmit }) {
  const { getCustomersInBrokerage } = useContext(BrokerageContext);
  const [customers, setCustomers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [locationData, setLocationData] = useSessionStorage(
    "locationFormData",
    {
      customerId: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zipCode: ""
    }
  );

  const addFormContainerStyle = {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  };

  const addFormStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: "1",
    width: "90%",
    margin: "30px 20px 0px 20px"
  };

  const locationFieldSetStyle = {
    marginTop: "1em",
    flexGrow: "0.5",
    justifyContent: "space-between"
  };

  function handleControlledInputChange(e) {
    const newLocationData = { ...locationData };
    newLocationData[e.target.name] = e.target.value;
    setLocationData(newLocationData);
  }

  useEffect(() => {
    async function fetchCustomers() {
      const response = await getCustomersInBrokerage();
      if (response !== null) {
        setIsLoading(false);
        setCustomers(response);
      }
      // If this occurs, we had an error and were not able to load customers
      // So we need to inform user somehow
      setIsLoading(false);
    }

    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if required fields have been enter
    if (
      locationData.customerId !== "" &&
      locationData.city !== "" &&
      locationData.state !== ""
    ) {
      setIsSubmitActive(true);
    } else {
      setIsSubmitActive(false);
    }
  }, [locationData]);

  if (isLoading || customers === null) {
    return (
      <CircularProgress color="secondary" style={{ placeSelf: "center" }} />
    );
  }

  return (
    <section style={addFormContainerStyle}>
      <form noValidate onSubmit={onSubmit} style={addFormStyle}>
        <fieldset>
          <legend>Customer</legend>
          <TextField
            name="customerId"
            label="Select Customer"
            variant="outlined"
            size="small"
            select
            SelectProps={{
              native: true
            }}
            autoComplete="new-password"
            value={locationData.customerId}
            onChange={handleControlledInputChange}
            required
          >
            <option value=""></option>
            {customers.map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </TextField>
        </fieldset>

        <fieldset style={locationFieldSetStyle}>
          <legend>Location</legend>

          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between"
            }}
          >
            <TextField
              style={{ width: "65%" }}
              name="city"
              label="City"
              variant="outlined"
              size="small"
              autoComplete="new-password"
              value={locationData.city}
              placeholder="City"
              onChange={handleControlledInputChange}
              required
            />

            <TextField
              style={{ flexBasis: "25%" }}
              name="state"
              label="State"
              variant="outlined"
              size="small"
              select
              SelectProps={{
                native: true
              }}
              autoComplete="new-password"
              value={locationData.state}
              placeholder="State"
              onChange={handleControlledInputChange}
              required
            >
              <InputStates />
            </TextField>
          </div>
        </fieldset>

        <fieldset className="formAddWorkflowForm__fieldset--optional">
          <legend style={{ padding: "0 0.5em" }}>Optional</legend>

          <TextField
            name="address"
            label="Address 1 (optional)"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={locationData.address1}
            placeholder="Address 1"
            onChange={handleControlledInputChange}
          />

          <TextField
            style={{ margin: "1em 0em" }}
            name="address2"
            label="Address 2 (optional)"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            value={locationData.address2}
            placeholder="Address 2"
            onChange={handleControlledInputChange}
          />

          <TextField
            style={{ width: "41%" }}
            name="zipCode"
            label="Zip Code (optional)"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 5 }}
            autoComplete="new-password"
            value={locationData.zipCode}
            placeholder="Zip Code"
            onChange={handleControlledInputChange}
          />
        </fieldset>
      </form>
      <FormModalFooter onClickEvent={onSubmit} isActive={isSubmitActive} />
    </section>
  );
}

FormAddWorkflowLocation.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
