import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "@material-ui/core";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DrawerCarriersListItem from "./DrawerCarriersListItem";

export default function DrawerCarriersList({
  carriers,
  isEditActive,
  setIsFormModalOpen,
  setCarrierToEdit
}) {
  const [sortedCarriers, setSortedCarriers] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [sort, setSort] = useState("name");
  const [anchorEl, setAnchorEl] = useState();
  const isSortSelectOpen = Boolean(anchorEl);

  useEffect(() => {
    function sortCarriers(carriersToSort) {
      let newlySortedCarriers;
      switch (sort) {
        case "name":
          if (ascending) {
            newlySortedCarriers = carriersToSort.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
            });
          } else {
            newlySortedCarriers = carriersToSort.sort((a, b) => {
              if (a.name < b.name) {
                return 1;
              }
              if (a.name > b.name) {
                return -1;
              }
            });
          }
          break;
        case "rate":
          if (ascending) {
            newlySortedCarriers = carriersToSort.sort((a, b) => {
              if (a.historicalRate < b.historicalRate) {
                return -1;
              }
              if (a.historicalRate > b.historicalRate) {
                return 1;
              }
            });
          } else {
            newlySortedCarriers = carriersToSort.sort((a, b) => {
              if (a.historicalRate < b.historicalRate) {
                return 1;
              }
              if (a.historicalRate > b.historicalRate) {
                return -1;
              }
            });
          }
          break;
        default:
          console.error("Sort case not defined");
          break;
      }

      setSortedCarriers(newlySortedCarriers);
    }

    if (carriers.length > 0) {
      const copy = [...carriers];
      sortCarriers(copy);
    } else if (carriers.length === 0) {
      sortCarriers([]);
    }
  }, [carriers, ascending, sort]);

  const handleSortClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleSortPopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelect = sort => {
    setSort(sort);
    setAnchorEl(null);
  };

  return (
    <>
      {sortedCarriers.length === 0 ? null : (
        <section className="favoriteCarriers__sort">
          <div className="favoriteCarriers__sortText">
            Sort by{" "}
            <span className="click" onClick={handleSortClick}>
              {sort}
            </span>
          </div>
          <Popover
            open={isSortSelectOpen}
            anchorEl={anchorEl}
            onClose={handleSortPopoverClose}
          >
            <div
              className="favoriteCarriers__sortItem click"
              onClick={() => handleSortSelect("name")}
            >
              Name
            </div>
            <div
              className="favoriteCarriers__sortItem click"
              onClick={() => handleSortSelect("rate")}
            >
              Rate
            </div>
          </Popover>
          <ExpandMoreIcon
            className={
              ascending ? "favoriteCarriers__ascending click" : "click"
            }
            onClick={() => setAscending(!ascending)}
          />
        </section>
      )}
      <section className="favoriteCarriers__list hide__scroll">
        {sortedCarriers.length > 0 ? (
          sortedCarriers.map(c => (
            <DrawerCarriersListItem
              key={c.id}
              carrier={c}
              isEditActive={isEditActive}
              setIsFormModalOpen={setIsFormModalOpen}
              setCarrierToEdit={setCarrierToEdit}
            />
          ))
        ) : (
          <ButtonOutlined
            style={{ margin: "2em" }}
            text="Add Carrier"
            isAdding
            onClick={() => setIsFormModalOpen(true)}
          />
        )}
      </section>
    </>
  );
}

DrawerCarriersList.propTypes = {
  carriers: PropTypes.array.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  setIsFormModalOpen: PropTypes.func.isRequired,
  setCarrierToEdit: PropTypes.func.isRequired
};
