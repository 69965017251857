import React, { useContext } from 'react'
import { numberWithCommas, getColorForWorkflow } from '../../../components/generic/Utils'
import { ApplicationStateContext } from '../../../components/providers/ApplicationStateProvider'

export default function UserDefaultTags({ customerCount, loadsPerMonth, spend }) {
  const { workflow } = useContext(ApplicationStateContext)
  const color = getColorForWorkflow(workflow)

  return (
    <section className="drawer__column--2 drawer__column--right">
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>CUSTOMERS</div>
        <div className={`chip--${color}__bottom`}>🏢 {customerCount}</div>
      </div>
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>LOADS</div>
        <div className={`chip--${color}__bottom`}>🗓 {loadsPerMonth}/mo</div>
      </div>
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>REVENUE</div>
        <div className={`chip--${color}__bottom`}>💳 ${numberWithCommas(+(Math.round(spend + "e+" + 2)  + "e-" + 2))}</div>
      </div>
    </section>
  )
}