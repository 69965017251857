import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { CircularProgress, Backdrop } from "@material-ui/core";
import DrawerWorkflowTabs from "./DrawerWorkflowTabs";
import "./Drawer.css";
// DrawerContainer renders Drawer and uses useDrawer to handle drawer state
export default function DrawerContainer({
  drawerContent,
  isLoading,
  isMapLoading
}) {
  const { isDrawerOpen, setIsDrawerOpen } = useContext(ApplicationStateContext);
  const drawer = useRef();

  // Reset scroll position on close so drawer header is always on top
  useEffect(() => {
    if (!isDrawerOpen) {
      drawer.current.scrollTop = 0;
    }
  }, [isDrawerOpen]);

  return (
    <div className="drawerContainer">
      <DrawerWorkflowTabs isMapLoading={isMapLoading} />
      <div className={`drawer drawer--${isDrawerOpen ? "open" : "close"}`}>
        <button
          className="drawer__expand drawer__expand__button"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <ExpandLessIcon
            style={{
              width: "1.5em",
              height: "1.5em"
            }}
            className={`drawer__expand__img drawer__arrow--${
              isDrawerOpen ? "open" : "close"
            }`}
          />
        </button>

        <section
          className={
            isDrawerOpen
              ? "drawer__content"
              : "drawer__content drawer__content--closed"
          }
          ref={drawer}
        >
          {// Get the first value of the drawerContent props & only render if it's not falsey
          Object.values(drawerContent.props)[0] && drawerContent}
          <Backdrop
            open={isLoading}
            style={{
              zIndex: 0,
              backgroundColor: "#0000001a"
            }}
          >
            <CircularProgress
              style={{
                zIndex: 1,
                backgroundColor: "#f8f8f8",
                borderRadius: "50%",
                border: "solid #f8f8f8 5px",
                boxShadow: "#0000004a 0 0 10px"
              }}
              color="secondary"
              className="loading"
            />
          </Backdrop>
        </section>
      </div>
    </div>
  );
}

DrawerContainer.propTypes = {
  drawerContent: PropTypes.shape({
    props: PropTypes.object.isRequired
  }).isRequired,
  // Any ViewDrawerContent should have a props when the Component is passed in,
  isLoading: PropTypes.bool.isRequired,
  isMapLoading: PropTypes.bool.isRequired
};
