import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocationContext } from "../providers/LocationProvider";
import DrawerToolbeltItemEstimatedVolume from "./DrawerToolbeltItemEstimatedVolume";
import DrawerToolbeltItemEstimatedSpend from "./DrawerToolbeltItemEstimatedSpend";
import DrawerToolbeltItemRequirements from "./DrawerToolbeltItemRequirements";
import DrawerToolbeltItemPainPoints from "./DrawerToolbeltItemPainPoints";
import DrawerToolbeltItemCompetitionAnalysis from "./DrawerToolbeltItemCompetitionAnalysis";

export default function DrawerToolbeltListLocation({ location }) {
  const { updateLocation } = useContext(LocationContext);

  return (
    <>
      <DrawerToolbeltItemEstimatedVolume
        item={location}
        updateMethod={updateLocation}
      />

      <DrawerToolbeltItemEstimatedSpend
        item={location}
        updateMethod={updateLocation}
      />

      <DrawerToolbeltItemRequirements
        item={location}
        updateMethod={updateLocation}
      />

      <DrawerToolbeltItemPainPoints
        item={location}
        updateMethod={updateLocation}
      />

      <DrawerToolbeltItemCompetitionAnalysis
        item={location}
        updateMethod={updateLocation}
      />
    </>
  );
}

DrawerToolbeltListLocation.propTypes = {
  location: PropTypes.object
};
