import React from "react";
import { useHistory } from "react-router-dom";
import { getVolumeKeyForWorkflow } from "../../generic/Utils";

export default function TopLaneRow({ lane, workflow }) {
  const history = useHistory();
  const volumeKey = getVolumeKeyForWorkflow(workflow);

  const handleClick = () => {
    history.push(`/lane/${lane.id}`);
  };

  return (
    <div onClick={handleClick} className="trow click">
      <div className="td td--wide">
        {lane.origin.city}, {lane.origin.state}
      </div>
      <div className="td td--wide">
        {lane.destination.city}, {lane.destination.state}
      </div>
      <div className="td">{lane[volumeKey]}</div>
      <div className="td td--bold">${Math.round(lane.rate)}</div>
    </div>
  );
}
