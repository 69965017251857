import React, { useContext } from "react";
import PropTypes from "prop-types";
import PopoutItem from "./PopoutItem";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";

// To use a ref as a prop, need to use React.forwardRef
const Popout = React.forwardRef((props, ref) => {
  const { isDrawerOpen } = useContext(ApplicationStateContext);

  const popoutContainerStyle = {
    zIndex: 500,
    cursor: "default",
    position: "relative",
    top: isDrawerOpen ? "5px" : "-20px",
    left: isDrawerOpen ? "" : "20px"
  };

  const popoutStyle = {
    position: "absolute",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    backgroundColor: "white",
    border: "1.75px solid var(--gray)",
    borderRadius: "5px",
    boxShadow: "3px 5px 10px rgba(0, 0, 0, 0.2)",
    opacity: props.isVisible ? 1 : 0, // Content active state based on style's opacity
    pointerEvents: props.isVisible ? "auto" : "none",
    transition: "opacity 0.15s"
  };

  return (
    <section style={popoutContainerStyle} ref={ref}>
      <div style={popoutStyle}>
        {props.popoutItems.map(item => (
          <PopoutItem
            key={item.title}
            title={item.title}
            method={item.method}
          />
        ))}
      </div>
    </section>
  );
});

Popout.propTypes = {
  props: PropTypes.shape({
    ref: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    popoutItems: PropTypes.array.isRequired
  })
};

export default Popout;
