import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import { locationToastMessages } from "../generic/ToastMessages";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";

export const LocationContext = createContext();

const LocationProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL + "/location";
  const history = useHistory()
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);

  const [wasLocationUpdated, setWasLocationUpdated] = useState(false)

  async function getLocation(id) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${id}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const location = await responseHandler({ response, setAlertModal });

      return location;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function addLocation(location) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(location)
      })

      const addedLocation = await responseHandler({
        response,
        setAlertModal
      })

      return addedLocation
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function updateLocation(location) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${location.id}`, {
        method: "PUT",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(location)
      });

      const result = await responseHandler({
        response: response,
        toastSuccess: locationToastMessages.updateSuccess,
        toastError: locationToastMessages.updateFailure,
        setAlertModal
      });

      setWasLocationUpdated(!wasLocationUpdated)

      return result
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function deleteLocation(id) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: token,
        },
      });

      await responseHandler({
        response: response,
        toastSuccess: locationToastMessages.deleteSuccess,
        toastError: locationToastMessages.deleteFailure,
        setAlertModal
      });


      history.push("/")
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  return (
    <LocationContext.Provider
      value={{
        getLocation,
        addLocation,
        updateLocation,
        deleteLocation,
        wasLocationUpdated
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
