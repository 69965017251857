import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemCompetitionAnalysis({
  item,
  updateMethod
}) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"multiline"}
      updateMethod={updateMethod}
      nameOfValue="competitionAnalysis"
      title={"Competition Analysis"}
      placeholder={"Enter Competition Analysis..."}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          Who are the other providers they are using, and why are they using
          them?
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemCompetitionAnalysis.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
