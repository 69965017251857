import React, { createContext, useContext, useState } from 'react'
import { UserContext } from './UserProvider'

export const BrokerageContext = createContext()

const BrokerageProvider = (props) => {
    const { getToken } = useContext(UserContext)
    const apiUrl = process.env.REACT_APP_API_URL + '/brokerage1'

    async function getBrokerage(brokerageId) { 
        const token = await getToken()
        const brokerage = await fetch(`${apiUrl}/${brokerageId}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then(resp => resp.json())

        return brokerage
    }

    async function getAllBrokerages() { 
        const token = await getToken()
        const brokerage = await fetch(`${apiUrl}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then(resp => resp.json())
        return brokerage
    }

    async function getUsersInBrokerage() { 
        const token = await getToken()
        const users = await fetch(`${apiUrl}/users`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then(resp => resp.json())

        return users
    }
    
    async function getUsersInCustomBrokerage(id) { 
        const token = await getToken()
        const users = await fetch(`${apiUrl}/${id}/users`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then(resp => resp.json())

        return users
    }

    async function updateUserFromCustomBrokerage(user) {
        const token = await getToken()
        const users = await fetch(`${apiUrl}/${user.brokerageId}/users`, {
            method: "POST",
            headers: {
                Authorization: token
            },
            body: JSON.stringify(user)
        }).then(resp => resp.json())

        return users
    }

    async function createUserFromCustomBrokerage(user) {
        const token = await getToken()
        const users = await fetch(`${apiUrl}/users`, {
            method: "PUT",
            headers: {
                Authorization: token
            },
            body: JSON.stringify(user)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            return "409"
        })

        return users
    }

    async function getCustomersInBrokerage() { 
        const token = await getToken()
        const customers = await fetch(`${process.env.REACT_APP_API_URL}/onboarding1/customers`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then(resp => resp.json())

        return customers
    }

    async function updateBrokerage(brokerage) { 
        const token = await getToken()
        const response = await fetch(`${apiUrl}`, {
            method: "PUT",
            headers: {
                Authorization: token
            },
            body: JSON.stringify(brokerage)
        })
        
        return 
    }

    async function createBrokerage(brokerage) { 
        const token = await getToken()
        const response = await fetch(`${apiUrl}`, {
            method: "POST",
            headers: {
                Authorization: token
            },
            body: JSON.stringify(brokerage)
        })
        
        return 
    }

    async function deleteBrokerage(brokerageId) { 
        const token = await getToken()
        const response = await fetch(`${apiUrl}/${brokerageId}`, {
            method: "DELETE",
            headers: {
                Authorization: token,
                "Content-Type": "application/json"
            }
        })
        
        return 
    }

    return (
      <BrokerageContext.Provider
        value={{
          getBrokerage,
          getUsersInBrokerage,
          updateBrokerage,
          getCustomersInBrokerage,
          getAllBrokerages,
          createBrokerage,
          deleteBrokerage,
          getUsersInCustomBrokerage,
          updateUserFromCustomBrokerage,
          createUserFromCustomBrokerage
        }}
      >
        {props.children}
      </BrokerageContext.Provider>
    );
}

export default BrokerageProvider