import React, { useContext, useEffect, useState } from 'react'
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';
import { UserContext } from '../providers/UserProvider';

const CreateProfile = () => {
    const { setFirstName, setLastName, phoneNumber, setLoginComponent,
                setPhoneNumber, role, setRole, team, setTeam } = useContext(ApplicationStateContext)
    const { getTeamsForUser } = useContext(UserContext)
    const [teams, setTeams] = useState([])
    const [enabled, setEnabled] = useState()
    const [name, setName] = useState('')

    useEffect(() => {
        getTeamsForUser()
            .then(teams => setTeams(teams))
    }, [])

    useEffect(() => {
        if(name !== '' && phoneNumber !== '' && role !=='' && team !== '') {
            setEnabled(true)
        }
        else {
            setEnabled(false)
        }
    }, [name, phoneNumber, role, team])

    useEffect(() => {
        if (name.includes(' ') && name.split(' ').length > 0) {
            const nameArray = name.split(' ')
            if(nameArray.length === 2) {
                setFirstName(nameArray[0])
                setLastName(nameArray[1])
            }
            else if (nameArray.length === 3) {
                setFirstName(`${nameArray[0]} ${nameArray[1]}`)
                setLastName(nameArray[2])
            }
        }
        else {
            setFirstName(name)
        }
    }, [name, setFirstName, setLastName])

    const handleSubmit = () => {
        setLoginComponent('addProfileImage')
    }

    return (
        <div className='auth'>
            <div className='auth__header'>
                    <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                    <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__title'>Your Information</div>
                    <div className='auth__form__field'>
                        <TextField autoComplete="new-password" id="name" variant='outlined' label='Name' onChange={e => setName(e.target.value)} className='auth__form__input'/>
                    </div>
                    <div className="auth__form__field">
                        <TextField autoComplete="new-password" id="phone" variant='outlined' label='Phone Number' onChange={e => setPhoneNumber(e.target.value)} className='auth__form__input'/>
                    </div>
                    <div className="auth__form__field">
                        <TextField autoComplete="new-password" id="role" variant='outlined' label='Your role' onChange={e => setRole(e.target.value)} className='auth__form__input'/>
                    </div>
                        <FormControl variant="outlined" className='auth__form__select' disabled={!teams.length}>
                            <InputLabel id="demo-simple-select-outlined-label">Your team</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={team}
                            onChange={e => setTeam(e.target.value)}
                            label="Your team"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    teams.map(t => {
                                        return <MenuItem key={t.id} value={t.id}>
                                                <span role='img' aria-label='icon' className='auth__form__select__emoji'>{t.icon}</span>
                                                <span className='auth__form__select__text'>{t.name}</span>
                                            </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    <div className='auth__form__field'>
                        <button className={`auth__form__submit btn 
                            ${enabled ? 'btn-enabled' : 'btn-disabled'}`}
                            onClick={handleSubmit}>Continue</button>
                    </div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text'>Contact your company's Terralanes manager</div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CreateProfile