import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useIsSubmitDisabled from "../../hooks/useIsSubmitDisabled";
import ButtonTextOnly from "../generic/buttons/ButtonTextOnly";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import { useContext } from "react";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

export default function DrawerToolbeltStateEdit({
  title,
  infoButton,
  originalValue,
  inputValue,
  input,
  isSubmitting,
  onCancel,
  onSave
}) {
  const {
    triggerToolbeltSave,
    setIsToolbeltCancelButtonClicked,
    setTriggerToolbeltSave
  } = useContext(ApplicationStateContext);
  const isDisabled = useIsSubmitDisabled(originalValue, inputValue);

  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: "var(--lightBlue)",
    border: "2px solid var(--lightBlue)",
    borderRadius: "10px",
    padding: "1em",
    margin: "0.5em 0"
  };

  const headerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.75em"
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "0.9em",
    color: "var(--darkBlue)"
  };

  const buttonContainer = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  };

  function cancelEdit() {
    setIsToolbeltCancelButtonClicked(true);
    onCancel();
  }

  useEffect(() => {
    if (triggerToolbeltSave === true) {
      if (isDisabled === false) {
        onSave();
      } else if (isDisabled === true) {
        setTriggerToolbeltSave(false);
        setIsToolbeltCancelButtonClicked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerToolbeltSave]);

  return (
    <form style={containerStyle}>
      <header style={headerStyle}>
        <div>
          <span style={titleStyle}>{title}</span>
          {infoButton}
        </div>
        <div style={buttonContainer}>
          <ButtonTextOnly
            type="button"
            style={{ marginRight: "5px" }}
            onClick={cancelEdit}
          >
            Cancel
          </ButtonTextOnly>
          <ButtonSolid
            style={{ width: "4.5em" }}
            onClick={onSave}
            disabled={isDisabled}
            isSubmitting={isSubmitting}
            type="submit"
            color="blue"
          >
            Save
          </ButtonSolid>
        </div>
      </header>
      {input}
    </form>
  );
}

DrawerToolbeltStateEdit.propTypes = {
  title: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
  originalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};
