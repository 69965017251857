import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function FormWorkflowTip({ text }) {
  return (
    <Container>
      <StyledParagraph>
        <Bold>Workflow Tip: </Bold> {text}
      </StyledParagraph>
    </Container>
  );
}

FormWorkflowTip.propTypes = {
  text: PropTypes.string.isRequired
};

const Container = styled.section`
  background-color: var(--lightBlue);
  width: 90%;
  align-self: center;
  margin-top: 30px;
  padding: 10px;
  border-radius: 7px;
`;

const StyledParagraph = styled.p`
  color: var(--lavender);
  font-weight: 500;
  margin: 0;
`;

const Bold = styled.span`
  color: var(--darkBlue);
  font-weight: 700;
`;
