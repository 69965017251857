import React, { createContext, useContext } from "react";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";
import Papa from "papaparse";
import { uploadToastMessages } from "../generic/ToastMessages";

export const UploadContext = createContext();

const UploadProvider = props => {
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const apiUrl = process.env.REACT_APP_API_URL + "/upload";

  function parseCSVtoJSON(csv) {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        dynamicTyping: true,
        header: true,
        complete(results, csv) {
          resolve(results.data);
        },
        error(err, csv) {
          reject(err);
        }
      });
    });
  }

  async function uploadCSV(csv) {
    try {
      const token = await getToken();

      const parsedFile = await parseCSVtoJSON(csv);

      const arrayLength = parsedFile.length;

      if (parsedFile[arrayLength - 1]["Customer Name"] === null) {
        parsedFile.pop();
      }

      const prepped = { body: parsedFile, token };

      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(prepped)
      });

      await responseHandler({
        response,
        toastError: uploadToastMessages.failure,
        setAlertModal
      });
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  return (
    <UploadContext.Provider
      value={{
        uploadCSV
      }}
    >
      {props.children}
    </UploadContext.Provider>
  );
};

export default UploadProvider;
