import React, { createContext, useContext, useState } from "react";
import { tagToastMessages } from "../generic/ToastMessages";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";

export const TagContext = createContext();

const TagProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL + "/tag1";
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const [tags, setTags] = useState([]);

  async function getTags(page, item) {
    // tagType options: 'lane' 'location' 'customer'
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${item.id}?tagType=${page}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });
      
      const tags = await responseHandler({ response, setAlertModal });

      setTags(tags);
    } catch (error) {      
      errorHandler(error, setAlertModal);
    }
  }

  async function addExistingTag(page, item, tag) {
    try {
      const token = await getToken();

      const response = await fetch(
        `${apiUrl}/${item.id}?tagType=${page}&existing=true`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(tag)
        }
      );

      await responseHandler({
        response: response,
        toastSuccess: tagToastMessages.addSuccess,
        toastError: tagToastMessages.addFailure,
        setAlertModal
      });

      await getTags(page, item);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  // Returns boolean to know whether to clear input box in component
  async function addCustomTag(page, item, tag) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}/${item.id}?tagType=${page}`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(tag)
      });

      await responseHandler({
        response: response,
        toastSuccess: tagToastMessages.addNamedSuccess,
        toastSuccessParam: tag.content,
        toastError: tagToastMessages.addFailure,
        setAlertModal
      });

      await getTags(page, item);

      return true;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return false;
    }
  }

  async function deleteTag(page, item, tag) {
    try {
      const token = await getToken();

      const response = await fetch(`${apiUrl}?tagType=${page}`, {
        method: "DELETE",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(tag)
      });

      await responseHandler({
        response: response,
        toastSuccess: tagToastMessages.removeSuccess,
        toastSuccessParam: tag.content,
        toastError: tagToastMessages.removeFailure,
        toastErrorParam: tag.content,
        setAlertModal
      });

      await getTags(page, item);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  return (
    <TagContext.Provider
      value={{
        getTags,
        addExistingTag,
        addCustomTag,
        deleteTag,
        tags,
        setTags
      }}
    >
      {props.children}
    </TagContext.Provider>
  );
};

export default TagProvider;
