import React from "react";
import PropTypes from "prop-types";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToastMessages } from "../../generic/ToastMessages";
import "./TooltipStructureCopy.css";

export default function TooltipStructureCopy({ content }) {
  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(content);
      copyToastMessages.success(content);
    } catch (error) {
      copyToastMessages.failure(content);
      console.error("Error while copying", error);
    }
  }

  return (
    <FileCopyIcon
      className="tooltipStructureCopy__btn"
      onClick={() => copyToClipboard()}
    />
  );
}

TooltipStructureCopy.propTypes = {
  content: PropTypes.string.isRequired
};
