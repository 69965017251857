import React, { createContext, useContext } from "react";
import { UserContext } from "./UserProvider";

export const LedgerContext = createContext();

const LedgerProvider = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL + '/ledger1'
  const { getToken } = useContext(UserContext);

  async function getMessages(ledgerId) {
    try {
      const token = await getToken();
      const results = await fetch(`${apiUrl}/${ledgerId}`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }).then((resp) => resp.json());
      return results;
    } catch (error) {
      window.alert(error);
    }
  }

  async function postMessage(message) {
    try {
      const token = await getToken()
      await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      })
      const ledger = await getMessages(message.ledgerId)
      return ledger
    }
    catch (error) {
      window.alert(error)
    }
  }

  async function deleteMessage(message) {
    try {
      const token = await getToken()
      await fetch(`${apiUrl}/message/${message.id}`, {
        method: "DELETE",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      })
      const ledger = await getMessages(message.ledgerId)
      return ledger
    }
    catch (error) {
      window.alert(error)
    }
  }

  async function editMessage(message) {
    try {
      const token = await getToken()
      await fetch(`${apiUrl}/message/${message.id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      })
      const ledger = await getMessages(message.ledgerId)
      return ledger
    }
    catch (error) {
      window.alert(error)
    }
  }

  async function filterMessages(id, q) {
    try {
      const token = await getToken();
      const results = await fetch(`${apiUrl}/search?id=${id}&q=${q}`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }).then((resp) => resp.json());
      return results;
    } catch (error) {
      window.alert(error);
    }
  }

  return (
    <LedgerContext.Provider
      value={{
        getMessages, postMessage, deleteMessage, editMessage, filterMessages
      }}
    >
      {props.children}
    </LedgerContext.Provider>
  );
};

export default LedgerProvider;
