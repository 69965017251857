import React, { useContext, useState, useEffect } from 'react'
import { Menu, MenuItem, withStyles } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserContext } from '../providers/UserProvider';
import { useHistory } from 'react-router-dom';
import HeaderMenuHelp from './HeaderMenuHelp';

const SettingsMenuList = withStyles({
    paper: {
      borderTop: '1px solid #fff',
      borderRadius: '0 0 20px 20px',
      marginTop: '7px',
      boxShadow: '6px 6px 10px rgba(15, 14, 69, 0.15);',
      maxWidth: '15%',
      width: '15%',
      minWidth: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'

    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))

const SettingsMenuItem = withStyles((theme) => ({
    root: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
    },
  }))(MenuItem);

export default function SettingsMenu({ settingsAnchor, setSettingsAnchor, isAdmin }) {
    const { signOut } = useContext(UserContext)
    const history = useHistory()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        return () => setSettingsAnchor(null)
    }, [])

    const handleSettingsClick = () => {
        setSettingsAnchor(null)
        history.push('/settings/profile')
    }

    const handleLogoutClick = () => {
        setSettingsAnchor(null)
        signOut()
    }

    const handleHelpClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
        <SettingsMenuList anchorEl={settingsAnchor} open={Boolean(settingsAnchor)} 
            onClose={() => setSettingsAnchor(null)}
            anchorPosition={{ bottom: 0, left: 0}}>
            <SettingsMenuItem onClick={handleSettingsClick}>
                <SettingsIcon style={{color: '#fa406f', fontSize: '17px'}}/>
                <div className='menuItem__title'>Settings</div>
            </SettingsMenuItem>
            <SettingsMenuItem onClick={handleHelpClick}>
                <HelpIcon style={{color: '#fa406f', fontSize: '17px'}}/>
                <div className='menuItem__title'>Help</div>
            </SettingsMenuItem>
            <SettingsMenuItem onClick={handleLogoutClick}>
                <ExitToAppIcon style={{color: '#fa406f', fontSize: '17px'}}/>
                <div className='menuItem__title'>Log Out</div>
            </SettingsMenuItem>
        </SettingsMenuList>
        <HeaderMenuHelp open={open} handleClose={handleClose} isAdmin={isAdmin}/>
        </>
    )
}