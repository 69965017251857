import { Card } from "@material-ui/core";
import React, { useContext, useEffect, useState, useCallback } from "react";
import debounce from 'lodash.debounce'
import { useHistory } from "react-router-dom";
import { SearchContext } from "../providers/SearchProvider";
import SearchResultCustomer from "./results/SearchResultCustomer";
import SearchResultCustomerLocation from "./results/SearchResultCustomerLocation";
import SearchResultLane from "./results/SearchResultLane";
import SearchResultLaneParter from "./results/SearchResultLanePartner";
import SearchResultTeam from "./results/SearchResultTeam";
import SearchResultTeammate from "./results/SearchResultTeammate";

// A Switch to format each search result
const formatResult = (result, i) => {
    switch (result["_index"]) {
        case "customer":
            return (
                <SearchResultCustomer
                    key={i}
                    result={result}
                    id={`search__result--${i}`}
                />
            );
        case "lane":
            return (
                <SearchResultLane key={i} result={result} id={`search__result--${i}`} />
            );
        case "customer_location":
            return (
                <SearchResultCustomerLocation
                    key={i}
                    result={result}
                    id={`search__result--${i}`}
                />
            );
        case "lane_partner":
            return (
                <SearchResultLaneParter
                    key={i}
                    result={result}
                    id={`search__result--${i}`}
                />
            );
        case "user":
            return (
                <SearchResultTeammate
                    key={i}
                    result={result}
                    id={`search__result--${i}`}
                />
            );
        case "team":
            return (
                <SearchResultTeam key={i} result={result} id={`search__result--${i}`} />
            );
        default:
            return <div>A result we don't need.</div>;
    }
};

export default function SearchResultsList() {
    const history = useHistory()
    const currentUser = JSON.parse(sessionStorage.getItem("user"))
    const { terms, closeSearch } = useContext(SearchContext)
    const { getResults } = useContext(SearchContext);
    const [results, setResults] = useState([])
    const [componentsList, setComponentsList] = useState([]); // an array containing the formatted search result components
    const [active, setActive] = useState(0) // holds the 'active' search result for arrow navigation [integer]
    const [go, setGo] = useState(false) //if true will go to the active results's component
    const [disabled, setDisabled] = useState(false) //onMouseOver disable arrow key navigation

    const keydownHandler = (e) => {
        if(disabled) {
            return
        }
        else if(e.keyCode === 40) {
            setActive(prev => (prev + 1) % componentsList.length) // down arrow event
        }
        else if(e.keyCode === 38) {
            setActive(prev => ((prev - 1) + componentsList.length) % componentsList.length) // up arrow event
        }
        else if(e.keyCode === 13 && results[active]) {
            setGo(true) // enter key event
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keydownHandler); // add keyboard event lister

        return () => {
            window.removeEventListener('keydown', keydownHandler); // remove event listeners on cleanup
        };
    }, [componentsList, disabled])
    
    async function updateResults() {
        const results = await getResults(terms)
        if (results !== null) {
            setResults(results)
            const componentsList = results.map((result, i) => {
                const component = formatResult(result, i);
                return component;
            })
            setComponentsList(componentsList)
            setActive(0)
        }
    }
    
    const delayedResults = useCallback(debounce(updateResults, 300), [terms]) // debounce search update
    
    useEffect(() => {
        delayedResults();
    
        return delayedResults.cancel // cancel previous  calls during useEffect cleanup.
    }, [terms, delayedResults]);

    useEffect(() => {
        if (componentsList.length) {
            const element = document.getElementById(`search__result--${active}`); // add active style to result
            if(element) {
                element.classList.add("search__results__active");
            }
        }

        return() => {
            const element = document.getElementById(`search__result--${active}`); // remove active style
            if(element) {
                element.classList.remove("search__results__active")
            }
        }
    }, [componentsList, active]);

    useEffect(() => {
        if(go) {
            closeSearch()
            switch(results[active]._index) {
                case 'user':
                    if(currentUser.id === results[active]._source.id){
                        history.push('/')
                        return
                    }
                    history.push(`/user/${results[active]._source.id}`)
                    break
                case 'customer':
                    history.push(`/customer/${results[active]._source.id}`)
                    break
                case 'customer_location':
                    history.push(`/location/${results[active]._source.id}`)
                    break
                case 'lane':
                    history.push(`/lane/${results[active]._source.id}`)
                    break
                case 'team':
                    history.push(`/team/${results[active]._source.id}`)
                    break
            }
            setGo(false)
        }
    }, [go])

    return (
        <div className="search__results">
            <Card className="search__results__list"
                elevation={16} 
                onMouseOver={() => {
                    setActive(null)
                    setDisabled(true)
                }} 
                onMouseLeave={() => {
                    setActive(0)
                    setDisabled(false)
                }}>
                    {componentsList}
            </Card>
        </div>
    );
}
