import React from "react";

export default function IconCustomer({ size }) {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      version="1.1"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Customer Icon</title>
      <path
        d="m9.5703 0.87109v5.2773l-8.5703 3.4297v9.2891h9.4258 1.7148v0.005859h4.2852v-0.005859h2.5703v-17.996h-9.4258zm0.85742 0.85742h7.7129v16.281h-1.7148v-4.2793h-4.2852v4.2793h-1.7129v-16.281zm1.498 1.7168v1.2852h1.9277v-1.2852h-1.9277zm3.2148 0v1.2852h1.9277v-1.2852h-1.9277zm-3.2148 3.2129v1.2852h1.9277v-1.2852h-1.9277zm3.2148 0v1.2852h1.9277v-1.2852h-1.9277zm-5.5703 0.41602v10.936h-1.7148v-3.4297h-4.2852v3.4297h-1.7129v-7.8496l7.7129-3.0859zm2.3555 2.7949v1.2852h1.9277v-1.2852h-1.9277zm3.2148 0v1.2852h1.9277v-1.2852h-1.9277zm-2.1426 4.7188h2.5723v3.4219h-2.5723v-3.4219zm-8.5703 0.84961h2.5703v2.5703h-2.5703v-2.5703z"
        fill="#fff"
      />
    </svg>
  );
}
