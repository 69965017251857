import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import { UserContext } from "../providers/UserProvider";
import FormModal from "./FormModal";
import FormAddWorkflowSelect from "./FormAddWorkflowSelect";
import FormAddWorkflowTutorialSteps from "./FormAddWorkflowTutorialSteps";
import FormAddWorkflowCustomerSteps from "./FormAddWorkflowCustomerSteps";
import FormAddWorkflowLocationSteps from "./FormAddWorkflowLocationSteps";
import FormAddWorkflowLaneSteps from "./FormAddWorkflowLaneSteps";
import { CircularProgress } from "@material-ui/core";
import "./FormAddWorkflow.css";
// Implements all add form components & handles their active state

export default function FormAddWorkflow({ isModalOpen, closeModal }) {
  const locationRoute = useLocation();
  const { showTutorial } = useContext(UserContext);
  const { triggerRefreshOnDataAdd } = useContext(ApplicationStateContext);
  const [activeModal, setActiveModal] = useState(null);

  const stepTutorialForm = FormAddWorkflowTutorialSteps(setActiveModal);

  const stepLaneForm = FormAddWorkflowLaneSteps(setActiveModal, closeModal);

  const stepCustomerForm = FormAddWorkflowCustomerSteps(
    setActiveModal,
    closeModal,
    stepLaneForm
  );

  const stepLocationForm = FormAddWorkflowLocationSteps(
    setActiveModal,
    closeModal,
    stepLaneForm
  );

  const stepDataSelection = {
    modalWidth: "45em",
    modalHeight: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Select Data to add",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowSelect
        closeModal={closeModal}
        setActiveForm={setActiveModal}
        stepCustomerForm={stepCustomerForm}
        stepLocationForm={stepLocationForm}
        stepLaneForm={stepLaneForm}
      />
    )
  };

  const stepLoading = {
    modalWidth: "28em",
    modalHeight: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <CircularProgress color="secondary" style={{ placeSelf: "center" }} />
    )
  };

  useEffect(() => {
    // If we're not on the current user page, show stepDataSelection
    const [, path] = locationRoute.pathname.split("/", 2);

    // Decides which modal state to show
    const handler = setTimeout(() => {
      if (showTutorial === null && path === "") {
        // Then we're fetching data for the first time we loaded the app
        setActiveModal(stepLoading);
      } else if (showTutorial === null && path === "login") {
        setActiveModal(stepLoading);
      } else if (showTutorial === null && path !== "") {
        // Then they have completed the tutorial
        setActiveModal(stepDataSelection);
      } else if (showTutorial === true) {
        setActiveModal(stepTutorialForm);
      } else {
        setActiveModal(stepDataSelection);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTutorial, isModalOpen]);

  // Definitely change this later to use modalType="success"
  // But for now, this successfully re-fetches data
  useEffect(() => {
    if (activeModal?.content?.props?.mainText?.includes("added")) {
      triggerRefreshOnDataAdd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal?.content?.props?.mainText]);

  if (activeModal === null) {
    return null;
  }

  return (
    <FormModal
      modalWidth={activeModal.modalWidth}
      modalHeight={activeModal.modalHeight}
      isModalOpen={isModalOpen || showTutorial}
      closeModal={activeModal.isSubmitting ? () => {} : closeModal} // Need an empty function instead of null
      hasHeader={activeModal.hasHeader}
      hasHeaderCloseBtn={activeModal.hasHeaderCloseBtn}
      headerIcon={activeModal.headerIcon}
      headerText={activeModal.headerText}
    >
      {activeModal.content}
    </FormModal>
  );
}

FormAddWorkflow.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};
