
import { Popper } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SearchContext } from '../providers/SearchProvider'
import './Search.css'
import SearchResultsList from './SearchResultsList'

// custom hook to capture window resizing events
const useResize = (myRef) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
  
    useEffect(() => {
      const handleResize = () => {
        setWidth(myRef.current.offsetWidth)
        setHeight(myRef.current.offsetHeight)
      }
  
      window.addEventListener('resize', handleResize)
  
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [myRef])
  
    return { width, height }
}

// main Search component
const Search = () => {
    // STATE VARIABLES
    const {anchorEl, setAnchorEl, open, terms, setTerms, closeSearch} = useContext(SearchContext)
    const inputRef = useRef() // DOM reference to <input />
    const { width, height } = useResize(inputRef) // custom hook to capture resizing events
    const [elWidth, setElWidth] = useState() // the width of the results <Popper />
    const [mouseOnResults, setMouseOnResults] = useState(false)

    //MOUSE EVENTS
    const handleMouseOver = () => {
        setMouseOnResults(true)
    }
    const handleMouseLeave = () => {
        setMouseOnResults(false)
    }
    const handleBlur = () => {
        if(!mouseOnResults) {
            closeSearch()
        }
    }

    // SIDE EFFECTS
    useEffect(() => {
        if(inputRef) {
            setElWidth(inputRef.current.offsetWidth) // on load, set the width of the results <Popper /> to the current <input /> width
        }
    })

    useEffect(() => {
        document.querySelector('.search').addEventListener('keydown', function(e) {
            if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault()
            }
        })
    }, [])
    useEffect(() => {
        setElWidth(width) // a side effect that changes the width of <Popper /> to equal <input /> on window resizing
    }, [width])

    // INPUT HANDLERS
    const handleChange = (e) => {
        setTerms(e.target.value)

        if(e.target.value) {
            setAnchorEl(e.currentTarget) // if terms is not empty, anchor <Popper /> to <input /> and open popper
        }
        else {
            setAnchorEl(null) // otherwise don't anchor/close <Popper />
        }
    }

    // DOM ELEMENTS AND COMPONENTS
    return (
        <>
            <div className='search' ref={inputRef}>
                <input placeholder="What are you working on today?" className={terms ? 'search__input   ' : 'search__input'}
                    value={terms}
                    onChange={e => handleChange(e)} 
                    onBlur={handleBlur}/>
            </div>
            <Popper
                style={{width: elWidth}}
                open={open}
                anchorEl={anchorEl}
                className='search__popover'
                transition={true}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseLeave}>
                <SearchResultsList />
            </Popper>
        </>
    )
}

export default Search