import React, { useContext } from 'react'
import { Card } from "@material-ui/core";
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';

// this component displays a message that the password reset has failed

export default function PasswordResetFail() {
    const { setLoginComponent } = useContext(ApplicationStateContext)

    return (
        <div className='auth'>
            <div className='auth__header'>
                <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__emoji'><span role='img' aria-label='x'>❌</span></div>
                    <div className='auth__form__title--withEmoji'>There was an error resetting your password.</div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text' onClick={() => setLoginComponent('forgotPassword')}>← Go Back</div>
                    </div>
                </Card>
            </div>
        </div>
    )
}