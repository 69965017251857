import React from "react";
import { useHistory } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { Tooltip } from "@material-ui/core";
import "./MapPopup.css";

export default function MapPopupCustomer({ properties }) {
  const history = useHistory();
  return (
    <div className="popup">
      <div className="popup__content">
        <div
          className="popup__name"
          onClick={() =>
            history.push(`/location/${properties.customerLocationId}`)
          }
        >
          {properties.customerName}
        </div>

        {properties.customerAddress === "null" ? null : (
          <div className="popup__detail">{properties.customerAddress}</div>
        )}

        <div className="popup__detail">
          {properties.customerCity}, {properties.customerState}
        </div>
      </div>
      <div className="popup__btns">
        <Tooltip
          title={
            properties.customerPhone === "null" ||
            properties.customerPhone === ""
              ? "No phone entered"
              : properties.customerPhone
          }
          placement="right"
        >
          <PhoneIcon
            className="popup__btn"
            style={{
              fontSize: "25px",
              color: "#919191",
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "8px"
            }}
          />
        </Tooltip>
        <Tooltip
          title={
            properties.customerEmail === "null" ||
            properties.customerPhone === ""
              ? "No email entered"
              : properties.customerEmail
          }
          placement="bottom"
        >
          <MailIcon
            className="popup__btn"
            style={{
              fontSize: "25px",
              color: "#919191",
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "8px"
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
}
