import React, { useState, useContext, useEffect } from 'react'
import { Card, TextField } from "@material-ui/core";
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';
import { Auth } from 'aws-amplify'

export default function ForgotPassword() {
    const { setLoginComponent, email, setEmail } = useContext(ApplicationStateContext)
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        if(email !== '') {
            setEnabled(true)
        }
        else {
            setEnabled(false)
        }
    }, [email])

    const forgetPasswordSubmit = () => {
        if(enabled) {
            Auth.forgotPassword(email)
                .then(data => console.log(data))
                .catch(err => console.log(err));
            sessionStorage.setItem('resetEmail', email)
            setLoginComponent('forgotPasswordConfirm')
        }
    }

    return (
        <div className='auth'>
            <div className='auth__header'>
                <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__title'>Forgot Your Password?</div>
                    <div className='auth__form__field--top'>
                        <TextField autoComplete="new-password" id="email" variant='outlined' label='Email' className='auth__form__input' onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div className='auth__form__field'>
                        <button className={`auth__form__submit btn ${enabled ? 'btn-enabled' : 'btn-disabled'}`} onClick={forgetPasswordSubmit}>Reset Password</button>
                    </div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text' onClick={() => setLoginComponent('login')}>← Go Back</div>
                    </div>
                </Card>
            </div>
        </div>
    )
}