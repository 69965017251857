import React from "react";
import PropTypes from "prop-types";

export default function FormModalFooterBidirectional({ onAdvanceClick, onPreviousClick, isActive, buttonAdvanceLabel, buttonPrevious }) {
    const footerStyle = {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: buttonPrevious ? "space-between" : 'flex-end',
        alignItems: "center",
        height: "4em",
        backgroundColor: "var(--lightGray)"
    };

    return (
        <footer style={footerStyle}>
            {buttonPrevious &&
                <button
                    className='pillBtn__grey pillBtn__grey--form'
                    onClick={e => onPreviousClick(e)}
                >Previous</button>
            }
            <button
                className={
                    isActive
                        ? "pillBtn__red pillBtn__red--form"
                        : "pillBtn__red pillBtn__red--disabled pillBtn__red--form"
                }
                onClick={e => onAdvanceClick(e)}
            >
                {buttonAdvanceLabel}
            </button>
        </footer>
    );
}

FormModalFooterBidirectional.propTypes = {
    onAdvanceClick: PropTypes.func.isRequired,
    onPreviousClick: PropTypes.func,
    isActive: PropTypes.bool.isRequired,
    buttonAdvanceLabel: PropTypes.string.isRequired,
    buttonPrevious: PropTypes.bool
};
