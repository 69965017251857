import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContactContext } from "../providers/ContactProvider";
import DrawerIdCard from "./DrawerIdCard";
import DeleteButton from "../generic/buttons/DeleteButton";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { formatPhoneNumber } from "../generic/Utils";

export default function DrawerContactsListItem({
  page,
  contact,
  isPrimary,
  isEditActive,
  setIsFormModalOpen,
  setContactToEdit
}) {
  const { deleteContact } = useContext(ContactContext);
  const [renderedContact, setRenderedContact] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    setIsDeleting(true);
    const response = await deleteContact(page, contact);
    if (response === null) {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    function contactCreator(contact) {
      const idCard = {
        name: `${contact.firstName} ${contact.lastName}`,
        tags: [
          <div key={1} className="secondary__tag tag tag--purple">
            Level {contact.level}
          </div>
        ],
        subHeading: contact.title,
        showContactInfo: isEditActive === false ? true : false,
        phone: formatPhoneNumber(contact),
        email: contact.email,
        teamIcon: "",
        profileImage: "",
        profileType: "contact",
        onClickEvent: null,
        isBigButton: isPrimary,
        toolTipPosition: "left"
      };

      return <DrawerIdCard key={idCard.id} idCard={idCard} />;
    }

    setRenderedContact(contactCreator(contact));
  }, [contact, isEditActive, isPrimary]);

  if (renderedContact === null) {
    return null;
  }

  return (
    <Container key={contact.id}>
      {isEditActive === false ? (
        renderedContact
      ) : (
        <>
          <ButtonContainer>
            <button
              onClick={() => {
                const copy = { ...contact };
                setIsFormModalOpen(true);
                setContactToEdit(copy);
              }}
            >
              {" "}
              <CreateOutlinedIcon
                className="editBtn__img"
                style={{ fontSize: "16px" }}
              />
            </button>
            <DeleteButton onClick={handleDelete} isDeleting={isDeleting} />
          </ButtonContainer>
          {renderedContact}
        </>
      )}
    </Container>
  );
}

DrawerContactsListItem.propTypes = {
  contact: PropTypes.object.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  setIsFormModalOpen: PropTypes.func.isRequired,
  setContactToEdit: PropTypes.func.isRequired
};

const Container = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0em 1em;
`;
