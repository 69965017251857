import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SearchContext } from '../../providers/SearchProvider'

const SearchResultCustomer = ({result, id}) => {
    const { closeSearch } = useContext(SearchContext)
    const history = useHistory()

    const handleClick = () => {
        closeSearch()
        history.push(`/customer/${result._source.id}`)
    }

    return (
        <div className={`search__results__item`} id={id} onClick={handleClick}>
        <span className='search__results__emoji' role='img' aria-label='x'>🏢</span>
        {result._source.name}
        <span className='search__results__tag tag tag--red'>Customer</span>
        </div>
    )
}

export default SearchResultCustomer