import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import { CustomerContext } from "../components/providers/CustomerProvider";
import CustomerViewDrawerContent from "./CustomerViewDrawerContent";
import MapDrawerLayout from "../components/layouts/MapDrawerLayout";
import useLanes from "../hooks/useLanes";

export default function CustomerView() {
  const { customerId } = useParams();
  const { workflow, refreshOnAdd } = useContext(ApplicationStateContext);
  const {
    getCustomer,
    getLocationsForCustomers,
    wasCustomerUpdated
  } = useContext(CustomerContext);
  const [customer, setCustomer] = useState();
  const [locations, setLocations] = useState([]);
  const [isDrawerLoading, setIsDrawerLoading] = useState(true);
  const { spend, lanes, loadsPerMonth, areLanesLoading } = useLanes(
    customerId,
    "customer"
  );

  useEffect(() => {
    async function getCustomerData() {
      const customer = await getCustomer(customerId);
      if (customer) {
        const locations = await getLocationsForCustomers(customer.id, workflow);
        if (locations) {
          setCustomer(customer);
          setLocations(locations);
          setIsDrawerLoading(false);
        } else if (locations === null) {
          // TODO: Show an error message that we couldn't load locations
          setIsDrawerLoading(false);
        }
      } else if (customer === null) {
        // TODO: Show an error message that we couldn't load customer instead of a blank drawer
        setIsDrawerLoading(false);
      }
    }

    getCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, lanes, wasCustomerUpdated, refreshOnAdd]);

  return (
    <MapDrawerLayout
      mapLanes={lanes}
      isMapLoading={areLanesLoading}
      isDrawerLoading={isDrawerLoading}
      drawerContent={
        <CustomerViewDrawerContent
          customer={customer}
          lanes={lanes}
          locations={locations}
          spend={spend}
          freq={loadsPerMonth}
        />
      }
    />
  );
}
