import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import { BrokerageContext } from "../components/providers/BrokerageProvider";
import SettingsHeader from "../components/settings/SettingsHeader";
import SettingsNav from "../components/settings/SettingsNav";
import SettingsViewContent from "./SettingsViewContent";

export default function SettingsView() {
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const { getBrokerage } = useContext(BrokerageContext);
  const [selectedItem, setSelectedItem] = useState();
  const [brokerage, setBrokerage] = useState();

  const handleClick = text => {
    setSelectedItem(text);
    history.push(`${url}/${text}`);
  };

  useEffect(() => {
    const [_, location] = history.location.pathname.split("/settings/");
    setSelectedItem(location);
  }, [history.location.pathname]);

  useEffect(() => {
    async function fetchBrokerageData() {
      const brokerage = await getBrokerage(currentUser.brokerageId);
      setBrokerage(brokerage);
      // const teams = await getTeamsForUser()
      // setTeams(teams)
    }

    fetchBrokerageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <SettingsHeader user={currentUser} />
      <NavAndContentWrapper>
        <SettingsNav
          user={currentUser}
          selectedItem={selectedItem}
          handleClick={handleClick}
        />
        <ContentContainer className="hide__scroll">
          <Switch>
            <Route path={`${path}/:setting`}>
              <MainCard>
                <SettingsViewContent
                  brokerage={brokerage}
                  setBrokerage={setBrokerage}
                />
              </MainCard>
            </Route>
          </Switch>
        </ContentContainer>
      </NavAndContentWrapper>
    </PageContainer>
  );
}

const PageContainer = styled.section`
  padding-top: 54px;
`;

const NavAndContentWrapper = styled.div`
  display: flex;
  height: calc(100vh - 154px);
  background: #f5f5f5;
`;

const ContentContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  overflow-y: scroll;
  padding-bottom: 60px;
`;

const MainCard = styled.main`
  display: flex;
  flex-grow: 1;
  min-height: 27em;
  margin: 3.5em 2em 0 3em;
  padding: 4em 4em 4em 5em;
  background-color: #fff;
  height: fit-content;
  overflow-x: auto;
  border-radius: 10px;
  box-shadow: 10px 10px 15px rgba(31, 30, 68, 0.05);
`;
