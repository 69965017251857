import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { TeammateContext } from "../providers/TeammateProvider";
import DrawerSectionHeader from "./DrawerSectionHeader";
import DrawerTeammateTaggedListItem from "./DrawerTeammateTaggedListItem";
import DrawerTeammateTaggedSearch from "./DrawerTeammateTaggedSearch";

export default function DrawerTeammateTaggedList({
  customer,
  page,
  location,
  lane
}) {
  const {
    taggedTeammates,
    getTaggedTeammatesForCustomer,
    getTaggedTeammatesForLocation,
    getTaggedTeammatesForLane,
    deleteTaggedTeammateFromCustomer,
    deleteTaggedTeammateFromLocation,
    deleteTaggedTeammateFromLane
  } = useContext(TeammateContext);
  const [isEditing, setIsEditing] = useState(false);
  const [query, setQuery] = useState("");

  const customerName = customer?.displayName ?? customer?.name;
  const locationName = `${location?.city}, ${location?.state}`;
  const laneName = `${lane?.origin?.city} to ${lane?.destination?.city}`;

  async function deleteTeammate(teammate) {
    switch (page) {
      case "customer":
        await deleteTaggedTeammateFromCustomer(
          teammate.TaggedCustomer,
          teammate.fullName,
          customerName
        );
        break;
      case "location":
        await deleteTaggedTeammateFromLocation(
          teammate.TaggedLocation,
          teammate.fullName,
          locationName
        );
        break;
      case "lane":
        await deleteTaggedTeammateFromLane(
          teammate.TaggedLane,
          teammate.fullName,
          laneName
        );
        break;
      default:
        console.error("no page entered into tagged teammate list");
        break;
    }
  }

  useEffect(() => {
    switch (page) {
      case "customer":
        getTaggedTeammatesForCustomer(customer.id);
        break;
      case "location":
        getTaggedTeammatesForLocation(location.id);
        break;
      case "lane":
        getTaggedTeammatesForLane(lane.id);
        break;
      default:
        console.error("no page entered into tagged teammate list");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, customer?.id, location?.id, lane?.id]);

  useEffect(() => {
    if (query) {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  return (
    <section>
      <DrawerSectionHeader
        hasUnderline
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      >
        Tagged Teammates
      </DrawerSectionHeader>

      {isEditing && (
        <DrawerTeammateTaggedSearch
          page={page}
          query={query}
          setQuery={setQuery}
          customer={customer}
          customerName={customerName}
          location={location}
          locationName={locationName}
          lane={lane}
          laneName={laneName}
        />
      )}
      <TeammateContainer>
        {taggedTeammates.length === 0 ? (
          <Text>No teammates tagged to this {page}.</Text>
        ) : (
          taggedTeammates.map(t => {
            return (
              <DrawerTeammateTaggedListItem
                key={t.id}
                teammate={t}
                isEditing={isEditing}
                deleteTeammate={deleteTeammate}
              />
            );
          })
        )}
      </TeammateContainer>
    </section>
  );
}

const TeammateContainer = styled.div`
  min-height: 6em;
  margin-bottom: 20px;
`;

const Text = styled.p`
  margin: 0.5em;
  font-size: 0.9em;
`;
