import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CustomerContext } from "../providers/CustomerProvider";
import DrawerToolbeltItemEstimatedVolume from "./DrawerToolbeltItemEstimatedVolume";
import DrawerToolbeltItemEstimatedSpend from "./DrawerToolbeltItemEstimatedSpend";
import DrawerToolbeltItemRequirements from "./DrawerToolbeltItemRequirements";
import DrawerToolbeltItemPainPoints from "./DrawerToolbeltItemPainPoints";
import DrawerToolbeltItemCompetitionAnalysis from "./DrawerToolbeltItemCompetitionAnalysis";

export default function DrawerToolbeltListCustomer({ customer }) {
  const { updateCustomer } = useContext(CustomerContext);

  return (
    <>
      <DrawerToolbeltItemEstimatedVolume
        item={customer}
        updateMethod={updateCustomer}
      />

      <DrawerToolbeltItemEstimatedSpend
        item={customer}
        updateMethod={updateCustomer}
      />

      <DrawerToolbeltItemRequirements
        item={customer}
        updateMethod={updateCustomer}
      />

      <DrawerToolbeltItemPainPoints
        item={customer}
        updateMethod={updateCustomer}
      />

      <DrawerToolbeltItemCompetitionAnalysis
        item={customer}
        updateMethod={updateCustomer}
      />
    </>
  );
}

DrawerToolbeltListCustomer.propTypes = {
  customer: PropTypes.object
};
