import React, { useState, useContext, useEffect } from "react";
import './Auth.css'
import NewPassword from "./NewPassword";
import LoginForm from "./LoginForm";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import PasswordResetSuccess from "./PasswordResetSuccess";
import PasswordResetFail from "./PasswordResetFail";
import CreateProfile from "./CreateProfile";
import AddProfileImage from "./AddProfileImage";

// This is the parent component for the login process
// This component controls which child component is rendered based on the global state variable "loginComponent"

export default function Login() {
    const { cognitoUser, loginComponent } = useContext(ApplicationStateContext)

    const showLogin = () => <LoginForm />
    const showNewPassword = () => <NewPassword cognitoUser={cognitoUser} />
    const showForgotPassword = () => <ForgotPassword />
    const showForgotPasswordConfirm = () => <ForgotPasswordConfirm />
    const showPasswordResetSuccess = () => <PasswordResetSuccess />
    const showPasswordResetFail = () => <PasswordResetFail />
    const showCreateProfile = () => <CreateProfile />
    const showAddProfileImage = () => <AddProfileImage />

    const [component, setComponent] = useState(showLogin)

    useEffect(() => {
        switch(loginComponent) {
            case 'login':
                setComponent(showLogin)
                break;
            case 'newPassword':
                setComponent(showNewPassword);
                break;
            case 'forgotPassword':
                setComponent(showForgotPassword);
                break;
            case 'forgotPasswordConfirm':
                setComponent(showForgotPasswordConfirm);
                break;
            case 'passwordResetSuccess':
                setComponent(showPasswordResetSuccess)
                break;
            case 'passwordResetFail':
                setComponent(showPasswordResetFail)
                break;
            case 'createProfile':
                setComponent(showCreateProfile)
                break;
            case 'addProfileImage':
                setComponent(showAddProfileImage)
                break;
        }
    }, [loginComponent])

    return component
}