import React, { useState } from 'react'
import SubmitCreditCard from '../../library/credit card/SubmitCreditCard'

export default function PaymentInfo({ paymentMethod, brokerage, subscription, retreiveCustomerBillingDetails }) {
    const [showCardForm, setShowCardForm] = useState(false)
    const subscriptionId = subscription.id

    const handleUpdateClick = () => {
        setShowCardForm(!showCardForm)
    }

    async function handleSubmit() {
        await retreiveCustomerBillingDetails()
    }

    return (
        <div className='manageBilling__card'>
            <div className='settings__card__title'>Payment Info </div>
            <div className='paymentInfo'>
                <div className='manageBilling__left flex align-center'>
                    <div className='paymentInfo__left'>
                        <div className='paymentInfo__card'>{paymentMethod.data[0].card.brand}</div>
                    </div>
                    <div className='paymentInfo__right'>
                        <div>**** **** **** {paymentMethod.data[0].card.last4}</div>
                        <div>{paymentMethod.data[0]['billing_details'].name}</div>
                        <div>Expires {paymentMethod.data[0].card['exp_month']}/{paymentMethod.data[0].card['exp_year']}</div>
                    </div>
                </div>
                <div className='manageBilling__right'>
                    <div className='settings__teams__header__btn btn__save' onClick={handleUpdateClick}>Update</div>
                </div>
            </div>
            {showCardForm && 
                <div className='paymentInfo__form'>
                    <SubmitCreditCard afterSubmit={handleSubmit} brokerage={brokerage} newCustomer={false} paymentMethod={paymentMethod} customerId={subscription.customer} />   
                </div>
            }
        </div>
    )
}