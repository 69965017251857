import React from 'react'
import './ReviewPlan.css'

export default function ReviewPlan({ stagedInvites, billingCycle, brokerage}) {
    const numOfUsers = brokerage.teammates + stagedInvites.length
    const billableUsers = numOfUsers - 1

    return (
        <div className='reviewPlan'>
            <div className='reviewPlan__section'>
                <div className='reviewPlan__section__title'>Your Account</div>
                <div className='reviewPlan__section__subhead'>Premium Account with {numOfUsers} users (Your Account is Free!)</div>
                <div className='reviewPlan__section__text'>Bills {billingCycle} at {billingCycle === 'monthly' ? `$${billableUsers * 50}.00` : `$${billableUsers * 480}.00`}</div>
            </div>
            <div className='reviewPlan__section'>
                <div className='reviewPlan__section__title'>Your Invites</div>
                {stagedInvites.map(i => {
                    return <div key={i} className='reviewPlan__section__text'>{i}</div>
                })}
            </div>
        </div>
    )
}