import React from "react";

export default function SettingsContentUpdatePasswordRequirements() {
  const containerStyle = {
    fontSize: "0.9em",
    backgroundColor: "rgba(78, 103, 216, 0.05)",
    padding: "1em",
    marginBottom: "1em",
    borderRadius: "10px",
    width: "16em"
  };

  const headingStyle = {
    fontWeight: 900,
    color: "rgba(20, 18, 114, 1)",
    margin: 0,
    padding: 0
  };

  const listStyle = {
    margin: 0,
    padding: 0,
    paddingLeft: "1.5em",
    marginTop: "0.25em"
  };

  const listItemStyle = {
    color: "rgba(137, 136, 184, 1)"
  };

  return (
    <div style={containerStyle}>
      <div style={headingStyle}>Minimum Requirements</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>8 characters</li>
        <li style={listItemStyle}>At least one special character</li>
        <li style={listItemStyle}>At least one number</li>
        <li style={listItemStyle}>Uppercase letters</li>
        <li style={listItemStyle}>Lowercase letters</li>
      </ul>
    </div>
  );
}
