import { useContext, useState, useEffect } from "react";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import { LaneContext } from "../components/providers/LaneProvider";
// useLanes fetches and returns state of lane-related data for map & drawer

// Params - id: int; view: string ("user", "customer", "location", "lane", "team", "brokerage", "customer")
export default function useLanes(id, view) {
  const { workflow, refreshOnAdd } = useContext(ApplicationStateContext);
  const {
    lanesUpdated,
    getLane,
    getLanesForUser,
    getLanesForCustomer,
    // getLanesForBrokerage,
    // getLanesForTeam,
    getLanesForLocation
  } = useContext(LaneContext);
  const [lane, setLane] = useState(null);
  const [lanes, setLanes] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [spend, setSpend] = useState(0);
  const [loadsPerMonth, setLoadsPerMonth] = useState(0);
  const [areLanesLoading, setAreLanesLoading] = useState(true);

  useEffect(() => {
    let fetchMethod = null;
    setAreLanesLoading(true);

    switch (view) {
      case "user":
        fetchMethod = getLanesForUser;
        break;
      case "customer":
        fetchMethod = getLanesForCustomer;
        break;
      case "location":
        fetchMethod = getLanesForLocation;
        break;
      case "lane":
        fetchMethod = getLane;
        break;
      // case "team":
      //   fetchMethod = getLanesForTeam;
      //   break;
      // case "brokerage":
      //   fetchMethod = getLanesForBrokerage;
      //   break;
      default:
        fetchMethod = null;
    }

    async function fetchMapData() {
      try {
        if (fetchMethod !== null) {
          const response = await fetchMethod(id, workflow);
          let lanesResp = [];

          if (response === undefined) {
            throw new Error("Lanes are undefined!");
          }

          // If response data is not an array of just lanes then
          if (!Array.isArray(response)) {
            // We have all lane & related data (revenue, loadsPerMonth, etc)
            if (response.Lanes) {
              console.log('here lanes',response.Lanes);
              lanesResp = response.Lanes;
            } else {
              // Or we have just the single lane object, so set it as the full lanesResp
              lanesResp = response;
            }

            if (response?.spend !== undefined) {
              setSpend(response.spend);
            }

            if (response?.revenue !== undefined) {
              setRevenue(response.revenue);
            }

            if (response?.loadsPerMonth !== undefined) {
              setLoadsPerMonth(response.loadsPerMonth);
            }
          } else {
            // If the response is an array of only lanes, set lanes
            lanesResp = response;
          }

          if (!Array.isArray(lanesResp)) {
            setLane(lanesResp);
          }

          setLanes(lanesResp);
          setAreLanesLoading(false);
        }
      } catch (error) {
        setAreLanesLoading(false);
        console.error("useLanes.js - fetchMapData():", error);
      }
    }

    if (workflow) {
      fetchMapData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lanesUpdated, workflow, refreshOnAdd]);

  return {
    areLanesLoading,
    lane,
    lanes,
    revenue,
    spend,
    loadsPerMonth
  };
}
