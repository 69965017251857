import React from "react";
import ButtonSolid from "../generic/buttons/ButtonSolid";

export default function FormAddWorkflowTutorialWelcome({
  setActiveForm,
  stepAddData
}) {
  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-around",
    margin: "2em"
  };

  const paragraphStyle = {
    fontSize: "1.2em",
    lineHeight: "1.5em"
  };

  const spanStyle = {
    fontWeight: "900"
  };

  return (
    <section style={containerStyle}>
      <p style={paragraphStyle}>
        <span style={spanStyle}>Terralanes</span> is a specialized tool for
        growing your wallet share and managing your customers!
      </p>

      <ButtonSolid
        style={{
          padding: "1.4em",
          fontSize: "1em"
        }}
        onClick={() => setActiveForm(stepAddData)}
        color="red"
      >
        <span style={spanStyle}>Let's get started!</span>
      </ButtonSolid>
    </section>
  );
}
