import React, { useContext } from "react";
import { useHistory } from "react-router";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import { LocationContext } from "../providers/LocationProvider";
import FormAddWorkflowLocation from "./FormAddWorkflowLocation";
import FormAddWorkflowSubmitting from "./FormAddWorkflowSubmit";
import FormAddWorkflowSuccess from "./FormAddWorkflowSuccess";
// Contains all logic & steps for the location workflow
// Lives here and imported into FormAddWorkflow to keep that file small

export default function FormAddWorkflowLocationSteps(
  setActiveModal,
  closeModal,
  stepLaneForm
) {
  const { triggerRefreshOnDataAdd } = useContext(ApplicationStateContext);
  const { addLocation } = useContext(LocationContext);
  const history = useHistory();

  let goToAddedId = null;

  function goToAddedObject() {
    history.push(`/location/${goToAddedId}`);
    closeModal();
  }

  const stepLocationForm = {
    modalHeight: "30em",
    modalWidth: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Enter Location Details",
    headerIcon: "Location",
    isSubmitting: false,
    content: <FormAddWorkflowLocation onSubmit={addLocationSubmit} />
  };

  const stepLocationSubmitting = {
    modalHeight: "18em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: false,
    headerText: "",
    headerIcon: "",
    isSubmitting: true,
    content: (
      <FormAddWorkflowSubmitting
        iconToAssign={"Location"}
        text={"Adding location..."}
      />
    )
  };

  const stepLocationSuccess = {
    modalHeight: "20em",
    modalWidth: "20em",
    hasHeader: false,
    hasHeaderCloseBtn: true,
    headerText: "",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormAddWorkflowSuccess
        mainText={"Location added!"}
        buttonText={"View Location"}
        goToAdded={goToAddedObject}
        goToForm={function() {
          setActiveModal(stepLocationForm);
        }}
        goToAddLane={function() {
          setActiveModal(stepLaneForm);
        }}
      />
    )
  };

  async function addLocationSubmit(e) {
    // Form validation lives in FormAddWorkflowCustomer
    e.preventDefault();

    setActiveModal(stepLocationSubmitting);
    const locationFormData = JSON.parse(
      sessionStorage.getItem("locationFormData")
    );

    // Change optional inputs from empty strings to null
    const preppedLocation = {
      customerId: locationFormData.customerId,
      address:
        locationFormData.address === "" ? null : locationFormData.address,
      address2:
        locationFormData.address2 === "" ? null : locationFormData.address2,
      city: locationFormData.city,
      state: locationFormData.state,
      zipCode: locationFormData.zipCode === "" ? null : locationFormData.zipCode
    };

    const response = await addLocation(preppedLocation);

    if (response !== null) {
      triggerRefreshOnDataAdd();
      sessionStorage.removeItem("locationFormData");
      goToAddedId = response.id;
      setActiveModal(stepLocationSuccess);
    } else {
      setActiveModal(stepLocationForm);
    }
  }

  return stepLocationForm;
}
