import React from "react";
import PropTypes from "prop-types";
import "./ProfileImage.css";
// ProfileImage contains logic for displaying circle profile images or first letter of name

export default function ProfileImage({
  className,
  size,
  location,
  type,
  name,
  image,
  icon // ALWAYS ensure you null check these before passing in --- ie: message.User?.Team?.icon
}) {
  // If no className prop is given, this sets it to an empty string instead of undefined
  ProfileImage.defaultProps = {
    className: ""
  };

  // Assigning all strings here to reduce chance of error
  const USER = "user";
  const TEAM = "team";
  const TEAMMATE = "teammate";
  const CUSTOMER = "customer";
  const CONTACT = "contact";
  const BROKERAGE = "brokerage";
  const LEDGER = "ledger";
  const DRAWERHEADER = "drawerHeader";

  let stampColor = "";
  let teammateBadgeModifier = "";

  function assignStampColor() {
    if (!image) {
      // Switch statement doesn't like checking type & location
      if (type === CUSTOMER && location === DRAWERHEADER) {
        stampColor = "stamp--grey";
        return;
      }

      switch (type) {
        case USER:
          stampColor = "stamp--blue";
          break;

        case TEAMMATE:
          stampColor = "stamp--blue";
          break;

        case CUSTOMER:
          stampColor = "stamp--red";
          break;

        case CONTACT:
          stampColor = "stamp--grey";
          break;

        case BROKERAGE:
          // Not doing yet
          break;

        default:
          stampColor = "stamp--grey";
          break;
      }
    }
  }

  function assignLocationClass() {
    // Based on profile image location, display correct teammate badge size
    switch (location) {
      case LEDGER:
        teammateBadgeModifier = "--ledger";
        break;

      case DRAWERHEADER:
        teammateBadgeModifier = "--drawerHeader";
        break;

      default:
        teammateBadgeModifier = "";
        break;
    }
  }

  // Called outside of any useEffect to ensure classes are assigned before initial render
  assignStampColor();
  assignLocationClass();

  // For customers, maybe make it so they have a default image as their Building emoji
  return (
    <div className={`${className} profileImage__container--${location}`}>
      {// If there is profile image, display it or the first character as a colored stamp
      image ? (
        <img
          className={`profileImage__img profileImage__size--${size}`}
          src={image}
          alt="profile image"
        />
      ) : (
        <div className={`profileImage__size--${size} stamp ${stampColor}`}>
          {type === TEAM ? icon : null}
          {type === CUSTOMER && location === DRAWERHEADER ? (
            <span role="img" aria-label="office building">
              🏢
            </span>
          ) : (
            name?.charAt(0)
          )}
        </div>
      )}
      {type === TEAMMATE && icon ? (
        <span
          className={
            // If this is on the ledger, add the ledger modifier to class
            `teammateBadge${teammateBadgeModifier} stamp stamp--grey`
          }
        >
          {icon}
        </span>
      ) : null}
    </div>
  );
}

ProfileImage.propTypes = {
  className: PropTypes.string,
  // Optional className for specific styling to main container (mainly for grid positioning)
  size: PropTypes.string.isRequired,
  //  "small", "medium", "large" - assigns css for circle's size
  location: PropTypes.string.isRequired,
  // "header", "drawerHeader", "drawer", "list", "ledger" - assigns margins
  type: PropTypes.string.isRequired,
  // "user", "teammate", "team", "customer", "brokerage (not yet)", or none for grey - assigns stamp css & whether to show teammate badge
  name: PropTypes.string,
  // Incoming object's name property -  allows for stamp to have first letter
  image: PropTypes.string,
  // Profile Image
  icon: PropTypes.string
  // Team badge icon
};
