import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LaneContext } from "../providers/LaneProvider";
import DrawerIdCard from "./DrawerIdCard";
import DeleteButton from "../generic/buttons/DeleteButton";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { numberWithCommas, formatPhoneNumber } from "../generic/Utils";

export default function DrawerCarriersListItem({
  carrier,
  isEditActive,
  setIsFormModalOpen,
  setCarrierToEdit
}) {
  const { deleteCarrier } = useContext(LaneContext);
  const [renderedCarrier, setRenderedCarrier] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const buttonContainerStyle = {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    margin: "0em 1em"
  };

  async function handleDelete() {
    setIsDeleting(true);
    const response = await deleteCarrier(carrier);
    if (response === null) {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    function favoriteCarrierCreator(carrier) {
      carrier.phone = carrier.contactPhone;
      const idCard = {
        id: carrier.id,
        name: carrier.name,
        tags: [
          <div key={1} className="tag tag--green">
            Historical Rate: ${numberWithCommas(carrier.historicalRate)}
          </div>
        ],
        subHeading: carrier.contactName
          ? "Contact: " + carrier.contactName
          : "",
        showContactInfo: isEditActive === false ? true : false,
        phone:
          carrier.contactPhone === null ? null : formatPhoneNumber(carrier),
        email: carrier.contactEmail,
        teamIcon: "",
        toolTipPosition: "left",
        profileImage: "",
        profileType: "carrier",
        onClickEvent: null,
        isBigButton: false
      };

      return <DrawerIdCard key={idCard.id} idCard={idCard} />;
    }

    setRenderedCarrier(favoriteCarrierCreator(carrier));
  }, [carrier, isEditActive]);

  if (renderedCarrier === null) {
    return null;
  }

  return (
    <div
      key={carrier.id}
      className="border__listItem"
      style={{ display: "flex" }}
    >
      {isEditActive === false ? (
        renderedCarrier
      ) : (
        <>
          <div style={buttonContainerStyle}>
            <button
              onClick={() => {
                // MCN needs to be a string to run submit check
                const copy = { ...carrier };
                copy.mcn = copy.mcn.toString();
                setIsFormModalOpen(true);
                setCarrierToEdit(copy);
              }}
            >
              {" "}
              <CreateOutlinedIcon
                className="editBtn__img"
                style={{ fontSize: "16px" }}
              />
            </button>
            <DeleteButton onClick={handleDelete} isDeleting={isDeleting} />
          </div>
          {renderedCarrier}
        </>
      )}
    </div>
  );
}

DrawerCarriersListItem.propTypes = {
  carrier: PropTypes.object.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  setIsFormModalOpen: PropTypes.func.isRequired,
  setCarrierToEdit: PropTypes.func.isRequired
};
