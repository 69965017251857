import React, { useContext, useState, useEffect, useRef } from "react";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";
import { UserContext } from "../providers/UserProvider";
import Search from "../search/Search";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import logo from "../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg";
import ProfileImage from "../library/profile/ProfileImage";
import useProfileImage from "../../hooks/useProfileImage";
import HeaderMenu from "./HeaderMenu";
import HeaderButtonAdd from "./HeaderButtonAdd";
import HeaderButtonInvite from "./HeaderButtonInvite";
import HeaderButtonHome from "./HeaderButtonHome";
import "./Header.css";

export default function Header() {
  const { isLoggedIn } = useContext(UserContext);
  const {
    refreshHeader,
    setRefreshHeader,
    setIsAddModalOpen,
    setIsInviteModalOpen
  } = useContext(ApplicationStateContext);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [isAdmin, setIsAdmin] = useState(null);
  const profileImage = useProfileImage(currentUser);
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const menuAnchor = useRef();

  function handleAddClick() {
    setIsAddModalOpen(true);
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setIsAdmin(user?.admin);
    setCurrentUser(user);
    setRefreshHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshHeader, isLoggedIn, currentUser?.admin]);

  if (!isLoggedIn || !currentUser) {
    return null;
  }

  return (
    <div className="header">
      <div className="header__logo">
        <img src={logo} alt="Terralanes Logo" className="header__logo__img" />
        <div className="header__logo__title">Terralanes</div>
        <HeaderButtonHome />
      </div>
      <Search />
      <div className="header__user" ref={menuAnchor}>
        <ProfileImage
          size="medium"
          location="header"
          type="user"
          name={currentUser.fullName}
          image={profileImage}
        />
        <div className="header__user__txt">
          {currentUser.firstName} {currentUser.lastName}
        </div>
        <ExpandLessIcon
          style={{
            fontSize: "20px",
            transform: "scaleY(-1)",
            outline: "none"
          }}
          className="click"
          onClick={() => {
            setSettingsAnchor(menuAnchor.current);
          }}
        />
        <HeaderMenu
          settingsAnchor={settingsAnchor}
          setSettingsAnchor={setSettingsAnchor}
          isAdmin={isAdmin}
        />
        <div className="header__buttons">
          <HeaderButtonAdd setIsAddOpen={handleAddClick} />
          {isAdmin && (
            <HeaderButtonInvite setIsInviteOpen={setIsInviteModalOpen} />
          )}
        </div>
      </div>
    </div>
  );
}
