import React, { useContext, useEffect, useState } from "react";
import { numberWithCommas } from "../../../components/generic/Utils";
import { getColorForWorkflow } from "../../../components/generic/Utils";
import RateTag from "./RateTag";
import LoadsTag from "./LoadsTag";
import { ApplicationStateContext } from "../../../components/providers/ApplicationStateProvider";

export default function LaneDefaultTags({ lane }) {
  const { workflow } = useContext(ApplicationStateContext);
  const [spendType, setSpendType] = useState(0);
  const color = getColorForWorkflow(workflow);

  useEffect(() => {
    if (workflow === "owned") {
      setSpendType(lane.spend);
    } else if (workflow === "opportunities") {
      setSpendType(lane.opportunitySpend);
    } else if (workflow === "potential") {
      setSpendType(lane.potentialSpend);
    } else {
      setSpendType(0);
    }
  }, [lane, workflow]);

  return (
    <section className="drawer__column--2 drawer__column--right">
      <RateTag lane={lane} color={color} />
      <LoadsTag lane={lane} color={color} />
      <div className={`chip chip--${color}`}>
        <div className={`chip--${color}__top`}>SPEND</div>
        <div className={`chip--${color}__bottom`}>
          💳 ${numberWithCommas(spendType)}
        </div>
      </div>
    </section>
  );
}
