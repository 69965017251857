import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./Buttons.css";

export default function DeleteButton({ onClick, isDeleting }) {
  return (
    <button className="deleteBtn" onClick={onClick} disabled={isDeleting}>
      {!isDeleting ? (
        "\u00D7"
      ) : (
        <CircularProgress
          color="secondary"
          style={{
            width: "24px",
            height: "24px"
          }}
        />
      )}
    </button>
  );
}
