import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemCurrentRate({ item, updateMethod }) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"number"}
      valueIsCurrency
      updateMethod={updateMethod}
      nameOfValue="rate"
      title={"Current Rate"}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          This is the actual current rate this lane is moving for.
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemCurrentRate.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
