import React, { createContext, useContext, useState } from "react";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";
import { taggedTeammateToastMessages } from "../generic/ToastMessages";

export const TeammateContext = createContext();

const TeammateProvider = props => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const [taggedTeammates, setTaggedTeammates] = useState([]);

  async function getTaggedTeammatesForCustomer(customerId) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${apiUrl}/customer/${customerId}/teammates`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const teammates = await responseHandler({
        response,
        setAlertModal
      });

      setTaggedTeammates(teammates);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function addTaggedTeammateToCustomer(
    taggedTeammateCustomer,
    teammateName,
    customerName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/customer/teammates`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(taggedTeammateCustomer)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.addSuccess,
        toastSuccessParam: { teammateName, typeName: customerName },
        toastError: taggedTeammateToastMessages.addFailure,
        toastErrorParam: { teammateName, typeName: customerName },
        setAlertModal
      });

      await getTaggedTeammatesForCustomer(taggedTeammateCustomer.customerId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function deleteTaggedTeammateFromCustomer(
    taggedTeammateCustomer,
    teammateName,
    customerName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/customer/teammates`, {
        method: "DELETE",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(taggedTeammateCustomer)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.removeSuccess,
        toastSuccessParam: { teammateName, typeName: customerName },
        toastError: taggedTeammateToastMessages.removeFailure,
        toastErrorParam: { teammateName, typeName: customerName },
        setAlertModal
      });

      await getTaggedTeammatesForCustomer(taggedTeammateCustomer.customerId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  // (As of May 14, 2021): Did not refactor this as onboarding hasn't been revised yet
  async function addCustomerForUser(selectedCustomer) {
    const token = await getToken();
    const response = await fetch(`${apiUrl}/customer/teammates`, {
      method: "POST",
      headers: {
        Authorization: token
      },
      body: JSON.stringify(selectedCustomer)
    });
  }

  async function getTaggedTeammatesForLocation(locationId) {
    try {
      const token = await getToken();
      const response = await fetch(
        `${apiUrl}/location/${locationId}/teammates`,
        {
          method: "GET",
          headers: {
            Authorization: token
          }
        }
      );

      const teammates = await responseHandler({
        response,
        setAlertModal
      });

      setTaggedTeammates(teammates);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function addTaggedTeammateToLocation(
    newTaggedLocation,
    teammateName,
    locationName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/location/teammates`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(newTaggedLocation)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.addSuccess,
        toastSuccessParam: { teammateName, typeName: locationName },
        toastError: taggedTeammateToastMessages.addFailure,
        toastErrorParam: { teammateName, typeName: locationName },
        setAlertModal
      });

      await getTaggedTeammatesForLocation(newTaggedLocation.locationId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function deleteTaggedTeammateFromLocation(
    taggedLocation,
    teammateName,
    locationName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/location/teammates`, {
        method: "DELETE",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(taggedLocation)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.removeSuccess,
        toastSuccessParam: { teammateName, typeName: locationName },
        toastError: taggedTeammateToastMessages.removeFailure,
        toastErrorParam: { teammateName, typeName: locationName },
        setAlertModal
      });

      await getTaggedTeammatesForLocation(taggedLocation.locationId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function getTaggedTeammatesForLane(laneId) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/lane1/${laneId}/teammates`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const teammates = await responseHandler({ response, setAlertModal });

      setTaggedTeammates(teammates);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function addTaggedTeammateToLane(
    newTaggedLane,
    teammateName,
    laneName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/lane1/teammates`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(newTaggedLane)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.addSuccess,
        toastSuccessParam: { teammateName, typeName: laneName },
        toastError: taggedTeammateToastMessages.addFailure,
        toastErrorParam: { teammateName, typeName: laneName },
        setAlertModal
      });

      await getTaggedTeammatesForLane(newTaggedLane.laneId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function deleteTaggedTeammateFromLane(
    taggedLane,
    teammateName,
    laneName
  ) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/lane1/teammates`, {
        method: "DELETE",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(taggedLane)
      });

      await responseHandler({
        response,
        toastSuccess: taggedTeammateToastMessages.removeSuccess,
        toastSuccessParam: { teammateName, typeName: laneName },
        toastError: taggedTeammateToastMessages.removeFailure,
        toastErrorParam: { teammateName, typeName: laneName },
        setAlertModal
      });

      await getTaggedTeammatesForLane(taggedLane.laneId);
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  return (
    <TeammateContext.Provider
      value={{
        taggedTeammates,
        getTaggedTeammatesForCustomer,
        addTaggedTeammateToCustomer,
        deleteTaggedTeammateFromCustomer,
        getTaggedTeammatesForLocation,
        addTaggedTeammateToLocation,
        deleteTaggedTeammateFromLocation,
        getTaggedTeammatesForLane,
        addTaggedTeammateToLane,
        deleteTaggedTeammateFromLane,
        addCustomerForUser
      }}
    >
      {props.children}
    </TeammateContext.Provider>
  );
};

export default TeammateProvider;
