import React from "react";
import ButtonSolid from "../generic/buttons/ButtonSolid";

export default function FormAddWorkflowTutorialImageStep({
  text,
  image,
  imageAltText,
  imageWidth,
  setActiveForm,
  nextComponentStep
}) {
  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: "100%",
    margin: "2em"
  };

  const tipContainerStyle = {
    backgroundColor: "var(--lightBlue)",
    width: "fit-content",
    placeSelf: "self-start",
    padding: "10px",
    borderRadius: "7px"
  };

  const paragraphStyle = {
    color: "var(--lavender)",
    fontWeight: "500",
    margin: "0"
  };

  const spanStyle = {
    color: "var(--darkBlue)",
    fontWeight: "700"
  };

  return (
    <section style={containerStyle}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap"
        }}
      >
        <section style={tipContainerStyle}>
          <p style={paragraphStyle}>
            <span style={spanStyle}>{text}</span>
          </p>
        </section>

        <div
          style={{
            display: "flex",
            placeSelf: "center",
            width: "fit-content",
            height: "fit-content",
            margin: "1em 0em"
          }}
        >
          <img
            className={
              !imageWidth
                ? "formAddWorkflow__img--responsive"
                : "formAddWorkflow__img--responsive--vertical"
            }
            style={{
              width: !imageWidth ? "clamp(100px, 90vh, 830px)" : imageWidth,
              border: "solid",
              borderColor: "var(--lightGray)",
              minHeight: "200px"
            }}
            src={image}
            alt={imageAltText}
          />
        </div>
      </div>

      <ButtonSolid
        style={{
          padding: "1.4em",
          fontSize: "1em",
          width: "30%",
          placeSelf: "center"
        }}
        onClick={() => setActiveForm(nextComponentStep)}
        color="red"
      >
        <span style={{ fontWeight: "700" }}>Next</span>
      </ButtonSolid>
    </section>
  );
}

// NEED PROP TYPES
