import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../components/auth/Login";
import { UserContext } from "../components/providers/UserProvider";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import CurrentUserView from "./CurrentUserView";
import SettingsView from "./SettingsView";
import UserView from "./UserView";
import CustomerView from "./CustomerView";
import LocationView from "./LocationView";
import LaneView from "./LaneView";
// import TeamView from "./TeamView";
// import BrokerageView from "./BrokerageView";
import FormAddWorkflow from "../components/forms/FormAddWorkflow";
import FormInviteWorkflow from "../components/forms/FormInviteWorkflow.js";
import NotFoundView from "./NotFoundView";

export default function ApplicationViews() {
  const { isLoggedIn } = useContext(UserContext);
  const {
    isAddModalOpen,
    setIsAddModalOpen,
    isInviteModalOpen,
    setIsInviteModalOpen
  } = useContext(ApplicationStateContext);

  return (
    <>
      {isLoggedIn && (
        <>
          <FormAddWorkflow
            isModalOpen={isAddModalOpen}
            closeModal={setIsAddModalOpen}
          />
          <FormInviteWorkflow
            isModalOpen={isInviteModalOpen}
            closeModal={setIsInviteModalOpen}
          />
        </>
      )}
      <Switch>
        {
          // The key={window.location.pathname} re-mounts the component if you try to
          // go to the same page, but a different ID, ensuring data loads correctly
        }
        <Route path="/" exact key={window.location.pathname}>
          {isLoggedIn ? <CurrentUserView /> : <Redirect to="/login" />}
        </Route>

        <Route path="/user/:userId" key={window.location.pathname}>
          {isLoggedIn ? <UserView /> : <Redirect to="/login" />}
        </Route>

        <Route path="/customer/:customerId" key={window.location.pathname}>
          {isLoggedIn ? <CustomerView /> : <Redirect to="/login" />}
        </Route>

        <Route path="/location/:locationId" key={window.location.pathname}>
          {isLoggedIn ? <LocationView /> : <Redirect to="/login" />}
        </Route>

        <Route path="/lane/:laneId" key={window.location.pathname}>
          {isLoggedIn ? <LaneView /> : <Redirect to="/login" />}
        </Route>

        {
          // <Route path="/team/:teamId" key={window.location.pathname}>
          //   {isLoggedIn ? <TeamView /> : <Redirect to="/login" />}
          // </Route>
          // <Route path="/brokerage/:brokerageId" key={window.location.pathname}>
          //   {isLoggedIn ? <BrokerageView /> : <Redirect to="/login" />}
          // </Route>
        }

        <Route path="/settings" key={window.location.pathname}>
          {isLoggedIn ? <SettingsView /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login" key={window.location.pathname}>
          {isLoggedIn ? <Redirect to="/" /> : <Login />}
        </Route>

        <Route path="*" key={window.location.pathname}>
          <NotFoundView />
        </Route>
      </Switch>
    </>
  );
}
