import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import uploadBtn from "../../../images/Add Profile Image.png";
import { CircularProgress, Dialog } from "@material-ui/core";
import { Storage } from "aws-amplify";
import { UserContext } from "../../providers/UserProvider";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";
import { BrokerageContext } from "../../providers/BrokerageProvider";
import { CustomerContext } from "../../providers/CustomerProvider";

export default function UpdateImage({ sourceType, source }) {
  // this component will allow users to upload, change, or remove images for Users, Brokerages, or Customers

  const { updateUserProfile } = useContext(UserContext);
  const { updateBrokerage, updateUserFromCustomBrokerage } = useContext(BrokerageContext);
  const { updateCustomer } = useContext(CustomerContext);
  const { setRefreshHeader } = useContext(ApplicationStateContext);
  const [isDeleting, setIsDeleting] = useState(false);

  // Image Handlers
  const [image, setImage] = useState();

  useEffect(() => {
    async function handleExistingImage(source) {
      if (sourceType === "User") {
        if (source.profileImage) {
          const image = await Storage.get(source.profileImage);
          setImage(image);
        }
      } else if (sourceType === "Brokerage" || sourceType === "Customer") {
        if (source.logo) {
          const image = await Storage.get(source.logo);
          setImage(image);
        }
      }
    }

    handleExistingImage(source);
  }, [source, sourceType]);

  // Dialog Controls

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenAddingImage, setDialogOpenAddingImage] = useState(false);

  async function onSelectFile(e) {
    if (sourceType === "User") {
      const updatedUser = source;
      setDialogOpenAddingImage(true);
      const result = await Storage.put(
        `profileImage/${source.id}`,
        e.target.files[0]
      );
      updatedUser.profileImage = result.key;
      const user = await updateUserFromCustomBrokerage(updatedUser);
      // sessionStorage.setItem("user", JSON.stringify(user));
      const image = await Storage.get(updatedUser.profileImage);
      setImage(image);
      setDialogOpenAddingImage(false);
      // setRefreshHeader(true);
    } else if (sourceType === "Brokerage") {
      const updatedBrokerage = source;
      setDialogOpenAddingImage(true);
      const result = await Storage.put(
        `brokerage/${source.id}`,
        e.target.files[0]
      );
      updatedBrokerage.logo = result.key;
      await updateBrokerage(updatedBrokerage);
      const logo = await Storage.get(updatedBrokerage.logo);
      setImage(logo);
      setDialogOpenAddingImage(false);
    } else if (sourceType === "Customer") {
      const updatedCustomer = source;
      const result = await Storage.put(
        `customer/${source.id}`,
        e.target.files[0]
      );
      updatedCustomer.logo = result.key;
      await updateCustomer(updatedCustomer);
      const logo = await Storage.get(updatedCustomer.logo);
      setImage(logo);
    }
  }

  async function handleImageDelete() {
    setIsDeleting(true);
    if (sourceType === "User") {
      const updatedUser = source;
      updatedUser.profileImage = null;
      await Storage.remove(source.profileImage);
      const user = await updateUserFromCustomBrokerage(updatedUser);
      // sessionStorage.setItem("user", JSON.stringify(user));
      setImage();
      // setRefreshHeader(true);
      setDialogOpen(false);
      setIsDeleting(false);
    } else if (sourceType === "Brokerage") {
      const updatedBrokerage = source;
      updatedBrokerage.logo = null;
      await Storage.remove(source.logo);
      await updateBrokerage(updatedBrokerage);
      setImage();
      setDialogOpen(false);
      setIsDeleting(false);
    } else if (sourceType === "Customer") {
      const updatedCustomer = source;
      updatedCustomer.logo = null;
      await Storage.remove(source.logo);
      await updateBrokerage(updatedCustomer);
      setImage();
      setDialogOpen(false);
      setIsDeleting(false);
    }
  }

  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCancelClick = () => {
    setDialogOpen(false);
  };

  const imageStyle = {
    width: sourceType === "Customer" ? "42px" : "180px",
    height: sourceType === "Customer" ? "42px" : "180px"
  };

  const deleteStyle = {
    position: "relative",
    bottom: sourceType === "Customer" ? "15px" : "25px",
    left: sourceType === "Customer" ? "25px" : "125px"
  };

  return (
    <>
      {sourceType !== "Customer" && (
        <div className="settings__profile__photo">Photo</div>
      )}
      {image ? (
        <div>
          <img
            src={image}
            style={imageStyle}
            className="settings__profile__img"
          />
          <div
            className="delete click settings__profile__img__delete"
            onClick={handleDeleteClick}
            style={deleteStyle}
          >
            x
          </div>
        </div>
      ) : (
        <div>
          {dialogOpenAddingImage ? (
            <div className="profileImage__upload">
            <CircularProgress
              color="secondary"
              style={{ width: "100px", height: "100px", marginTop: "75px" }}
            /> 
            </div>
          ) : ( 
            <div className="profileImage__upload">
              <label
                htmlFor="profileImageUpload"
                className="profileImage__upload__label"
              >
                <img src={uploadBtn} className="click" style={imageStyle} />
              </label>
              <input
                type="file"
                id="profileImageUpload"
                onChange={onSelectFile}
                accept="image/*"
              />
          </div>
          )} 
        </div>
      )}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <div className="dialog settings__profile__dialog">
          <div className="dialog__title title mr--50">Remove Image?</div>
          <div className="dialog__btns">
            <div
              className="cancel dialog__btn click"
              onClick={handleCancelClick}
            >
              Cancel
            </div>
            {isDeleting ? (
              <CircularProgress
                color="secondary"
                style={{ width: "19px", height: "19px", marginRight: "20px" }}
              />
            ) : (
              <div
                className="save dialog__btn click"
                onClick={handleImageDelete}
              >
                Delete
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

UpdateImage.propTypes = {
  sourceType: PropTypes.string.isRequired,
  // User, Customer, or Brokerage
  source: PropTypes.object.isRequired
  // User, Customer or Brokerage object
};
