import React from 'react'
import TagOption from '../TagOption'

export default function Accessorials({ page, item}) {
    return (
        <div className='addTags__tagList'>
            <TagOption id={1} content={'Block & Brace'} page={page} item={item}/>
            <TagOption id={2} content={'Demurrage'} page={page} item={item}/>
            <TagOption id={3} content={'Detention'} page={page} item={item}/>
            <TagOption id={4} content={'Drop Trailer'} page={page} item={item}/>
            <TagOption id={5} content={'Labor'} page={page} item={item}/>
            <TagOption id={6} content={'Loading Charge'} page={page} item={item}/>
            <TagOption id={7} content={'Lumper'} page={page} item={item}/>
            <TagOption id={8} content={'Misc.'} page={page} item={item}/>
            <TagOption id={9} content={'Overdimensional'} page={page} item={item}/>
            <TagOption id={10} content={'Overweight'} page={page} item={item}/>
            <TagOption id={11} content={'Permits'} page={page} item={item}/>
            <TagOption id={12} content={'Pick & Pack'} page={page} item={item}/>
            <TagOption id={13} content={'Unloading Charge'} page={page} item={item}/>
            <TagOption id={14} content={'Weekend'} page={page} item={item}/>
        </div>
    )
}