import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import SettingsContentUpdateProfile from "../components/settings/SettingsContentUpdateProfile";
import SettingsContentUpdatePassword from "../components/settings/SettingsContentUpdatePassword";
import SettingsContentUpload from "../components/settings/SettingsContentUpload";
import ManageBrokerage from "../components/settings/brokerage/ManageBrokerage";
import SelectCompanyOption from "../components/settings/company/SelectCompanyOption";
import ManageUsers from "../components/settings/users/ManageUsers";
import ManageBilling from "../components/settings/billing/ManageBilling";
import SelectCompanyForUsersOption from "../components/settings/usersSA/SelectCompanyForUsersOption";
import EditUser from "../components/settings/usersSA/EditUser";
import "../components/settings/Settings.css";

export default function SettingsViewContent({ brokerage, setBrokerage }) {
  const { setting } = useParams();
  const [content, setContent] = useState(null);

  useEffect(() => {
    switch (setting) {
      case "profile":
        setContent(<SettingsContentUpdateProfile />);
        break;
      case "password":
        setContent(<SettingsContentUpdatePassword />);
        break;
      case "upload":
        setContent(<SettingsContentUpload />);
        break;
      case "brokerage":
        setContent(
          <ManageBrokerage brokerage={brokerage} setBrokerage={setBrokerage} />
        );
        break;
      case "company":
          setContent(
            <SelectCompanyOption />
          );
          break;
      case "usersSA":
          setContent(
            <SelectCompanyForUsersOption />
          );
          break;
      case "editUser":
          setContent(
            <EditUser />
          );
          break;
      case "createUser":
          setContent(
            <EditUser />
          );
          break;
      case "users":
        setContent(<ManageUsers />);
        break;
      case "billing":
        setContent(<ManageBilling brokerage={brokerage} />);
        break;
      default:
        setContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, brokerage]);

  if (content === null) {
    return null;
  }

  return <Container style={{width:'100%'}}>{content}</Container>;
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
