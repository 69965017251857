import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ApplicationViews from "./views/ApplicationViews";
import { UserProvider } from "./components/providers/UserProvider";
import LaneProvider from "./components/providers/LaneProvider";
import ApplicationStateProvider from "./components/providers/ApplicationStateProvider";
import CustomerProvider from "./components/providers/CustomerProvider";
import Header from "./components/header/Header";
import SearchProvider from "./components/providers/SearchProvider";
import LedgerProvider from "./components/providers/LedgerProvider";
import AlertProvider from "./components/providers/AlertProvider";
import Alert from "./components/alert/Alert";
import UploadProvider from "./components/providers/UploadProvider";
import LocationProvider from "./components/providers/LocationProvider";
import ContactProvider from "./components/providers/ContactProvider";
import TeammateProvider from "./components/providers/TeammateProvider";
import TagProvider from "./components/providers/TagProvider";
import TeamProvider from "./components/providers/TeamProvider";
import BrokerageProvider from "./components/providers/BrokerageProvider";
import BillingProvider from "./components/providers/BillingProvider";
import RegistrationProvider from "./components/providers/RegistrationProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
);

// This file holds the Router and Context Providers for the application

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        closeOnClick
        draggable
        autoClose={3000}
      />

      <Router>
        <ApplicationStateProvider>
          <AlertProvider>
            <UserProvider>
              <TeamProvider>
                <BrokerageProvider>
                  <LaneProvider>
                    <CustomerProvider>
                      <LocationProvider>
                        <ContactProvider>
                          <TagProvider>
                            <TeammateProvider>
                              <UploadProvider>
                                <SearchProvider>
                                  <LedgerProvider>
                                    <RegistrationProvider>
                                      <BillingProvider>
                                        <Elements stripe={stripePromise}>
                                          <Header />
                                          <ApplicationViews />
                                          <Alert />
                                        </Elements>
                                      </BillingProvider>
                                    </RegistrationProvider>
                                  </LedgerProvider>
                                </SearchProvider>
                              </UploadProvider>
                            </TeammateProvider>
                          </TagProvider>
                        </ContactProvider>
                      </LocationProvider>
                    </CustomerProvider>
                  </LaneProvider>
                </BrokerageProvider>
              </TeamProvider>
            </UserProvider>
          </AlertProvider>
        </ApplicationStateProvider>
      </Router>
    </>
  );
}

export default App;
