import React, { useContext, useState } from "react";
import { ApplicationStateContext } from "../components/providers/ApplicationStateProvider";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import CustomerBio from "../components/drawers/customer/CustomerBio";
import DrawerContacts from "../components/drawers/DrawerContacts";
import DrawerTeammateTaggedList from "../components/drawers/DrawerTeammateTaggedList";
import QuickTagsList from "../components/drawers/tags/QuickTagsList";
import LocationDefaultTags from "../components/drawers/tags/LocationDefaultTags";
import EditLocationInfo from "../components/drawers/customer/EditLocationInfo";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DrawerLanes from "../components/drawers/DrawerLanes";
import ButtonOutlined from "../components/generic/buttons/ButtonOutlined";
import DrawerToolbelt from "../components/drawers/DrawerToolbelt";
import TooltipPhone from "../components/library/tooltip/TooltipPhone";
import TooltipEmail from "../components/library/tooltip/TooltipEmail";
import DrawerHeaderSettingsButton from "../components/drawers/DrawerHeaderSettingsButton";

export default function LocationViewDrawerContent({
  location,
  lanes,
  spend,
  loadsPerMonth
}) {
  const history = useHistory();
  const { isDrawerOpen } = useContext(ApplicationStateContext);
  const [edit, setEdit] = useState(false); // controls EditCustomerInfo dialog state

  return (
    <>
      <header
        className={`drawer__upper mb--10 drawer__upper--${
          isDrawerOpen ? "open" : "close"
        }`}
      >
        <div className="drawer__top">
          <section className="drawer__column--2 drawer__noImage">
            <div className="drawer__row">
              <DrawerHeaderSettingsButton
                openEditModal={() => setEdit(true)}
                objectName={`${location.city}, ${location.state}`}
              />
              <div className="tag tag--red" style={{ margin: "0 0.5rem" }}>
                Location
              </div>
              {// TODO: instead of phone & email being able to submit as "", make them null
              location.phone === null &&
              location.email === null &&
              location.open === null &&
              location.close === null &&
              location.hoursType === null ? (
                <ButtonOutlined
                  isAdding
                  text={"Add Details"}
                  onClick={() => setEdit(true)}
                />
              ) : null}
              <EditLocationInfo
                location={location}
                open={edit}
                setOpen={setEdit}
              />
            </div>
            <div className="drawer__row">
              <Tooltip
                title={
                  <div className="drawer__tooltip">
                    <div className="drawer__tooltip__symbol">📍</div>
                    <div>
                      <div>{location.address}</div>
                      <div>
                        {location.city}, {location.state} {location.zipcode}
                      </div>
                    </div>
                  </div>
                }
                placement="right"
              >
                <div className="drawer__name border--dashed">
                  {location.city}, {location.state}
                </div>
              </Tooltip>
            </div>
            <div className="drawer__row">
              <div
                className="drawer__customerName click"
                onClick={() =>
                  history.push(
                    `/customer/${location?.CustomerLocation.Customer.id}`
                  )
                }
              >
                {location.CustomerLocation.Customer.displayName ||
                  location.CustomerLocation.Customer.name}
              </div>

              <TooltipPhone style={{ marginLeft: "5px" }}>
                {location.phoneExt
                  ? `${location.phone} x${location.phoneExt}`
                  : location.phone}
              </TooltipPhone>

              <TooltipEmail style={{ marginLeft: "5px" }}>
                {location.email}
              </TooltipEmail>

              <Tooltip
                title={
                  !location.open && !location.close && !location.hoursType
                    ? "No hours of operation entered."
                    : `${location.open || ""} - ${location.close ||
                        ""} ${location.hoursType || ""}`
                }
                placement="bottom-start"
              >
                <AccessTimeIcon
                  style={{
                    fontSize: "17px",
                    color: "#919191",
                    backgroundColor: "#EAEAEA",
                    borderRadius: "50%",
                    padding: "4px",
                    margin: "0 2px"
                  }}
                />
              </Tooltip>
            </div>
          </section>

          <LocationDefaultTags
            laneCount={lanes.length}
            volume={loadsPerMonth}
            spend={spend}
          />
        </div>
      </header>

      <section className="drawer__lower" style={{ display: "flex" }}>
        <div className="drawer__lower__top">
          <QuickTagsList page="location" item={location} />
        </div>
        <div className="drawer__lower__columns">
          <div className="drawer__lower__column drawer__lower__column--left">
            <DrawerToolbelt type={"location"} location={location} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--middle">
            <DrawerLanes view={"location"} />
          </div>
          <div className="drawer__lower__column drawer__lower__column--right">
            <DrawerTeammateTaggedList location={location} page="location" />
            <CustomerBio customer={location.CustomerLocation.Customer} />
            <DrawerContacts page="location" item={location} />
          </div>
        </div>
      </section>
    </>
  );
}
