import { format } from 'date-fns'
import React, { useState } from 'react'
import CurrentPlanOptions from './CurrentPlanOptions'

export default function CurrentPlan({ subscription, brokerage }) {
    const [showOptions, setShowOptions] = useState(false)

    const renewalUnix = subscription['current_period_end'] * 1000
    const renewalDate = format(new Date(renewalUnix), 'MMMM d, y')

    const handleUpdateClick = () => {
        setShowOptions(!showOptions)
    }

    return (
        <div className='manageBilling__card'>
            <div className='settings__card__title'>Current Plan</div>
            <div className='currentPlan'>
                <div className='manageBilling__left'>
                    <div className='currentPlan__details'>Pro Plan with {subscription.items.data[0].quantity} users</div>
                    <div>Bills at ${subscription.plan.interval === 'month' ? ((subscription.quantity - 1) * 50) : ((subscription.quantity - 1) * 480)}.00 USD per {subscription.items.data[0].price.recurring.interval}</div>
                    <div>Renews on {renewalDate}</div>
                </div>
                <div className='manageBilling__right'>
                    <div className='settings__teams__header__btn btn__save' onClick={handleUpdateClick}>Update</div>
                </div>
            </div>
            {showOptions && <CurrentPlanOptions brokerage={brokerage} subscription={subscription} />}
        </div>
    )
}