import React, { createContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

export const ApplicationStateContext = createContext()

const ApplicationStateProvider = (props) => {
    //LOGIN
    const [loginComponent, setLoginComponent] = useState('login') //determines which login component is displayed
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [verificationCode, setVerificationCode] = useState('') //holds the verification code for a password reset
    const [cognitoUser, setCognitoUser] = useState()

    //RESET LOGIN CREDENTIALS ON COMPONENT CHANGE
    useEffect(() => {
        setEmail('')
        setPassword('')
    }, [loginComponent])

    //CREATE PROFILE
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [role, setRole] = useState('')
    const [team, setTeam] = useState('')


    // TOOLBELT
    const [editingToolbeltCount, setEditingToolbeltCount] = useState(0)
    const [isSubmittingToolbelt, setIsSubmittingToolbelt] = useState(false)
    const [triggerToolbeltSave, setTriggerToolbeltSave] = useState(false)
    const [clickedToolbeltTitle, setClickedToolbeltTitle] = useState("")
    const [isToolbeltCancelButtonClicked, setIsToolbeltCancelButtonClicked] = useState(false) 

    //REFRESH HEADER
    const [refreshHeader, setRefreshHeader] = useState(false)

    //LAYOUT
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    // Don't set a default workflow; otherwise for a split second it's the default
    // then the correct param. it happens so quickly the map occasionally shows incorrect layer
    const [workflow, setWorkflow] = useState('') 

    //-----------------------------

    const { pathname, search } = useLocation()
    const history = useHistory()

    const checkPathandParams = () => {
        let drawerParam
        let workflowParam
        if (search && search.includes('w=') && search.includes('d=')) {
            const drawerParamIndex = search.indexOf('d=')
            const workflowParamIndex = search.indexOf('&w=')
            drawerParam = JSON.parse(search.substring(drawerParamIndex + 2, workflowParamIndex))
            workflowParam = search.substring(workflowParamIndex + 3)
        }
        else {
            drawerParam = false
            workflowParam = 'opportunities'
        }
        return {
            d: drawerParam,
            w: workflowParam
        }
    }

    // INITIAL STATE
    const setInitialStateForParams = () => {
        const initialParams = checkPathandParams()    
        setWorkflow(initialParams.w)
        setIsDrawerOpen(initialParams.d)
    }

    useEffect(() => {
        setInitialStateForParams()
    }, [])

    // UPDATING PARAMS

    const updateParamsOnStateChange = () => {
        const paths = pathname.split('/')
        if (paths.includes('settings') || paths.includes('login')) {
            return
        }
        const params = new URLSearchParams()
        params.append("d", isDrawerOpen)
        params.append("w", workflow)
        history.replace({ search: params.toString() })
    }

    useEffect(() => {
        updateParamsOnStateChange()

    }, [workflow, isDrawerOpen, pathname])

    //-----------------------------
  
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

    const [refreshOnAdd, setRefreshOnAdd] = useState(false)
    
    function triggerRefreshOnDataAdd() {
        setRefreshOnAdd(!refreshOnAdd)
    }


    return (
        <ApplicationStateContext.Provider value={{
            loginComponent, setLoginComponent, email, setEmail, password, setPassword, verificationCode, setVerificationCode,
            cognitoUser, setCognitoUser, firstName, setFirstName, lastName, setLastName, phoneNumber, setPhoneNumber,
            role, setRole, team, setTeam, refreshHeader, setRefreshHeader, isDrawerOpen, setIsDrawerOpen, workflow, setWorkflow, 
            isAddModalOpen, setIsAddModalOpen, isInviteModalOpen, setIsInviteModalOpen, refreshOnAdd, triggerRefreshOnDataAdd,
            editingToolbeltCount, setEditingToolbeltCount, isSubmittingToolbelt, setIsSubmittingToolbelt, triggerToolbeltSave, setTriggerToolbeltSave,
            clickedToolbeltTitle, setClickedToolbeltTitle, isToolbeltCancelButtonClicked, setIsToolbeltCancelButtonClicked
        }}>
            {props.children}
        </ApplicationStateContext.Provider>
    );
}

export default ApplicationStateProvider