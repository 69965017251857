import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function DrawerContactsFormSearchItem({
  contact,
  handleSearchSelect
}) {
  return (
    <>
      <Border />
      <ResultButton type="button" onClick={() => handleSearchSelect(contact)}>
        <div
          className="secondary__tag tag tag--purple"
          style={{ fontSize: "0.7em" }}
        >
          Level {contact.level}
        </div>
        <StyledDiv>
          {contact.firstName} {contact.lastName}
        </StyledDiv>
        {contact.title === "" ? null : (
          <StyledDiv>
            Title: <StyledSpan>{contact.title}</StyledSpan>
          </StyledDiv>
        )}
        {contact.phone === "" ? null : (
          <StyledDiv>
            Phone: <StyledSpan>{contact.phone}</StyledSpan>
          </StyledDiv>
        )}
        {contact.email === "" ? null : (
          <StyledDiv>
            Email: <StyledSpan> {contact.email}</StyledSpan>
          </StyledDiv>
        )}
      </ResultButton>
    </>
  );
}

DrawerContactsFormSearchItem.propTypes = {
  contact: PropTypes.object.isRequired,
  handleSearchSelect: PropTypes.func.isRequired
};

const Border = styled.hr`
  margin: 0;
  border: solid;
  border-color: var(--lightGray);
  border-bottom: none;
  border-width: 2px;
`;

const ResultButton = styled.button`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0.8em;
  font-size: 1em;
  transition: 0.15s;

  &:hover {
    background-color: var(--lightGray);
  }
`;

const StyledDiv = styled.div`
  font-weight: 600;
`;

const StyledSpan = styled.span`
  font-weight: 400;
`;
