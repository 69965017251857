import React from "react";
import PropTypes from "prop-types";
import IconCheck from "../icons/IconCheck";
import ButtonSolid from "../generic/buttons/ButtonSolid";
import ButtonOutlined from "../generic/buttons/ButtonOutlined";

export default function FormAddWorkflowSuccess({
  mainText,
  buttonText,
  goToAdded,
  goToAddLane
}) {
  const containerStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    marginBottom: "1em"
  };

  const greenCircleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column nowrap",
    backgroundColor: "var(--darkGreen)",
    height: "130px",
    width: "130px",
    borderRadius: "50%"
  };

  const textStyle = {
    fontSize: "1.4em",
    fontWeight: "900",
    marginTop: "1em",
    marginBottom: "0.75em",
    padding: "0"
  };

  const buttonAddStyle = {
    marginTop: "1em",
    fontSize: "0.85em",
    height: "2.6em",
    width: "10em"
  };

  return (
    <section style={containerStyle}>
      <div style={greenCircleStyle}>
        <IconCheck />
      </div>
      <p style={textStyle}>{mainText}</p>

      <ButtonSolid
        color="red"
        style={buttonAddStyle}
        onClick={() => goToAddLane()}
      >
        {mainText.includes("Customer") || mainText.includes("Location")
          ? "+ Add Lane"
          : "+ Add Another"}
      </ButtonSolid>

      <ButtonOutlined
        text={buttonText}
        style={buttonAddStyle}
        color="red"
        onClick={() => goToAdded()}
      />
    </section>
  );
}

FormAddWorkflowSuccess.propTypes = {
  mainText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  goToAdded: PropTypes.func.isRequired,
  goToAddLane: PropTypes.func
};
