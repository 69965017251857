import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ApplicationStateContext } from "../../providers/ApplicationStateProvider";

// To use a ref as a prop, need to use React.forwardRef
const TooltipBubble = React.forwardRef((props, ref) => {
  const { isDrawerOpen } = useContext(ApplicationStateContext);

  TooltipBubble.defaultProps = {
    position: "right"
  };

  function calculateTopLeftRadius() {
    if (!isDrawerOpen && props.position === "right") {
      return "15px";
    } else if (!isDrawerOpen && props.position === "left") {
      return "0px";
    }
    if (isDrawerOpen && props.position === "right") {
      return "0px";
    } else {
      return "15px";
    }
  }

  const bubbleContainerStyle = {
    zIndex: 1500,
    marginLeft: "10px",
    cursor: "default",
    position: "relative",
    left: "5px",
    bottom: !isDrawerOpen ? "65px" : null
  };

  const bubbleStyle = {
    position: "absolute",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    backgroundColor: "#242424",
    color: "#fff",
    textAlign: "left",
    padding: "11px 14px 13px 16px",
    fontSize: "0.85rem",
    minWidth: "175px",
    maxWidth: "300px",
    wordWrap: "break-word",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    fontWeight: "500",
    lineHeight: "1.4em",
    borderRadius: "15px",
    borderTopRightRadius: props.position === "right" ? "15px" : "0px",
    borderTopLeftRadius: calculateTopLeftRadius(),
    borderBottomLeftRadius: !isDrawerOpen ? "0px" : "15px",
    boxShadow: "3px 5px 10px rgba(0, 0, 0, 0.2)",
    opacity: props.isVisible ? 1 : 0, // Content active state based on style's opacity
    pointerEvents: props.isVisible ? "auto" : "none",
    right: props.position === "right" ? "" : "1.5rem",
    top: props.variant === "tooltip__blueOutline" ? "0.8em" : "",
    transition: "opacity 0.15s"
  };

  return (
    <section style={bubbleContainerStyle} ref={ref}>
      <div style={bubbleStyle}>{props.children}</div>
    </section>
  );
});

TooltipBubble.propTypes = {
  props: PropTypes.shape({
    ref: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    position: PropTypes.string, // 'left', 'right' (decides which side the bubble should pop-out on)
    variant: PropTypes.string
    // Possible variants:
    // tooltip__smallCircle
    // tooltip__blueOutline
  })
};

export default TooltipBubble;
