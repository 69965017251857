import React from "react";

export default function IconGear() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78347 10.8C7.04086 10.8 6.32867 10.505 5.80357 9.9799C5.27846 9.4548 4.98347 8.74261 4.98347 8C4.98347 7.25739 5.27846 6.5452 5.80357 6.0201C6.32867 5.495 7.04086 5.2 7.78347 5.2C8.52607 5.2 9.23826 5.495 9.76336 6.0201C10.2885 6.5452 10.5835 7.25739 10.5835 8C10.5835 8.74261 10.2885 9.4548 9.76336 9.9799C9.23826 10.505 8.52607 10.8 7.78347 10.8ZM13.7275 8.776C13.7595 8.52 13.7835 8.264 13.7835 8C13.7835 7.736 13.7595 7.472 13.7275 7.2L15.4155 5.896C15.5675 5.776 15.6075 5.56 15.5115 5.384L13.9115 2.616C13.8155 2.44 13.5995 2.368 13.4235 2.44L11.4315 3.24C11.0155 2.928 10.5835 2.656 10.0795 2.456L9.78347 0.336C9.75147 0.144 9.58347 0 9.38347 0H6.18347C5.98347 0 5.81547 0.144 5.78347 0.336L5.48747 2.456C4.98347 2.656 4.55147 2.928 4.13547 3.24L2.14347 2.44C1.96747 2.368 1.75147 2.44 1.65547 2.616L0.0554653 5.384C-0.0485347 5.56 -0.000534642 5.776 0.151465 5.896L1.83947 7.2C1.80747 7.472 1.78347 7.736 1.78347 8C1.78347 8.264 1.80747 8.52 1.83947 8.776L0.151465 10.104C-0.000534642 10.224 -0.0485347 10.44 0.0554653 10.616L1.65547 13.384C1.75147 13.56 1.96747 13.624 2.14347 13.56L4.13547 12.752C4.55147 13.072 4.98347 13.344 5.48747 13.544L5.78347 15.664C5.81547 15.856 5.98347 16 6.18347 16H9.38347C9.58347 16 9.75147 15.856 9.78347 15.664L10.0795 13.544C10.5835 13.336 11.0155 13.072 11.4315 12.752L13.4235 13.56C13.5995 13.624 13.8155 13.56 13.9115 13.384L15.5115 10.616C15.6075 10.44 15.5675 10.224 15.4155 10.104L13.7275 8.776Z"
        fill="#919191"
      />
    </svg>
  );
}
