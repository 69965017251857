import React, { useContext, useEffect, useState } from 'react'
import { BillingContext } from '../../providers/BillingProvider'
import CurrentPlan from './CurrentPlan'
import PaymentHistory from './PaymentHistory'
import PaymentInfo from './PaymentInfo'

export default function ManageBilling({ brokerage }) {
    const { getCustomerBillingDetails } = useContext(BillingContext)
    const [subscription, setSubscription] = useState()
    const [paymentMethod, setPaymentMethod] = useState()
    const [invoices, setInvoices] = useState()

    async function retreiveCustomerBillingDetails() {
        const { subscription, invoices, paymentMethods } = await getCustomerBillingDetails()
        setSubscription(subscription)
        setPaymentMethod(paymentMethods)
        setInvoices(invoices)
    }

    useEffect(() => {
        retreiveCustomerBillingDetails()
    }, [])

    return (
        <div className='manageBilling'>
            {(brokerage && !brokerage.stripeSubscriptionId) &&
                <div className='manageBilling__card' style={{padding: '30px', textAlign: 'center', lineHeight: '22px'}}>
                    You are currently on a free plan! Revisit this section after you invite more users to review your billing information.
                </div>
            }
            {subscription && <CurrentPlan subscription={subscription} brokerage={brokerage} />}
            {paymentMethod && <PaymentInfo paymentMethod={paymentMethod} brokerage={brokerage} subscription={subscription} retreiveCustomerBillingDetails={retreiveCustomerBillingDetails} />}
            {invoices && <PaymentHistory invoices={invoices} />}
        </div>
    )
}