export function numberWithCommas(x) {
  if (x !== undefined && x !== null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function formatPhoneNumber(contact) {
  if (contact.phone) {
    return contact.phoneExt
      ? `${contact.phone} x${contact.phoneExt}`
      : contact.phone;
  } else {
    return "No phone number listed";
  }
}

export function getColorForWorkflow(workflow) {
  if (workflow === "owned") {
    return "blue";
  }
  if (workflow === "opportunities") {
    return "green";
  }
  if (workflow === "potential") {
    return "yellow";
  }
}

export function getVolumeKey(workflow) {
  if (workflow === "owned") {
    return "currentVolume";
  }
  if (workflow === "opportunities") {
    return "opportunityVolume";
  }
  if (workflow === "potential") {
    return "potentialVolume";
  }
}

export function checkForNull(value) {
  if (value === null) {
    return "";
  } else if (typeof value === "string") {
    return value;
  } else if (typeof value === "number") {
    return numberWithCommas(value);
  } else {
    return JSON.stringify(value);
  }
}

export function getVolumeKeyForWorkflow(workflow) {
  if (workflow === "owned") {
    return "currentVolume";
  }
  if (workflow === "opportunities") {
    return "opportunityVolume";
  }
  if (workflow === "potential") {
    return "potentialVolume";
  }
}
