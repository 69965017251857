import React, { createContext, useContext, useState } from "react";
import { UserContext } from "./UserProvider";
import { AlertContext } from "./AlertProvider";
import responseHandler from "./utils/responseHandler";
import errorHandler from "./utils/errorHandler";
import { teamToastMessages } from "../generic/ToastMessages";

export const TeamContext = createContext();

const TeamProvider = props => {
  const { getToken } = useContext(UserContext);
  const { setAlertModal } = useContext(AlertContext);
  const apiUrl = process.env.REACT_APP_API_URL + "/team1";

  async function getTeam(teamId) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${teamId}`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const team = await responseHandler({
        response,
        setAlertModal
      });

      return team;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function getTeammatesForTeam(teamId) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${teamId}/teammates`, {
        method: "GET",
        headers: {
          Authorization: token
        }
      });

      const teammates = responseHandler({
        response,
        setAlertModal
      });

      return teammates;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function addTeam(team) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(team)
      });

      const newTeam = await responseHandler({
        response,
        toastSuccess: teamToastMessages.addSuccess,
        toastSuccessParam: team.name,
        toastError: teamToastMessages.addFailure,
        toastErrorParam: team.name,
        setAlertModal
      });

      return newTeam.id;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return null;
    }
  }

  async function updateTeam(team) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${team.id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(team)
      });

      await responseHandler({
        response,
        toastSuccess: teamToastMessages.updateSuccess,
        toastSuccessParam: team.name,
        toastError: teamToastMessages.updateFailure,
        toastErrorParam: team.name,
        setAlertModal
      });

      return true;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return false;
    }
  }

  async function deleteTeam(team) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/${team.id}`, {
        method: "DELETE",
        headers: {
          Authorization: token
        }
      });

      await responseHandler({
        response,
        toastSuccess: teamToastMessages.removeSuccess,
        toastSuccessParam: team.name,
        toastError: teamToastMessages.removeFailure,
        toastErrorParam: team.name,
        setAlertModal
      });

      return true;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return false;
    }
  }

  async function addTeammate(body, isMultiple) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/teammate`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      if (isMultiple) {
        await responseHandler({ response, setAlertModal });
      } else {
        await responseHandler({
          response,
          toastSuccess: teamToastMessages.addTeammateSuccess,
          toastError: teamToastMessages.addTeammateFailure,
          setAlertModal
        });
      }
    } catch (error) {
      errorHandler(error, setAlertModal);
    }
  }

  async function removeTeammate(user) {
    try {
      const token = await getToken();
      const response = await fetch(`${apiUrl}/teammate/${user.id}`, {
        method: "DELETE",
        headers: {
          Authorization: token
        }
      });

      const FULL_NAME = `${user.firstName} ${user.lastName}`;

      await responseHandler({
        response,
        toastSuccess: teamToastMessages.removeTeammateSuccess,
        toastSuccessParam: FULL_NAME,
        toastError: teamToastMessages.removeTeammateFailure,
        toastErrorParam: FULL_NAME,
        setAlertModal
      });

      return true;
    } catch (error) {
      errorHandler(error, setAlertModal);
      return false;
    }
  }

  return (
    <TeamContext.Provider
      value={{
        getTeam,
        addTeam,
        updateTeam,
        deleteTeam,
        getTeammatesForTeam,
        addTeammate,
        removeTeammate
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
