import React, { useContext, useEffect, useState } from 'react'
import logo from '../../images/terralanes-logo-logistics-visualization-software-nashville-tn@2x.svg'
import btn from '../../images/Add Profile Image.png'
import { Card } from "@material-ui/core";
import { ApplicationStateContext } from '../providers/ApplicationStateProvider';
import { UserContext } from '../providers/UserProvider';
import Storage from '@aws-amplify/storage'

const AddProfileImage = () => {
    const { firstName, lastName, phoneNumber, role, team } = useContext(ApplicationStateContext)
    const { updateUserProfile, setIsLoggedIn } = useContext(UserContext)
    let unconfirmedUser = JSON.parse(sessionStorage.getItem('incompleteUser'))
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory whenever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    async function handleSubmit() {
        try {
            unconfirmedUser.firstName = firstName
            unconfirmedUser.lastName = lastName
            unconfirmedUser.phone = phoneNumber
            unconfirmedUser.title = role
            unconfirmedUser.teamId = team
            
            if(selectedFile) {
                const result = await Storage.put(`profileImage/${unconfirmedUser.username}`, selectedFile)
                unconfirmedUser.profileImage = result.key
            }
            const updatedUser = await updateUserProfile(unconfirmedUser)
            sessionStorage.clear()
            sessionStorage.setItem('user', JSON.stringify(updatedUser))
            setIsLoggedIn(true)
        }
        catch (error) {
            window.alert(error)
        }
    }

    return (
        <div className='auth'>
            <div className='auth__header'>
                    <img src={logo} alt='Terralanes Logo' className='auth__header__img' />
                    <div className='auth__header__title'>Terralanes</div>
            </div>
            <div className='auth__form'>
                <Card className='auth__form__card' elevation={0}>
                    <div className='auth__form__title'>Add a profile photo</div>
                    <div className='auth__form__field profileImage__upload'>
                        <label htmlFor='profileImageUpload' className='profileImage__upload__label'>
                            {selectedFile ?  <img src={preview} className='profileImage__upload__prev'/> : <img src={btn} className='profileImage__upload__prev'/>}
                        </label>
                        <input type='file' id='profileImageUpload' onChange={onSelectFile} accept="image/*"/>
                    </div>
                    <div className='auth__form__field'>
                        <button className='auth__form__submit btn btn-enabled' onClick={() => handleSubmit()}>{selectedFile ? 'Submit': 'Submit without photo'}</button>
                    </div>
                    <hr className='auth__form__divider'/>
                    <div className='auth__form__subtext'>
                        <div className='auth__form__subtext__text' >Contact your company's Terralanes manager</div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default AddProfileImage