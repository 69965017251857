// responseHandler takes responses from database and:
// 1. checks status codes
// 2. shows notifications
// 3. returns data or throws errors
// NOTE: Can add specific actions for specific status codes

// TESTING (two ways)
// 1. In the response key:value pair, write "response: {status: 400}" (or whatever case you want to test)
// 2. In the backend, change response code value

function checkForToast(toastMessage, paramItem) {
  if (toastMessage) {
    toastMessage(paramItem && paramItem);
  }
}

export default async function responseHandler({
  response,
  toastSuccess,
  toastSuccessParam,
  toastError,
  toastErrorParam,
  setAlertModal // must pass in from provider. Non-React functions can't import hooks
}) {
  const statusCode = response?.status;

  const fetchError = {
    error: "Error on fetch",
    statusCode
  };

  switch (statusCode) {
    case 200:
    case 201:
      checkForToast(toastSuccess, toastSuccessParam);
      return await response.json();
    case 204: // 204s return no content; no response to json()
      checkForToast(toastSuccess, toastSuccessParam);
      break;

    // If we need more precise toast notifications, can add here
    case 400:
    case 401:
    case 403:
    case 404:
    case 409:
      checkForToast(toastError, toastErrorParam);
      throw fetchError;

    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      setAlertModal(`${statusCode} - ${response?.statusText}`);
      throw fetchError;

    default:
      setAlertModal(`${statusCode} - ${response?.statusText}`);
      throw { error: "No case in switch for this status code", statusCode };
  }
}
