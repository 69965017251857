import React, { useState, useContext } from 'react'
import { AccountCircleOutlined } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { LedgerContext } from '../../../providers/LedgerProvider';
import TeaxtareaAutosize from 'react-textarea-autosize'
import ProfileImage from '../../../library/profile/ProfileImage'

export default function MyMessage({ message, profileImage, setMessages, formatDate, setFilterTerms }) {
    const currentUser = JSON.parse(sessionStorage.getItem("user"))
    const { deleteMessage, editMessage } = useContext(LedgerContext)
    const [deleted, setDeleted] = useState(false)
    const [edit, setEdit] = useState(false)
    const [showTools, setShowTools] = useState(false)
    const [editedText, setEditedText] = useState(message.content)

    const handleOver = () => {
        if(message.User.id === currentUser.id) {
            setShowTools(true)
        }
    }

    const handleLeave = () => {
        if(message.User.id === currentUser.id) {
            setShowTools(false)
        }
    }

    const handleDeleteClick = () => {
        setDeleted(true)
        setFilterTerms('')
        deleteMessage(message)
            .then(ledger => setMessages(ledger.Messages))
    }

    const handleEditClick = () => {
        if(message.User.id === currentUser.id) {
            setEdit(!edit)
        }
    }

    const handleEditKeyDown = (e) => {
        if(e.keyCode === 13) {
            message.content = editedText
            editMessage(message)
            setEdit(false)
        }
    }

    return (
        <div className='message--me' onMouseEnter={handleOver} onMouseMove={handleOver} onMouseLeave={handleLeave}>
            <div className='message__top--me'>
                <div className={deleted ? 'message__bubble--me message--delete' : 'message__bubble--me'}>
                    <div className='message__text'>
                        {edit
                            ? <TeaxtareaAutosize
                                className='message__text--edit'
                                value={editedText} 
                                onChange={(e) => setEditedText(e.target.value)} 
                                onKeyDown={handleEditKeyDown} />
                            : message.content
                        }
                    </div>
                    <div className='message__info'>
                        <div className='message__name--me'>
                            {message.User.firstName} {message.User.lastName}
                        </div>
                        <div className='message__tag tag tag--blue'>You</div>
                    </div>
                </div>
                <div className='message__poster'>
                {
                    showTools &&
                        <>
                        <CreateOutlinedIcon className='message__tool' style={{fontSize: '19px'}} onClick={handleEditClick} />
                        <DeleteOutlineIcon className='message__tool' style={{fontSize: '19px'}} onClick={handleDeleteClick} />
                        </>
                }

                <ProfileImage
                    size="small"
                    location="ledger"
                    type="teammate"
                    name={message.User.firstName}
                    image={profileImage}
                    icon={message.User?.Team?.icon}
                />

                </div>
            </div>
            <div className='message__bottom'>
                <div className='message__timestamp--me'>{formatDate(message.createdAt)}</div>
            </div>
        </div>
    )
}