import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";

export default function DrawerToolbeltItemPainPoints({ item, updateMethod }) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"multiline"}
      updateMethod={updateMethod}
      nameOfValue="painPoints"
      title={"Pain Points"}
      placeholder={"Enter Pain Points..."}
    />
  );
}

DrawerToolbeltItemPainPoints.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
