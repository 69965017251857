import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function DrawerWorkflowTab(props) {
  return (
    <Tab
      tabColor={props.tabColor}
      textColor={props.textColor}
      isActive={props.isActive}
      isDisabled={props.isDisabled}
      disabled={props.isDisabled}
      onClick={() => props.handleClickEvent()}
    >
      <span>{props.children}</span>
    </Tab>
  );
}

DrawerWorkflowTab.propTypes = {
  props: PropTypes.shape({
    tabColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    handleClickEvent: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired
  })
};

const Tab = styled.button`
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0 4px;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 0.9em;
  transition: opacity 0.15s;
  color: ${props =>
    props.isActive
      ? props.textColor
        ? props.textColor
        : "white"
      : "var(--black)"};
  pointer-events: ${props => {
    if (props.isDisabled) {
      return "none";
    }
    if (props.isActive) {
      return "none";
    }
    return "all";
  }};
  background-color: ${props => {
    if (props.isActive) {
      return props.tabColor;
    } else {
      return "white";
    }
  }};
  opacity: ${props => {
    if (props.isDisabled & !props.isActive) {
      return 0.25;
    }
    if (props.isActive === false) {
      return 0.5;
    } else if (props.isActive === true) {
      return 1;
    }
  }};

  &:hover {
    opacity: ${props => (props.isActive ? 1 : 0.65)};
    cursor: ${props => (props.isActive ? "default" : "pointer")};
  }

  &:active {
    opacity: ${props => (props.isActive ? 1 : 0.75)};
  }
`;
