import React, { useContext, useEffect, useState } from 'react'
import BillingCycleSelect from './BillingCycleSelect'
import './UpgradeAccount.css'
import FormInviteUsersInputs from '../forms/FormInviteWorkflowInputs'
import FormModalFooterBidirectional from '../forms/FormModalFooterBidirectional'
import PaymentMethodCollect from './PaymentMethodCollect'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ReviewPlan from './ReviewPlan'
import { BillingContext } from '../providers/BillingProvider'
import { toast } from 'react-toastify'
import { RegistrationContext } from '../providers/RegistrationProvider'
import { clearStorage } from 'mapbox-gl'

const getSteps = () => {
  return ['Select Billing Cycle', 'Add Payment Info', 'Review Plan']
}

// CARD ELEMENT
const cardElementOptions = {
  style: {
    base: {
      fontFamily: 'Helvetica Neue',
      fontSize: '16px',
      fontWeight: '400',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      }
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const CardInput = ({ onChange, display }) => (
  <div style={{ margin: '25px 30px 0px 30px', display: display ? '' : 'none' }}>
    <div className='input__title'>Credit Card</div>
    <div className='stripeInput'>
      <CardElement options={cardElementOptions} onChange={onChange} />
    </div>
  </div>
)

export default function UpgradeAccountWorkflow({ brokerage, existingUsers, activeModal, setActiveModal, closeModal }) {
  const { createStripeCustomer, createStripeSubscription } = useContext(BillingContext)
  const { sendInvitesToNewUsers } = useContext(RegistrationContext)
  const stripe = useStripe()
  const elements = useElements()
  const [activeStep, setActiveStep] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const steps = getSteps()
  const [billingCycle, setBillingCycle] = useState('monthly')
  const stagedInvites = JSON.parse(sessionStorage.getItem('stagedInvites'))
  const [paymentInfoAddress, setPaymentInfoAddress] = useState({
    cardholder: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    hasValidCard: false
  }) 

  function handleControlledInputChange(e) {
    const updatedAddress = { ...paymentInfoAddress };
    updatedAddress[e.target.name] = e.target.value;
    setPaymentInfoAddress(updatedAddress);
  }

  function handleCardInputChange(e) {
    const updatedAddress = { ...paymentInfoAddress };
    updatedAddress.hasValidCard = e.complete;
    setPaymentInfoAddress(updatedAddress);
  }

  useEffect(() => {
    if (isSubmitting) {
      setIsActive(false)
    }
    else if (paymentInfoAddress.address === '' || paymentInfoAddress.city === '' || paymentInfoAddress.state === '' || paymentInfoAddress.cardholder === '' || paymentInfoAddress.email === '' || paymentInfoAddress.hasValidCard === false) {
      setIsActive(false)
    }
    else {
      setIsActive(true)
    }
  }, [paymentInfoAddress])

  const stepDataSelection = {
    modalWidth: "45em",
    modalHeight: "30em",
    hasHeader: true,
    hasHeaderCloseBtn: true,
    headerText: "Invite Users",
    headerIcon: "",
    isSubmitting: false,
    content: (
      <FormInviteUsersInputs activeModal={activeModal} setActiveModal={setActiveModal} closeModal={closeModal} brokerage={brokerage} existingUsers={existingUsers} />
    )
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BillingCycleSelect brokerage={brokerage} billingCycle={billingCycle} setBillingCycle={setBillingCycle} numberOfUsers={stagedInvites.length} />
      case 1:
        return <PaymentMethodCollect CardInput={CardInput} handleControlledInputChange={handleControlledInputChange} paymentInfoAddress={paymentInfoAddress} setPaymentInfoAddress={setPaymentInfoAddress} />;
      case 2:
        return <ReviewPlan stagedInvites={stagedInvites} billingCycle={billingCycle} brokerage={brokerage} />;
      default:
        return 'Unknown step';
    }
  }

  const handlePreviousClick = () => {
    if (activeStep > 0 && activeStep <= 2) {
      setActiveStep(activeStep - 1)
    }
    else if (activeStep === 0) {
      setActiveModal(stepDataSelection)
    }
  }

  async function handleAdvanceClick() {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1)
    }
    else if (activeStep === 2) {
      await handleSubmit()
    }
  }

  async function handleSubmit() {
    try {
      setIsSubmitting(true)

      //------------------------
      // TO DO: Differentiate processes if payment method already exists
      //------------------------

      const billingAddress = {
        line1: paymentInfoAddress.address,
        line2: paymentInfoAddress.address2,
        city: paymentInfoAddress.city,
        state: paymentInfoAddress.state
      }



      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        console.log("STRIPE HAS NOT LOADED")
        return
      }

      let customer
      if (!brokerage.stripeCustomerId) {
        customer = await createStripeCustomer(brokerage.id, paymentInfoAddress.email, billingAddress, paymentInfoAddress.cardholder)
      }


      if (!brokerage.stripeSubscriptionId) {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement)

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: paymentInfoAddress.cardholder,
            email: paymentInfoAddress.email,
            address: {
              city: paymentInfoAddress.city,
              line1: paymentInfoAddress.address,
              line2: paymentInfoAddress.address2,
              state: paymentInfoAddress.state
            }
          }
        })

        let usersCount
        if (error) {
          console.log('[error]', error);
        }
        else {
          const priceId = (billingCycle === 'yearly') ? process.env.REACT_APP_YEARLY_PRICEID : process.env.REACT_APP_MONTHLY_PRICEID
          usersCount = brokerage.teammates + stagedInvites.length
          const result = await createStripeSubscription(brokerage.id, customer.id, paymentMethod.id, priceId, usersCount)

          await sendInvitesToNewUsers(stagedInvites)
        }

      }
      else {

        // TO DO: Update an existing subscription
        
        return
      }


      toast.success(`Successfully upgraded your account with ${stagedInvites.length} new users.`)
      closeModal()
      setIsSubmitting(false)

      sessionStorage.removeItem('stagedInvites')
    }
    catch (e) {
      setIsSubmitting(false)
      toast.error(e)
    }
  }

  if(!brokerage) {
    return null
  }

  return (
    <div className='upgradeAccount'>
      <div className='upgradeAccount__stepper'>
        {steps.map((label, i) => {
          return (
            <div key={label} className='upgradeAccount__step'>
              <div className={activeStep === i ? 'upgradeAccount__step__index--active' : 'upgradeAccount__step__index'}>{i + 1}</div>
              <div className={activeStep === i ? 'upgradeAccount__step__label--active' : 'upgradeAccount__step__label'}>{label}</div>
            </div>
          )
        })}
      </div>
      <CardInput onChange={handleCardInputChange} display={activeStep === 1} />
      <div style={{ flexGrow: '1' }}>{getStepContent(activeStep)}</div>
      <FormModalFooterBidirectional
        isActive={isSubmitting ? false : (activeStep === 1 ? isActive : true)}
        onAdvanceClick={handleAdvanceClick}
        buttonAdvanceLabel={isSubmitting ? 'Submitting...' : (activeStep === 2 ? 'Submit' : 'Continue')}
        buttonPrevious={true}
        onPreviousClick={handlePreviousClick} />
    </div>
  )
}