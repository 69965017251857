import React from "react";
import PropTypes from "prop-types";
import FormAddWorkflowSelectButton from "./FormAddWorkflowSelectButton";

export default function FormAddWorkflowTutorialAddData({
  setActiveForm,
  stepCustomerForm
}) {
  const mainContainer = {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%"
  };

  const selectButtonContainer = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    flexGrow: "1"
  };

  const tipContainerStyle = {
    backgroundColor: "var(--lightBlue)",
    width: "90%",
    alignSelf: "center",
    marginTop: "30px",
    padding: "10px",
    borderRadius: "7px"
  };

  const paragraphStyle = {
    color: "var(--lavender)",
    fontWeight: "500",
    margin: "0"
  };

  const spanStyle = {
    color: "var(--darkBlue)",
    fontWeight: "700"
  };

  return (
    <div style={mainContainer}>
      <section style={tipContainerStyle}>
        <p style={paragraphStyle}>
          <span style={spanStyle}>
            Let's get started by adding a customer and a lane.
          </span>
        </p>
      </section>
      <div style={selectButtonContainer}>
        <FormAddWorkflowSelectButton
          icon={"Customer"}
          text={"Customer"}
          onClickEvent={() => setActiveForm(stepCustomerForm)}
          isSingleButton
        />
      </div>
    </div>
  );
}

FormAddWorkflowTutorialAddData.propTypes = {
  setActiveForm: PropTypes.func.isRequired,
  stepCustomerForm: PropTypes.object.isRequired
};
