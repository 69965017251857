import React from "react";
import PropTypes from "prop-types";
import DrawerToolbeltItem from "./DrawerToolbeltItem";
import TooltipInformation from "../library/tooltip/TooltipInformation";

export default function DrawerToolbeltItemOwnedVolume({ item, updateMethod }) {
  return (
    <DrawerToolbeltItem
      item={item}
      inputType={"number"}
      valueType={"loads per month"}
      updateMethod={updateMethod}
      nameOfValue="currentVolume"
      title={"Owned Volume"}
      label={"loads per month"}
      infoButton={
        <TooltipInformation style={{ marginLeft: "4px" }}>
          When you win volume on this lane, record the loads/mo here.
        </TooltipInformation>
      }
    />
  );
}

DrawerToolbeltItemOwnedVolume.propTypes = {
  item: PropTypes.object.isRequired,
  updateMethod: PropTypes.func.isRequired
};
