import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import "./ButtonSolid.css";

export default function ButtonSolid(props) {
  return (
    <button
      disabled={props.disabled}
      style={props.style}
      type={props.type}
      className={`buttonSolid__btn buttonSolid__btn--${props.color} ${
        props.disabled === true
          ? `buttonSolid__btn--${props.color}--disabled`
          : ""
      }
        ${props.isSubmitting === true ? `buttonSolid__btn--inActive` : ""}`}
      onClick={e => props.onClick(e)}
    >
      {props.isSubmitting ? (
        <CircularProgress
          color="inherit"
          style={{
            width: "14px",
            height: "14px"
          }}
        />
      ) : (
        props.children
      )}
    </button>
  );
}

ButtonSolid.propTypes = {
  props: PropTypes.shape({
    type: PropTypes.string,
    color: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    disabled: PropTypes.bool
  })
};
