import React from 'react'
import TagOption from '../TagOption'

export default function Equipment({ page, item}) {
    return (
        <>
            <div className='addTags__tagList'>
                <TagOption id={18} content={'Van'} page={page} item={item}/>
                <TagOption id={19} content={'Van - Air Ride'} page={page} item={item}/>
                <TagOption id={20} content={'Van - Vented'} page={page} item={item}/>
                <TagOption id={21} content={'Van With Curtains'} page={page} item={item}/>
                <TagOption id={22} content={'Reefer'} page={page} item={item}/>
                <TagOption id={23} content={'Flatbed'} page={page} item={item}/>
                <TagOption id={24} content={'Stepdeck'} page={page} item={item}/>
                <TagOption id={25} content={'Hotshot'} page={page} item={item}/>
                <TagOption id={26} content={'Double Drop'} page={page} item={item}/>
                <TagOption id={27} content={'Lowboy'} page={page} item={item}/>
                <TagOption id={28} content={'Maxi'} page={page} item={item}/>
                <TagOption id={29} content={'Hopper Bottom'} page={page} item={item}/>
                <TagOption id={30} content={'Tanker'} page={page} item={item}/>
                <TagOption id={31} content={'Power Only'} page={page} item={item}/>
                <TagOption id={32} content={'Box Truck'} page={page} item={item}/>
                <TagOption id={33} content={'Sprinter Van'} page={page} item={item}/>
                <TagOption id={34} content={'Auto Carrier'} page={page} item={item}/>
                <TagOption id={35} content={'Dump Trailer'} page={page} item={item}/>
            </div>
            <div className='addTags__subsection addTags__subsection--full'>
                <div className='addTags__subsection__title'>EQUIPMENT LENGTH</div>
                <div className='addTags__tagList'>
                    <TagOption id={36} content={"53'"} page={page} item={item} /> 
                    <TagOption id={37} content={"48'"} page={page} item={item} /> 
                    <TagOption id={38} content={"45'"} page={page} item={item} /> 
                    <TagOption id={39} content={"40'"} page={page} item={item} /> 
                    <TagOption id={40} content={"28'"} page={page} item={item} /> 
                    <TagOption id={41} content={"20'"} page={page} item={item} /> 
                </div>
            </div>
            <div className='addTags__subsection addTags__subsection--full'>
                <div className='addTags__subsection__title'>OTHER</div>
                <div className='addTags__tagList'>
                    <TagOption id={53} content={'Team Drivers'} page={page} item={item} /> 
                </div>
            </div>
        </>
    )
}