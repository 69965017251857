import React from "react";
import PropTypes from "prop-types";
import "./SettingsNavItem.css";

export default function SettingsNavItem(props) {
  return (
    <button
      className={`settingsNavItem__container ${
        props.selectedItem === props.name ? "settingsNavItem__selected" : ""
      }`}
      onClick={() => props.handleClick(props.name)}
    >
      {props.children}
    </button>
  );
}

SettingsNavItem.propTypes = {
  props: PropTypes.shape({
    name: PropTypes.string.isRequired,
    selectedItem: PropTypes.string.isRequired,
    handClick: PropTypes.func.isRequired
  })
};
