import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DrawerToolbeltStateEmpty from "./DrawerToolbeltStateEmpty";
import DrawerToolbeltStateEdit from "./DrawerToolbeltStateEdit";
import DrawerToolbeltStateAdded from "./DrawerToolbeltStateAdded";
import { useContext } from "react";
import { ApplicationStateContext } from "../providers/ApplicationStateProvider";

export default function DrawerToolbeltState({
  title,
  infoButton,
  valueType,
  valueIsCurrency,
  originalValue,
  inputValue,
  input,
  isEditing,
  setIsEditing,
  onSave
}) {
  const {
    isSubmittingToolbelt,
    editingToolbeltCount,
    setEditingToolbeltCount,
    triggerToolbeltSave,
    setTriggerToolbeltSave,
    setClickedToolbeltTitle,
    isToolbeltCancelButtonClicked,
    setIsToolbeltCancelButtonClicked
  } = useContext(ApplicationStateContext);
  const [activeState, setActiveState] = useState(null);

  useEffect(() => {
    function setEmptyAsActive() {
      setIsEditing(false);
      if (
        originalValue !== "" &&
        originalValue !== "NaN" &&
        originalValue !== "0"
      ) {
        setAddedAsActive();
      } else {
        setActiveState(
          <DrawerToolbeltStateEmpty
            title={title}
            infoButton={infoButton}
            onAdd={setEditAsActive}
          />
        );
      }
    }

    async function setEditAsActive(e) {
      let clickedToolbelt = null;

      if (e !== undefined) {
        const isEditButton = e.currentTarget.innerHTML.includes("Edit");

        // Grab toolbelt's title based on which button was pressed
        if (isEditButton === false) {
          // Then user clicked '+ Add'
          clickedToolbelt =
            e.currentTarget.parentElement.children[0].children[0].innerHTML;
        } else {
          clickedToolbelt =
            e.currentTarget.parentElement.children[0].children[0].children[1]
              .innerHTML;
        }
        setClickedToolbeltTitle(clickedToolbelt);
      }

      if (
        editingToolbeltCount > 0 &&
        clickedToolbelt &&
        !isToolbeltCancelButtonClicked
      ) {
        setTriggerToolbeltSave(true);
      } else if (isToolbeltCancelButtonClicked) {
        setEmptyAsActive();
      } else {
        setIsEditing(true);
        setActiveState(
          <DrawerToolbeltStateEdit
            title={title}
            infoButton={infoButton}
            originalValue={originalValue}
            inputValue={inputValue}
            input={input}
            isSubmitting={isSubmittingToolbelt}
            onSave={onSave}
            onCancel={setEmptyAsActive}
          />
        );
      }
      if (isToolbeltCancelButtonClicked === true) {
        setIsToolbeltCancelButtonClicked(false);
      }
    }

    function setAddedAsActive() {
      setActiveState(
        <DrawerToolbeltStateAdded
          title={title}
          infoButton={infoButton}
          valueType={valueType}
          valueIsCurrency={valueIsCurrency}
          originalValue={originalValue}
          onEdit={setEditAsActive}
        />
      );
    }

    if (isEditing) {
      setEditAsActive();
    } else {
      if (
        originalValue !== "" &&
        originalValue !== "NaN" &&
        originalValue !== "0"
      ) {
        setAddedAsActive();
      } else setEmptyAsActive();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputValue,
    originalValue,
    triggerToolbeltSave,
    isSubmittingToolbelt,
    isToolbeltCancelButtonClicked,
    editingToolbeltCount
  ]);

  useEffect(() => {
    if (editingToolbeltCount >= 0 && isEditing) {
      setEditingToolbeltCount(editingToolbeltCount + 1);
    } else if (editingToolbeltCount > 0 && !isEditing) {
      setEditingToolbeltCount(editingToolbeltCount - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    // Because of how edit is set in the first useEffect,
    // need this to run last to ensure we start at 0
    setEditingToolbeltCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{activeState}</div>;
}

DrawerToolbeltState.propTypes = {
  title: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  valueIsCurrency: PropTypes.bool,
  valueType: PropTypes.string,
  originalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  input: PropTypes.object,
  onSave: PropTypes.func.isRequired
};
