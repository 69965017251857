import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DrawerIdCard from "./DrawerIdCard";
import useProfileImage from "../../hooks/useProfileImage";
import { formatPhoneNumber } from "../generic/Utils";
import DeleteButton from "../generic/buttons/DeleteButton";

export default function DrawerTeammateTaggedListItem({
  teammate,
  isEditing,
  deleteTeammate
}) {
  const profileImage = useProfileImage(teammate);
  const history = useHistory();
  const [renderedTeammate, setRenderedTeammate] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function teammateCreator() {
      const idCard = {
        name: teammate.fullName,
        tags: [],
        subHeading: teammate.title,
        phone: formatPhoneNumber(teammate),
        showContactInfo: isEditing === true ? false : true,
        email: teammate.email,
        teamIcon: teammate?.Team?.icon,
        profileImage: profileImage !== null ? profileImage : "",
        profileType: "teammate",
        isBigButton: false,
        toolTipPosition: "left",
        onClickEvent:
          isEditing === true ? null : () => history.push(`/user/${teammate.id}`)
      };

      return <DrawerIdCard key={idCard.id} idCard={idCard} />;
    }

    setRenderedTeammate(teammateCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teammate]);

  if (renderedTeammate === null) {
    return null;
  }

  return (
    <MainContainer>
      {isEditing & isDeleting ? (
        <CircularProgress color="secondary" style={spinnerOverrides} />
      ) : (
        isEditing && (
          <DeleteButton
            onClick={async () => {
              setIsDeleting(true);
              await deleteTeammate(teammate);
              setIsDeleting(false);
            }}
          />
        )
      )}
      {renderedTeammate}
    </MainContainer>
  );
}

DrawerTeammateTaggedListItem.propTypes = {
  teammate: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  deleteTeammate: PropTypes.func.isRequired
};

const MainContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:first-child) {
    border-top: solid 1px var(--lightGray);
  }
`;

const spinnerOverrides = {
  width: "24px",
  height: "24px",
  marginRight: "5px"
};
