import React from "react";
import PropTypes from "prop-types";
import TooltipStructureBubble from "./TooltipStructureBubble";
import useComponentVisible from "../../../hooks/useComponentVisible";
// Unstyled button with open/close logic for bubble

export default function TooltipStructureButton(props) {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  return (
    <button
      type="button"
      className={`${props.variant} ${props.variant}--visible--${isComponentVisible}`}
      style={props.style}
      onClick={() => setIsComponentVisible(true)}
    >
      {props.icon} {props.buttonText}
      <TooltipStructureBubble
        ref={ref}
        isVisible={isComponentVisible}
        position={props.position}
        variant={props.variant}
      >
        {props.children}
      </TooltipStructureBubble>
    </button>
  );
}

TooltipStructureButton.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object,
    variant: PropTypes.string, // ClassName for :hover, :active effects
    position: PropTypes.string, // 'left', 'right'
    buttonText: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  })
};
