import React from 'react'

const SearchResultLaneParter = ({result, id}) => {
    return (
        <div className={`search__results__item`} id={id} onClick={() => console.log(result)}>
        <span className='search__results__emoji' role='img' aria-label='x'>📍</span>
        {result._source.city}, {result._source.state} - {result._source.name} 
        <span className='search__results__tag tag tag--purple'>Location</span>
        </div>
    )
}

export default SearchResultLaneParter