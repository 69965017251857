import React, { useContext, useState } from 'react'
import { TagContext } from '../../../providers/TagProvider'
import TagOption from '../TagOption'
import {numberWithCommas} from '../../../generic/Utils'

export default function Load({ page, item}) {
    const { addCustomTag } = useContext(TagContext)
    const [weight, setWeight] = useState(null)
    const [weightUnit, setWeightUnit] = useState(null)
    const [addWeight, setAddWeight] = useState(false)
    const [temp, setTemp] = useState(null)
    const [tempUnit, setTempUnit] = useState(null)
    const [addTemp, setAddTemp] = useState(false)
    const [amount, setAmount] = useState(null)
    const [addAmount, setAddAmount] = useState(false)


    const handleWeightClick = (selection) => {
        if (weight && weight > 0) {
            setWeightUnit(selection)
            setAddWeight(true)
        }
        else {
            setWeightUnit(null)
            setAddWeight(false)
        }
    }

    const handleWeightChange = (e) => {
        if(e.target.value > 0) {
            setWeight(e.target.value)
        }
        else {
            setWeight(null)
            setWeightUnit(null)
            setAddWeight(false)
        }
    }

    const handleAddWeight = () => {
        if(addWeight) {
            const newTag = {
                content: `${numberWithCommas(weight)} ${weightUnit}`,
                type: 'Load'
            }
            addCustomTag(page, item, newTag)
            setWeight(0)
            setWeightUnit(null)
            setAddWeight(false)
        }
    }

    const handleTempClick = (selection) => {
        if (temp) {
            setTempUnit(selection)
            setAddTemp(true)
        }
        else {
            setTempUnit(null)
            setAddWeight(false)
        }
    }

    const handleTempChange = (e) => {
        if(e.target.value) {
            setTemp(e.target.value)
        }
        else {
            setTempUnit(null)
            setAddTemp(false)
        }
    }

    const handleAddTemp = () => {
        if(addTemp) {
            const newTag = {
                content: `${numberWithCommas(temp)} °${tempUnit}`,
                type: 'Load'
            }
            addCustomTag(page, item, newTag)
            setTemp(0)
            setTempUnit(null)
            setAddTemp(false)
        }
    }

    const handleAmountChange = (e) => {
        if(e.target.value > 0) {
            setAmount(e.target.value)
            setAddAmount(true)
        }
        else {
            setAmount(null)
            setAddAmount(false)
        }
    }

    const handleAddAmount = () => {
        if(addAmount) {
            const newTag = {
                content: `$${numberWithCommas(amount)}`,
                type: 'Load'
            }
            addCustomTag(page, item, newTag)
            setAmount(0)
            setAddAmount(false)
        }
    }

    return (
        <div className='addTags__subsection__container'>
            <div className='addTags__subsection'>
                <div className='addTags__subsection__title'>WEIGHT</div>
                <div className='addTags__inputContainer'>
                    <div className='addTags__input'>
                        <input className='addTags__input__field' placeholder='Enter weight' type='number' onChange={handleWeightChange} value={weight} />
                        <div className={weightUnit === 'lbs' ? 'addTags__input__btn--selected click' : 'addTags__input__btn click'} onClick={() => handleWeightClick('lbs')}>LBS</div>
                        <div className={weightUnit === 'kg' ? 'addTags__input__btn--selected click' : 'addTags__input__btn click'} onClick={() => handleWeightClick('kg')}>KG</div>
                    </div>
                    <div className={`addTags__add click ${addWeight ? 'addTags__add--enabled' : 'addTags__add--disabled'}`} onClick={handleAddWeight}>+</div>
                </div>
            </div>
            <div className='addTags__subsection'>
                <div className='addTags__subsection__title'>TEMPERATURE</div>
                <div className='addTags__inputContainer'>
                    <div className='addTags__input'>
                        <input className='addTags__input__field' placeholder='Enter temp' type='number' onChange={handleTempChange} value={temp}/>
                        <div className={tempUnit === 'f' ? 'addTags__input__btn--selected click' : 'addTags__input__btn click'} onClick={() => handleTempClick('f')}>F</div>
                        <div className={tempUnit === 'c' ? 'addTags__input__btn--selected click' : 'addTags__input__btn click'} onClick={() => handleTempClick('c')}>C</div>
                    </div>
                    <div className={`addTags__add click ${addTemp ? 'addTags__add--enabled' : 'addTags__add--disabled'}`} onClick={handleAddTemp}>+</div>
                </div>
            </div>
            <div className='addTags__subsection'>
                <div className='addTags__subsection__title'>LOAD VALUE</div>
                <div className='addTags__inputContainer'>
                    <div className='addTags__input'>
                        <input className='addTags__input__field' placeholder='Enter amount' type='number' onChange={handleAmountChange} value={amount}/>
                        <div className='addTags__input__btn hidden'>$</div>
                        <div className={amount > 0 ? 'addTags__input__btn--selected' : 'addTags__input__btn '}>$</div>
                    </div>
                    <div className={`addTags__add click ${addAmount ? 'addTags__add--enabled' : 'addTags__add--disabled'}`} onClick={handleAddAmount}>+</div>
                </div>
            </div>
            <div className='addTags__subsection'>
                <div className='addTags__subsection__title'>LOAD SIZE</div>
                <div className='addTags__tagList'>
                    <TagOption id={51} content={'Full Load'} page={page} item={item} />
                    <TagOption id={52} content={'Partial Load'} page={page} item={item} />
                </div>
            </div>
            <div className='addTags__subsection'>
                <div className='addTags__subsection__title'>TARP</div>
                <div className='addTags__tagList'>
                    <TagOption id={15} content={"4' Tarp"} page={page} item={item} />
                    <TagOption id={16} content={"6' Tarp"} page={page} item={item} />
                    <TagOption id={17} content={"8' Tarp"} page={page} item={item} />
                </div>
            </div>
        </div>
    )
}