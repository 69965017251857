import React from "react";
import PropTypes from "prop-types";
import TooltipStructureButton from "./TooltipStructureButton";
import "./TooltipInformation.css";

export default function TooltipInformation(props) {
  return (
    <TooltipStructureButton
      variant={"tooltipInformation__btn"}
      style={props.style}
      icon={"i"}
    >
      {props.children}
    </TooltipStructureButton>
  );
}

TooltipInformation.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object
  })
};
