import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import useProfileImage from "../../hooks/useProfileImage";
import DrawerIdCard from "./DrawerIdCard";
import { formatPhoneNumber } from "../generic/Utils";

export default function TaggedTeammateSearchResult({ teammate, addTeammate }) {
  const [renderedTeammate, setRenderedTeammate] = useState(null);
  const profileImage = useProfileImage(teammate);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    function teammateCreator() {
      const idCard = {
        name: teammate.fullName,
        tags: [],
        subHeading: teammate.title,
        showContactInfo: false,
        phone: formatPhoneNumber(teammate),
        email: teammate.email,
        teamIcon: teammate?.Team?.icon,
        profileImage: profileImage !== null ? profileImage : "",
        profileType: "teammate",
        isBigButton: false,
        onClickEvent: null,
        toolTipPosition: "left"
      };

      return <DrawerIdCard key={idCard.id} idCard={idCard} />;
    }

    setRenderedTeammate(teammateCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teammate]);

  if (renderedTeammate === null) {
    return null;
  }

  return (
    <SearchItem
      className="taggedTeammates__searchItem"
      onClick={async () => {
        setIsAdding(true);
        await addTeammate(teammate);
        setIsAdding(false);
      }}
      disabled={isAdding}
    >
      {isAdding && (
        <CircularProgress
          color="secondary"
          style={{
            width: "35px",
            height: "35px",
            gridRow: "1",
            gridColumn: "1",
            placeSelf: "center"
          }}
        />
      )}
      <ResultContainer>{renderedTeammate}</ResultContainer>
    </SearchItem>
  );
}

TaggedTeammateSearchResult.propTypes = {
  teammate: PropTypes.object.isRequired,
  addTeammate: PropTypes.func.isRequired
};

const SearchItem = styled.button`
  display: grid;
  border-top: 1px solid #d2d5d5;
  padding: 0 10px;
  text-align: left;

  &:hover {
    background: #ebedf7;
    cursor: pointer;
  }

  &:disabled {
    color: var(--black);
    cursor: auto;
  }

  & > div > section {
    margin: 0;
    margin-top: 5px;
  }
`;

const ResultContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
`;
