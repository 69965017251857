import { useEffect, useState } from "react";

export default function useIsSubmitDisabled(initialValue, newValue) {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (typeof newValue === "number") {
      const parsedInitialValue = parseInt(initialValue.replace(/,/g, ""));
      if (parsedInitialValue === newValue) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }

    if (typeof newValue === "string") {
      if (
        initialValue === newValue ||
        newValue === undefined ||
        (initialValue === "0" && newValue === "") // for clearing the current rate
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [initialValue, newValue]);

  return isDisabled;
}
