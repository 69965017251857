import React from "react";
import { useHistory } from "react-router-dom";
import "./MapPopup.css";

export default function MapPopupLane({ properties }) {
  const history = useHistory();

  return (
    <div className="popup__content">
      <div
        className="popup__title click"
        onClick={() => history.push(`/lane/${properties.laneId}`)}
      >
        {properties.originCity}, {properties.originState} to{" "}
        {properties.destinationCity}, {properties.destinationState}
      </div>
      <div
        className={`tag ${
          properties.customerOrigin ? "tag--red" : "tag--grey"
        }`}
      >
        Origin
      </div>
      {properties.originName === "null" ? null : (
        <div
          className="popup__name"
          onClick={() =>
            history.push(`/location/${properties.originLocationId}`)
          }
        >
          {properties.originName}
        </div>
      )}
      <div>
        {properties.originCity}, {properties.originState}
      </div>
      <br />
      <div
        className={`tag ${
          properties.customerDestination ? "tag--red" : "tag--grey"
        }`}
      >
        Destination
      </div>
      <div>
        {properties.destinationCity}, {properties.destinationState}
      </div>
    </div>
  );
}
