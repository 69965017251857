import React from 'react'
import { AccountCircleOutlined } from '@material-ui/icons';
import ProfileImage from '../../../library/profile/ProfileImage';

export default function OtherMessage({ message, formatDate, profileImage }) {
    return (
        <div className='message'>
            <div className='message__top'>
                <div className='message__poster'>
                    <ProfileImage
                        size="small"
                        location="ledger"
                        type="teammate"
                        name={message.User.firstName}
                        image={profileImage}
                        icon={message.User?.Team?.icon}
                    />
                </div>
                <div className='message__bubble'>
                    <div className='message__text'>{message.content}</div>
                    <div className='message__info'>
                        <div className='message__name'>
                            {message.User.firstName} {message.User.lastName}
                        </div>
                        <div className='message__tag tag tag--grey--inverse'>{message.User.title}</div>
                    </div>
                </div>
            </div>
            <div className='message__bottom'>
                <div className='message__timestamp'>{formatDate(message.createdAt)}</div>
            </div>
        </div>
    )
}