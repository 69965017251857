import React, { useContext, useState } from 'react'
import EditButton from '../../generic/buttons/EditButton'
import { CustomerContext } from '../../providers/CustomerProvider'
import './CustomerDrawer.css'
import TeaxtareaAutosize from 'react-textarea-autosize'
import ButtonOutlined from "../../generic/buttons/ButtonOutlined";

export default function CustomerBio({customer}) {
    const { updateCustomer } = useContext(CustomerContext)
    const [edit, setEdit] = useState(false)
    const [editedText, setEditedText] = useState(customer.bio ? customer.bio : '')
    const [shiftPressed, setShiftPressed] = useState(false)

    const updateBio = (cust) => {
        cust.bio = editedText
        updateCustomer(cust)
        setEdit(false)
    }

    const handleCancel = () => {
        setEdit(false)
        setEditedText(customer.bio)
    }

    const keydownHandler = (e) => {
        if(e.keyCode === 16) {
            setShiftPressed(true)
        }
        else if(e.keyCode === 13 && !shiftPressed) {
            e.preventDefault()
            updateBio(customer)
        }
    }

    const keyupHandler = (e) => {
        if(e.keyCode === 16) {
            setShiftPressed(false)
        }
    }

    const renderContent = () => {
        if(edit) {
            return(
                <>
                    <TeaxtareaAutosize
                        className='customerBio__bio--edit'
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        onKeyDown={(e) => keydownHandler(e)}
                        onKeyUp={(e) => keyupHandler(e)}
                    />
                    <div className='customerBio__subtext'>Shift + Return for new line. Enter to save.</div>
                </>
            )
        }
        else {
            return(
                customer.bio
                    ? <div className='customerBio__bio'>{customer.bio}</div>
                    : <ButtonOutlined text='Add Company Bio' onClick={() => setEdit(true)} isAdding/>
            )
        }
    }

    return (
        <div className='customerBio'>
            <div className='titleContainer'>
                <div className='title'>Customer Bio</div>
                {edit
                    ? <div className='btnContainer'>
                        <div onClick={handleCancel} className='btn__cancel'>Cancel</div>
                        <div onClick={() => updateBio(customer)} className='btn__save'>Save</div>
                      </div>
                    : <div className='customerBio__edit' onClick={() => setEdit(!edit)}><EditButton/></div>
                }
            </div>
            {renderContent()}
        </div>
    )
}