import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import DrawerTeammateTaggedSearchResult from "./DrawerTeammateTaggedSearchResult";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SearchContext } from "../providers/SearchProvider";
import { TeammateContext } from "../providers/TeammateProvider";

export default function DrawerTeammateTaggedSearch({
  page,
  query,
  setQuery,
  customer,
  customerName,
  location,
  locationName,
  lane,
  laneName
}) {
  const { searchUsersInBrokerage } = useContext(SearchContext);
  const {
    addTaggedTeammateToCustomer,
    addTaggedTeammateToLocation,
    addTaggedTeammateToLane
  } = useContext(TeammateContext);

  const [searchResults, setSearchResults] = useState([]);

  async function addTeammate(teammate) {
    switch (page) {
      case "customer":
        const taggedTeammateCustomer = {
          customerId: customer.id,
          userId: teammate.id
        };
        await addTaggedTeammateToCustomer(
          taggedTeammateCustomer,
          teammate.fullName,
          customerName
        );
        break;
      case "location":
        const taggedTeammateLocation = {
          locationId: location.id,
          userId: teammate.id
        };
        await addTaggedTeammateToLocation(
          taggedTeammateLocation,
          teammate.fullName,
          locationName
        );
        break;
      case "lane":
        const taggedTeammateLane = {
          laneId: lane.id,
          userId: teammate.id
        };
        await addTaggedTeammateToLane(
          taggedTeammateLane,
          teammate.fullName,
          laneName
        );
        break;
      default:
        console.error("no page entered into tagged teammate list");
        break;
    }
    setQuery("");
  }

  useEffect(() => {
    async function handleTeammateSearch() {
      const res = await searchUsersInBrokerage(query);
      if (res !== null) {
        setSearchResults(res);
      }
    }

    if (query !== "") {
      handleTeammateSearch();
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <MainContainer>
      <TextField
        variant="outlined"
        autoComplete="new-password"
        value={query}
        onChange={e => setQuery(e.target.value)}
        style={{ flexBasis: "100%" }}
        placeholder="Search for Teammates"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ color: "#979797" }}>
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      {query !== "" ? (
        <SearchResultsContainer>
          {searchResults.map(r => (
            <DrawerTeammateTaggedSearchResult
              key={r.id}
              teammate={r}
              addTeammate={addTeammate}
            />
          ))}
        </SearchResultsContainer>
      ) : null}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  position: relative;
`;

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: fit-content;
  position: absolute;
  top: 40px;
  width: 99.5%;
  border: 1px solid #d2d5d5;
  box-shadow: 7px 7px 10px rgba(215, 215, 221, 0.3);
  background: #ffffff;
  z-index: 1;
`;
