import React from "react";
import PropTypes from "prop-types";
import MapContainer from "../map/MapContainer";
import DrawerContainer from "../drawers/DrawerContainer";
// Layout template for views requiring a Map & Drawer

export default function MapDrawerLayout({
  mapLanes,
  isMapLoading,
  isDrawerLoading,
  drawerContent
}) {
  return (
    <main>
      <MapContainer lanes={mapLanes} isLoading={isMapLoading} />
      <DrawerContainer
        drawerContent={drawerContent}
        isMapLoading={isMapLoading}
        isLoading={isDrawerLoading}
      />
    </main>
  );
}

MapDrawerLayout.propTypes = {
  mapLanes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isMapLoading: PropTypes.bool.isRequired,
  drawerContent: PropTypes.object.isRequired, // Is a component like <CurrentUserViewDrawerContent />
  isDrawerLoading: PropTypes.bool.isRequired
};
