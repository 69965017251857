import React from "react";
import AddIcon from "@material-ui/icons/Add";
import "./HeaderButtons.css";

export default function HeaderButtonAdd({ setIsAddOpen }) {
  return (
    <button
      className="headerButtonAdd__button"
      onClick={() => setIsAddOpen(true)}
    >
      <AddIcon style={{ fill: "white", width: "30px", height: "30px" }} />
      <div className="headerButtonAdd__button__text">Add Data</div>
    </button>
  );
}
