import React from "react";
import PropTypes from "prop-types";
import MailIcon from "@material-ui/icons/Mail";
import TooltipStructureButton from "./TooltipStructureButton";
import TooltipStructureCopy from "./TooltipStructureCopy";
import "./Tooltip.css";

export default function TooltipEmail(props) {
  TooltipEmail.defaultProps = {
    variant: "tooltip__smallCircle"
  };

  const iconStyle = {
    fontSize: "17px",
    color: "#919191"
  };

  return (
    <TooltipStructureButton
      variant={props.variant}
      style={props.style}
      position={props.position}
      icon={<MailIcon style={iconStyle} />}
      buttonText={props.name ? `Email ${props.name}` : null}
    >
      <span className="tooltip__emoji" role="img" aria-label="mail">
        📨
      </span>
      {!props.children ? (
        <p>No email entered</p>
      ) : (
        <>
          <a style={{ color: "white" }} href={`mailto:${props.children}`}>
            {props.children}
          </a>
          <TooltipStructureCopy content={props.children} />
        </>
      )}
    </TooltipStructureButton>
  );
}

TooltipEmail.propTypes = {
  props: PropTypes.shape({
    style: PropTypes.object,
    position: PropTypes.string, // 'left', 'right'
    name: PropTypes.string,
    variant: PropTypes.string.isRequired
    // Possible variants:
    // tooltip__smallCircle
    // tooltip__blueOutline
  })
};
