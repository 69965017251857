import React from "react";
import PropTypes from "prop-types";
import "./PopoutItem.css";

export default function PopoutItem(props) {
  return (
    <div className="popoutButton__button" onClick={() => props.method()}>
      {props.title}
    </div>
  );
}

PopoutItem.propTypes = {
  props: PropTypes.shape({
    title: PropTypes.string.isRequired,
    method: PropTypes.func.isRequired
  })
};
