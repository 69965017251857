import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LaneContext } from "../providers/LaneProvider";
import DrawerSectionHeader from "./DrawerSectionHeader";
import DrawerCarriersList from "./DrawerCarriersList";
import DrawerCarriersForm from "./DrawerCarriersForm";

export default function DrawerCarriers({ lane }) {
  const { getCarriersForLane, refreshOnCarrierChange } = useContext(
    LaneContext
  );
  const [carriers, setCarriers] = useState([]);
  const [carrierToEdit, setCarrierToEdit] = useState(null);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  useEffect(() => {
    async function getCarrierData() {
      const response = await getCarriersForLane(lane.id);
      if (response !== null) {
        setCarriers(response);
      }
    }

    getCarrierData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lane.id, refreshOnCarrierChange]);

  return (
    <>
      <section>
        <DrawerSectionHeader
          hasUnderline
          isEditing={isEditActive}
          setIsEditing={carriers.length === 0 ? null : setIsEditActive}
          setIsAdding={setIsFormModalOpen}
        >
          My Favorite Carriers
        </DrawerSectionHeader>
        <DrawerCarriersList
          carriers={carriers}
          isEditActive={isEditActive}
          setIsFormModalOpen={setIsFormModalOpen}
          setCarrierToEdit={setCarrierToEdit}
        />
      </section>
      <DrawerCarriersForm
        laneId={lane.id}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        setIsEditActive={setIsEditActive}
        carrierToEdit={carrierToEdit}
        setCarrierToEdit={setCarrierToEdit}
      />
    </>
  );
}

DrawerCarriers.propTypes = {
  lane: PropTypes.object.isRequired
};
