import React from "react";
import PropTypes from "prop-types";
import ButtonSolid from "../generic/buttons/ButtonSolid";

export default function FormAddFooter({
  onClickEvent,
  isActive,
  isSubmit,
  isSubmitting
}) {
  const footerStyle = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "4em",
    backgroundColor: "var(--lightGray)"
  };

  return (
    <footer style={footerStyle}>
      <ButtonSolid
        style={{ marginRight: "25px", height: "3em", padding: "0px 30px" }}
        color="red"
        disabled={!isActive}
        isSubmitting={isSubmitting}
        onClick={e => onClickEvent(e)}
      >
        {isSubmit ? "Submit" : "Continue"}
      </ButtonSolid>
    </footer>
  );
}

FormAddFooter.propTypes = {
  onClickEvent: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool
};
