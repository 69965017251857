import React, { useEffect, useState } from "react";
import { formatPhoneNumber, numberWithCommas } from "../generic/Utils";
import { useHistory } from "react-router-dom";
import DrawerIdCard from "./DrawerIdCard";
import useProfileImage from "../../hooks/useProfileImage";

export default function DrawerCustomerTopListItem({ customer }) {
  const profileImage = useProfileImage(customer);
  const history = useHistory();
  const [renderedCustomer, setRenderedCustomer] = useState(null);

  useEffect(() => {
    function customerCreator() {
      const idCard = {
        name: customer.displayName || customer.name,
        tags: [
          <div key={1} className="tag tag--green">
            💰 ${numberWithCommas(customer.spend)}/mo
          </div>,
          <div key={2} style={{ marginLeft: "5px" }} className="tag tag--grey">
            🗓 {customer.loadsPerMonth}/mo
          </div>
        ],
        subHeading: customer.title,
        showContactInfo: true,
        phone: customer.phone === null ? null : formatPhoneNumber(customer),
        email: customer.email,
        teamIcon: "",
        profileImage: profileImage,
        profileType: "customer",
        onClickEvent: () => history.push(`/customer/${customer.id}`),
        isBigButton: false,
        toolTipPosition: "left"
      };

      return <DrawerIdCard key={idCard.id} idCard={idCard} />;
    }

    setRenderedCustomer(customerCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  if (renderedCustomer === null) {
    return null;
  }

  return renderedCustomer;
}
