import React from "react";

export default function IconLocation({ size }) {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      version="1.1"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Location Icon</title>
      <path
        d="m11.985 24.541 1.25e-4 1.14e-4 0.34352 0.25799 0.17104 0.12842 0.17105-0.12842 0.34352-0.25799h1.14e-4l4.56e-4 -3.42e-4 0.0015-0.0011 0.0055-0.0042 0.02096-0.01584c0.01823-0.0139 0.04512-0.03441 0.07988-0.06142 0.06962-0.0539 0.17127-0.13355 0.30038-0.23725 0.2581-0.20728 0.62605-0.51062 1.0672-0.89646 0.88176-0.77123 2.0582-1.8736 3.2353-3.1979 2.3409-2.6335 4.8017-6.2201 4.8017-10.026 0-5.5312-4.4962-10.028-10.028-10.028-5.5312 0-10.028 4.4963-10.028 10.028 0 3.8059 2.4608 7.3924 4.8017 10.026 1.1772 1.3244 2.3536 2.4267 3.2354 3.1979 0.44111 0.38584 0.80901 0.68918 1.0671 0.89646 0.12907 0.1037 0.23071 0.18335 0.30037 0.23725 0.03482 0.02701 0.06166 0.04752 0.07993 0.06142l0.02091 0.01584 0.0055 0.0042 0.0015 0.0011 4.33e-4 2.28e-4zm0.51469-22.752c4.5972 0 8.3117 3.7144 8.3117 8.3117 0 3.0713-2.1242 6.362-4.3679 8.8862-1.7599 1.9799-3.3211 3.2714-3.9438 3.7642-0.62256-0.49273-2.1839-1.7843-3.9438-3.7642-2.2437-2.5242-4.3679-5.8149-4.3679-8.8862 0-4.5972 3.7144-8.3117 8.3115-8.3117zm0 2.8689c-2.9992 0-5.4428 2.4436-5.4428 5.4428 0 2.9991 2.4436 5.4427 5.4428 5.4427 2.9992 0 5.4428-2.4436 5.4428-5.4427 0-2.9992-2.4436-5.4428-5.4428-5.4428zm0 1.716c2.065 0 3.7268 1.6618 3.7268 3.7268 0 2.065-1.6618 3.7268-3.7268 3.7268s-3.7268-1.6618-3.7268-3.7268c0-2.065 1.6618-3.7268 3.7268-3.7268z"
        fill="#fff"
      />
    </svg>
  );
}
