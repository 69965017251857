import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Accessorials from './sections/Accessorials';
import Equipment from './sections/Equipment';
import Location from './sections/Location';
import Load from './sections/Load';
import Commodity from './sections/Commodity';
import CustomTag from './sections/CustomTag';

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      padding: '15px',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {
        backgroundColor: '#ffffff'
    },
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap'
    },
  }))(MuiAccordionDetails);
  

export default function AddQuickTags({ page, item, handleClose }) {
    const [expanded, setExpanded] = useState(false)

    const handleChange = (int) => (e, newExpanded) => {
        setExpanded(newExpanded ? int : false)
    }

    return (
        <div className='dialog addTags'>
            <div className='titleContainer addTags__title'>
                <div className='title dialog__title'>Add Quick Tags</div>
                <div className='dialog__btns'>
                    <div className='cancel click dialog__btn' onClick={handleClose}>Close</div>
                </div>
            </div>
            <div className='dialog__list addTags__list'>
                <Accordion className='addTags__section' expanded={expanded === 1} onChange={handleChange(1)}>
                    <AccordionSummary className={expanded === 1 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>👀 Accessorials</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accessorials page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='addTags__section' expanded={expanded === 2} onChange={handleChange(2)}>
                    <AccordionSummary className={expanded === 2 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>🚚 Equipment</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Equipment page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='addTags__section' expanded={expanded === 3} onChange={handleChange(3)}>
                    <AccordionSummary className={expanded === 3 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>📍 Location</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Location page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='addTags__section' expanded={expanded === 4} onChange={handleChange(4)}>
                    <AccordionSummary className={expanded === 4 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>⚖️ Load</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Load page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='addTags__section' expanded={expanded === 5} onChange={handleChange(5)}>
                    <AccordionSummary className={expanded === 5 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>📦 Commodity</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Commodity page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='addTags__section' expanded={expanded === 6} onChange={handleChange(6)}>
                    <AccordionSummary className={expanded === 6 ? 'addTags__section--selected' : ''}>
                        <div className='addTags__section__header'>Custom</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomTag page={page} item={item}/>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}