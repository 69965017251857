import React from "react";
import styled from "styled-components";

export default function SettingsContentUploadTable() {
  return (
    <Table>
      <thead>
        <tr>
          <RequiredHeader>Customer Name</RequiredHeader>
          <RequiredHeader>Origin City</RequiredHeader>
          <RequiredHeader>Origin State</RequiredHeader>
          <RequiredHeader>Destination City</RequiredHeader>
          <RequiredHeader>Destination State</RequiredHeader>
          <RequiredHeader>Truck Type*</RequiredHeader>
          <OptionalHeader>Customer Rate</OptionalHeader>
          <OptionalHeader>Total Volume / mo</OptionalHeader>
          <OptionalHeader>Owned Volume / mo</OptionalHeader>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Legend>
            <LegendRow>
              <svg width="20" height="20">
                <rect
                  width="20"
                  height="20"
                  style={{ fill: "var(--darkRed)" }}
                />
              </svg>
              <LegendText>Highlighted fields are required</LegendText>
            </LegendRow>
            <LegendRow style={{ marginTop: "1em" }}>
              <LegendAsterisk>*</LegendAsterisk>
              <LegendText>
                Truck Type values must be a single letter: <br /> V, F, or R
              </LegendText>
            </LegendRow>
          </Legend>
          <BlankCell />
          <BlankCell />
          <BlankCell />
        </tr>
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  margin: 1em;
  margin-left: 7.25em;
  font-size: 0.75em;
  border-spacing: 0;
  border: 1px solid var(--lightLavender);
  width: 70%;
`;

const RequiredHeader = styled.th`
  text-align: left;
  padding: 0.25em 0.75em;
  background-color: var(--darkRed);
  color: white;
  border: 2px solid var(--lightLavender);
`;

const OptionalHeader = styled.th`
  text-align: left;
  padding: 0.25em 0.75em;
  color: var(--black);
  background-color: transparent;
  border: 2px solid var(--lightLavender);
`;

const Legend = styled.th.attrs({
  colSpan: 6
})`
  text-align: left;
  color: var(--black);
  border: solid 2px var(--lightLavender);
  padding: 3em;
`;

const LegendRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const LegendText = styled.span`
  margin-left: 1em;
  font-size: 1.25em;
`;

const LegendAsterisk = styled.span`
  height: 20px;
  width: 20px;
  font-size: 3em;
  place-self: self-start;
`;

const BlankCell = styled.th`
  border: solid 2px var(--lightLavender);
  padding: 3em;
`;
