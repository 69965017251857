import React from "react";

export default function IconLane({ size }) {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      version="1.1"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Lane Icon</title>
      <path
        d="m11.154 17.304v-1.5374c0-0.63678-0.51623-1.153-1.153-1.153-0.6368 0-1.153 0.51619-1.153 1.153v1.5374c0 0.63678 0.51623 1.153 1.153 1.153 0.6368 0 1.153-0.51619 1.153-1.153zm0-6.9179v-1.5374c0-0.6368-0.51623-1.153-1.153-1.153-0.6368 0-1.153 0.51623-1.153 1.153v1.5374c0 0.6368 0.51623 1.153 1.153 1.153 0.6368 0 1.153-0.51623 1.153-1.153zm0-6.9154v-1.5374c0-0.6368-0.51623-1.153-1.153-1.153-0.6368 0-1.153 0.51623-1.153 1.153v1.5374c0 0.6368 0.51623 1.153 1.153 1.153 0.6368 0 1.153-0.51623 1.153-1.153zm-6.9192 15.369v-17.68c0-0.6368-0.51623-1.153-1.153-1.153s-1.153 0.51623-1.153 1.153v17.68c0 0.63678 0.51623 1.153 1.153 1.153s1.153-0.51619 1.153-1.153zm13.836 0v-17.68c0-0.6368-0.51619-1.153-1.153-1.153s-1.1531 0.51623-1.1531 1.153v17.68c0 0.63678 0.51632 1.153 1.1531 1.153s1.153-0.51619 1.153-1.153z"
        fill="#fff"
      />
    </svg>
  );
}
