import React, { useContext, useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { Popover } from '@material-ui/core'
import { UserContext } from '../../providers/UserProvider'

export default function ManageUser({ user, refresh }) {
    const { promoteUserToAdmin, demoteUserFromAdmin, changeActiveStatus } = useContext(UserContext)
    const [profileImage, setProfileImage] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    useEffect(() => {
        if (user.profileImage) {
            (async () => {
                const image = await Storage.get(user.profileImage)
                setProfileImage(image)
            })()
        }
    }, [user])

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    async function handleMakeAdminClick() {
        setAnchorEl(null)
        await promoteUserToAdmin(user)
        refresh()
    }

    async function handleRemoveAdminClick() {
        setAnchorEl(null)
        await demoteUserFromAdmin(user)
        refresh()
    }

    async function handleDeactivateUserClick() {
        setAnchorEl(null)
        await changeActiveStatus(user, false)
        refresh()
    }

    async function handleReactivateUserClick() {
        setAnchorEl(null)
        await changeActiveStatus(user, true)
        refresh()
    }

    return (
        <>
        <div className={user.active ? 'settings__user__trow' : 'settings__user__trow--deactivated'}>
            <div className='settings__user__td settings__user__info'>
                {profileImage
                    ? <img className='settings__user__img' src={profileImage} />
                    : <div className='settings__user__img stamp stamp--blue'>{user.firstName?.charAt(0)}</div>
                }
                <div>
                    <div className='settings__user__name'>
                        {user.firstName} {user.lastName}
                        {user.admin &&
                            <span className='tag tag--purple--dark settings__user__tag'>Admin</span>
                        }
                    </div>
                    <div className='settings__user__email'>{user.email}</div>
                </div>
            </div>
            {
                // <div className='settings__user__td'>{user.Team?.name}</div>
            }
            <div className='settings__user__td settings__user__status'>
                {user.active
                    ? <div>Activated</div>
                    : <div>Deactivated</div>
                }
                <div className='click settings__user__menu' onClick={handleClick}>...</div>
            </div>
        </div>
        <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
                <div className='settings__user__popover'>
                    {user.admin 
                        ? <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleRemoveAdminClick}>Remove Admin</div>
                        : <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleMakeAdminClick}>Make Admin</div>
                    }
                    {user.active 
                        ? <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleDeactivateUserClick}>Deactivate User</div>
                        : <div className='settings__user__popover__btn popover__btn--purple click' onClick={handleReactivateUserClick}>Reactivate User</div>
                    }
                    <div className='settings__user__popover__btn popover__btn--red click'>Delete User</div>
                </div>
        </Popover>
        </>
    )
}